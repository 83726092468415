import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getStaticPageDetail } from "../../../store/actions/adminActions/staticPages";
import Loader from "../../Alert/Loader";

const StaticPage = () => {
  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(async () => {
    const response = await dispatch(getStaticPageDetail(id));
    if (response.success) {
      setData(response.data);
    } else {
      setData(false);
    }
  }, [id]);
  var parser = new DOMParser();
  return (
    <div>
      {console.log(data)}
      {data === null ? (
        <Loader active={true} />
      ) : data === false ? (
        <h1>Page Not Found</h1>
      ) : (
        <>
          <div class="title-box">
            <Link
              to={`/admin/static-page/edit/${id}`}
              className="btn btn-danger btn-lg float-left"
            >
              Edit
            </Link>
            <h4>{data.name}</h4>
          </div>
          <div dangerouslySetInnerHTML={{ __html: data.page_description }}>
            {/* <p id="output"></p>
            {
              parser
                .parseFromString(data.page_description, "text/html")
                .querySelector("body").innerHTML
            } */}
          </div>
        </>
      )}
    </div>
  );
};

export default StaticPage;
