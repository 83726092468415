import React, { useState } from "react";
import BreadCrumb from "../../BreadCrumb";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changePassword } from "../../../store/actions/studentAction/studentAuth";
import { useHistory } from "react-router-dom";

const UpdatePassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const breadcrumbs = [
    {
      path: "/student/dashboard",
      label: "Dashboard",
    },
    {
      // path: "/aboutus",
      label: "Update Password",
    },
  ];
  const [error, setError] = useState("");
  const [data, setData] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  const { old_password, new_password, confirm_password } = data;
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
    setError("");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (new_password != confirm_password) {
      setError("Password doesn't match");
      return;
    } else if (new_password.length < 6) {
      setError("Password should be of atleast 6 characters");
      return;
    }
    const response = await dispatch(changePassword(data));
    if (response) {
      history.push("/student/dashboard");
    }
  };
  return (
    <>
      {" "}
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
          <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <BreadCrumb crumbs={breadcrumbs} />

            <div class="d-flex align-items-center">
              <Link
                to="/student/dashboard"
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                Dashboard
              </Link>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
              <form onSubmit={handleSubmit}>
                <div class="card-body row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>
                        Old Password <span class="text-danger">*</span>
                      </label>
                      <input
                        type="password"
                        name="old_password"
                        value={old_password}
                        onChange={handleChange}
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>
                        New Password <span class="text-danger">*</span>
                      </label>
                      <input
                        type="password"
                        name="new_password"
                        value={new_password}
                        onChange={handleChange}
                        class="form-control"
                        required
                      />
                      <span class="invalid-feedback d-block text-center">
                        {error}
                      </span>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>
                        Confirm Password <span class="text-danger">*</span>
                      </label>
                      <input
                        type="password"
                        name="confirm_password"
                        value={confirm_password}
                        onChange={handleChange}
                        class="form-control"
                        required
                      />
                      <span class="invalid-feedback d-block text-center">
                        {error}
                      </span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <button class="btn btn-success" type="submit">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdatePassword;
