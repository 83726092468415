import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner, Table } from "react-bootstrap";
import { rtis, rtiTitle } from "../../store/actions/councilActions/rtiActions";
import StateCitySelector from "../misc/StateCitySelector";
import Pagination from "../misc/Pagination";

const RTI = () => {
  const dispatch = useDispatch();
  const [rtiType, setRtiType] = useState("");
  const [title, setTitle] = useState("");
  const [state, setState] = useState("");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [activePage, setActivePage] = useState("1");
  const [rtiTitles, setRtititles] = useState(null);
  const fetchdata = async () => {
    setLoading(true);
    const response = await dispatch(rtis(rtiType, title, activePage));
    if (response) {
      setTotalPages(Math.ceil(response.total / response.per_page));
      setData(response.data);
      setLoading(false);
    } else {
      setData([]);
      setLoading(false);
    }
  };
  const fetchTitle = async () => {
    const response = await dispatch(rtiTitle(1));
    if (response) {
      setRtititles(response);
      setLoading(false);
    } else {
      setData([]);
      setLoading(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    fetchdata();
  };

  useEffect(() => {
    if (data) {
      fetchdata();
    }
  }, [activePage]);

  useEffect(() => {
    if (!rtiTitles) {
      fetchTitle();
    }
  }, []);
  return (
    <div>
      <div className="topper-student">
        <div class="container" style={{ borderColor: "#3d3f9" }}>
          <div class="page_Tile" style={{ background: "#3d3f9" }}>
            <span> RTI</span>
          </div>
          <div class="row">
            <div class="serach-form">
              <div class="form-list">
                <div
                  class="listSS"
                  value={rtiType}
                  onChange={(e) => {
                    setRtiType(e.target.value);
                    setTitle("");
                    setData(null);
                  }}
                >
                  <label class={`year_srch ${rtiType == 1 && "active"} `}>
                    <input type="radio" name="srch" value={1} />{" "}
                    <span>Ministry</span>
                  </label>
                  <label class={`center_srch ${rtiType == 2 && "active"}`}>
                    <input type="radio" name="srch" value={2} />{" "}
                    <span>Department</span>
                  </label>
                </div>
                <div class="box_list_main">
                  {rtiType == 1 && (
                    <div class="srchbyyear" style={{ display: "block" }}>
                      <form class="selectbox" onSubmit={handleSubmit}>
                        <div className="col-md-6">
                          <div class="boxlabel">
                            <label>Title</label>
                            <select
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                              className="form-control"
                            >
                              <option value="" selected>
                                --Select--
                              </option>
                              {!rtiTitles ? (
                                <option disabled>Loading...</option>
                              ) : (
                                rtiTitles.map((title, index) => (
                                  <option key={index} value={title.title}>
                                    {title.title}
                                  </option>
                                ))
                              )}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div
                            class="text-center"
                            style={{ marginTop: "25px" }}
                          >
                            <button type="submit" class="btn btn-reset">
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                  {rtiType == 2 && (
                    <div class="srchbycenter mb-4" style={{ display: "block" }}>
                      <form class="selectbox" onSubmit={handleSubmit}>
                        <StateCitySelector
                          user_city={title}
                          user_state={state}
                          set_user_state={(e) => setState(e)}
                          set_user_city={(e) => setTitle(e)}
                        />
                        <div class="col-md-12">
                          <button
                            type="submit"
                            class="btn btn-reset d-block mx-auto"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
                {loading ? (
                  <div className="text-center">
                    <Spinner animation="border" role="status" size="lg">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                ) : data === null ? null : rtiType == 1 ? (
                  <>
                    <Table bordered hover striped responsive className="mb-0">
                      <thead>
                        <th>S No</th>
                        <th>Title</th>
                        <th>Department</th>
                        <th>Reference Number</th>
                        <th>Posted Date</th>
                        <th>File</th>
                      </thead>
                      <tbody>
                        {data.length == 0 ? (
                          <tr>
                            <td colSpan={6} className="text-center text-danger">
                              No Record Found
                            </td>
                          </tr>
                        ) : (
                          data.map((element, index) => (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{element.title}</td>
                              <td>{element.department}</td>
                              <td>{element.reference_number}</td>
                              <td>{element.posted_date}</td>
                              <td>
                                <a
                                  href={element.file}
                                  download
                                  target={"_blank"}
                                >
                                  Download
                                </a>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </Table>
                  </>
                ) : (
                  <>
                    <Table bordered hover striped responsive className="mb-0">
                      <thead>
                        <th>S No</th>
                        <th>Title</th>
                        <th>Department</th>
                        <th>Reference Number</th>
                        <th>Posted Date</th>
                        <th>State</th>
                        <th>City</th>
                        <th>File</th>
                      </thead>
                      <tbody>
                        {data.length == 0 ? (
                          <tr>
                            <td colSpan={8} className="text-center text-danger">
                              No Record Found
                            </td>
                          </tr>
                        ) : (
                          data.map((element, index) => (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{element.title}</td>
                              <td>{element.department}</td>
                              <td>{element.reference_number}</td>
                              <td>{element.posted_date}</td>
                              <td>{element.state}</td>
                              <td>{element.city}</td>
                              <td>
                                <a
                                  href={element.file}
                                  download
                                  target={"_blank"}
                                >
                                  Download
                                </a>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </Table>
                  </>
                )}
                <>{console.log(typeof totalPages)}</>
                <div>
                  {totalPages && Number(totalPages) !== 0 ? (
                    <Pagination
                      setActivePage={(value) => setActivePage(value)}
                      totalPages={totalPages}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RTI;
