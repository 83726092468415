import { getDataAPI } from "../../../services/apiWrapper";
import { errorAlert } from "../../../utils/alert";

export const getStudentFees = (setLoading,setExist,setData) => async (dispatch) => {
  try {
    const token = localStorage.getItem("studentToken");
    const response = await dispatch(
      getDataAPI("student/fees-status", token)
    );
    console.log(response.data.data)
 if(response.data.data && response.data.data !=="null"){
  setExist(true)
  setData(response.data.data)
  setLoading(false)
 }
 else {
  setExist(false)
  setData(null)
  setLoading(false)
  console.log("else")
  errorAlert(response.data.message)
 }
  } catch (error) {
    setExist(false)
  setData(null)
  setLoading(false)
  // errorAlert("Somethinf")
  }
};