import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCourseList } from "../../store/actions/councilActions/courseAction";
import { getTrades } from "../../store/actions/councilActions/trade";
import { contactUs } from "../../store/actions/homeActions/homeActions";
import StateCitySelector from "../misc/StateCitySelector";
import BreadCrumb from "./../BreadCrumb";
import Offices from "./Offices";

export default function ContactUs() {
  const breadcrumbs = [
    {
      path: "/",
      label: "Home",
    },
    {
      // path: "/aboutus",
      label: "Contact Us",
    },
  ];
  const initialState = {
    name: "",
    mobile: "",
    email: "",
    enquiry_type: "1",
    address: "",
    message: "",
    city: "",
    state: "",
  };
  const [data, setData] = useState(initialState);
  const { name, mobile, email, enquiry_type, address, message, state, city } =
    data;
  const [trade, setTrade] = useState("");
  const [course, setCourse] = useState("");
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const dispatch = useDispatch();
  const { trades, courses } = useSelector((state) => state.admin);
  useEffect(() => {
    if (!trades) {
      dispatch(getTrades());
    }
  }, []);
  useEffect(() => {
    if (!courses) {
      dispatch(getCourseList());
    }
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const reqbody = {
      ...data,
    };
    enquiry_type == "1" ? (reqbody.trade = trade) : (reqbody.course = course);
    const response = await dispatch(contactUs(reqbody));
    if (response) {
      setData(initialState);
      setTrade("");
      setCourse("");
    }
  };
  return (
    <>
      <div class="lists-box ">
        <div class="container">
          <BreadCrumb crumbs={breadcrumbs} />
          <div class="title-box">
            <h4>Contact Us</h4>
          </div>
          <div class="row">
            <div class="col-md-6 mb-4">
              <div class="card">
                <div class="myForm">
                  <form onSubmit={handleSubmit}>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="boxlabel">
                              <p> Name</p>
                              <input
                                type="text"
                                name="name"
                                value={name}
                                onChange={handleChange}
                                class="form-control"
                                required
                              />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="boxlabel">
                              <p>Contact</p>
                              <input
                                type="text"
                                name="mobile"
                                value={mobile}
                                onChange={handleChange}
                                class="form-control"
                                required
                              />
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="boxlabel">
                              <p> Email</p>
                              <input
                                type="email"
                                name="email"
                                class="form-control"
                                value={email}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <StateCitySelector
                            user_city={city}
                            user_state={state}
                            set_user_state={(e) =>
                              setData({ ...data, state: e })
                            }
                            set_user_city={(e) => setData({ ...data, city: e })}
                          />
                          <div class="col-md-6">
                            <div
                              class="boxlabel"
                              value={enquiry_type}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  enquiry_type: e.target.value,
                                });
                                setCourse("");
                                setTrade("");
                              }}
                            >
                              <p>Inquiry For</p>
                              <label class="Radioc">
                                Affiliation
                                <input
                                  type="radio"
                                  name="enquiry_type"
                                  checked={enquiry_type == 1}
                                  value="1"
                                  id="afl"
                                />
                                <span class="checC"></span>
                              </label>{" "}
                              <label class="Radioc">
                                Admission
                                <input
                                  type="radio"
                                  name="enquiry_type"
                                  checked={enquiry_type == 2}
                                  value="2"
                                  id="adm"
                                />
                                <span class="checC"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="boxlabel">
                              {enquiry_type == "1" ? (
                                <>
                                  <p>Select Trade</p>
                                  <select
                                    class="col-md-12 form-control"
                                    name="trade"
                                    value={trade}
                                    onChange={(e) => setTrade(e.target.value)}
                                    required
                                  >
                                    <option value={""} selected>
                                      SELECT TRADE
                                    </option>
                                    {!trades ? (
                                      <option disabled value={""}>
                                        Loading...
                                      </option>
                                    ) : trades.length > 0 ? (
                                      trades.map((trade, index) => (
                                        <option value={trade.id} key={index}>
                                          {trade.name}
                                        </option>
                                      ))
                                    ) : (
                                      <option value={""} disabled>
                                        No Trade Found
                                      </option>
                                    )}
                                  </select>
                                </>
                              ) : (
                                <>
                                  <p>Select Course</p>
                                  <select
                                    class="col-md-12 form-control"
                                    name="course"
                                    value={course}
                                    onChange={(e) => setCourse(e.target.value)}
                                    required
                                  >
                                    <option value={""} selected>
                                      SELECT COURSE
                                    </option>
                                    {!courses ? (
                                      <option disabled value={""}>
                                        Loading...
                                      </option>
                                    ) : courses.length > 0 ? (
                                      courses.map((course, index) => (
                                        <option value={course.id} key={index}>
                                          {course.name}
                                        </option>
                                      ))
                                    ) : (
                                      <option value={""} disabled>
                                        No Course Found
                                      </option>
                                    )}
                                  </select>
                                </>
                              )}
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="boxlabel">
                              <p>Address</p>
                              <textarea
                                class="form-control"
                                name="address"
                                value={address}
                                onChange={handleChange}
                              ></textarea>
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="boxlabel">
                              <p>Message</p>
                              <textarea
                                class="form-control"
                                name="message"
                                value={message}
                                onChange={handleChange}
                              ></textarea>
                            </div>
                          </div>

                          <div class="col-md-12">
                            <div
                              class="btn-groups mt-4 text-right"
                              role="group"
                              aria-label="First group"
                            >
                              <button class="btn btn-Next" type="submit">
                                Submit<span></span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                {/* <StateCitySelector /> */}
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <div className="card office">
                <Offices />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
