import {
  STUDENT_AUTH_SUCCESS,
  AUTH_FAIL,
  SET_STUDENT_CERTIFICATE,
  ASSESSMENT_QUESTIONS,
  PRACTICE_EXAM,
  DATE_SHEET,
  EXAM_QUESTIONS,
} from "../../actions/actionTypes";

const initialState = {
  studentProfile: {},
  studentAuth: {
    isAuth: false,
    profile: null,
    loading: true,
    token: null,
  },
};

const studentReducer = (state = initialState, action) => {
  switch (action.type) {
    case STUDENT_AUTH_SUCCESS:
      return {
        ...state,
        studentAuth: {
          isAuth: true,
          profile: action.payload,
          loading: false,
        },
      };
    case AUTH_FAIL:
      return {
        studentProfile: {},
        studentAuth: {
          isAuth: false,
          profile: null,
          loading: false,
        },
      };
    case SET_STUDENT_CERTIFICATE:
      return {
        ...state,
        certificates: action.payload,
      };
    case ASSESSMENT_QUESTIONS:
      return {
        ...state,
        assessment: action.payload,
      };
    case PRACTICE_EXAM:
      return {
        ...state,
        practiceQuestion: action.payload,
      };
    case DATE_SHEET:
      return {
        ...state,
        dateSheet: action.payload,
      };
    case EXAM_QUESTIONS:
      return {
        ...state,
        examQuestions: action.payload,
      };
    default:
      return state;
  }
};

export default studentReducer;
