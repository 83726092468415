import { getDataAPI, postDataAPI } from "../../../services/apiWrapper";
import {
  ALERT,
  CENTER_AUTH_FAIL,
  CENTER_AUTH_SUCCESS,
  CENTER_REGISTER_DETAILS,
} from "../actionTypes";

export const centerRegisterAction = (data) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(postDataAPI("center/register", data));
    dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });
    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};

export const changePassword = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("centerToken");
    dispatch({
      type: ALERT,
      payload: { loading: true },
    });
    const response = await dispatch(
      postDataAPI("center/change-password", data, token)
    );
    dispatch({
      type: ALERT,
      payload: { success: response.data.message },
    });
    dispatch({
      type: ALERT,
      payload: { loading: false },
    });
    return true;
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: { loading: false },
    });
    dispatch({
      type: ALERT,
      payload: { loading: false, error: error.response.data.message },
    });
    return false;
  }
};

export const centerUpdate = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("centerToken");
    dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(
      postDataAPI("center/edit-center", data, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });
    dispatch({ type: ALERT, payload: { loading: false } });
    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};

export const updateStaffPic = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("centerToken");
    dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(
      postDataAPI("center/update/staff/image", data, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });
    dispatch({ type: ALERT, payload: { loading: false } });
    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};

export const centerLogin = (data) => async (dispatch) => {
  try {
    dispatch({
      type: ALERT,
      payload: { loading: true },
    });
    const response = await dispatch(postDataAPI("center/login", data));

    localStorage.setItem("centerToken", response.data.data.token);
    dispatch({
      type: CENTER_AUTH_SUCCESS,
      payload: response.data.data,
    });
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: { loading: false, error: error.response.data.message },
    });
    dispatch({
      type: CENTER_AUTH_FAIL,
    });
  }
};

export const centerDashboard = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("centerToken");
    dispatch({
      type: ALERT,
      payload: { loading: true },
    });
    const response = await dispatch(getDataAPI("center/dashboard", token));
    dispatch({
      type: CENTER_AUTH_SUCCESS,
      payload: response.data.data,
    });
    dispatch({
      type: ALERT,
      payload: { loading: false },
    });
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: { loading: false },
    });
    dispatch({
      type: CENTER_AUTH_FAIL,
    });
  }
};
export const editCenter = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("centerToken");
    dispatch({
      type: ALERT,
      payload: { loading: true },
    });
    const response = await dispatch(getDataAPI("center/profileGet", token));
    dispatch({
      type: CENTER_REGISTER_DETAILS,
      payload: response.data.data,
    });
    dispatch({
      type: ALERT,
      payload: { loading: false },
    });
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: { loading: false },
    });
    // dispatch({
    //   type: CENTER_AUTH_FAIL,
    // });
  }
};
