import {
  deleteDataAPI,
  getDataAPI,
  postDataAPI,
} from "../../../services/apiWrapper";
import { exportToCSV } from "../../../utils/exportCsv";
import { ALERT } from "../actionTypes";
export const addCategory = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(
      postDataAPI("admin/create/category", data, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });
    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};

export const updateCategory = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(
      postDataAPI("admin/update/category", data, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });
    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};

export const deleteCategory = (id) => async (dispatch) => {
  const token = localStorage.getItem("adminToken");
  try {
    const response = await dispatch(
      deleteDataAPI(`admin/category/delete/${id}`, token)
    );
    return true;
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: { error: error.response?.data?.message || error.message },
    });
    return false;
  }
};

export const getCategory = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");

    const response = await dispatch(getDataAPI(`admin/list/category`, token));

    return {
      data: response.data.data || [],
    };
  } catch (error) {
    // dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    return {
      data: [],
    };
  }
};
export const getSubCategory = (id, page) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    console.log("token====>", token);
    const response = await dispatch(
      getDataAPI(`admin/list/subcategory`, token)
    );

    return response.data.data;
  } catch (error) {
    // dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    return false;
  }
};

export const downloadBodies = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");

    const response = await dispatch(
      getDataAPI(`admin/governing/list/${id}`, token)
    );
    const dataToExport = [];
    response.data.data.forEach((ele, index) => {
      dataToExport.push({
        Sno: index + 1,
        Name: ele.name,
        Email: ele.email,
        Mobile: ele.mobile,
        Designation: ele.designation,
      });
    });
    exportToCSV(dataToExport, "Bodies");
  } catch (error) {
    // dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    return false;
  }
};

export const getCategoryDetail = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      getDataAPI(`admin/update/category/${id}`, token)
    );

    return {
      data: response.data.data,
      success: true,
    };
  } catch (error) {
    // dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    return {
      data: false,
      success: false,
    };
  }
};

export const addSubCategory = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(
      postDataAPI("admin/create/subcategory", data, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });
    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};

export const getSubCategoryDetail = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      getDataAPI(`admin/update/subcategory/${id}`, token)
    );

    return {
      data: response.data.data,
      success: true,
    };
  } catch (error) {
    // dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    return {
      data: false,
      success: false,
    };
  }
};

export const updateSubCategory = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(
      postDataAPI("admin/update/subcategory", data, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });
    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};

export const deleteSUbCategory = (id) => async (dispatch) => {
  const token = localStorage.getItem("adminToken");
  try {
    const response = await dispatch(
      deleteDataAPI(`admin/subcategory/delete/${id}`, token)
    );
    return true;
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: { error: error.response?.data?.message || error.message },
    });
    return false;
  }
};

export const addPage = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(
      postDataAPI("admin/create/page", data, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });
    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};

export const getPage = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");

    const response = await dispatch(getDataAPI(`admin/list/page`, token));

    return {
      data: response.data.data || [],
    };
  } catch (error) {
    // dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    return {
      data: [],
    };
  }
};

export const updatePage = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(
      postDataAPI("admin/update/page", data, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });
    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};

export const getPageDetail = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      getDataAPI(`admin/update/page/${id}`, token)
    );

    return {
      data: response.data.data,
      success: true,
    };
  } catch (error) {
    // dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    return {
      data: false,
      success: false,
    };
  }
};

export const deletePage = (id) => async (dispatch) => {
  const token = localStorage.getItem("adminToken");
  try {
    const response = await dispatch(
      deleteDataAPI(`admin/update/delete/${id}`, token)
    );
    return true;
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: { error: error.response?.data?.message || error.message },
    });
    return false;
  }
};

export const getsubCategoryId = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      getDataAPI(`admin/category/subcategory/${id}`, token)
    );

    return {
      data: response.data.data,
      success: true,
    };
  } catch (error) {
    // dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    return {
      data: false,
      success: false,
    };
  }
};

export const getsubCategory = (id) => async (dispatch) => {
  try {
    const response = await dispatch(
      getDataAPI(`admin/category/subcategory/${id}`)
    );

    return {
      data: response.data.data,
      success: true,
    };
  } catch (error) {
    // dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    return {
      data: false,
      success: false,
    };
  }
};

export const getpagesubCategory = (id) => async (dispatch) => {
  try {
    const response = await dispatch(
      getDataAPI(`admin/list/page/subcategory/${id}`)
    );

    return {
      data: response.data.data,
      success: true,
    };
  } catch (error) {
    // dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    return {
      data: false,
      success: false,
    };
  }
};

export const getIndividualMember = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");

    const response = await dispatch(
      getDataAPI(`admin/individual/member/list`, token)
    );

    return {
      data: response.data.data || [],
    };
  } catch (error) {
    // dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    return {
      data: [],
    };
  }
};

export const getOtherMember = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");

    const response = await dispatch(
      getDataAPI(`admin/other/member/list`, token)
    );

    return {
      data: response.data.data || [],
    };
  } catch (error) {
    // dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    return {
      data: [],
    };
  }
};

export const deleteMembership = (id) => async (dispatch) => {
  const token = localStorage.getItem("adminToken");
  try {
    const response = await dispatch(
      deleteDataAPI(`admin/individual/member/delete/${id}`, token)
    );
    return true;
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: { error: error.response?.data?.message || error.message },
    });
    return false;
  }
};

export const deleteOther = (id) => async (dispatch) => {
  const token = localStorage.getItem("adminToken");
  try {
    const response = await dispatch(
      deleteDataAPI(`admin/other/member/delete/${id}`, token)
    );
    return true;
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: { error: error.response?.data?.message || error.message },
    });
    return false;
  }
};

export const changeStatus = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(
      postDataAPI("admin/change/membership/status", data, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });
    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};

export const filterCtageory = (category, type) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");

    const response = await dispatch(
      getDataAPI(`admin/member/filter/${category}/${type}`, token)
    );

    return {
      data: response.data.data || [],
    };
  } catch (error) {
    // dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    return {
      data: [],
    };
  }
};

export const updateMembershipIndividual = (id, data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(
      postDataAPI(`admin/update/individual/member/${id}`, data, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });
    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};

export const getMembershipIndividual = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      getDataAPI(`admin/update/individual/member/${id}`, token)
    );

    return {
      data: response.data.data,
      success: true,
    };
  } catch (error) {
    // dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    return {
      data: false,
      success: false,
    };
  }
};

export const updateMembershipOther = (id, data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(
      postDataAPI(`admin/update/other/member/${id}`, data, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });
    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};

export const getMembershipOther = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      getDataAPI(`admin/update/other/member/${id}`, token)
    );

    return {
      data: response.data.data,
      success: true,
    };
  } catch (error) {
    // dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    return {
      data: false,
      success: false,
    };
  }
};

// Import necessary actions/types as needed

export const checkMembershipstatus = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    dispatch({ type: ALERT, payload: { loading: true } });

    const response = await dispatch(
      postDataAPI(`admin/member/check/status`, data, token)
    );

    return {
      data: response.data.data || [],
    };
  } catch (error) {
    // dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    return {
      data: [],
    };
  }
};

export const MemebrshipAction = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(
      postDataAPI("admin/member/approwed", data, token)
    );
    return {
      data: response.data.data || [],
    };
  } catch (error) {
    // dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    return {
      data: [],
    };
  }
  return false;
};

export const getMember = (value) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");

    const response = await dispatch(
      getDataAPI(`admin/individual/member/list/${value}`)
    );

    return {
      data: response.data.data || [],
    };
  } catch (error) {
    // dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    return {
      data: [],
    };
  }
};

export const addFeedBack = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(
      postDataAPI("admin/create/feedback/question", data, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });
    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};

export const getFeedBack = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");

    const response = await dispatch(
      getDataAPI(`admin/list/feedback/question`, token)
    );

    return {
      data: response.data.data || [],
    };
  } catch (error) {
    // dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    return {
      data: [],
    };
  }
};

export const getFeedBackDetail = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      getDataAPI(`admin/update/feedback/question/${id}`, token)
    );

    return {
      data: response.data.data,
      success: true,
    };
  } catch (error) {
    // dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    return {
      data: false,
      success: false,
    };
  }
};

export const updateFeedBack = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(
      postDataAPI("admin/update/feedback/question", data, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });
    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};

export const deleteFeedBack = (id) => async (dispatch) => {
  const token = localStorage.getItem("adminToken");
  try {
    const response = await dispatch(
      deleteDataAPI(`admin/feedback/question/delete/${id}`, token)
    );
    return true;
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: { error: error.response?.data?.message || error.message },
    });
    return false;
  }
};
