import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  approveCenter,
  getCenterDetail,
} from "../../../../store/actions/councilActions/centerActions";
import Loader from "../../../Alert/Loader";
import ApproveCenter from "./ApproveCenter";
import RejectCenter from "./RejectCenter";

const ViewCenter = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [reject, setReject] = useState(false);
  const { id } = useParams();
  const [center, setCenter] = useState(false);

  const fetchCenter = async () => {
    const response = await dispatch(getCenterDetail(id));
    if (response.success) {
      setCenter(response.details);
    } else {
      setCenter(null);
    }
  };

  useEffect(async () => {
    fetchCenter();
  }, [id, reject, show]);

  const reapprove = async () => {
    const response = await dispatch(approveCenter({ reapprowed: "1", id }, id));
    if (response) {
      fetchCenter();
    }
  };

  return (
    <div>
      <ApproveCenter show={show} onHide={() => setShow(false)} id={id} />
      <RejectCenter show={reject} onHide={() => setReject(false)} id={id} />
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="container ">
          <div class="row">
            <div class="col-md-12">
              <div class="card-body p-0 ">
                {/* <div class="rjct">
                  <img src="/images/reject.png" />
                </div> */}
                <div class="col-md-12">
                  <div class="card" id="invoice">
                    {center == null && (
                      <div class="card-header bg-transparent header-elements-inline">
                        <h4 class="invoice-color mb-2 mt-md-2 text-danger">
                          Center not found
                        </h4>
                      </div>
                    )}
                    {center == false && <Loader active={true} />}
                    {center && (
                      <>
                        <div class="card-header bg-transparent header-elements-inline">
                          <h6 class="card-title text-primary">
                            Center Status
                            {center.status == "0" ? (
                              <span class="text-info">Pending</span>
                            ) : center.status == "1" ? (
                              <span class="text-success"> Approved</span>
                            ) : center.status == "3" ? (
                              <span class="text-success">
                                {" "}
                                Reapprove Pending
                              </span>
                            ) : (
                              <span class="text-danger">Rejected</span>
                            )}
                          </h6>
                          {center.status == "1" && (
                            <h4 class="invoice-color mb-2 mt-md-2">
                              Center Code - <span>{center.center_code}</span>
                            </h4>
                          )}
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <div class="col-sm-6">
                              <div class="mb-4 pull-left"></div>
                            </div>
                            <div class="col-sm-6">
                              <div class="mb-4 ">
                                <div class="text-sm-right"></div>
                              </div>
                            </div>
                          </div>

                          <div class="d-md-flex flex-md-wrap mb-4">
                            <div class="col-md-6">
                              <span class="text-muted">Md Details:</span>
                              <ul class="list list-unstyled mb-0">
                                <li>
                                  <span class="font-weight-semibold">
                                    <span class="sd">MD Name: </span>
                                    {center.md_name}
                                  </span>
                                </li>
                                <li>
                                  <span class="font-weight-semibold">
                                    <span class="sd">MD Mobile No: </span>
                                    {center.mobile}
                                  </span>
                                </li>
                                <li>
                                  <span class="sd">MD Email: </span>
                                  {center.email}
                                </li>
                                <li>
                                  <span class="sd">MD Education: </span>
                                  {center.md_qualification}
                                </li>
                                <li>
                                  <span class="sd">MD Dob: </span>
                                  {center.md_dob}
                                </li>
                                <li>
                                  <span class="sd">MD Adhar: </span>
                                  {center.md_adhar}
                                </li>
                                <li>
                                  <span class="sd">MD Pan No: </span>
                                  {center.md_pan}
                                </li>

                                <li>
                                  <span class="sd">MD Address: </span>
                                  {`${center.md_address} ${center.md_city} ${center.md_state} ${center.md_pin_code}`}
                                </li>
                              </ul>
                            </div>
                            <div class="col-md-6">
                              <span class="text-muted">Center Details</span>
                              <div class="d-flex flex-wrap wmin-md-400">
                                <ul class="list list-unstyled mb-0"></ul>

                                <ul class="list list-unstyled  mb-0 ">
                                  <li>
                                    <span class="sd">Center name:</span>
                                    <span class="font-weight-semibold">
                                      {center.center_name}
                                    </span>
                                  </li>
                                  <li>
                                    <span class="sd">Center Type:</span>
                                    {center.center_type}
                                  </li>
                                  <li>
                                    <span class="sd">Center Trade:</span>
                                    {center.center_trade}
                                  </li>

                                  <li>
                                    <span class="sd">Center Contact:</span>
                                    <span class="font-weight-semibold">
                                      {center.center_mobile}
                                    </span>
                                  </li>
                                  <li>
                                    <span class="sd">Center Email:</span>
                                    <span class="font-weight-semibold">
                                      {center.center_email}
                                    </span>
                                  </li>
                                  <li>
                                    <span class="sd">Center Address</span>
                                    <span class="font-weight-semibold">
                                      {`${center.center_address} ${center.center_city} ${center.center_state} ${center.center_pincode}`}
                                    </span>
                                  </li>
                                  {center.status == "1" && (
                                    <li>
                                      <span class="sd">Total Seats:</span>
                                      <span class="font-weight-semibold">
                                        {center.total_seats}
                                      </span>
                                    </li>
                                  )}
                                  <li>
                                    <span class="sd">Registration Date:</span>
                                    <span class="font-weight-semibold">
                                      {moment(center.created_at).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          {center.centerfiles.length > 0 && (
                            <>
                              <h6 class="mb-0 ty text-muted">Photos Details</h6>
                              <div class="pclist row">
                                <div class="col-md-6 mb-5">
                                  {center.centerfiles.filter(
                                    (c) => c.file_type == "md_sign_pic"
                                  ).length > 0 && (
                                    <div>
                                      <h6 class="text-center">
                                        Singnature Photo
                                      </h6>
                                      <img
                                        height={"250px"}
                                        src={
                                          center.centerfiles.find(
                                            (c) => c.file_type == "md_sign_pic"
                                          ).file
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                                <div class="col-md-6">
                                  {center.centerfiles.filter(
                                    (c) => c.file_type == "office_pic"
                                  ).length > 0 && (
                                    <div>
                                      <h6 class="text-center">Office Photo</h6>
                                      <img
                                        height={"250px"}
                                        src={
                                          center.centerfiles.find(
                                            (c) => c.file_type == "office_pic"
                                          ).file
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                                <div class="col-md-6">
                                  {center.centerfiles.filter(
                                    (c) => c.file_type == "lab_pic"
                                  ).length > 0 && (
                                    <div>
                                      <h6 class="text-center">Lab Photo</h6>
                                      <img
                                        height={"250px"}
                                        src={
                                          center.centerfiles.find(
                                            (c) => c.file_type == "lab_pic"
                                          ).file
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        <div class="table-responsive">
                          <h6 class="mb-0 ty">Staff Details</h6>
                          <table class="table table-lg">
                            <tbody>
                              <tr>
                                <th>Name</th>
                                <th>Qualification</th>
                                <th>Designation</th>
                                <th>Joindate</th>
                              </tr>
                              {center.staff?.length > 0 ? (
                                center.staff.map((s, index) => (
                                  <tr>
                                    <td>{s.name}</td>
                                    <td>{s.qualification}</td>
                                    <td>{s.designation}</td>
                                    <td>
                                      {moment(s.date_of_joining).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={4}>No Staff added</td>
                                </tr>
                              )}
                              {/* <%staff.forEach((item2,index)=>{ %>
                                 <tr>
                                     <td><%=item2.name%></td>
                                     <td><%=item2.qualification%></td>
                                     <td><%=item2.designation%></td>
                                     <td><%=item2.joindate%></td>
                                 
                                 </tr>
                                <%})%> */}
                            </tbody>
                          </table>
                          <div class="table-responsive">
                            <h6 class="mb-0 ty">Facility Details</h6>
                            <table
                              class="table table-lg"
                              // style={{
                              //   width: "100%",
                              //   borderCollapse: "collapse",
                              //   background: "#FFF",
                              // }}
                              // border="1"
                              // cellspacing="3"
                              // cellpadding="10"
                            >
                              <tbody>
                                <tr>
                                  <th>Name</th>
                                  <th>Number</th>
                                  <th>Area</th>
                                  <th>Seats</th>
                                </tr>
                                {center.facility?.length > 0 ? (
                                  center.facility.map((c, index) => (
                                    <tr>
                                      <td>{c.name}</td>
                                      <td>{c.number}</td>
                                      <td>{c.carpet_area}</td>
                                      <td>{c.seat_capicity}</td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan={4}>No Facility added</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>

              {center && (
                <div class="apsbtn pb-4">
                  {center.status == "0" ? (
                    <>
                      <button
                        onClick={() => setReject(true)}
                        class="btn btn-danger"
                      >
                        Reject
                      </button>

                      <button
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#exampleModal"
                        onClick={() => setShow(true)}
                      >
                        Approve
                      </button>
                    </>
                  ) : center.status == "1" ? (
                    <button
                      onClick={() => setReject(true)}
                      class="btn btn-danger"
                    >
                      Reject
                    </button>
                  ) : center.status == "3" ? (
                    <button
                      class="btn btn-success"
                      data-toggle="modal"
                      data-target="#exampleModal"
                      onClick={reapprove}
                    >
                      Reapprove
                    </button>
                  ) : (
                    <button
                      class="btn btn-success"
                      data-toggle="modal"
                      data-target="#exampleModal"
                      onClick={() => setShow(true)}
                    >
                      Approve
                    </button>
                  )}

                  {/* <a
                    href="javascript:void(0)"
                    class="btn btn-info"
                    id="download"
                  >
                    Download Pdf
                  </a> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewCenter;
