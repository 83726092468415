import { getDataAPI, postDataAPI } from "../../../services/apiWrapper";
import { ALERT } from "../actionTypes";

export const addActivity = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");

    dispatch({
      type: ALERT,
      payload: { loading: true },
    });
    const response = await dispatch(
      postDataAPI("admin/activity/create", data, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: true },
    });
    return true;
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: { loading: false },
    });
    return false;
  }
};

export const viewActivity = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");

    dispatch({
      type: ALERT,
      payload: { loading: true },
    });
    const response = await dispatch(getDataAPI("admin/activity/list", token));
    dispatch({
      type: ALERT,
      payload: { loading: true },
    });
    return {
      success: true,
      data: response.data.data,
    };
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: { loading: false },
    });
    return {
      success: false,
    };
  }
};
