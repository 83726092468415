import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { Card, Table, Accordion, Spinner } from "react-bootstrap";
import {
  checkExamFormFilled,
  fillForm,
  getStudentDetails,
} from "../../store/actions/homeActions/homeActions";
import { ALERT } from "../../store/actions/actionTypes";

const ExamForm = () => {
  const [loading, setLoading] = useState(false);
  const [checking, setChecking] = useState(false);
  const [roll_number, setroll_number] = useState("");
  const[exam_mode,setExam_mode]=useState("")
  const [filled, setFilled] = useState(false);
  const [dob, setDob] = useState("");
  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const [semester, setSemester] = useState("");
  const [userSubjects, setUserSubject] = useState(null);
  const convertArrayToObject = (array, key) => {
    const hs = new Map();
    array.map((element) => {
      if (hs.get(element.semester)) {
        hs.set(element.semester, [...hs.get(element.semester), element]);
      } else {
        hs.set(element.semester, [element]);
      }
    });
    return Object.fromEntries(hs);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setData(null);
    setSemester("");
    const response = await dispatch(
      getStudentDetails({
        roll_number: roll_number,
        dob: dob,
      })
    );
    if (response) {
      setData(response[0] || false);
      setLoading(false);
    } else {
      setData(false);
      setLoading(false);
    }
  };
  useEffect(async () => {
    if (data) {
      if (data.course.exam_type == "course_wise") {
        setChecking(true);
        const response = await dispatch(
          checkExamFormFilled({
            student_id: data.id,
            course_id: data.course.id,
          })
        );
        if (response) {
          setFilled(true);
          setChecking(false);
          dispatch({
            type: ALERT,
            payload: { error: `You already have filled exam form ` },
          });
        } else {
          setChecking(false);
          setFilled(false);
        }
      } else {
        if (semester) {
          setChecking(true);

          const response = await dispatch(
            checkExamFormFilled({
              student_id: data.id,
              course_id: data.course.id,
              semester: semester,
            })
          );
          if (response) {
            setChecking(false);
            setFilled(true);
            dispatch({
              type: ALERT,
              payload: {
                error: `You already have filled exam form for ${
                  data.course.course_type == "2" ? "Semester" : "Year"
                } ${semester}`,
              },
            });
          } else {
            setChecking(false);
            setFilled(false);
          }
        }
      }
    }
  }, [data, semester]);
  const fillExamForm = async () => {
    const response = await dispatch(
      fillForm({
        student_id: data.id,
        course_id: data.course.id,
        samster_id: semester,
        exam_mode:exam_mode
      })
    );
    if (response) {
      setroll_number("");
      setDob("");
      setData(null);
      setSemester("");
      setFilled(true);
    }
  };
  return (
    <div className="gallery-box">
      <div className="container">
        <div className="title-box">
          <div className="col-md-12 inner">
            <section className="innerbanner">
              <ol className="breadcrumb">
                <li>
                  <Link to="/" className="none">
                    Home
                  </Link>
                </li>
                <li> / </li>
                <li className="active"> Examination Form</li>
              </ol>
            </section>
            <div className="col-lg-12">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-5">
                    <div className="row d-flex">
                      <label className="col-md-3">Roll No.</label>
                      <input
                        className="col-md-8"
                        placeholder="Enter Roll No"
                        value={roll_number}
                        onChange={(e) => setroll_number(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="row">
                      <label className="col-md-3">DOB</label>
                      <input
                        className="col-md-8"
                        type="date"
                        placeholder="Enter DOB"
                        value={dob}
                        onChange={(e) => setDob(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="float-center ">
                      <input
                        type={"submit"}
                        value="Search"
                        className="btn-success btn-lg btn"
                      />
                    </div>
                  </div>
                </div>
              </form>

              {data === false && <h3>No record found</h3>}
              {data && (
                <div className="row justify-content-center mt-3">
                  <div className="col-md-8 ">
                    <div className="card">
                      <div className="card-header text-center">
                        Fill Exam Form
                      </div>
                      <div className="card-body">
                        <div className="row exam-form">
                          <table className="w-100 table">
                            <tbody>
                              <tr>
                                <td className="w-50">Student Name</td>
                                <td className="w-50">{data.name}</td>
                              </tr>
                              <tr>
                                <td>Roll No.</td>
                                <td>{data.roll_number}</td>
                              </tr>
                              <tr>
                                <td>DOB</td>
                                <td>{moment(data.dob).format("DD/MM/YYYY")}</td>
                              </tr>
                              <tr>
                                <td>Course</td>
                                <td>{data.course.name}</td>
                              </tr>
                              {data.course.exam_type == "course_wise" && (
                                <>
                                <tr><td>Exam Mode</td><td><select required value={exam_mode} onChange={e=>setExam_mode(e.target.value)}><option value="">--Select--</option><option value={0}>Online</option><option vlue={1}>Offline</option></select></td></tr>
                                <tr>
                                  <td>Action</td>
                                  <td>
                                    {checking ? (
                                      <Spinner
                                        animation="border"
                                        role="status"
                                        size="lg"
                                      >
                                        <span className="visually-hidden">
                                          Loading...
                                        </span>
                                      </Spinner>
                                    ) : filled ? (
                                      "Already filled"
                                    ) : (
                                      <button
                                        onClick={fillExamForm}
                                        className="btn btn-success mt-4 float-right"
                                      >
                                        Submit Exam Form
                                      </button>
                                    )}
                                  </td>
                                </tr>
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="form-group">
                          {data.course.exam_type == "course_wise" ? null : (
                            <>
                              <label>Exam Mode</label>
                              <select
                                className="form-control"
                                value={exam_mode}
                                onChange={(e) => setExam_mode(e.target.value)}
                                required
                              >
                                <option value="">--Select--</option>
                               <option value={0}>Online</option><option value={1}>Offline</option>
                              </select>
                            </>
                          )}
                          </div>
                        <div className="form-group">
                          {data.course.exam_type == "course_wise" ? null : (
                            <>
                              <label>Select Semester</label>
                              <select
                                className="form-control"
                                value={semester}
                                onChange={(e) => setSemester(e.target.value)}
                                required
                              >
                                <option value="">--Select--</option>
                                {Object.keys(
                                  convertArrayToObject(data.course.subject)
                                ).map((element) => (
                                  <option value={element}>{element}</option>
                                ))}
                              </select>
                            </>
                          )}
                          {data.course.exam_type != "course_wise" &&
                            (checking ? (
                              <Spinner
                                animation="border"
                                role="status"
                                size="lg"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </Spinner>
                            ) : filled ? null : !semester ? null : (
                              <button
                                onClick={fillExamForm}
                                className="btn btn-success mt-4 float-right"
                              >
                                Submit Exam Form
                              </button>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {loading && (
                <div className="text-center">
                  <Spinner animation="border" role="status" size="lg">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExamForm;
