import * as FileSaver from "file-saver";
import * as ExcelJS from "exceljs";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

export const exportToCSV = (jsonData, fileName) => {
  const wb = new ExcelJS.Workbook();
  var ws = wb.addWorksheet("Data");
  let headers = Object.keys(jsonData[0]);
  let values = jsonData.map((obj) => Object.values(obj));
  for (let index = 1; index <= headers.length; index++) {
    ws.getRow(1).getCell(String.fromCharCode(index + 64)).value =
      headers[index - 1];
    ws.getColumn(index).width = headers[index - 1].length + 10;
  }
  for (let index = 1; index <= values.length; index++) {
    for (let j = 1; j <= values[index - 1].length; j++) {
      ws.getRow(index + 1).getCell(String.fromCharCode(j + 64)).value =
        values[index - 1][j - 1];
      ws.getRow(index + 1).getCell(String.fromCharCode(j + 64)).alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
      ws.getColumn(j).width = String(values[index - 1][j - 1]).length + 10;
    }
  }
  wb.xlsx.writeBuffer().then((buf) => {
    FileSaver.saveAs(new Blob([buf]), fileName + `.xlsx`);
  });
};
