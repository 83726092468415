import React, { useState, useRef } from "react";
import { useEffect } from "react";
import {
  getStudents,
  getStudentsAdmin,
} from "../../../../store/actions/centerActions/studentAction";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import UploadCertificate from "./UploadCertificate";
import BreadCrumb from "../../../BreadCrumb";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { courseType, examType } from "../../../../utils/constant";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import Search from "../../../Search";

export default function StudentList() {
  const breadcrumbs = [
    {
      path: "/admin/dashboard",
      label: "Dashboard",
    },
    {
      label: "Student List",
    },
  ];
  const dispatch = useDispatch();
  const [totalPages, settotalPages] = useState(0);
  const [activePage, setactivePage] = useState(1);
  const [studentModalId, setstudentModalId] = useState(false);
  const [modalOpen, setmodalOpen] = useState(false);
  const { center } = useSelector((state) => state);
  const { students } = center;
  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(getStudentsAdmin(activePage, search));
  }, [activePage, search]);

  useEffect(() => {
    if (students) {
      settotalPages(Math.ceil(students.total / students.per_page));
    }
  }, [students]);

  const [show, setShow] = useState(false);
  const [id, setid] = useState("");

  const domEl = useRef(null);

  const handleDownload = (e) => {
    window.open(e.target.value, "'_blank'");
  };

  return (
    <div>
      <UploadCertificate show={show} onHide={() => setShow(false)} id={id} />
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
          <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <BreadCrumb crumbs={breadcrumbs} />

            {/* <div class="d-flex align-items-center">
              <Link
                to={"/admin/add-student"}
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                Add Student
              </Link>
            </div> */}
            <div className="col-md-4">
              <Search
                setQuery={(val) => {
                  setSearch(val);
                  setactivePage(1);
                }}
              />
            </div>
          </div>
        </div>

        <div class="mid-content-box">
          <div class="Widthdata">
            <div class="Applicationdata s">
              <div class="row ">
                <div class="table-responsive" id="domEl" ref={domEl}>
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Sr No. </th>
                        <th> Student Name </th>
                        <th>Roll /Pdf </th>
                        <th>Course </th>

                        <th>Mobile </th>
                        <th>Reg.Date </th>
                        <th>Photo </th>
                        <th>Fee's </th>
                        <th>Fees Status </th>
                        <th> Result </th>
                        <th>Diploma Certificate</th>
                        <th>Registration Certificate</th>
                        <th>Registration Form</th>
                        {/* <th>Marksheet</th> */}
                        <th> Action</th>
                        <th>Edit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {students && students?.data?.length > 0 ? (
                        students.data.map((student, index) => (
                          <tr>
                            <td>{(activePage - 1) * 10 + index + 1}</td>
                            <td>{student.name}</td>
                            <td>{student.roll_number}</td>
                            <td>{student.course?.name || student.course}</td>
                            <td>{student.student_details?.mobile}</td>
                            <td>
                              {student.is_update == "1"
                                ? moment(student.register_date).format(
                                    "DD/MM/yyyy"
                                  )
                                : moment(student.created_at).format(
                                    "DD/MM/yyyy"
                                  )}
                            </td>
                            <td>
                              <img src={student.profile_pic?.file} />
                            </td>
                            <td>
                              {student.course?.fee
                                ? +student.course.fee.course_fee +
                                  +student.course.fee.registration_fee +
                                  +student.course.fee.certificate_fee +
                                  +student.course.fee.exam_fee +
                                  +student.course.fee.annual_fee
                                : 0}
                            </td>
                            <td>
                              {student.submitFees?.length > 0 ? (
                                student.course?.exam_type ===
                                examType.course_wise ? (
                                  <a
                                    className="btn btn-success btn-sm"
                                    href={student.submitFees[0]?.link}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Receipt
                                  </a>
                                ) : (
                                  <select onChange={handleDownload}>
                                    <option>--Select--</option>
                                    {student.submitFees.map((fee) => (
                                      <option value={fee.link}>
                                        {student.course?.course_type ===
                                        courseType.semester
                                          ? "Semester"
                                          : "Year"}{" "}
                                        {fee.semester}
                                      </option>
                                    ))}
                                  </select>
                                )
                              ) : (
                                "Pending"
                              )}
                            </td>
                            <td>
                              {student.marksheet &&
                              student.marksheet.length > 0 ? (
                                <select
                                  class=""
                                  name="download"
                                  onChange={handleDownload}
                                >
                                  <option selected>Select</option>
                                  {student.marksheet.map((mark) => (
                                    <option value={mark.pdf}>
                                      {`Semester ${mark.semster_year}`}
                                    </option>
                                  ))}
                                </select>
                              ) : (
                                <p>No Result</p>
                              )}
                            </td>
                            <td>
                              {student.certificate &&
                              student.certificate.length > 0 ? (
                                <select
                                  class=""
                                  name="download"
                                  onChange={handleDownload}
                                >
                                  <option selected>Select</option>
                                  {student.certificate.map((mark) => (
                                    <option value={mark.pdf}>
                                      {`${mark.certificate_reg_no}`}
                                    </option>
                                  ))}
                                </select>
                              ) : (
                                <p>No Certificate</p>
                              )}
                            </td>
                            <td>
                              {student.reg && student.reg.length > 0 ? (
                                <select
                                  class=""
                                  name="download"
                                  onChange={handleDownload}
                                >
                                  <option selected>Select</option>
                                  {student.reg.map((mark) => (
                                    <option value={mark.pdf}>
                                      {`${mark.certificate_reg_no}`}
                                    </option>
                                  ))}
                                </select>
                              ) : (
                                <p>No Certificate</p>
                              )}
                            </td>
                            <td>
                              <Button
                                onClick={() => {
                                  setstudentModalId(btoa(student.id));
                                  setmodalOpen(true);
                                }}
                                className="btn-sm"
                              >
                                Registration Form
                              </Button>
                            </td>
                            <td>
                              {student.reg.length > 0 ? (
                                <button class="btn btn-sm btn-success">
                                  Completed
                                </button>
                              ) : (
                                <button
                                  className="btn btn-sm btn-primary"
                                  type="button"
                                  onClick={() => {
                                    setid(student.id);
                                    setShow(true);
                                  }}
                                >
                                  Upload Document
                                </button>
                              )}
                            </td>
                            <td>
                              <Link to={`/admin/edit-student/${student.id}`}>
                                Edit
                              </Link>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={10}>{"No student Found"}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <ReactPaginate
                  className="pagination mt-5 justify-content-center"
                  previousClassName="page-item"
                  nextClassName="page-item"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  activeClassName="active"
                  //   marginPagesDisplayed={10}
                  onPageChange={(e) => setactivePage(e.selected + 1)}
                  pageCount={totalPages}
                  // renderOnZeroPageCount={null}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalOpen}
        onHide={() => {
          setmodalOpen(false);
          studentModalId(false);
        }}
      >
        <Modal.Body>
          <Container>
            <Row>
              <Col xs={12} md={12} style={{ height: "80vh" }}>
                {studentModalId && (
                  <iframe
                    src={`https://npvec.com/api/public/ViewHallticket/${studentModalId}`}
                    width={"100%"}
                    height={"100%"}
                  ></iframe>
                )}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setmodalOpen(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
