import {
  AUTH_FAIL,
  MEMBER_AUTH_SUCCESS,
  CENTER_REGISTER_DETAILS,
  PRACTICAL_DATA,
  SET_STUDENTS,
  MEMBER_DATA,
} from "../../actions/actionTypes";

const initialState = {
  memberProfile: {},
  memberAuth: {
    isAuth: false,
    profile: null,
    loading: true,
    token: null,
  },
  practical: null,
  data: {},
};

const memberReducer = (state = initialState, action) => {
  switch (action.type) {
    case CENTER_REGISTER_DETAILS:
      return {
        ...state,
        centerProfile: {
          ...state.centerProfile,
          ...action.payload,
        },
      };
    case MEMBER_AUTH_SUCCESS:
      return {
        ...state,
        memberAuth: {
          isAuth: true,
          profile: action.payload,
          loading: false,
        },
      };
    case AUTH_FAIL:
      return {
        memberProfile: {},
        memberAuth: {
          isAuth: false,
          profile: null,
          loading: false,
        },
      };
    case SET_STUDENTS:
      return {
        ...state,
        students: action.payload,
      };
    case PRACTICAL_DATA:
      return {
        ...state,
        practical: action.payload,
      };
    case MEMBER_DATA:
      return {
        data: {},
      };
    default:
      return state;
  }
};

export default memberReducer;
