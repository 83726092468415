import React, { useEffect, useState } from "react";
import CKEditor from "react-ckeditor-component";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  addStaticPage,
  getStaticPage,
  getStaticPageDetail,
  updateStaticPage,
} from "../../../store/actions/adminActions/staticPages";

const StaticPages = () => {
  const [content, setContent] = useState("");
  const [name, setname] = useState("");
  const [page_title, setpage_title] = useState("");
  const [file, setFile] = useState([]);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [data, setData] = useState(null);

  useEffect(async () => {
    if (id) {
      const response = await dispatch(getStaticPageDetail(id));
      if (response.success) {
        setData(true);
        setContent(response.data.page_description);
        setname(response.data.name);
        setpage_title(response.data.page_title);
      }
    }
  }, [id]);

  const onBlur = (evt) => {
    //console.log("onBlur event called with event info: ", evt);
  };

  const afterPaste = (evt) => {
    //console.log("afterPaste event called with event info: ", evt);
  };

  const onChange = (evt) => {
    //console.log("onChange fired with event info: ", evt);
    var newContent = evt.editor.getData();
    setContent(newContent);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("name", name);
    formdata.append("page_title", page_title);
    formdata.append("file", file);
    formdata.append("page_description", content);
    if (!id) {
      dispatch(addStaticPage(formdata));
    } else {
      formdata.append("id", id);
      formdata.append("status", 1);
      dispatch(updateStaticPage(formdata, id));
    }
  };

  return (
    <div>
      <form onSubmit={submitHandler}>
        <div class="row">
          <div class="col-md-6">
            <div class="boxlabel">
              <p>Page Name</p>
              <input
                type="text"
                class="form-control"
                name="name"
                value={name}
                onChange={(e) => setname(e.target.value)}
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="boxlabel">
              <p>Page Title</p>
              <input
                type="text"
                class="form-control"
                name="page_title"
                value={page_title}
                onChange={(e) => setpage_title(e.target.value)}
                required
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="boxlabel">
              <p>Description</p>
              <CKEditor
                activeClass="p10"
                content={content}
                events={{
                  blur: onBlur,
                  afterPaste: afterPaste,
                  change: onChange,
                }}
              />
            </div>
          </div>
          <div class="col-md-12 mb-3">
            <div class="form-group">
              <label>Image</label>
              <input
                type="file"
                name="file"
                onChange={(e) => setFile(e.target.files[0])}
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label />
              <button type="submit" class="form-control btn btn-primary">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default StaticPages;
