import React, { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCollegeList } from "../../store/actions/homeActions/homeActions";
import Loader from "../Alert/Loader";
import BreadCrumb from "../BreadCrumb";
import Filters from "./Filters";

const Centers = () => {
  // const [filterType, setFilterType] = useState("");
  const [totalPages, settotalPages] = useState(0);
  const [activePage, setactivePage] = useState(1);
  const [filter, setFilter] = useState({
    filterType: "",
    trade: "",
    subtrade: "",
    state: "",
    center_code: "",
  });
  const breadcrumbs = [
    {
      path: "/",
      label: "Home",
    },
    {
      // path: "/aboutus",
      label: "Our centers",
    },
  ];
  const [selectedTrades, setSelectedTrades] = useState([]);
  const [list, setList] = useState(null);
  const { admin } = useSelector((state) => state);
  const { trades } = admin;
  const dispatch = useDispatch();

  const fetchColleges = useCallback(async () => {
    const setTrades = selectedTrades.join(",");
    const response = await dispatch(
      getCollegeList(setTrades, activePage, filter)
    );
    settotalPages(Math.ceil(response.data.total / response.data.per_page));
    setList(response.data?.data || []);
  }, [activePage, dispatch, selectedTrades, filter]);

  useEffect(() => {
    fetchColleges();
  }, [fetchColleges]);

  return (
    <>
      <div className="topper-student mt-4">
        <>{console.log(filter)}</>
        <div class="container" style={{ borderColor: "#ff0000" }}>
          <div class="page_Tile" style={{ background: "#ff0000" }}>
            <span> Training Center List</span>
          </div>
          <div class="row">
            <div class="serach-form">
              <div class="form-list">
                <Filters
                  filter={filter}
                  setFilter={(value) => setFilter(value)}
                />
              </div>
            </div>
            {/* <div class="download_pdf">
              <a href="javascript:void(0);" id="export">
                Download pdf
              </a>
            </div> */}
            <div
              class="tablelist"
              id="tblCustomers"
              style={{ width: "1186px" }}
              cellspacing="0"
              cellpadding="0"
            >
              <div
                data-example-id="striped-table "
                class="bs-example table-responsive"
              >
                <table class="table table-bordered  table-striped">
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Center Name</th>
                      <th>MD name</th>
                      <th>State</th>
                      <th>District</th>
                      <th>Address</th>
                      <th>Center Code</th>
                      <th>Enquiry Now</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list == null ? (
                      <Loader active={true} />
                    ) : list.length > 0 ? (
                      list.map((e, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{e.center_name}</td>
                          <td>{e.md_name}</td>
                          <td>{e.center_state}</td>
                          <td>{e.center_city}</td>
                          <td>{e.center_address}</td>
                          <td>{e.center_code}</td>
                          <td>Send Enquiry</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={8} className="text-center">
                          No Center Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <ReactPaginate
            className="pagination mt-5 justify-content-center"
            previousClassName="page-item"
            nextClassName="page-item"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            nextLinkClassName="page-link"
            previousLinkClassName="page-link"
            activeClassName="active"
            //   marginPagesDisplayed={10}
            onPageChange={(e) => setactivePage(e.selected + 1)}
            pageCount={totalPages}
            forcePage={activePage - 1}
            // renderOnZeroPageCount={null}
          />
        </div>
      </div>
    </>
  );
};

export default Centers;
