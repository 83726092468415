import React from "react";
import { useState } from "react";
import { Button, Form, FormControl, FormLabel, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { trackComplaint } from "../../store/actions/helpdeskActions/complaint";
import BreadCrumb from "../BreadCrumb";
import Details from "./Details";

const breadcrumbs = [
  {
    path: "/",
    label: "Home",
  },
  {
    label: "Track Complaint",
  },
];

const TrackComplaint = () => {
  const dispatch = useDispatch();
  const [complaint_number, setcomplaint_number] = useState("");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const searchComplaint = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formdata = new FormData();
    formdata.append("complain_number", complaint_number);
    const response = await dispatch(trackComplaint(formdata));
    if (response) {
      console.log(response);
      setData(response);
      setLoading(false);
    } else {
      setLoading(false);
      setData(false);
    }
  };
  return (
    <div className="container">
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
          <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <BreadCrumb crumbs={breadcrumbs} />

            <div class="d-flex align-items-center">
              <Link
                to="/help-desk"
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                Register a Complaint
              </Link>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="title-box">
            <h4>Track Complaint</h4>
          </div>
          <div class="row">
            <div className="col-md-2"></div>
            <div class="col-md-8 mb-4">
              <div class="card mb-4">
                <div class="myForm">
                  <FormLabel>Search Complaint</FormLabel>
                  <Form className="d-flex " onSubmit={searchComplaint}>
                    <FormControl
                      type="search"
                      placeholder="Enter Tracking Number"
                      className="me-2"
                      aria-label="Search"
                      value={complaint_number}
                      onChange={(e) => setcomplaint_number(e.target.value)}
                      required
                    />
                    <Button type="submit" variant="outline-success">
                      Search
                    </Button>
                  </Form>
                </div>
              </div>
              {loading ? (
                <div className="text-center">
                  <Spinner animation="border" role="status" size="lg">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : data ? (
                <Details data={data} />
              ) : data === false ? (
                "No Record Found"
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackComplaint;
