import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { memberRegisterAction } from "../../store/actions/MemberAction/memberActions";
import {
  getCategory,
  getpagesubCategory,
  getsubCategory,
} from "../../store/actions/printActions/printcategory";
import TradeSubtradeSelector from "../misc/TradeSubtradeSelector";
import StateCitySelector from "../misc/StateCitySelector";
import { Button, Modal } from "react-bootstrap";

const initialState = {
  membership_type: "",
  userInput: "",
  course: "",
  passing_year: "",
  student_name: "",
  father_name: "",
  mother_name: "",
  aadhaar_number: "",
  mobile: "",
  address: "",
  center_name: "",
  center_address: "",
  center_state: "",
  center_city: "",
  roll_number: "",
  certificate_number: "",
  city: "",
  state: "",
  pin_code: "",
  examination_body_name: "",
  examination_state: "New Delhi",
  examination_city: "East Delhi",
  examination_address: "",
  document_type: "",
};

function Nvpce() {
  const dispatch = useDispatch();
  const [data, setdata] = useState(initialState);
  const [captchaText, setCaptchaText] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [issecValid, setIssecValid] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [captchaerror, setCaptchaerror] = useState("");
  const [certificate_picpreview, setcertificate_picpreview] = useState(null);
  const [certificate_pic, setcertificate_pic] = useState([]);
  const [document_pic, setdocument_pic] = useState([]);
  const [document_picpreview, setdocument_picpreview] = useState(null);
  const [student_picpreview, setstudent_picpreview] = useState(null);
  const [student_pic, setstudent_pic] = useState([]);
  const [termsService, setTermsService] = useState(0);
  const [registerno, setRegisterno] = useState("");
  const [termlist, settermlist] = useState([]);
  const [datalist, setDatalist] = useState([]);
  const [courselist, setcourseList] = useState([]);
  const [rollNumber, setRollNumber] = useState("");
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [passyear, setPassYear] = useState("");

  const {
    membership_type,
    course,
    passing_year,
    student_name,
    father_name,
    mother_name,
    aadhaar_number,
    mobile,
    address,
    userInput,
    center_name,
    roll_number,
    certificate_number,
    city,
    state,
    pin_code: Pincode,
    center_address,
    center_city,
    center_state,
    examination_body_name,
    course_name,
    examination_state,
    examination_city,
    examination_address,
    document_type: document,
    sub_trade,
  } = data;

  const handleClose = () => {
    setShowModal(false);
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(registerno)
      .then(() => {
        toast.success("Registration number copied to clipboard!");
      })
      .catch((err) => {
        toast.error("Could not copy text: ", err);
      });
  };

  useEffect(async () => {
    generateCaptcha();
    const catResponse = await dispatch(getCategory());

    const subresponse = await dispatch(getsubCategory(catResponse.data[4]?.id));
    setDatalist(subresponse.data);
  }, []);

  const generateCaptcha = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let captcha = "";
    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      captcha += characters[randomIndex];
    }
    setCaptchaText(captcha);
  };

  const checkCaptcha = () => {
    if (data.userInput === captchaText) {
      setIsValid(true);
      setCaptchaerror("");
    } else {
      setIsValid(false);
      toast.error(setCaptchaerror("CAPTCHA does not match.Please try again"));
    }
  };

  const handleRollNumberSubmit = async () => {
    try {
      const response = await fetch(
        `https://npvec.com/api/public/api/center/student/verify?roll_number=${rollNumber}`
      );
      if (response.ok) {
        const data = await response.json();
        // Check the response and set form visibility
        console.log("data", data);
        setIsFormVisible(true);
        const year =
          data?.data?.result?.Marksheet &&
          data?.data?.result?.Marksheet[0]?.exam_date.split("-");
        const passingYear = year ? year[1] : "";
        console.log("year=====>", passingYear);
        setdata({
          membership_type: "",
          userInput: "",
          course: data?.data?.course?.name,
          passing_year: passingYear,
          student_name: data?.data?.name,
          father_name: data?.data?.student_details?.father_name,
          mother_name: data?.data?.student_details?.mother_name,
          aadhaar_number: data?.data?.aadhar,
          mobile: data?.data?.student_details?.mobile,
          address: data?.data?.student_details?.address,
          center_name: data?.data?.center?.center_name,
          center_address: data?.data?.center?.center_address,
          center_state: data?.data?.center?.center_state,
          center_city: data?.data?.center?.center_city,
          roll_number: data?.data?.roll_number,
          certificate_number: data?.data?.result?.certificateNUmber,
          city: data?.data?.student_details?.city,
          state: data?.data?.student_details?.state,
          course_name: data?.data?.course?.name,
          pin_code: "",
          examination_body_name:
            "National Paramedical & Vocational Education Council (New Delhi)",
          examination_state: "Delhi",
          examination_city: "East Delhi",
          examination_address: "8A POCKET B-3 MAYUR VIHAR PHASE-III NEW DELHI",
          document_type: "",
          is_npvec: true,
        });
        // setPassYear(data?.data?.result?.Marksheet[0]?.exam_date);
        setstudent_pic(data?.data?.profile_pic?.file);
        setstudent_picpreview(data?.data?.profile_pic?.file);

        setcertificate_pic(data?.data?.result?.CertificateData);
        if (setcertificate_pic) {
          setcertificate_picpreview("/images/pdf.png");
        }
        // You might want to do something with the response data
      } else {
        // Handle errors
        console.error("Roll number verification failed");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  console.log("roll_number", data);

  console.log("certificate_picpreview", certificate_picpreview);

  const handleChange = async (e) => {
    const { name, value } = e.target;

    if (name === "membership_type") {
      setdata({
        ...data,
        [name]: value,
      });

      // Fetch subpages when the membership_type is changed
      const subpageResponse = await dispatch(getpagesubCategory(value));
      console.log("subpageResponse", subpageResponse);
      // You can handle the response data here
      settermlist(subpageResponse.data || []);
    } else {
      setdata({
        ...data,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("data", data);
    var formdata = new FormData();
    if (userInput) {
      for (let [key, value] of Object.entries(data)) {
        if (Array.isArray(value)) {
          formdata.append(key, JSON.stringify(value));
        } else {
          formdata.append(key, value);
        }
      }
      if (certificate_pic) {
        formdata.append("certificate_file", certificate_pic);
      } else {
        formdata.append("certificate_file", document_pic);
      }

      formdata.append("student_image", student_pic);
      formdata.append("is_accepted", termsService);
      // formdata.append("ragistration_certificate", ragistration_pic);
      // formdata.append("diploma_certificate", diploma_pic);

      setSubmitting(true);
      const response = await dispatch(memberRegisterAction(formdata));
      console.log("response22", response);
      if (response.status && response.status === true) {
        setShowModal(true);
        setRegisterno(response.data.registration_number);
        setdata(initialState);

        //   setfacilityDetail([]);
        //   setStaffDetail([]);

        setSubmitting(false);
      } else {
        setSubmitting(false);
        toast.error(response.data.message);
      }
    } else {
      toast.error("Please Fill the Capctha");
    }
    // dispatch(centerRegisterAction(formdata));
  };

  return (
    <div>
      {!isFormVisible && (
        <div>
          <input
            type="text"
            placeholder="Enter Roll Number"
            value={rollNumber}
            onChange={(e) => setRollNumber(e.target.value)}
          />
          <button onClick={handleRollNumberSubmit}>Submit Roll Number</button>
        </div>
      )}

      {isFormVisible && (
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <>
                  <div className="col-md-12">
                    <div className="formTitle"> Details</div>
                  </div>
                  <div className="col-md-4">
                    <div className="boxlabel">
                      <p>Memebrship Type</p>

                      <select
                        className="form-control"
                        name="membership_type"
                        value={data.membership_type}
                        onChange={handleChange}
                      >
                        {datalist?.map((ele) => {
                          return (
                            <>
                              <option value="" disabled selected>
                                select membertype
                              </option>
                              <option key={ele.id} value={ele?.id}>
                                {ele?.name}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="boxlabel">
                      <p>Student Name</p>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="student_name"
                        value={data?.student_name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-12">
                    <div className="Fileboxbale">
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="customFiler73"
                          name="student_image"
                          onChange={(e) => {
                            setstudent_pic(e.target.files[0]);
                            setstudent_picpreview(
                              URL.createObjectURL(e.target.files[0])
                            );
                          }}
                        />
                        <label className="custom-file2" for="customFiler73">
                          {student_picpreview ? (
                            <img
                              src={student_picpreview}
                              height={"100px"}
                              id="frontphoto"
                            />
                          ) : (
                            <>
                              <img
                                src="/images/dummy.png"
                                height={"100px"}
                              ></img>
                            </>
                          )}
                          <h4>Upload Student Image</h4>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="boxlabel">
                      <p>Father Name</p>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="father_name"
                        value={father_name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="boxlabel">
                      <p>Mother Name</p>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="mother_name"
                        value={mother_name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="boxlabel">
                      <p> Mobile no</p>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="mobile"
                        value={mobile}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="boxlabel">
                      <p>Roll Number</p>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="roll_number"
                        value={roll_number}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="boxlabel">
                      <p>Certificate Number</p>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="certificate_number"
                        value={certificate_number}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="boxlabel">
                      <p>Passing Year</p>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="passing_year"
                        value={passing_year}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {!certificate_pic && (
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Document Type</p>
                        <select
                          className="form-control form-control-sm"
                          name="document_type"
                          value={document}
                          onChange={handleChange}
                        >
                          <option value="" disabled selected>
                            Select Document Type
                          </option>
                          <option value="registration certificate">
                            Registration Certificate
                          </option>
                          <option value="diploma">Diploma</option>
                        </select>
                      </div>
                    </div>
                  )}

                  {!course_name ? (
                    <div className="row">
                      <div className="col-md-6">
                        <TradeSubtradeSelector data={data} setData={setdata} />
                      </div>
                      {sub_trade && (
                        <div className="col-md-6">
                          <div>
                            <p>Course</p>
                            <select
                              className="form-control"
                              name="course"
                              value={course}
                              onChange={handleChange}
                            >
                              {courselist?.data?.map((ele) => (
                                <option key={ele.id} value={ele?.id}>
                                  {ele?.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Course</p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="course"
                          value={course}
                          // onChange={handleChange}
                        />
                      </div>
                    </div>
                  )}

                  <div className="col-md-12">
                    <div className="formTitle"> Address</div>
                  </div>
                  <div className="col-md-4">
                    <div className="boxlabel">
                      <p> Addhar Card Number</p>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="aadhaar_number"
                        value={aadhaar_number}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="boxlabel">
                      <p>Address </p>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="address"
                        value={address}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <StateCitySelector
                    user_city={city}
                    user_state={state}
                    set_user_state={(e) => setdata({ ...data, state: e })}
                    set_user_city={(e) => setdata({ ...data, city: e })}
                  />

                  <div className="col-md-4">
                    <div className="boxlabel">
                      <p>PinCode </p>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="pin_code"
                        value={Pincode}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </>
                <>
                  <div className="col-md-12">
                    <div className="formTitle">Center Details</div>
                  </div>
                  <div className="col-md-4">
                    <div className="boxlabel">
                      <p>Center Name </p>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="center_name"
                        value={center_name}
                        onChange={handleChange}
                        readonly
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="boxlabel">
                      <p>Center Address </p>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="center_address"
                        value={center_address}
                        onChange={handleChange}
                        readonly
                        required
                      />
                    </div>
                  </div>
                  <StateCitySelector
                    user_city={center_city}
                    user_state={center_state}
                    set_user_state={(e) =>
                      setdata({ ...data, center_state: e })
                    }
                    set_user_city={(e) => setdata({ ...data, center_city: e })}
                  />

                  <div className="col-md-4">
                    <div className="boxlabel">
                      <p>Examination Body Name </p>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="examination_body_name"
                        value={examination_body_name}
                        onChange={handleChange}
                        readonly
                        required
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="boxlabel">
                      <p>Examination Body Address </p>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="examination_address"
                        value={examination_address}
                        onChange={handleChange}
                        readonly
                        required
                      />
                    </div>
                  </div>

                  <StateCitySelector
                    user_city={examination_city}
                    user_state={examination_state}
                    set_user_state={(e) =>
                      setdata({ ...data, examination_state: e })
                    }
                    set_user_city={(e) =>
                      setdata({ ...data, examination_city: e })
                    }
                  />
                </>
              </div>
            </div>
            {!certificate_pic && (
              <>
                <div className="col-md-12">
                  <div className="formTitle">Documents</div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="Fileboxbale">
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="customFiler7"
                        name="certificate_file"
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={(e) => {
                          setdocument_pic(e.target.files[0]);
                          // setcertificate_picpreview(
                          //   URL.createObjectURL(e.target.files[0])
                          // );
                          const file = e.target.files[0]; // Define 'file' here
                          setdocument_pic(file);

                          if (file) {
                            if (file.type.startsWith("image/")) {
                              // It's an image file
                              setdocument_picpreview(URL.createObjectURL(file));
                            } else if (file.type === "application/pdf") {
                              // It's a PDF file
                              setdocument_picpreview("/images/pdf.png");
                            }
                          } else {
                            setdocument_picpreview(null);
                          }
                        }}
                      />

                      <label className="custom-file2" for="customFiler7">
                        {document_picpreview ? (
                          <img
                            src={document_picpreview}
                            height={"100px"}
                            id="frontphoto"
                          />
                        ) : (
                          <>
                            <img src="/images/dummy.png" height={"100px"}></img>
                          </>
                        )}
                        <h4>Upload The Document you Choose</h4>
                      </label>
                    </div>
                  </div>
                </div>
              </>
            )}
            {/* <div className="col-md-12">
                  <div className="captchaSection">
                    <h4>Verify you're human</h4>

                    <div className="captchaContent">
                      <div className="captchaDisplay">
                        {captchaText}
                        <button
                          type="button"
                          onClick={generateCaptcha}
                          className="refreshCaptchaBtn"
                        >
                          Refresh
                        </button>
                      </div>

                      <div className="captchaInput">
                        <input
                          type="text"
                          placeholder="Enter CAPTCHA"
                          value={userInput}
                          onChange={handleChange}
                          className="captchaTextBox"
                        />
                      </div>
                    </div>

                    <div className="captchaVerification">
                      <button
                        type="button"
                        onClick={checkCaptcha}
                        className="verifyCaptchaBtn"
                      >
                        Verify
                      </button>
                      {isValid && (
                        <p className="validCaptchaText">CAPTCHA is valid!</p>
                      )}
                      {!isValid && captchaerror && (
                        <p className="errorText">{captchaerror}</p>
                      )}
                    </div>
                  </div>
                </div> */}
            <div className="col-md-12">
              <div className="check-box-div">
                {termlist?.do_description && (
                  <>
                    {termlist.do_description}
                    <br />
                  </>
                )}
                {termlist?.donts_description && (
                  <>
                    {termlist.donts_description}
                    <br />
                  </>
                )}
                {termlist?.terms_condition && (
                  <>
                    {termlist.terms_condition}
                    <br />
                  </>
                )}
                {termlist?.do_description ||
                termlist?.donts_description ||
                termlist?.terms_condition ? (
                  <label
                    for="termsOfService"
                    style={{
                      cursor: "pointer",
                      display: "inline-block",
                      margin: "10px 0",
                    }}
                  >
                    <input
                      type="checkbox"
                      className="check-button"
                      name="is_accepted"
                      id="termsOfService"
                      onChange={() =>
                        setTermsService(termsService === 0 ? 1 : 0)
                      }
                    />
                    <span style={{ fontSize: "14px", marginLeft: "5px" }}>
                      I accept the Terms of Service
                    </span>
                  </label>
                ) : null}
              </div>
            </div>

            <div className="col-md-12">
              <div className="captchaSection">
                <button
                  className="captchaButton"
                  type="button"
                  onClick={generateCaptcha}
                >
                  Generate CAPTCHA
                </button>
                <div className="captchaVerification">
                  <span>
                    CAPTCHA:
                    {captchaText.split("").map((char, index) => (
                      <span className="captchaCharacter" key={index}>
                        {char}
                      </span>
                    ))}
                  </span>
                  <input
                    type="text"
                    placeholder="Enter CAPTCHA"
                    name="userInput"
                    value={data.userInput || ""}
                    onChange={handleChange}
                    className="captchaInput"
                  />
                  <button
                    className="captchaButton"
                    type="button"
                    onClick={checkCaptcha}
                  >
                    Verify
                  </button>
                  {isValid && (
                    <p className="validCaptchaText">CAPTCHA is valid!</p>
                  )}
                </div>
                {!isValid && captchaerror && (
                  <p className="errorText">{captchaerror}</p>
                )}
              </div>
            </div>

            <div className="col-md-12">
              <div
                className="btn-groups mt-4 text-center"
                role="group"
                aria-label="First group"
              >
                <button
                  className="btn btn-Next"
                  type="submit"
                  disabled={submitting}
                >
                  Submit
                  <span>
                    <svg
                      width="35"
                      height="12"
                      viewBox="0 0 35 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M34.8403 6.00003C34.8403 6.2151 34.7582 6.43013 34.5943 6.5941L29.4346 11.7538C29.1063 12.0821 28.5742 12.0821 28.2461 11.7538C27.918 11.4257 27.918 10.8937 28.2461 10.5654L32.8117 6.00003L28.2462 1.43461C27.9181 1.10639 27.9181 0.574383 28.2462 0.246316C28.5743 -0.0820704 29.1065 -0.0820704 29.4347 0.246316L34.5945 5.40596C34.7584 5.57001 34.8403 5.78505 34.8403 6.00003Z"
                        fill="white"
                      ></path>
                      <line
                        x1="33.25"
                        y1="5.94995"
                        x2="0.75"
                        y2="5.94995"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      ></line>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
          {showModal && (
            <Modal show={showModal} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Success!</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                This is Your Registration number : <strong>{registerno}</strong>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={copyToClipboard}>
                  Copy Registration Number
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </form>
      )}
    </div>
  );
}

export default Nvpce;
