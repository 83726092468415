import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import { getStudentCertificates } from "../../../store/actions/studentAction/studentAuth";
import Loader from "../../Alert/Loader";

export default function Certificates() {
  const dispatch = useDispatch();
  const { certificates } = useSelector((state) => state.student);
  useEffect(() => {
    if (!certificates) {
      dispatch(getStudentCertificates());
    }
  }, []);
  return (
    <>
      <div className="Title-box">
        <div className="row">
          <div className="col-md-6">
            <div className="BigTitle">My Certificates</div>
          </div>
        </div>
      </div>
      {!certificates ? (
        <Loader active={true} />
      ) : certificates.error ? (
        <h4>{certificates.error}</h4>
      ) : (
        <div className="mid-content-box">
          <div className="Widthdata">
            <div className="Applicationdata s">
              <div className="row ">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th> Roll Number </th>

                        <th>Mark sheet </th>
                        <th>Certificate </th>
                        <th> Reg.Certificate </th>
                      </tr>
                      <tr>
                        <td>{certificates.name}</td>
                        <td>{certificates.roll_number}</td>

                        <td>
                          {certificates.marksheet?.length > 0 ? (
                            <table border="1">
                              <tr>
                                <th>Date/Sem.</th>
                                <th>Enrollment</th>
                                <th>Markseet</th>
                              </tr>

                              {certificates.marksheet.map((m, index) => (
                                <tr>
                                  <td>
                                    {moment(m.date, "YYYY-MM").format(
                                      "MM-YYYY"
                                    )}
                                    <span> / </span> {m.semster_year}
                                  </td>
                                  <td>{m.enrollment_no}</td>
                                  <td>
                                    <a
                                      href={m.pdf}
                                      target="_blank"
                                      download={`Marksheet-${m.semster_year}`}
                                    >
                                      Download
                                    </a>
                                  </td>
                                </tr>
                              ))}
                            </table>
                          ) : (
                            <h6>No Marksheet Found</h6>
                          )}
                        </td>
                        <td>
                          {certificates.certificate?.length > 0 ? (
                            <table border="1">
                              <tr>
                                <th> Date/Certificate No.</th>

                                <th>Certificate</th>
                              </tr>
                              {certificates.certificate.map((c, index) => (
                                <tr key={index}>
                                  <td>
                                    {moment(c.date, "YYYY-MM-DD").format(
                                      "DD-MM-YYYY"
                                    )}{" "}
                                    / {c.certificate_reg_no}
                                  </td>
                                  <td>
                                    <a
                                      href={c.pdf}
                                      target="_blank"
                                      download={`Certificate-${c.certificate_reg_no}`}
                                    >
                                      Download
                                    </a>
                                  </td>
                                </tr>
                              ))}
                            </table>
                          ) : (
                            <h6>No Certificate Found</h6>
                          )}
                        </td>
                        <td>
                          {certificates.reg?.length > 0 ? (
                            <table border="1">
                              <tr>
                                <th> Date/Certificate No.</th>

                                <th>Certificate</th>
                              </tr>
                              {certificates.reg.map((c, index) => (
                                <tr key={index}>
                                  <td>
                                    {moment(c.date, "YYYY-MM-DD").format(
                                      "DD-MM-YYYY"
                                    )}{" "}
                                    / {c.certificate_reg_no}
                                  </td>
                                  <td>
                                    <a
                                      href={c.pdf}
                                      target="_blank"
                                      download={`Certificate-${c.certificate_reg_no}`}
                                    >
                                      Download
                                    </a>
                                  </td>
                                </tr>
                              ))}
                            </table>
                          ) : (
                            <h6>No Certificate Found</h6>
                          )}
                        </td>
                      </tr>
                    </thead>
                    <tbody></tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
