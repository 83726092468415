import { getDataAPI, postDataAPI } from "../../../services/apiWrapper";
import { exportToCSV } from "../../../utils/exportCsv";
import { ALERT, SET_SUB_TRADE, SET_TRADE } from "../actionTypes";
import { successAlert } from "../../../utils/alert";

export const createTrade = (data) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      postDataAPI("admin/trade/create", data, token)
    );
    dispatch({ type: ALERT, payload: { loading: false } });

    dispatch({ type: ALERT, payload: { success: response.data.message } });
    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({ type: ALERT, payload: { error: error.response.data.status } });
  }
};
export const updateTrade = (data) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      postDataAPI("admin/trade/udpate", data, token)
    );
    dispatch({ type: ALERT, payload: { loading: false } });

    dispatch({ type: ALERT, payload: { success: response.data.message } });
    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({ type: ALERT, payload: { error: error.response.data.status } });
  }
};
export const getTrades = () => async (dispatch) => {
  try {
    // dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(getDataAPI("admin/trade/list", token));
    // dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({ type: SET_TRADE, payload: response.data.data });
    return response.data.data;
  } catch (error) {
    // dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({ type: ALERT, payload: { error: error.response.data.status } });
    return [];
  }
};
export const editTrade = (id) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(getDataAPI(`admin/trade/${id}`, token));
    dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({ type: ALERT, payload: { success: response.data.message } });

    // dispatch({ type: SET_TRADE, payload: response.data.data });
    // console.log(response);
    return response.data.data;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({ type: ALERT, payload: { error: error.response.data.status } });
    return false;
  }
};

export const updateSubTrade = (data) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      postDataAPI("admin/subtrade/updateSubTrade", data, token)
    );
    dispatch({ type: ALERT, payload: { loading: false } });

    dispatch({ type: ALERT, payload: { success: response.data.message } });
    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({ type: ALERT, payload: { error: error.response.data.status } });
  }
};

export const createSubTrade = (data) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      postDataAPI("admin/subtrade/create", data, token)
    );
    dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({ type: ALERT, payload: { success: response.data.message } });
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({ type: ALERT, payload: { error: error.response.data.status } });
  }
};

export const editSubTrade = (id) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(getDataAPI(`admin/subtrade/${id}`, token));
    dispatch({ type: ALERT, payload: { loading: false } });
    // dispatch({ type: ALERT, payload: { success: response.data.message } });
    return response.data.data;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({ type: ALERT, payload: { error: error.response.data.status } });
  }
};

export const getSubTrades = () => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(getDataAPI("admin/subtrade/list", token));
    dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({ type: SET_SUB_TRADE, payload: response.data.data });
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    // dispatch({ type: ALERT, payload: { error: error.response.data.status } });
  }
};

export const getSubTradesPagination = (page) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      getDataAPI(`admin/all/subtrade/list?page=${page}`, token)
    );
    return response.data.data;
  } catch (error) {
    return false;
    // dispatch({ type: ALERT, payload: { error: error.response.data.status } });
  }
};

export const getTradesPagination = (page) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      getDataAPI(`admin/all/trade/list?page=${page}`, token)
    );
    return response.data.data;
  } catch (error) {
    return false;
    // dispatch({ type: ALERT, payload: { error: error.response.data.status } });
  }
};

export const exportSubtrades = (data = []) => {
  const dataToExport = [];
  data.forEach((ele, index) => {
    dataToExport.push({
      Sno: index + 1,
      Trade: ele.trade?.name,
      "Sub Trade": ele.companyName,
      "Sub Trade": ele.name,
    });
  });
  exportToCSV(dataToExport, "Subtrades");
  successAlert("CSV downloaded successfully.");
};
