import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { CLEAR_ASSIGNMENT_QUESTION } from "../../../store/actions/actionTypes";
import { getAssignmentQuestions } from "../../../store/actions/councilActions/assignmentAction";
import Loader from "../../Alert/Loader";
import BreadCrumb from "../../BreadCrumb";

const ViewAssignment = () => {
  const dispatch = useDispatch();
  const { questions } = useSelector((state) => state.admin);
  const breadcrumbs = [
    {
      path: "/admin/dashboard",
      label: "Dashboard",
    },
    {
      label: "View Assignment",
    },
  ];
  const { id } = useParams();
  useEffect(() => {
    dispatch(getAssignmentQuestions(id));
    return () => dispatch({ type: CLEAR_ASSIGNMENT_QUESTION });
  }, [id]);
  return (
    <div>
      {" "}
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
          <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <BreadCrumb crumbs={breadcrumbs} />

            <div class="d-flex align-items-center">
              <Link
                to="/admin/view-assignment-list"
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                Assignment List
              </Link>
              <Link
                to="/admin/add-assignment"
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                Add Assignment
              </Link>
            </div>
          </div>
        </div>
        <div class="container">
          {!questions ? (
            <Loader active={true} />
          ) : (
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-12">
                <table
                  class="table table-separate table-head-custom table-checkable"
                  id="kt_datatable"
                >
                  <thead>
                    <tr>
                      <th>Sr.No</th>
                      <th>Question</th>
                      <th>Question Type</th>
                      <th>Answer</th>
                      <th>Action </th>
                    </tr>
                  </thead>
                  <tbody>
                    {questions.length > 0 ? (
                      questions.map((question, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{question.question}</td>
                          <td>
                            {question.question_type == "1"
                              ? "MCQ"
                              : "True/False"}
                          </td>
                          <td>
                            {
                              question.mcq.find((q) => q.is_correct == "1")
                                ?.answer
                            }
                          </td>
                          <td>
                            <Link
                              to={`/admin/edit-question/${question.id}`}
                              class="btn btn-sm btn-danger"
                              title="Edit details"
                            >
                              Edit
                            </Link>
                            <a
                              href="/council/deleteassignment/<%=item.id%>"
                              onclick="return confirm('Are you sure you want to delete this item')"
                              class="btn btn-sm btn-clean btn-icon"
                              title="Delete"
                            >
                              <i class="la la-trash"></i>
                            </a>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="text-center">
                        <td colSpan={4}>No Question Added</td>
                      </tr>
                    )}
                    {/* <% assigment.forEach((item,index)=>{%> */}~
                    {/* <%})%> */}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
        <div class="col-md-2"></div>
      </div>
      {/* </div>
      </div> */}
    </div>
  );
};

export default ViewAssignment;
