import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getSubject } from "../../../store/actions/studentAction/assessmentActions";

import "./css/dashboard.css";

export default function Index() {
  const dispatch = useDispatch();
  const { student } = useSelector((state) => state);
  const { studentAuth, assessment } = student;
  const [clearedAssessment, setClearedAssessment] = useState("");
  const { profile } = studentAuth;
  const fetchData = async () => {
    const response = await dispatch(getSubject(profile.id, profile.course.id));
  };

  useEffect(() => {
    if (profile) {
      fetchData();
    }
  }, [profile]);

  useEffect(() => {
    if (assessment) {
      let count = 0;
      if (assessment.exam_type == "course_wise") {
        Object.values(assessment.semester)[0]?.map((sub, i) => {
          if (sub.marks >= 4) {
            count++;
          }
        });
        setClearedAssessment(count);
      } else {
        Object.keys(assessment.semester).map((sem, index) => {
          assessment.semester[sem].map((sub, i) => {
            if (sub.marks >= 4) {
              count++;
            }
          });
        });
        setClearedAssessment(count);
      }
    }
  }, [assessment]);
  return (
    <div className="mid-content-box">
      <div className="box-welcome">
        <div className="row">
          <div className="col-md-6">
            <div className="box-status">
              <div className="box-statusImg">
                <img src="/images/girl.png" />
              </div>
              <div className="box-statusData">
                <h2>
                  Hello <span>{profile.name}</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid-box">
        <div className="row">
          <div className="col-md-3">
            <div className="grid-data gcolor1">
              <div className="img-data">
                <img src="/images/student.png" />
              </div>
              <div className="grid-content">
                <h5>Roll Number</h5>
                <h6>{profile.roll_number}</h6>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="grid-data gcolor2">
              <div className="img-data">
                <img src="/images/student.png" />
              </div>
              <div className="grid-content">
                <h5>Name</h5>
                <h6>{profile.name}</h6>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="grid-data gcolor3">
              <div className="img-data">
                <img src="/images/wallet.png" />
              </div>
              <div className="grid-content">
                <h5>Assessment Completed</h5>
                <h6>{clearedAssessment}</h6>
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="grid-data gcolor5">
              <div className="img-data">
                {" "}
                <img src="/images/wallet.png" />
              </div>
              <div className="grid-content">
                <h5>My Certificates</h5>
                <h6>0</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Title-box">
        <div className="row">
          <div className="col-md-6">
            <div className="BigTitle">My Profile</div>
          </div>
          <div className="col-md-6">
            <div className="CommanForm d-flex">
              <div className="btncomplt">
                <Link to="/student/assessments" className="btn btn-comman">
                  Assessments{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Widthdata">
        <div className="Applicationdata">
          <div className="cartitems">
            <div className="cartitembox">
              {" "}
              <div className="PaymentStatus"></div>
              <div className="cartTop row">
                <div className=" col-lg-3">
                  <div className="text-center">
                    <div className="cartImg m-auto">
                      <img src={profile.profile_pic?.file} />
                    </div>
                  </div>
                </div>
                <div className=" col-lg-9">
                  <div className="">
                    <div className="quick-names text-center">
                      <div className="QVasName text-center ">
                        {profile.name}
                      </div>
                      <div className="QLocationmark">
                        {`${profile.student_details.address}, ${profile.student_details.city}, ${profile.student_details.state}`}
                      </div>
                      <div className="CenterName">
                        <i className="fa fa-building-o" aria-hidden="true"></i>
                        {"  "}
                        {profile.center.center_name}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-3 text-center profile-item">
                  <h5>Course</h5>
                  <h6>{profile.course?.name}</h6>
                </div>
                <div className="col-md-3 text-center profile-item">
                  <h5>Admission Date</h5>
                  <h6>
                    {profile.is_update == "1"
                      ? moment(profile.register_date).format("DD/MM/yyyy")
                      : moment(profile.created_at).format("DD/MM/yyyy")}
                  </h6>
                </div>
                <div className="col-md-3 text-center profile-item ">
                  <h5>Contact Number</h5>
                  <h6>{profile.student_details.mobile}</h6>
                </div>
                <div className="col-md-3 text-center profile-item">
                  <h5>Father Name</h5>
                  <h6>
                    {`${profile.student_details.father_title} ${profile.student_details.father_name}`}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
