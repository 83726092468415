import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Pagination from "../../../misc/Pagination";
import {
  downloadCenterList,
  getCenterList,
  getCenterPaginate,
} from "../../../../store/actions/councilActions/centerActions";
import Loader from "../../../Alert/Loader";
import BreadCrumb from "../../../BreadCrumb";
import { FormControl } from "react-bootstrap";
import { getSubTrades } from "../../../../store/actions/councilActions/trade";

export default function CenterList() {
  const dispatch = useDispatch();
  const [centerlist, setCenterlist] = useState(null);
  const inputRef = useRef(null);
  const [search, setSearch] = useState("");
  const [date, setDate] = useState("");
  const [sub_trade_id, setsub_trade_id] = useState("");
  const [totalPages, settotalPages] = useState(0);
  const [activePage, setactivePage] = useState(1);
  const { admin } = useSelector((state) => state);
  const { subtrades } = admin;

  const breadcrumbs = [
    {
      path: "/admin/dashboard",
      label: "Dashboard",
    },
    {
      // path: "/aboutus",
      label: "Center List",
    },
  ];

  const handleDownload = () => {
    dispatch(downloadCenterList(sub_trade_id));
  };

  useEffect(() => {
    if (!subtrades) {
      dispatch(getSubTrades());
    }
  }, []);

  const fetch = useCallback(async () => {
    const response = await dispatch(
      getCenterPaginate(activePage, sub_trade_id)
    );
    if (response) {
      settotalPages(Math.ceil(response.total / response.per_page));
      setCenterlist(response.data);
    } else {
      setCenterlist([]);
    }
  }, [sub_trade_id, activePage]);

  useEffect(() => {
    fetch();
  }, [fetch]);
  return (
    <div>
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
          <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <BreadCrumb crumbs={breadcrumbs} />

            <div class="d-flex align-items-center"></div>
          </div>
        </div>
        <div className=" mb-4">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-4">
              <select
                class="form-control"
                name="sub_trade_id"
                onChange={(e) => {
                  setsub_trade_id(e.target.value);
                  setactivePage(1);
                }}
                value={sub_trade_id}
                required
              >
                <option value="">Select</option>
                {subtrades &&
                  subtrades.map((subtrade) => (
                    <option key={subtrade.id} value={subtrade.id}>
                      {subtrade.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-4">
              <FormControl
                type="date"
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                  setactivePage(1);
                }}
              />
            </div>
            <div className="col-md-2">
              <button
                onClick={handleDownload}
                className="btn btn-light-primary font-weight-bolder btn-sm"
              >
                Download list
              </button>
            </div>
          </div>
        </div>
        <div class="container" ref={inputRef}>
          <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-12">
              <div class="card card-custom">
                <div class="table-responsive">
                  <table
                    class="table table-separate table-head-custom table-checkable"
                    id="kt_datatable"
                  >
                    <thead>
                      <tr>
                        <th>Sr.No</th>
                        <th>Center Name</th>
                        <th>Address</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Contact No</th>
                        <th>Email</th>
                        <th>Trade</th>

                        <th>Md Name</th>
                        <th>Md Pic</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {centerlist ? (
                        centerlist.length > 0 ? (
                          centerlist.map((center, index) => (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{center.center_name}</td>
                              <td>{center.center_address}</td>
                              <td>{center.center_city}</td>
                              <td>{center.center_state}</td>
                              <td>{center.center_mobile}</td>
                              <td>{center.email}</td>
                              <td>{center.trade?.name}</td>
                              <td>{center.md_name}</td>
                              <td>
                                <img
                                  src={center.md_pic || "/images/dummy.png"}
                                />
                              </td>
                              <td>
                                {center.status == "0"
                                  ? "Pending"
                                  : center.status == "1"
                                  ? "Approved"
                                  : center.status == "3"
                                  ? "Need Reapprove"
                                  : "Rejected"}
                              </td>
                              <td>
                                <Link to={`/admin/centers/${center.id}`}>
                                  View
                                </Link>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={7}>No center found ...</td>
                          </tr>
                        )
                      ) : (
                        <Loader active={true} />
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-md-2"></div>
            {totalPages && (
              <Pagination
                setActivePage={(value) => setactivePage(value)}
                totalPages={totalPages}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
