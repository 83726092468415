import { getDataAPI, postDataAPI } from "../../services/apiWrapper";
import { ALERT, PRINT_STATUS } from "./actionTypes";

export const getPrintStatus = (data) => async (dispatch) => {
    try {
       
        dispatch({
          type: ALERT,
          payload: { loading: true },
        });
        const response = await dispatch(postDataAPI("admin/print/status", data));
        dispatch({
          type: PRINT_STATUS,
          payload: response.data.data,
        });
        dispatch({
          type: ALERT,
          payload: { loading: false },
        });
      } catch (error) {
        dispatch({
          type: ALERT,
          payload: { loading: false },
        });
       
      }
};
