import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./css/assessment.css";

const StartExam = ({ start, setStart, count, totalMarks }) => {
  const { studentAuth } = useSelector((state) => state.student);
  const { profile } = studentAuth;
  const [agree, setAgree] = useState(false);
  return (
    <div className="exam">
      {/* <div class="watermarked"> */}
      {/* <img src="http://placehold.it/500x325.jpg" alt="Photo"/> */}
      {/* <div id="watermark" className="align-self-center text-center">
        {" "}
        <img src="/images/logo.png" alt="Photo" />
      </div> */}
      <div className="mid-content-box">
        <div className="box-welcome ">
          {/* <img src="/images/logo.png" className="back-image" alt="Photo" /> */}
          <div className="row mt-5">
            <div className="col-md-1"></div>
            <div className="col-md-10 mb-5">
              <fieldset>
                <legend className="mb-3">Candidate Details</legend>
                <div className="">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="box-statusImg">
                        <img src={profile.profile_pic?.file} height={"120px"} />
                      </div>
                    </div>

                    <div className="col-md-9">
                      <div className="box-statusData">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-6">Name :</div>
                              <div className="col-md-6">{profile.name}</div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-6">Roll No :</div>
                              <div className="col-md-6">
                                {profile.roll_number}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3">Exam Duration :</div>
                          <div className="col-md-3">{`${count} min`}</div>
                          <div className="col-md-3">Total Marks : </div>
                          <div className="col-md-3">{totalMarks}</div>
                          <div className="col-md-3">Center Name :</div>
                          <div className="col-md-9">
                            {profile.center.center_name}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-1"></div>

            <div className="col-md-10">
              {/* <td style={{ width: "90%", color: "#003AFF" }}> */}
              <input
                id="chkTerms"
                type="checkbox"
                style={{ width: "15px", marginLeft: "0px" }}
                checked={agree}
                onChange={() => setAgree(!agree)}
              />
              I have read and understood the instructions. All computer hardware
              allotted to me are in proper working condition. I declare that I
              am not in possession of / not wearing / not carrying any
              prohibited gadget like mobile phone, bluetooth devices etc. /any
              prohibited material with me into the Examination Hall.I agree that
              in case of not adhering to the instructions, I shall be liable to
              be debarred from this Test and/or to disciplinary action, which
              may include ban from future Tests / Examinations
              {/* </td> */}
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-1"></div>
            <div className="col-md-10">
              <button
                className={`float-right btn-lg btn-success rounded-pill`}
                disabled={!agree}
                onClick={setStart}
              >
                Start Test
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default StartExam;
