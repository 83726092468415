import React, { useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { useDispatch } from "react-redux";
import { rejectCenter } from "../../../../store/actions/councilActions/centerActions";
const RejectCenter = ({ show, onHide, id }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    reject_reason: "",
    id: id,
  });
  const { reject_reason } = data;
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await dispatch(rejectCenter(data, id));
    if (true) {
      onHide();
    }
  };
  return (
    <div>
      <Modal show={show} onHide={onHide} centered>
        <ModalHeader>Approve Center</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <div class="form-group">
              <label>
                Reject reason <span class="text-danger">*</span>
              </label>
              <textarea
                type="text"
                class="form-control"
                placeholder="Enter reject reason"
                name="reject_reason"
                value={reject_reason}
                required
                onChange={handleChange}
              />
            </div>
            <button type="submit" class="btn btn-primary">
              Upload
            </button>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default RejectCenter;
