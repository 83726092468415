import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import { getStudentVerification } from "../../store/actions/globalActions";
import { getPrintStatus } from "../../store/actions/printStatusAction";
import { ALERT, AUTH_FAIL } from "../../store/actions/actionTypes";
import { useEffect } from "react";

export default function Print() {
  const [user_type, setuser_type] = useState("");
  const [roll, setroll] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [student, setStudent] = useState(null);
  const [backgroundSelection, setBackgroundSelection] = useState("with");
  const [expand, setExpand] = useState(false);
  const { admin } = useSelector((state) => state);
  const { adminAuth } = admin;
  const { profile } = adminAuth;
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await dispatch(getStudentVerification(roll));
    if (response) {
      setLoading(false);
      setStudent(response);
    } else {
      setStudent(false);
      setLoading(false);
    }

    console.log("student.result.result", student);
  };

  const handleStatus = async (e, studentId, url, type) => {
    e.preventDefault();
    let payload = {
      student_id: studentId,
      type,
    };

    // Get the current date
    const date = new Date();
    const year = date.getFullYear();
    // Ensure month and day are two digits
    const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() is zero-based, add 1 to make it 1-12
    const day = String(date.getDate()).padStart(2, "0"); // getDate() gives day of the month
    // Construct formatted date string in 'YYYY-MM-DD' format
    const formattedDate = `${year}-${month}-${day}`;

    switch (type) {
      case "marksheet":
        const semester = url.split("/").pop(); // Extract the last part of the URL
        payload = {
          ...payload,
          semester,
          marksheet_print_date: formattedDate, // Use formatted date
        };
        break;
      case "result":
        payload = {
          ...payload,
          result_print_date: formattedDate, // Use formatted date
        };
        break;
      case "certificate":
        payload = {
          ...payload,
          certificate_print_date: formattedDate, // Use formatted date
        };
        break;
    }

    dispatch(getPrintStatus(payload));
    const updatedStudent = await dispatch(getStudentVerification(roll));
    console.log("updatedStudent", updatedStudent);
    if (updatedStudent) {
      setStudent(updatedStudent);
    }
    console.log("updatedStudent", updatedStudent);
    dispatch({
      type: ALERT,
      payload: { loading: true },
    });
  };

  console.log("student", student);

  useEffect(() => {
    if (profile) {
      setuser_type(profile.admin.user_type);
    }
  }, [profile]);

  useEffect(() => {
    if (expand) {
      setExpand(false);
    }
  }, [window.location.pathname]);
  return (
    <div>
      <div className="gallery-box">
        <div className="container">
          <div className="title-box">
            <div className="col-md-12 inner">
              <section className="innerbanner">
                <h2>Print Portal</h2>
                <ol className="breadcrumb">
                  <li>
                    <Link to="/" className="none">
                      HOME
                    </Link>
                  </li>
                  <li>/ </li>
                  <li className="active">Print Portal</li>
                </ol>
              </section>
              <div className="clear-fix"></div>
              <div className="col-lg-12">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-12">
                      {" "}
                      <h3>
                        {/* Search by Month &amp; Year of Examination Held-In with */}
                        Enter Roll Number
                      </h3>
                    </div>
                    <div className="col-lg-4 table-box">
                      Candidate's Roll Number
                    </div>
                    <div className="col-lg-8 table-box">
                      <input
                        type="text"
                        maxlength="10"
                        id="ContentPlaceHolder1_txtRollNo"
                        className="input-md textinput textInput form-control"
                        placeholder="Please Enter Roll Number"
                        name="roll"
                        value={roll}
                        onChange={(e) => setroll(e.target.value)}
                        style={{ width: "202px" }}
                        required
                      />
                    </div>
                    <div className="col-lg-4 table-box"></div>
                    <div className="col-lg-8 table-box">
                      <input
                        type="submit"
                        value="Search Result"
                        className="btn btn-primary btn btn-info"
                      />
                    </div>
                  </div>{" "}
                </form>
              </div>

              <label className="radio-label">
                <input
                  className="radio-input"
                  type="radio"
                  value="with"
                  checked={backgroundSelection === "with"}
                  onChange={(e) => setBackgroundSelection(e.target.value)}
                />
                With Background
              </label>

              <label className="radio-label">
                <input
                  className="radio-input"
                  type="radio"
                  value="without"
                  checked={backgroundSelection === "without"}
                  onChange={(e) => setBackgroundSelection(e.target.value)}
                />
                Without Background
              </label>

              {loading ? (
                <div className="text-center">
                  <Spinner animation="border" role="status" size="lg">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : student && student.result && student.result.Marksheet ? (
                <div class="Widthdata">
                  <div class="Applicationdata s">
                    <div class="row ">
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th> Roll Number </th>
                              <th>Enrolment Number </th>
                              <th>Student Name </th>
                              <th>Center Name </th>
                              <th>Course </th>
                              <th>Marksheet </th>
                              <th>Result</th>
                              <th>Certificate</th>
                              <th> status </th>
                            </tr>
                          </thead>

                          <tbody>
                            {student &&
                              student.result &&
                              student.result.Marksheet &&
                              student.result.Marksheet.map(
                                (marksheet, index) => (
                                  <tr key={index}>
                                    {index === 0 && (
                                      <>
                                        <td
                                          rowSpan={
                                            student.result.Marksheet.length
                                          }
                                        >
                                          {student.roll_number}
                                        </td>
                                        <td
                                          rowSpan={
                                            student.result.Marksheet.length
                                          }
                                        >
                                          {student.enrollment}
                                        </td>
                                        <td
                                          rowSpan={
                                            student.result.Marksheet.length
                                          }
                                        >
                                          {student.name}
                                        </td>
                                        <td
                                          rowSpan={
                                            student.result.Marksheet.length
                                          }
                                        >
                                          {student.center.center_name}
                                        </td>
                                        <td
                                          rowSpan={
                                            student.result.Marksheet.length
                                          }
                                        >
                                          {student.course?.name ||
                                            student.course}
                                        </td>
                                      </>
                                    )}
                                    <td>
                                      <a
                                        href={`${marksheet.Marksheet}/${backgroundSelection}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={(e) => {
                                          handleStatus(
                                            e,
                                            student.profile_pic.student_id,
                                            marksheet.Marksheet,
                                            "marksheet"
                                          );
                                          window.open(
                                            `${marksheet.Marksheet}/${backgroundSelection}`,
                                            "_blank"
                                          );
                                        }}
                                      >
                                        View Marksheet {index + 1}
                                      </a>
                                      <strong>Marksheet:</strong>
                                      {console.log(
                                        "",
                                        marksheet.marksheet_print_status
                                      )}
                                      {marksheet.marksheet_print_status ===
                                      1 ? (
                                        <span className="badge bg-success">
                                          Printed
                                        </span>
                                      ) : (
                                        <span className="badge bg-danger">
                                          Not Printed
                                        </span>
                                      )}
                                      <br />
                                      {student?.marksheet_print_date?.map(
                                        (ele) => {
                                          return (
                                            <>
                                              <div>
                                                {ele.marksheet_print_date
                                                  ? moment(
                                                      ele.marksheet_print_date
                                                    ).format("YYYY-MM-DD")
                                                  : "-"}
                                              </div>
                                            </>
                                          );
                                        }
                                      )}
                                    </td>
                                    {index === 0 && (
                                      <>
                                        <td
                                          rowSpan={
                                            student.result.Marksheet.length
                                          }
                                        >
                                          <a
                                            href={`${student.result.result}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            onClick={(e) => {
                                              handleStatus(
                                                e,
                                                student.profile_pic.student_id,
                                                student.result.result,
                                                "result"
                                              );
                                              window.open(
                                                `${student.result.result}`,
                                                "_blank"
                                              );
                                            }}
                                          >
                                            View result
                                          </a>
                                        </td>
                                        <td
                                          rowSpan={
                                            student.result.Marksheet.length
                                          }
                                        >
                                          <a
                                            href={`${student.result.CertificateData}/${backgroundSelection}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            onClick={(e) => {
                                              handleStatus(
                                                e,
                                                student.profile_pic.student_id,
                                                student.result.CertificateData,
                                                "certificate"
                                              );
                                              window.open(
                                                `${student.result.CertificateData}/${backgroundSelection}`,
                                                "_blank"
                                              );
                                            }}
                                          >
                                            View Certificate
                                          </a>
                                        </td>
                                        <td
                                          rowSpan={
                                            student.result.Marksheet.length
                                          }
                                        >
                                          <div>
                                            <strong>Result:</strong>
                                            <br></br>

                                            {/* {marksheet.result_print_status ===
                                            1 ? (
                                              <span className="badge bg-success">
                                                Printed
                                              </span>
                                            ) : (
                                              <span className="badge bg-danger">
                                                Not Printed
                                              </span>
                                            )} */}
                                            <br />
                                            {student?.result_print_date?.map(
                                              (ele) => {
                                                return (
                                                  <>
                                                    <div>
                                                      {ele.result_print_date
                                                        ? moment(
                                                            ele.result_print_date
                                                          ).format("YYYY-MM-DD")
                                                        : "-"}
                                                    </div>
                                                  </>
                                                );
                                              }
                                            )}
                                          </div>
                                          <div>
                                            <strong>Certificate:</strong>
                                            {marksheet.certificate_print_status ===
                                            1 ? (
                                              <span className="badge bg-success">
                                                Printed
                                              </span>
                                            ) : (
                                              <span className="badge bg-danger">
                                                Not Printed
                                              </span>
                                            )}
                                            <br />

                                            {student?.certificate_print_date?.map(
                                              (ele) => {
                                                return (
                                                  <>
                                                    <div>
                                                      {ele.certificate_print_date
                                                        ? moment(
                                                            ele.certificate_print_date
                                                          ).format("YYYY-MM-DD")
                                                        : "-"}
                                                    </div>
                                                  </>
                                                );
                                              }
                                            )}
                                          </div>
                                        </td>
                                      </>
                                    )}
                                  </tr>
                                )
                              )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div class="Widthdata">
                  <div class="Applicationdata s">
                    <div class="row ">
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th>Enrolment Number </th>
                              <th>Semester Year </th>
                              <th>Student Id </th>
                              <th>Total Marks </th>
                              <th>Result</th>
                              {/* <th>Certificate</th> */}
                              <th> status </th>
                            </tr>
                          </thead>

                          <tbody>
                            {student &&
                              student.marksheet.map((marksheet, index) => (
                                <tr key={index}>
                                  {index === 0 && (
                                    <>
                                      <td rowSpan={student.marksheet.length}>
                                        {marksheet.enrollment_no}
                                      </td>
                                      <td rowSpan={student.marksheet.length}>
                                        {marksheet.semster_year}
                                      </td>
                                      <td rowSpan={student.marksheet.length}>
                                        {marksheet.student_id}
                                      </td>
                                      <td rowSpan={student.marksheet.length}>
                                        {marksheet.total_marks}
                                      </td>
                                    </>
                                  )}
                                  <td>
                                    <a
                                      href={`${marksheet.pdf}/${backgroundSelection}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      onClick={(e) => {
                                        handleStatus(
                                          e,
                                          student.profile_pic.student_id,
                                          marksheet.pdf,
                                          "marksheet"
                                        );
                                        window.open(
                                          `${marksheet.pdf}/${backgroundSelection}`,
                                          "_blank"
                                        );
                                      }}
                                    >
                                      View Marksheet {index + 1}
                                    </a>
                                    <strong>Marksheet:</strong>
                                    {marksheet.marksheet_print_status === 1 ? (
                                      <span className="badge bg-success">
                                        Printed
                                      </span>
                                    ) : (
                                      <span className="badge bg-danger">
                                        Not Printed
                                      </span>
                                    )}
                                    <br />
                                    {marksheet.marksheet_print_date
                                      ? moment(
                                          marksheet.marksheet_print_date
                                        ).format("YYYY-MM-DD")
                                      : "-"}
                                  </td>
                                  <td>
                                    <div>
                                      <strong>Result:</strong>
                                      <br></br>
                                      {marksheet.result_print_status === 1 ? (
                                        <span className="badge bg-success">
                                          Printed
                                        </span>
                                      ) : (
                                        <span className="badge bg-danger">
                                          Not Printed
                                        </span>
                                      )}
                                      <br />
                                      {marksheet.result_print_date
                                        ? moment(
                                            marksheet.result_print_date
                                          ).format("YYYY-MM-DD")
                                        : "-"}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
