import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import TradeSubtradeSelector from "../../misc/TradeSubtradeSelector";
import BreadCrumb from "../../BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import {
  getCourseList,
  getCourses,
  getStudents,
  getSubjects,
} from "../../../store/actions/councilActions/courseAction";
import { addExamSchedule } from "../../../store/actions/councilActions/scheduleActions";
import { getCenterList } from "../../../store/actions/councilActions/centerActions";
import {
  getSubjectList,
  getsubjectList,
} from "../../../store/actions/councilActions/subjectAction";
import { Accordion, Spinner, Table } from "react-bootstrap";
import ExamSchedule from "./exam-schedule";

const AddExamSchedule = () => {
  const breadcrumbs = [
    {
      path: "/admin/dashboard",
      label: "Dashboard",
    },
    {
      label: "Add Exam Schedule",
    },
  ];
  const today = new Date();
  const currentDate = today.toISOString().split("T")[0]; // YYYY-MM-DD format
  const currentTime =
    today.toTimeString().split(":")[0] +
    ":" +
    today.toTimeString().split(":")[1]; // HH:MM format
  const dispatch = useDispatch();
  const initialState = {
    trade: "",
    sub_trade: "",
    course: "",
    subject: "",
    date: currentDate,
    time: currentTime,
    batch: 1,
    subject_id: "",
  };
  const [data, setData] = useState(initialState);
  const { trade, sub_trade, subject, date, time, batch, subject_id } = data;
  const { admin } = useSelector((state) => state);
  const { tradeCourses, courseSubjects } = admin;
  const { centerlist, courses } = admin;
  console.log("coursecs", courses);
  const [course_type, setcourse_type] = useState("");
  const [exam_type, setexam_type] = useState("");
  const [duration, setDuration] = useState("");
  const [userSubject, setUserSubject] = useState(null);
  const [semester, setSemester] = useState("");
  const [center_id, setcenter_id] = useState("");
  const [studentcenter_id, setstudentcenter_id] = useState("");
  const [studentLists, setstudentLists] = useState(null);
  const [masterCheck, setMasterCheck] = useState(false);
  const [selectedList, setSelectedList] = useState([]);
  const [course, setCourse] = useState(0);
  const [courseName, setCourseName] = useState("");
  const [loading, setloading] = useState(false);
  console.log("llllcourse", course);

  // Select/ UnSelect Table rows
  const onMasterCheck = (e) => {
    let tempList = studentLists;
    // Check/ UnCheck All Items
    tempList.map((user) => (user.selected = e.target.checked));

    //Update State
    setMasterCheck(e.target.checked);
    setstudentLists(tempList);
    setSelectedList(tempList);
  };

  // Update List Item's state and Master Checkbox State
  const onItemCheck = (e, item) => {
    let tempList = studentLists;
    tempList.map((user) => {
      if (user.id === item.id) {
        user.selected = e.target.checked;
      }
      return user;
    });

    //To Control Master Checkbox State
    const totalItems = studentLists;
    const totalCheckedItems = tempList.filter((e) => e.selected).length;

    // Update Stateq
    setMasterCheck(totalCheckedItems === totalItems);
    setstudentLists(tempList);
    setSelectedList(tempList.filter((e) => e.selected));
  };

  useEffect(() => {
    if (sub_trade) {
      dispatch(getCourses(sub_trade));
    }
  }, [sub_trade]);

  // useEffect(() => {
  //   if (course) {
  //     dispatch(getSubjects(course));
  //   }
  // }, [course]);

  console.log("course_type", course_type);

  useEffect(async () => {
    if (data.course && studentcenter_id) {
      const response = await dispatch(
        getStudents({
          course_id: data.course,
          center_id: studentcenter_id,
        })
      );
      if (response) {
        setstudentLists(response);
        const data = response || [];
        data.map((d) => {
          d.selected = false;
        });
      } else {
        setstudentLists([]);
      }
    }
  }, [data.course, studentcenter_id]);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    if (courseSubjects) {
      convertArrayToObject(courseSubjects);
    }
  }, [courseSubjects]);
  const convertArrayToObject = (array, key) => {
    const hs = new Map();
    array.map((element) => {
      if (hs.get(element.semester)) {
        hs.set(element.semester, [...hs.get(element.semester), element]);
      } else {
        hs.set(element.semester, [element]);
      }
    });
    setUserSubject(Object.fromEntries(hs));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const temp = selectedList.map((s) => s.id);
    const formdata = new FormData();
    formdata.append("course_type", course_type);
    formdata.append("course_id", course);
    formdata.append("subject_id", subject);
    formdata.append("date", date);
    formdata.append("time", time);
    formdata.append("batch", batch);
    formdata.append("center_id", center_id);
    formdata.append("student_id", JSON.stringify(temp));
    formdata.append("subject_id", data.subject_id);
    const obj = {
      course_type: course_type,
      course_id: course,
      // subject_id: subject,
      date,
      time,
      batch,
      center_id,
      student_id: temp.toString(),
      subject_id,
    };
    console.log("obje", obj);
    const response = await dispatch(addExamSchedule(obj));
    if (response) {
      setData(initialState);
      setSemester("");
      setUserSubject(null);
      setcourse_type("");
      setcenter_id("");
    }
  };
  useEffect(() => {
    dispatch(getCenterList());
  }, []);
  const [coursedata, setCourcedata] = useState([]);

  const fetchdata = async (id) => {
    setloading(true);
    const response = await dispatch(getsubjectList(id));
    if (response) {
      setCourcedata(response);
      console.log("response", response);
      setloading(false);
      //  settotalPages(Math.ceil(response.total / response.per_page));
    } else {
      setCourcedata([]);
      setloading(false);
    }
  };
  // , [dispatch, course]);

  console.log("course", coursedata);
  useEffect(() => {
    if (!courses) {
      dispatch(getCourseList());
    }
  }, []);

  // useEffect(() => {
  //   fetchdata();
  // }, [fetchdata]);

  let groupedBySemester = {};

  if (coursedata) {
    groupedBySemester = coursedata.reduce((group, item) => {
      group[item.semester] = group[item.semester] || [];
      group[item.semester].push(item);

      return group;
    }, {});
    console.log("groupedBySemester ", groupedBySemester);
  }

  const handleChangeSubjectId = (subjectId) => {
    console.log("hiiii");
    setData({
      ...data,
      subject_id: subjectId,
    });
    console.log("subjectId", subjectId);
  };

  return (
    <div>
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
          <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <BreadCrumb crumbs={breadcrumbs} />

            <div class="d-flex align-items-center">
              <Link
                to="/admin/view-exam-schedule"
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                View Exam Schedule
              </Link>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-12">
              <form onSubmit={handleSubmit}>
                <div class="card-body">
                  <TradeSubtradeSelector data={data} setData={setData} />
                  {sub_trade && (
                    <div class="col-md-12">
                      <div class="boxlabel">
                        <p>Course Name </p>
                        <select
                          class="form-control"
                          name="course"
                          value={course}
                          onChange={(e) => {
                            handleChange(e);
                            console.log('lakshayTEst',e.target.selectedIndex,tradeCourses,e.target[e.target.selectedIndex].getAttribute(
                              "data-value"
                            ));
                            setcourse_type(e.target[e.target.selectedIndex].getAttribute(
                              "data-value"
                            ));
                            setexam_type(
                              e.target[e.target.selectedIndex].getAttribute(
                                "data-exam"
                              )
                            );
                            setDuration(
                              e.target[e.target.selectedIndex].getAttribute(
                                "data-duration"
                              )
                            );
                            setCourse(e.target.value);
                            setCourseName(
                              e.target.selectedOptions[0].getAttribute("name")
                            );
                            fetchdata(e.target.value);
                          }}
                          required
                        >
                          <option value="">Select Course</option>
                          {!tradeCourses ? (
                            <option value={""} disabled>
                              Loading...
                            </option>
                          ) : tradeCourses.length > 0 ? (
                            tradeCourses.map((course) => (
                              <option
                                value={course.id}
                                data-value={course.course_type}
                                data-duration={course.duration}
                                data-exam={course.exam_type}
                              >
                                {course.name}
                              </option>
                            ))
                          ) : (
                            <option value={""} disabled>
                              No Course Found
                            </option>
                          )}
                        </select>
                      </div>
                    </div>
                  )}

                  <div class="col-md-12">
                    <div class="boxlabel">
                      <p>Select Batch </p>
                      <select
                        className="form-control"
                        value={batch}
                        name="batch"
                        onChange={handleChange}
                      >
                        <option value={""} selected>
                          --Select--
                        </option>
                        <option value={1}>Batch 1</option>
                        <option value={2}>Batch 2</option>
                      </select>
                    </div>
                  </div>

                  <>
                    {
                      //coursedata?.exam_type === "subject_wise" ||

                      Object.keys(groupedBySemester).map((semester) => (
                        <>
                          {/* {Object.entries(
                              convertArrayToObject(coursedata?.subject)
                            ) */}
                          {/* {uniqueSemesters?.map((item) => {
                            return ( */}
                          <>
                            <Accordion className="mb-3">
                              <Accordion.Item></Accordion.Item>
                              <Accordion.Header>
                                {/* {coursedata?.course_type === "2"
                                  ? "Semester "
                                  : "Year "} */}
                                {/* {value} */}
                                Semester {semester}
                              </Accordion.Header>
                              <Accordion.Body>
                                <table
                                  class="table table-separate table-head-custom table-checkable"
                                  id="kt_datatable"
                                >
                                  <thead>
                                    <th>S.NO.</th>
                                    <th>Subject</th>
                                    <th>Subject Code</th>
                                    <th>Select Date</th>
                                    <th>Select Time </th>
                                    <th>Status</th>
                                    {/*   <th>Action</th> */}
                                  </thead>
                                  <tbody>
                                    {groupedBySemester[semester].map(
                                      (subject, index) => (
                                        <ExamSchedule
                                         {...subject} 
                                         sn={index + 1} 
                                        //  subject_id={} 
                                        //  course_id={course} 
                                         course_type={course_type}
                                         batch={batch}
                                         />
                                        // <tr>
                                        //   <td>{index + 1}</td>
                                        //   <td>{subject.name}</td>
                                        //   <td>{subject.code || "NA"}</td>
                                        //   <td>
                                        //     <input
                                        //       type="hidden"
                                        //       name="subject_id"
                                        //       value={subject?.course_name?.id}
                                        //       onChange={() =>
                                        //         handleChangeSubjectId(
                                        //           subject?.course_name?.id
                                        //         )
                                        //       }
                                        //     />
                                        //   </td>
                                        //   <td>
                                        //     {" "}
                                        //     <div class="col-md-12">
                                        //       <div class="boxlabel">
                                        //         <p>Select Exam Date </p>
                                        //         <input
                                        //           type={"date"}
                                        //           // min={moment(new Date()).format("YYYY-MM-DD")}
                                        //           name="date"
                                        //           className="form-control"
                                        //           value={data.date}
                                        //           onChange={handleChange}
                                        //           required
                                        //         ></input>
                                        //       </div>
                                        //     </div>
                                        //   </td>

                                        //   <td>
                                        //     <div class="col-md-12">
                                        //       <div class="boxlabel">
                                        //         <p>Select Exam Time </p>
                                        //         <input
                                        //           type={"time"}
                                        //           min={new Date()}
                                        //           name="time"
                                        //           className="form-control"
                                        //           value={data.time}
                                        //           onChange={handleChange}
                                        //           required
                                        //         ></input>
                                        //       </div>
                                        //     </div>{" "}
                                        //   </td>
                                        //   <td>
                                        //     <div class="boxlabel">
                                        //       <input
                                        //         className="btn-success btn btn-lg"
                                        //         type={"submit"}
                                        //         name="Submit"
                                        //         onClick={() => {
                                        //           handleSubmit();
                                        //           handleChangeSubjectId(
                                        //             subject?.id
                                        //           );
                                        //         }}
                                        //       />
                                        //     </div>
                                        //   </td>
                                        // </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </Accordion.Body>
                            </Accordion>
                          </>
                          {/* );
                          })} */}
                        </>
                        // ) : (
                        // <>
                        //   <Table
                        //     bordered
                        //     hover
                        //     striped
                        //     responsive
                        //     className="mb-0"
                        //   >
                        //     <thead>
                        //       <th>S.NO.</th>
                        //       <th>Subject</th>
                        //       <th>Subject Code</th>
                        //       <th>Max marks</th>
                        //       <th>Obtained Marks </th>
                        //       <th>Status</th>
                        //       <th>Action</th>
                        //     </thead>
                        //     <tbody>
                        //       {coursedata?.map((element, index) => (
                        //         <tr>
                        //           <td>{index + 1}</td>
                        //           <td>{element.name}</td>
                        //           <td>{element.code || "NA"}</td>
                        //           {/* <td>{getMaxMarks(element.practical)}</td> */}
                        //           <td>{element.marks}</td>
                        //           {/* <td>
                        //           {element.marks
                        //             ? (element.marks /
                        //                 getMaxMarks(element.practical)) *
                        //                 100 >=
                        //               40
                        //               ? "Pass"
                        //               : "Fail"
                        //             : "NA"}
                        //         </td> */}
                        //           <td>
                        //             {element.marks ? (
                        //               "Completed"
                        //             ) : (
                        //               <button
                        //                 class="btn btn-sm btn-success"
                        //                 // onClick={() => {
                        //                 //   setupload_marks(true);

                        //                 //   setMarksUploadData({
                        //                 //     courseId: data?.id,
                        //                 //     subId: element.id,
                        //                 //     studentId: data.studnt_id?.id,
                        //                 //     max_marks:
                        //                 //       element.practical == 1 ? 50 : 100,
                        //                 //   });
                        //                 // }}
                        //               >
                        //                 Upload Marks
                        //               </button>
                        //             )}
                        //           </td>
                        //         </tr>
                        //       ))}
                        //     </tbody>
                        //   </Table>
                        //   <Table
                        //     bordered
                        //     hover
                        //     striped
                        //     responsive
                        //     className="mb-0"
                        //   >
                        //     <thead>
                        //       <th>Exam Date</th>
                        //       <th>Action</th>
                        //     </thead>
                        //     <tbody>
                        //       <td></td>
                        //       <td>
                        //         <button
                        //           class="btn btn-sm"
                        //           // onClick={() => {
                        //           //   setUploadDate(true);

                        //           //   setUploadDateData({
                        //           //     course_id: data?.id,
                        //           //     student_id: data.studnt_id?.id,
                        //           //   });
                        //           // }}
                        //         >
                        //           Upload Marks
                        //         </button>
                        //       </td>
                        //     </tbody>
                        //   </Table>
                        // </>
                        // ""
                      ))
                    }
                  </>
                  {/* ) : null} */}
                  {/* <div class="col-md-12">
                    <div class="boxlabel">
                      <input
                        className="btn-success btn btn-lg"
                        type={"submit"}
                        name="Submit"
                      />
                    </div>
                  </div> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddExamSchedule;
