import React, { useEffect, useState } from "react";
import BreadCrumb from "../../BreadCrumb";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Alert/Loader";
import {
  MemebrshipAction,
  changeStatus,
  deleteOther,
  deletePage,
  filterCtageory,
  getCategory,
  getIndividualMember,
  getOtherMember,
  getPage,
} from "../../../store/actions/printActions/printcategory";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const Other = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const { admin } = useSelector((state) => state);
  const { adminAuth } = admin;
  const token = adminAuth?.profile?.admin?.token;
  const [listdata, setListData] = useState([]);
  const [updateList, setUpdateList] = useState([]);
  const [list, setList] = useState({
    data: [],
    currentPage: 1,
    totalPages: 1,
  });
  const [data, setData] = useState({
    category: 0,
  });
  const [selectedId, setSelectedId] = useState(null);
  const [actionData, setActionData] = useState({
    membership_id: 0,
    membership_type: "",
    login_code: "",
    reject_reason: "",
  });

  const { membership_id, membership_type, login_code, reject_reason } =
    actionData;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(
          getOtherMember(list.currentPage, token)
        );
        const responseData = response.data;
        setList({
          data: responseData.data,
          currentPage: responseData.current_page,
          totalPages: responseData.last_page,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [list.currentPage]);

  const handlePrevPage = () => {
    if (list.currentPage > 1) {
      setList({ ...list, currentPage: list.currentPage - 1 });
    }
  };

  const handleNextPage = () => {
    if (list.currentPage < list.totalPages) {
      setList({ ...list, currentPage: list.currentPage + 1 });
    }
  };

  const handleDelete = async (id) => {
    // Optimistically remove item from UI
    const updatedList = list.data.filter((item) => item.id !== id);

    // Store the original list state
    const originalList = { ...list };

    // Update the UI
    setList({ ...list, data: updatedList });
    // Make delete request
    const success = await dispatch(deleteOther(id, token));

    // If delete operation failed, revert UI change
    if (!success) {
      setList(originalList);
    }
    // Success toast message
    else {
      toast.success("Other deleted Successfully");
    }
  };

  const handleStatus = async (id, membership_type, status) => {
    let updatedStatus = status === 1 ? 0 : 1;

    const payload = {
      membership_type: "other",
      membership_id: id,
      status: updatedStatus,
    };
    // Make API request to update the status
    const success = await dispatch(changeStatus(payload, token));

    // If the status update operation failed, you can handle it accordingly
    if (!success) {
      toast.error("Failed to update membership status");
    } else {
      // Update the UI only if the API call was successful
      const updatedList = list.data.map((item) =>
        item.id === id ? { ...item, status: updatedStatus } : item
      );

      // Update the list with the modified status
      setList({ ...list, data: updatedList });

      toast.success("Membership status updated successfully");
    }
  };
  const displayStatus = (status) => {
    return status === 1 ? "Active" : "Deactivate";
  };

  useEffect(async () => {
    const catResponse = await dispatch(getCategory());
    setListData(catResponse.data);
  }, [dispatch]);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleFilter = async () => {
    try {
      const response = await dispatch(
        filterCtageory(data.category, "other", token)
      );

      // Assuming the response structure is { status: "success", data: [...] }

      setUpdateList(response.data); // Use response.data directly
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error, e.g., toast.error("Failed to fetch data")
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleChangeAction = (e) => {
    console.log("Input value:", e.target.value);
    setActionData({
      ...actionData,
      [e.target.name]: e.target.value,
    });
  };

  const handleActionSubmit = async () => {
    try {
      let payload = {
        membership_id: selectedId,
        membership_type: "other",
      };

      if (selectedOption === "approve") {
        payload.login_code = login_code;
        payload.is_approwed = 1; // Set is_approved to 1 when approving
      } else if (selectedOption === "reject") {
        payload.reject_reason = reject_reason;
        payload.is_approwed = 0; // Set is_approved to 0 when rejecting
      }

      // Make the API request with the payload
      const response = await dispatch(MemebrshipAction(payload, token));

      // Handle the response as needed
      if (response) {
        // Handle success
        toast.success("Action performed successfully");

        // Update the UI to display "Approved" for the selected row
        const updatedList = list.data.map((item) =>
          item.id === selectedId
            ? {
                ...item,
                is_approwed: selectedOption === "approve" ? 1 : 0, // Update status based on action
              }
            : item
        );

        // Update the list with the modified status
        setList({ ...list, data: updatedList });

        closeModal();
      } else {
        // Handle failure
        toast.error("Failed to perform action");
      }
    } catch (error) {
      console.error("Error performing action:", error);
      // Handle the error, e.g., toast.error("Failed to perform action")
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="membershipType" className="text-primary">
                  Membership Type
                </label>
                <div className="d-flex">
                  <select
                    className="form-control"
                    id="membershipType"
                    name="category"
                    value={data.category}
                    onChange={handleChange}
                    style={{
                      border: "2px solid #007bff",
                      backgroundColor: "#f8f9fa",
                    }}
                  >
                    {listdata?.map((ele) => (
                      <option key={ele.id} value={ele?.id}>
                        {ele?.name}
                      </option>
                    ))}
                  </select>
                  <button
                    className="btn btn-primary ml-2"
                    onClick={handleFilter}
                  >
                    Filter
                  </button>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Sr.No</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Mobile No</th>
                    <th>State</th>
                    <th>City</th>
                    <th>Ragistration Year</th>
                    <th>Document file</th>
                    <th>Total Employees</th>
                    <th>Status</th>
                    <th>Edit</th>
                    <th>Action</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {updateList ? (
                    (updateList && updateList?.length > 0
                      ? updateList
                      : list?.data
                    ).map((ele, index) => {
                      return (
                        <tr key={ele.id}>
                          <td>{index + 1}</td>
                          <td>{ele?.name}</td>
                          <td>{ele?.email}</td>
                          <td>{ele?.mobile}</td>
                          <th>{ele?.state}</th>
                          <th>{ele?.city}</th>
                          <td>{ele?.registration_year}</td>
                          <td>
                            <a
                              title={ele?.document_file}
                              target="_blank"
                              href={ele?.document_file}
                            >
                              Document
                            </a>
                          </td>
                          <td>{ele?.total_employees}</td>
                          {/* <td><button onClick={() =>handleStatus(ele?.id,ele?.membership_type,ele?.status)}>{ele?.status}</button></td> */}
                          <td>
                            <button
                              onClick={() =>
                                handleStatus(
                                  ele?.id,
                                  ele?.membership_type,
                                  ele?.status
                                )
                              }
                            >
                              {displayStatus(ele?.status)}
                            </button>
                          </td>
                          <td>
                            <Link
                              to={`/print/edit-membership-other/${ele?.id}`}
                            >
                              Edit
                            </Link>
                          </td>
                          <td>
                            <select
                              onChange={(e) => {
                                setSelectedId(ele.id); // Set the selected ID
                                setSelectedOption(e.target.value);
                                openModal();
                              }}
                            >
                              <option value="">Select</option>
                              <option value="approve" onClick={openModal}>
                                Approve
                              </option>
                              <option value="reject" onClick={openModal}>
                                Reject
                              </option>
                            </select>

                            <span
                              style={{
                                color: ele.is_approwed === 1 ? "green" : "red",
                                fontWeight: "bold",
                              }}
                            >
                              {ele.is_approwed === 1 ? "Approved" : "Rejected"}
                            </span>
                          </td>
                          <td>
                            <button onClick={() => handleDelete(ele.id)}>
                              Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6}>No Record Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <nav aria-label="Page navigation">
              <ul className="pagination justify-content-center">
                <li
                  className={`page-item ${
                    list.currentPage === 1 ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={handlePrevPage}
                    disabled={list.currentPage === 1}
                  >
                    Previous
                  </button>
                </li>
                <li
                  className={`page-item ${
                    list.currentPage === list.totalPages ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={handleNextPage}
                    disabled={list.currentPage === list.totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <Modal show={isModalOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedOption === "approve"
              ? "Approve"
              : selectedOption === "reject"
              ? "Reject"
              : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedOption === "approve" ? (
            <>
              <div>
                <label>Login Code:</label>
                <input
                  type="text"
                  name="login_code"
                  value={login_code}
                  onChange={handleChangeAction}
                />
              </div>
              {/* Other fields related to the "Approve" option */}
            </>
          ) : selectedOption === "reject" ? (
            <>
              <div>
                <label htmlFor="reject_reason">Reject Reason:</label>
                <textarea
                  rows="5" // Set the number of rows (adjust as needed)
                  cols="40" // Set the number of columns (adjust as needed)
                  id="reject_reason"
                  value={reject_reason}
                  name="reject_reason"
                  onChange={handleChangeAction}
                />
              </div>
              {/* Other fields related to the "Reject" option */}
            </>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleActionSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Other;
