import {
  ALERT,
  SET_COURSE,
  SET_SUBJECTS,
  SET_TRADE_COURSE,
} from "../actionTypes";
import { getDataAPI, postDataAPI } from "../../../services/apiWrapper";
import { exportToCSV } from "../../../utils/exportCsv";

export const getCourseList = () => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(getDataAPI("admin/course/list"));
    dispatch({ type: SET_COURSE, payload: response.data.data });
    dispatch({ type: ALERT, payload: { loading: false } });
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({ type: ALERT, payload: { error: error.response.data.status } });
  }
};

export const getCourseListPagination = (page) => async (dispatch) => {
  try {
    const response = await dispatch(
      getDataAPI(`admin/all/course/list?page=${page}`)
    );
    return response.data.data;
  } catch (error) {
    return false;
  }
};

export const downloadCourses = () => async (dispatch) => {
  try {
    const response = await dispatch(getDataAPI(`admin/all/course/list`));
    const dataToExport = [];
    response.data.data.forEach((ele, index) => {
      dataToExport.push({
        Sno: index + 1,
        Course: ele.name,
        Subtrade: ele.sub_trade?.name || "",
      });
    });
    exportToCSV(dataToExport, "Courses");
  } catch (error) {
    return false;
  }
};

export const getCourses = (id) => async (dispatch) => {
  try {
    // dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(getDataAPI(`admin/subtrade/course/${id}`));
    // dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({
      type: SET_TRADE_COURSE,
      payload: response.data.data || [],
    });
    return response.data.data;
  } catch (error) {
    // dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({ type: ALERT, payload: { error: error.response.data.status } });
    dispatch({
      type: SET_TRADE_COURSE,
      payload: [],
    });
    return false;
  }
};

export const getSubjects = (id) => async (dispatch) => {
  try {
    // dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(getDataAPI(`admin/subject/course/${id}`));
    // dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({
      type: SET_SUBJECTS,
      payload: response.data.data || [],
    });
    return response.data.data;
  } catch (error) {
    // dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({ type: ALERT, payload: { error: error.response.data.status } });
    dispatch({
      type: SET_SUBJECTS,
      payload: [],
    });
    return false;
  }
};

export const getStudents = (data) => async (dispatch) => {
  try {
    // dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      postDataAPI(`admin/course/student`, data, token)
    );
    // dispatch({ type: ALERT, payload: { loading: false } });
    return response.data.data;
  } catch (error) {
    // dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({ type: ALERT, payload: { error: error.response.data.status } });
    return false;
  }
};

export const getCourseDetail = (id) => async (dispatch) => {
  try {
    // dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(getDataAPI(`admin/course/details/${id}`));
    // dispatch({ type: ALERT, payload: { loading: false } });
    return response.data.data;
  } catch (error) {
    // dispatch({ type: ALERT, payload: { loading: false } });
    return false;
  }
};

export const getCourseEdit = (id) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      getDataAPI(`admin/course/edit/${id}`, token)
    );
    dispatch({ type: ALERT, payload: { loading: false } });
    return response.data.data;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    return false;
  }
};

export const updateCourse = (data) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      postDataAPI(`admin/course/edit`, data, token)
    );
    dispatch({ type: ALERT, payload: { loading: false } });
    dispatch(getCourseList());
    return response.data.data;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};

export const createCourse = (data) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      postDataAPI("admin/course/create", data, token)
    );
    dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({ type: ALERT, payload: { success: response.data.message } });
    dispatch(getCourseList());
    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({ type: ALERT, payload: { error: error.response.data.status } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};
