import React from "react";

const CenterDetails = () => {
  return (
    <div>
      {" "}
      <section class="getdetails2" id="home">
        <div class="container">
          <div class="infobox">
            <div class="DTBox">
              <div class="DTdata">
                <div class="DTimage">
                  <img src="<%=item.frontphoto?'/uploads/'+item.frontphoto:'https://www.admission24.com/images/college-banner.svg'%>" />
                </div>
                <div class="DTcontent">
                  {" "}
                  <div class="secure-verfity">
                    <img src="/assets/images/check.png" /> Verified Listing
                  </div>
                  <div class="DTaddress">
                    {"<%=item.address%>,<%=item.city%>,<%=item.state%>"}
                  </div>
                  <div class="DTname">{"<%=item.centername%>"}</div>
                  <div class="DTrating">
                    <div class="Dtranking">
                      <span class="rankspan">
                        <i class="fa fa-phone"></i> {"<%=item.contact%>"}
                      </span>
                      <span class="rankforspan">Approved By</span>
                      <span class="Linkmorerank">
                        <a href="#"> NPVEC </a>
                      </span>
                    </div>
                  </div>
                  <div class="DTlikebox">
                    <a href="#">
                      <i class="fa fa-heart" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              {" "}
              <div class="col-md-9">
                <section class="coursefee" id="aboutuscenter">
                  <div class="Coursefeebox">
                    <div class="showprogram">
                      {/* <%website.forEach((web)=>{ %> */}
                      <div class="courseblack">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="coursebalckdata">
                              <div class="pinkhead">About Us</div>

                              <div class="coursebabout">
                                <div class="bpindata">{"<%-web.aboutus%>"}</div>
                                <div class="cousebfeature">
                                  <div class="row-list-bcourse">
                                    <h4>Admission Start </h4>
                                    {"date"}
                                    <h5>
                                      {
                                        "<%=date.getMonth()+'-'+date.getDate()+'-'+date.getFullYear()%>"
                                      }
                                    </h5>
                                  </div>
                                  <div class="row-list-bcourse">
                                    <h4>Admission Last Date</h4>
                                    <h5>
                                      {
                                        "<%= date2.getMonth()+'-'+date2.getDate()+'-'+date2.getFullYear() %>"
                                      }
                                    </h5>
                                  </div>

                                  <div class="row-list-bcourse">
                                    <h4>Seats</h4>
                                    <h5>{"<%=item.seats%>"}</h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <%})%> */}
                    </div>
                  </div>
                </section>

                <section class="Documentrequire" id="Facalities">
                  <div class="boxCont projectDet clearfix">
                    <div class="OtherDumblock">
                      <div class="blockHeading mktDtlhead">Facalities</div>
                      <div class="propDescrip">
                        <div class="row">
                          {/* <%facility.forEach((facility)=>{ %> */}
                          <div class="col-md-4">
                            <div class="HlpDesk">
                              <a href="#" class="pos_rel">
                                <div class="titlehp">
                                  {"<%=facility.facility%>"}
                                </div>
                                <div class="titlehpdes">
                                  <ul>
                                    <li>
                                      {"<%=facility.facilityno%>"}{" "}
                                      <span>{"<%=facility.facility%>"}</span>
                                    </li>
                                    <li>
                                      {"<%=facility.facilityarea%>"}{" "}
                                      <span>Sq.Ft.</span>
                                    </li>
                                    <li>
                                      {"<%=facility.facilityseat%>"}{" "}
                                      <span>Seats</span>
                                    </li>
                                  </ul>
                                </div>
                              </a>
                            </div>
                          </div>
                          {/* <%})%> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div class="col-md-3 ppl-0">
                <div class="labdetails">
                  <div class="author_image-lab">
                    <img src="/uploads/<%=item.mdphoto%>" />
                  </div>
                  <div class="about_a_lab">
                    <div class="about-a_des">Director</div>
                    <div class="about-a_name">{"<%=item.mdame%>"}</div>
                  </div>
                  <div class="number_nd">
                    <div class="phonenumber">
                      <span class="phonenumber-l">
                        Call Now <i class="fa fa-phone"></i>{" "}
                        {"<%=item.mdmobile%>"}
                        <span class="dostno">.....</span>
                      </span>
                    </div>
                  </div>
                  <div class="icons-d_blist">
                    <ul>
                      <li>
                        <a href="tell:<%=item.mdmobile%>">
                          <i class="fa fa-whatsapp" aria-hidden="true"></i>
                          <span>Whatsapp</span>
                        </a>
                      </li>
                      <li>
                        <a href="mailto:<%=item.mdemail%>">
                          <i
                            class="fa fa-location-arrow"
                            aria-hidden="true"
                          ></i>
                          <span>Email </span>
                        </a>
                      </li>

                      <li>
                        <a href="#">
                          <i
                            class="fa fa-share-square-o"
                            aria-hidden="true"
                          ></i>
                          <span>On Social </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="pad-wrap">
                  <div class="content_box nopadding">
                    <h3 class="content_headright"> Map View</h3>
                    <div class="content_body">
                      <div class="address_mapview">
                        <div class="address_m">{"<%=item.address%>"}</div>
                        <div class="address_map"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <section
                  class="propPPostother OtherDumblock"
                  id="property-snapshot"
                >
                  <div class="blockHeading "> Fee & Courses</div>
                  <div
                    class="owl-carousel owl-theme owl-properties"
                    id="swiper-container3"
                  >
                    {/* <%courses.forEach((course)=>{%> */}
                    <div class="item">
                      <div class="propertyOTHER  shadow-hover">
                        <a href="#" class="property-img">
                          <div class="image-max">
                            <img src="/uploads/<%=course.image%>" />
                          </div>
                        </a>
                        <div class="prop-property-content">
                          <div class="sale-Propm">
                            <div class="sale-PropPrice">
                              {"<%=course.course_name%>"}
                            </div>
                            <div class="sale-Prop ellipsis">
                              {
                                "<%=Number(course.duration) >= 1?course.duration+' Year':course.duration+' Month'%> "
                              }
                            </div>
                            <div class="sale-PropC ellipsis">
                              <span>Eligibility:</span>
                              {"<%=course.eligibility%>"}
                            </div>
                          </div>
                          <div class="contactag-other-btn">
                            {" "}
                            <a href="#" class="btn">
                              Apply Now
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <%})%> */}
                  </div>
                </section>
                <section
                  class="propPPostother OtherDumblock"
                  id="property-snapshot"
                >
                  <div class="blockHeading "> Our Staff</div>
                  <div
                    class="owl-carousel owl-theme owl-properties"
                    id="swiper-container3"
                  >
                    {/* <%staff.forEach((staff)=>{%> */}
                    <div class="item">
                      <div class="propertyOTHER  shadow-hover">
                        <div class="image-maxi">
                          {" <%=(staff.name).slice(0,1)%>"}
                        </div>

                        <div class="prop-property-content">
                          <div class="sale-staff">
                            <div class="sale-PropPrice">
                              {"<%=staff.name%>"}
                            </div>

                            <div class="sale-PropC ellipsis">
                              <span></span>
                              {"<%=staff.designation%>"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <%})%> */}
                  </div>
                </section>

                <section id="Gallery">
                  <div class="details-left">
                    <div class="pad-wrap">
                      <div class="content_box nopadding">
                        <h3 class="content_head"> Gallery Images</h3>
                        <div class="content_body">
                          <div class="row">
                            {/* <%gallery.forEach((gallery)=>{%>
     <div class="col-md-4">
     <div class="Imgebox">
         <img src="/uploads/<%=gallery.image%>">
         </div>
     </div>
     <%})%> */}
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CenterDetails;
