import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CKEditor from "react-ckeditor-component";

import {
  createSubTrade,
  editSubTrade,
  getTrades,
  updateSubTrade,
} from "../../../../store/actions/councilActions/trade";
import BreadCrumb from "../../../BreadCrumb";
import Loading from "../../../Alert/Loader";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

export default function SubTrade() {
  const { admin } = useSelector((state) => state);
  const [exist, setExist] = useState(true);
  const { trades } = admin;
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const breadcrumbs = [
    {
      path: "/admin/dashboard",
      label: "Dashboard",
    },
    {
      // path: "/aboutus",
      label: id ? "Edit Subtrade" : "Add Subtrade",
    },
  ];

  useEffect(() => {
    if (!trades) {
      dispatch(getTrades());
    }
  }, []);

  const [trade_id, settrade_id] = useState("");
  const [name, setname] = useState("");
  const [description, setDescription] = useState("");

  useEffect(async () => {
    if (id) {
      const response = await dispatch(editSubTrade(id));
      if (response) {
        setDescription(response.description);
        settrade_id(response.trade_id);
        setname(response.name);
      } else {
        setExist(false);
      }
    }
  }, [id]);

  // const [data, setData] = useState({
  //   trade_id: "",
  //   name: "",
  //   description: "",
  // });
  // const { trade_id, name, description } = data;
  // const handleChange = (e) => {
  //   setData({
  //     ...data,
  //     [e.target.name]: e.target.value,
  //   });
  // };
  const onChange = (evt) => {
    //console.log("onChange fired with event info: ", evt);
    var newContent = evt.editor.getData();
    setDescription(newContent);
    // setData({ ...data, description: newContent });
  };
  const [file, setFile] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    // for (let [key, value] of Object.entries(data)) {
    //   if (Array.isArray(value)) {
    //     formdata.append(key, JSON.stringify(value));
    //   } else {
    //     formdata.append(key, value);
    //   }
    // }
    formdata.append("name", name);
    formdata.append("description", description);
    formdata.append("trade_id", trade_id);
    if (file) {
      formdata.append("file", file);
    }
    if (id) {
      formdata.append("id", id);
      const response = await dispatch(updateSubTrade(formdata));
      if (response) {
        history.push("/admin/view-sub-trade");
      }
    } else {
      const response = await dispatch(createSubTrade(formdata));
      if (response) {
        history.push("/admin/view-sub-trade");
      }
    }
  };

  return (
    <div>
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
          <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <BreadCrumb crumbs={breadcrumbs} />

            <div class="d-flex align-items-center">
              <Link
                to="/admin/add-trade"
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                Add Trade
              </Link>
              <Link
                to="/admin/view-sub-trade"
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                View Subtrade
              </Link>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8">
              <div>
                {exist ? (
                  <form onSubmit={handleSubmit}>
                    <div class="form-group">
                      <label>
                        Trade <span class="text-danger">*</span>
                      </label>
                      <select
                        class="form-control"
                        name="trade_id"
                        value={trade_id}
                        onChange={(e) => settrade_id(e.target.value)}
                        required
                      >
                        <option value="">Select</option>
                        {trades ? (
                          trades.map((trade) => (
                            <option value={trade.id}>{trade.name}</option>
                          ))
                        ) : (
                          <Loading active={true} />
                        )}
                      </select>
                    </div>
                    <div class="form-group">
                      <label>
                        Subtrade <span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter course Subtrade "
                        name="name"
                        value={name}
                        onChange={(e) => setname(e.target.value)}
                        required
                      />
                    </div>
                    <div class="form-group">
                      <label>
                        Subtrade <span class="text-danger">*</span>
                      </label>
                      <CKEditor
                        activeClass="p10"
                        content={description}
                        events={{
                          // blur: onBlur,
                          // afterPaste: afterPaste,
                          change: onChange,
                        }}
                      />
                    </div>
                    <div class="form-group">
                      <label>Pdf</label>
                      <input
                        type="file"
                        class="form-control"
                        accept="application/pdf"
                        placeholder="Enter course Subtrade "
                        name="file"
                        // required
                        onChange={(e) => setFile(e.target.files[0])}
                      />
                      <span class="form-text text-muted mt-4"></span>
                    </div>

                    <button type="submit" class="btn btn-primary mr-2">
                      Submit
                    </button>
                    <button type="reset" class="btn btn-secondary">
                      Cancel
                    </button>
                  </form>
                ) : (
                  <h5 className="text-center">No Sub Trade Found</h5>
                )}
              </div>
            </div>
            <div class="col-md-2"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
