import React, { useEffect, useState } from "react";
import BreadCrumb from "../../BreadCrumb";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getBodyList } from "../../../store/actions/adminActions/bodyAction";
import Loader from "../../Alert/Loader";

const BodyList = () => {
  const dispatch = useDispatch();
  const breadcrumbs = [
    {
      path: "/admin/dashboard",
      label: "Dashboard",
    },
    {
      // path: "/aboutus",
      label: "Body List",
    },
  ];
  const [list, setList] = useState(null);
  useEffect(async () => {
    const response = await dispatch(getBodyList());
    console.log('response', response)
    setList(response.data);
  }, []);
  return (
    <div>
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
          <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <BreadCrumb crumbs={breadcrumbs} />
            <div class="d-flex align-items-center">
              <Link
                to={"/admin/add-body"}
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                Add body
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-12">
            <table
              class="table table-separate table-head-custom table-checkable"
              id="kt_datatable"
            >
              <thead>
                <tr>
                  <th>Sr.No</th>
                  <th>Name</th>
                  <th>Email</th>

                  <th>Mobile</th>
                  <th>Designation</th>
                  <th>Type</th>
                  <th>Edit</th>
                </tr>
              </thead>
              {list ? (
                list.length > 0 ? (
                  list.map((element, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{element.name}</td>
                      <td>{element.email}</td>
                      <td>{element.mobile}</td>
                      <td>{element.designation}</td>
                      <td>
                        {element.body_type == "0"
                          ? "Governing Body"
                          : "General Body"}
                      </td>
                      <td>
                        <Link to={`/admin/edit-body/${element.id}`}>Edit</Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6}>{"No Record Found"}</td>
                  </tr>
                )
              ) : (
                <Loader active={true} />
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BodyList;
