import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { viewActivity } from "../../../store/actions/adminActions/activityActions";
import Loader from "../../Alert/Loader";
import BreadCrumb from "../../BreadCrumb";
const ViewActivity = () => {
  const dispatch = useDispatch();
  const [list, setList] = useState(null);
  const breadcrumbs = [
    {
      path: "/admin/dashboard",
      label: "Dashboard",
    },
    {
      label: "View Activity List",
    },
  ];
  useEffect(async () => {
    const response = await dispatch(viewActivity());
    setList(response.data || []);
  }, []);
  return (
    <div>
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
          <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <BreadCrumb crumbs={breadcrumbs} />
            <div class="d-flex align-items-center">
              <Link
                to="/admin/add-activity"
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                Add Activity
              </Link>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-12">
              <table
                class="table table-separate table-head-custom table-checkable"
                id="kt_datatable"
              >
                <thead>
                  <tr>
                    <th>Sr.No</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {list === null ? (
                    <Loader active={true} />
                  ) : list.length > 0 ? (
                    list.map((l, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{l.title}</td>
                        <td>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: l.description,
                            }}
                          ></div>
                        </td>
                        <td>
                          <Link to={`/admin/edit-activity/${l.id}`}>Edit</Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3}>No Data Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewActivity;
