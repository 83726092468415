import React, { useEffect, useState } from "react";
import BreadCrumb from "../../BreadCrumb";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  addCategory,
  updateCategory,
  getCategoryDetail,
} from "../../../store/actions/printActions/printcategory";
const AddCategory = () => {
  const dispatch = useDispatch();
  const [exist, setExist] = useState(true);
  const history = useHistory();
  const { id } = useParams();
  const [data, setData] = useState({
    name: "",
    description: "",
    validity: "",
    price: "",
  });
  useEffect(async () => {
    if (id) {
      const response = await dispatch(getCategoryDetail(id));
      console.log("response", response);
      if (response.success) {
        setData({
          name: response.data.name,
          description: response.data.description,
        });
      } else {
        setExist(false);
      }
    }
  }, [id]);
  const { name, description, validity, price } = data;
  const breadcrumbs = [
    {
      path: "/admin/dashboard",
      label: "Dashboard",
    },
    {
      // path: "/aboutus",
      label: "Add Bodies",
    },
  ];
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (id) {
      const response = await dispatch(updateCategory({ id: id, ...data }));
      if (response) {
        history.push("/print/category");
      }
    } else {
      const response = await dispatch(addCategory(data));
      if (response) {
        history.push("/print/category");
      }
    }
  };
  return (
    <div>
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
          <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/* <BreadCrumb crumbs={breadcrumbs} /> */}

            <div class="d-flex align-items-center">
              <Link
                to={"/print/category"}
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                View List
              </Link>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-2"></div>

            <div class="col-md-8">
              {exist ? (
                <form onSubmit={handleSubmit}>
                  <div class="form-group">
                    <label>
                      Name <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Name "
                      name="name"
                      value={name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label>
                      Description <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Description "
                      name="description"
                      value={description}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label>
                      Validity <span class="text-danger">*</span>
                    </label>
                    <select
                      className="form-control form-control-sm"
                      name="validity" // Add this line
                      value={validity} // And this line to control the select's value
                      onChange={handleChange}
                    >
                      <option value="">Select type</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label>
                      Price <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Description "
                      name="price"
                      value={price}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <button type="submit" class="btn btn-primary mr-2">
                    Submit
                  </button>
                  {/* <button type="reset" class="btn btn-secondary">
                  Cancel
                </button> */}
                </form>
              ) : (
                <h1>Record does not exist</h1>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCategory;
