import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAssignments } from "../../../store/actions/councilActions/assignmentAction";
import Loader from "../../Alert/Loader";
import BreadCrumb from "../../BreadCrumb";

const AssignmentList = () => {
  const { assignments } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const breadcrumbs = [
    {
      path: "/admin/dashboard",
      label: "Dashboard",
    },
    {
      label: "Assignment List",
    },
  ];

  useEffect(() => {
    dispatch(getAssignments());
  }, []);

  return (
    <div>
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
          <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <BreadCrumb crumbs={breadcrumbs} />

            <div class="d-flex align-items-center">
              <Link
                to="/admin/add-assignment"
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                Add Assignment
              </Link>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-12">
              <table
                class="table table-separate table-head-custom table-checkable"
                id="kt_datatable"
              >
                <thead>
                  <tr>
                    <th>Sr.No</th>
                    <th>Subject Name</th>
                    <th>Action </th>
                  </tr>
                </thead>
                <tbody>
                  {!assignments ? (
                    <Loader active={true} />
                  ) : assignments.length > 0 ? (
                    assignments.map((assignment, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{assignment.name}</td>
                        <td>
                          <Link
                            to={`/admin/view-assignment/${assignment.subject}`}
                          >
                            View
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3} class="text-center">
                        No Assignment Found
                      </td>
                    </tr>
                  )}
                  {/* <% assigment.forEach((item,index)=>{%> */}

                  {/* <%})%> */}
                </tbody>
              </table>
            </div>
            <div class="col-md-2"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignmentList;
