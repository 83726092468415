import {
  HELPDESK_AUTH_SUCCESS,
  HELPDESK_AUTH_FAIL,
  AUTH_FAIL,
} from "../../actions/actionTypes";

const initialState = {
  adminAuth: {
    isAuth: false,
    profile: null,
    loading: true,
    token: null,
  },
};

export const helpdeskReducer = (state = initialState, action) => {
  switch (action.type) {
    case HELPDESK_AUTH_SUCCESS:
      return {
        ...state,
        adminAuth: {
          isAuth: true,
          profile: action.payload,
          loading: false,
        },
      };
    case AUTH_FAIL:
      return {
        ...state,
        adminProfile: {},
        adminAuth: {
          isAuth: false,
          profile: null,
          loading: false,
        },
      };

    default:
      return state;
  }
};
