import { ADMIN_AUTH_FAIL, ADMIN_AUTH_SUCCESS, ALERT } from "../actionTypes";
import { postDataAPI, getDataAPI } from "../../../services/apiWrapper";

export const adminLogin = (data) => async (dispatch) => {
  try {
    dispatch({
      type: ALERT,
      payload: { loading: true },
    });
    const response = await dispatch(postDataAPI("admin/login", data));

    localStorage.setItem("adminToken", response.data.data.admin.token);
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });
    dispatch({
      type: ADMIN_AUTH_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: { loading: false, error: error.response.data.message },
    });
    dispatch({
      type: ADMIN_AUTH_FAIL,
    });
  }
};

export const adminDashboard = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    dispatch({
      type: ALERT,
      payload: { loading: true },
    });
    const response = await dispatch(getDataAPI("admin/dashboard", token));
    dispatch({
      type: ADMIN_AUTH_SUCCESS,
      payload: response.data.data,
    });
    dispatch({
      type: ALERT,
      payload: { loading: false },
    });
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: { loading: false },
    });
    dispatch({
      type: ADMIN_AUTH_FAIL,
    });
  }
};
