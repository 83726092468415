import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./css/mainsection.css";
import ImpLinks from "./ImpLinks";
import { viewStaticPage } from "../../../store/actions/adminActions/staticPages";

export default function MainSection() {
  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const fetchData = async () => {
    const response = await dispatch(viewStaticPage(5));
    setData(response.data);
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <section class="Main_sect">
      <div class="container ">
        <div class="row">
          <ImpLinks />
          <div class="col-md-9">
            <div class="row">
              {/* <div class="navitveadd">
                <div class="aNavtive"> */}
              <div class="col-md-12 col-lg-7 homeImg">
                {/* <div class=""> */}
                <img src="/images/1.jpg" />
              </div>
              {/* </div> */}
              <div class="col-md-12 col-lg-4">
                {data ? (
                  <>
                    <div class="Team-lefatbar notification-items NOtification">
                      {/* <div class="static-title"> */}
                      <h3 className="text-center">{data.page_title}</h3>
                      {/* </div> */}

                      <div class="about-content mt-4 p-2">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data.page_description,
                          }}
                        ></div>
                      </div>
                    </div>
                  </>
                ) : null}
                {/* <div class="aNavtiveData"> */}
              </div>
              {/* </div> */}
              {/* </div>
              </div> */}
            </div>
            {/* <div class="Team_list">
              <div class="owl-carousel owl-theme owl-single2 ">
                <div class="item">
                  <div class="partnerlogo">
                    <img src="/uploads/1625935098603download.png" />
                  </div>
                </div>

                <div class="item">
                  <div class="partnerlogo">
                    <img src="/uploads/1625935708074niti-logo-0B7DECBE76-seeklogo.com.png" />
                  </div>
                </div>

                <div class="item">
                  <div class="partnerlogo">
                    <img src="/uploads/1625935887670NSDC-Logo.png" />
                  </div>
                </div>

                <div class="item">
                  <div class="partnerlogo">
                    <img src="/uploads/1625936017437download-(2).png" />
                  </div>
                </div>

                <div class="item">
                  <div class="partnerlogo">
                    <img src="/uploads/1625936325875ISO-Logo-1024x1024.png" />
                  </div>
                </div>

                <div class="item">
                  <div class="partnerlogo">
                    <img src="/uploads/1625936526197images.png" />
                  </div>
                </div>

                <div class="item">
                  <div class="partnerlogo">
                    <img src="/uploads/1625937539783QCI-2-4-16big.jpg" />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}
