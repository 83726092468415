import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import StateCitySelector from "../misc/StateCitySelector";
// import { ALERT } from "../../store/actions/actionTypes";
import { Modal, Button } from "react-bootstrap";
import BreadCrumb from "../BreadCrumb";
import {
  memberOtherRegisterAction,
  memberRegisterAction,
} from "../../store/actions/MemberAction/memberActions";
import {
  getCategory,
  getpagesubCategory,
  getsubCategory,
} from "../../store/actions/printActions/printcategory";
import Individual from "../PrintPortal/Membership/Individual";
import {
  downloadCourses,
  getCourseList,
  getCourseListPagination,
  getCourses,
} from "../../store/actions/councilActions/courseAction";
import TradeSubtradeSelector from "../misc/TradeSubtradeSelector";
import { toast } from "react-toastify";
import Nvpce from "./Nvpce";

// import Profile from "../Profile/Profile";

const breadcrumbs = [
  {
    path: "/",
    label: "Home",
  },
  {
    // path: "/aboutus",
    label: "Center Register",
  },
];
const initialState = {
  membership_type: "",
  userInput: "",
  course: "",
  passing_year: "",
  student_name: "",
  father_name: "",
  mother_name: "",
  aadhaar_number: "",
  mobile: "",
  address: "",
  center_name: "",
  center_address: "",
  center_state: "",
  center_city: "",
  roll_number: "",
  certificate_number: "",
  city: "",
  state: "",
  pin_code: "",
  examination_body_name: "",
  examination_state: "",
  examination_city: "",
  examination_address: "",
  document_type: "",
};
const secondinitialState = {
  membership_type: 0,
  subcategory: "",
  name: "",
  service_information: "",
  contact_person_name: "",
  contact_person_number: "",
  achievements: "",
  state: "",
  city: "",
  location: "",
  pincode: "",
  mobile: "",
  email: "",
  document_type: "",
  document_file: null,
  website: "",
  correspondence_address: "",
  registered_address: "",
  services_location: "",
  directors_name: "",
  registration_year: "",
  total_employees: "",
  why_choose_npvec_member: "",
  document_number: "",
  ragistration_certificate: null,
  userInput: "",
};
export default function Register() {
  const dispatch = useDispatch();
  //   const [trades, setTrades] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [secondsubmitting, setSecondSubmitting] = useState(false);
  const [data, setdata] = useState(initialState);
  //   const subtraderef = useRef();
  const [secondData, setSecondData] = useState(secondinitialState);
  const [termsService, setTermsService] = useState(0);
  const {
    membership_type,
    course,
    passing_year,
    student_name,
    father_name,
    mother_name,
    aadhaar_number,
    mobile,
    address,
    userInput,
    center_name,
    roll_number,
    certificate_number,
    city: City,
    state: State,
    pin_code: Pincode,
    center_address,
    center_city,
    center_state,
    examination_body_name,
    examination_state,
    examination_city,
    examination_address,
    document_type: document,
    sub_trade,
  } = data;

  const {
    membership_type: secondMembership_type,
    subcategory,
    name,
    service_information,
    contact_person_name,
    contact_person_number,
    achievements,
    state,
    city,
    location,
    pincode,
    mobile: secondMobile,
    email,
    document_type,
    website,
    correspondence_address,
    registered_address,
    services_location,
    directors_name,
    registration_year,
    total_employees,
    why_choose_npvec_member,
    document_number,
    userInput: captcha,
  } = secondData;

  const [typeSelection, setTypeSelection] = useState("");
  const [formSelection, setFormSelection] = useState("");
  const [list, setList] = useState([]);
  const [datalist, setDatalist] = useState([]);
  const [certificate_picpreview, setcertificate_picpreview] = useState(null);
  const [certificate_pic, setcertificate_pic] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [courselist, setcourseList] = useState([]);
  const [student_picpreview, setstudent_picpreview] = useState(null);
  const [student_pic, setstudent_pic] = useState([]);
  const [otherstudent_picpreview, setotherstudent_picpreview] = useState(null);
  const [otherstudent_pic, othersetstudent_pic] = useState([]);
  const [registerno, setRegisterno] = useState("");
  const [document_pic, setdocument_pic] = useState([]);
  const [document_picpreview, setdocument_picpreview] = useState(null);
  const [anyragistration_pic, setanyragistration_pic] = useState([]);
  const [anyragistration_picpreview, setanyragistration_picpreview] =
    useState(null);
  const [sublist, setSubList] = useState([]);
  const [termlist, settermlist] = useState([]);

  const [captchaText, setCaptchaText] = useState("");
  // const [userInput, setUserInput] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [issecValid, setIssecValid] = useState(false);
  const [captchaerror, setCaptchaerror] = useState("");
  const [showother, setShowOther] = useState(false);

  useEffect(() => {
    generateCaptcha();
    generateSecondCaptcha();
  }, []);

  const generateCaptcha = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let captcha = "";
    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      captcha += characters[randomIndex];
    }
    setCaptchaText(captcha);
  };

  const checkCaptcha = () => {
    if (data.userInput === captchaText) {
      setIsValid(true);
      setCaptchaerror("");
    } else {
      setIsValid(false);
      toast.error(setCaptchaerror("CAPTCHA does not match.Please try again"));
    }
  };

  const generateSecondCaptcha = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let captcha = "";
    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      captcha += characters[randomIndex];
    }
    setCaptchaText(captcha);
  };

  const checkSecondCaptcha = () => {
    if (secondData.userInput === captchaText) {
      setIssecValid(true);
      setCaptchaerror("");
    } else {
      setIssecValid(false);
      toast.error(setCaptchaerror("CAPTCHA does not match.Please try again"));
    }
  };
  console.log("issecValid", issecValid);
  // Handle user input change
  // const handleInputChange = (e) => {
  //   setUserInput(e.target.value);
  // };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;

    if (name === "membership_type") {
      setdata({
        ...data,
        [name]: value,
      });
    }
    if (value === "Other") {
      setShowOther(true);

      // Fetch subpages when the membership_type is changed
      const subpageResponse = await dispatch(getpagesubCategory(value));
      console.log("subpageResponse", subpageResponse);
      // You can handle the response data here
      settermlist(subpageResponse.data || []);
    } else {
      setdata({
        ...data,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("data", data);
    var formdata = new FormData();
    if (userInput) {
      for (let [key, value] of Object.entries(data)) {
        if (Array.isArray(value)) {
          formdata.append(key, JSON.stringify(value));
        } else {
          formdata.append(key, value);
        }
      }
      formdata.append("certificate_file", certificate_pic);
      formdata.append("student_image", student_pic);
      formdata.append("is_accepted", termsService);
      // formdata.append("ragistration_certificate", ragistration_pic);
      // formdata.append("diploma_certificate", diploma_pic);

      setSubmitting(true);
      const response = await dispatch(memberRegisterAction(formdata));
      console.log("response22", response);
      if (response) {
        setShowModal(true);
        setRegisterno(response.data.registration_number);
        setdata(initialState);

        //   setfacilityDetail([]);
        //   setStaffDetail([]);

        setSubmitting(false);
      } else {
        setSubmitting(false);
      }
    } else {
      toast.error("Please Fill the Capctha");
    }
    // dispatch(centerRegisterAction(formdata));
  };

  useEffect(async () => {
    const catResponse = await dispatch(getCategory());
    setList(catResponse.data);

    const subresponse = await dispatch(getsubCategory(catResponse.data[4]?.id));
    setDatalist(subresponse.data);

    const course = await dispatch(getCourseListPagination());

    setcourseList(course);
  }, [dispatch]);

  const secondhandleChange = async (e) => {
    const { name, value } = e.target;

    if (name === "membership_type") {
      setSecondData((prevData) => ({
        ...prevData,
        // subcategory:data.subcategory, // Reset subcategory
        [name]: value,
      }));

      // Fetch subcategories when the category is changed

      const subresponse = await dispatch(getsubCategory(value));
      console.log("subresponse", subresponse.data.name);

      // setSubList(subresponse.data[0].id|| "");
      setSubList(subresponse.data || []);
    } else {
      setSecondData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    if (name === "subcategory") {
      console.log("sublist", sublist);
      console.log("value", value);
      const selectedSubcategory = sublist.find(
        (ele) => ele.id === parseInt(value)
      );
      console.log("selectedSubcategory", selectedSubcategory);
      const subcategoryName = selectedSubcategory
        ? selectedSubcategory.name
        : "";

      setSecondData({
        ...secondData,
        subcategory: value,
        name: subcategoryName,
      });

      // Fetch subcategories when the category is changed
      const subresponse = await dispatch(getpagesubCategory(value));
      console.log("subresponse", subresponse);

      // setSubList(subresponse.data[0].id|| "");
      settermlist(subresponse.data || []);
    } else {
      setSecondData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  console.log("datalist", secondData);
  const secondHandleSubmit = async (e) => {
    e.preventDefault();

    console.log("data", data);
    var formdata = new FormData();
    if (captcha) {
      for (let [key, value] of Object.entries(secondData)) {
        if (Array.isArray(value)) {
          formdata.append(key, JSON.stringify(value));
        } else {
          formdata.append(key, value);
        }
      }
      formdata.append("document_file", document_pic);
      formdata.append("ragistration_certificate", anyragistration_pic);
      formdata.append("is_accepted", termsService);
      formdata.append("otherstudent_image", otherstudent_pic);
      const payload = {
        ...secondData,
      };
      console.log("formData", payload);
      setSecondSubmitting(true);
      const response = await dispatch(memberOtherRegisterAction(formdata));
      if (response) {
        setShowModal(true);
        setRegisterno(response.data.registration_number);
        setSecondData(secondinitialState);
        //   setfacilityDetail([]);
        //   setStaffDetail([]);
        setSecondSubmitting(false);
      } else {
        setSecondSubmitting(false);
      }
    } else {
      toast.error("Please Fill The Capctha");
    }
    // dispatch(centerRegisterAction(formdata));
  };
  useEffect(() => {
    if (sub_trade) {
      dispatch(getCourses(sub_trade));
    }
  }, [sub_trade]);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(registerno)
      .then(() => {
        toast.success("Registration number copied to clipboard!");
      })
      .catch((err) => {
        toast.error("Could not copy text: ", err);
      });
  };

  return (
    <>
      <div class="lists-box">
        <div class="container">
          <BreadCrumb crumbs={breadcrumbs} />
          <div class="title-box">
            <h4>Member Register</h4>
            <h6> Application Form</h6>
          </div>

          <div className="col-md-12">
            <div className="boxlabel">
              <p>Type of Registration</p>
              <select
                className="form-control form-control-sm"
                onChange={(e) => setTypeSelection(e.target.value)}
              >
                <option value="" disabled selected>
                  Select type
                </option>
                <option value="individual">Individual</option>
                <option value="other">Other</option>
              </select>
            </div>
          </div>

          {typeSelection === "individual" && (
            <div className="col-md-12">
              <div className="boxlabel">
                <p>Select Option</p>
                <select
                  className="form-control form-control-sm"
                  onChange={(e) => setFormSelection(e.target.value)}
                >
                  <option value="">Choose option</option>
                  <option value="npvce">NPVCE</option>
                  <option value="fillForm">OTHER</option>
                </select>
              </div>
            </div>
          )}

          {formSelection === "fillForm" && (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <>
                      <div className="col-md-12">
                        <div className="formTitle"> Details</div>
                      </div>
                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p>Memebrship Type</p>

                          <select
                            className="form-control"
                            name="membership_type"
                            value={data.membership_type}
                            onChange={handleChange}
                          >
                            {datalist?.map((ele) => {
                              return (
                                <>
                                  <option value="" disabled selected>
                                    select membertype
                                  </option>
                                  <option key={ele.id} value={ele?.id}>
                                    {ele?.name}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p>Student Name</p>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="student_name"
                            value={student_name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="Fileboxbale">
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input"
                              id="customFiler73"
                              name="student_image"
                              onChange={(e) => {
                                setstudent_pic(e.target.files[0]);
                                setstudent_picpreview(
                                  URL.createObjectURL(e.target.files[0])
                                );
                              }}
                            />
                            <label className="custom-file2" for="customFiler73">
                              {student_picpreview ? (
                                <img
                                  src={student_picpreview}
                                  height={"100px"}
                                  id="frontphoto"
                                />
                              ) : (
                                <>
                                  <img
                                    src="/images/dummy.png"
                                    height={"100px"}
                                  ></img>
                                </>
                              )}
                              <h4>Upload Student Image</h4>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p>Father Name</p>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="father_name"
                            value={father_name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p>Mother Name</p>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="mother_name"
                            value={mother_name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p> Mobile no</p>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="mobile"
                            value={mobile}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p>Roll Number</p>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="roll_number"
                            value={roll_number}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p>Certificate Number</p>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="certificate_number"
                            value={certificate_number}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p>Passing Year</p>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="passing_year"
                            value={passing_year}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p>Document Type</p>
                          <select
                            className="form-control form-control-sm"
                            name="document_type"
                            value={document}
                            onChange={handleChange}
                          >
                            <option value="" disabled selected>
                              Select Document Type
                            </option>
                            <option value="registration certificate">
                              Registration Certificate
                            </option>
                            <option value="diploma">Diploma</option>
                          </select>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <TradeSubtradeSelector
                            data={data}
                            setData={setdata}
                          />
                        </div>

                        {sub_trade && (
                          <div className="col-md-6">
                            <div>
                              <p>Course</p>

                              {showother ? (
                                <input
                                  type="text"
                                  className="form-control mt-2"
                                  placeholder="Enter Other Course"
                                  name="course"
                                  value={course}
                                  onChange={handleChange}
                                />
                              ) : (
                                <select
                                  className="form-control"
                                  name="course"
                                  value={course}
                                  onChange={handleChange}
                                >
                                  {courselist?.data?.map((ele) => (
                                    <option key={ele.id} value={ele?.id}>
                                      {ele?.name}
                                    </option>
                                  ))}
                                  <option value="Other">Other</option>
                                </select>
                              )}
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="col-md-12">
                        <div className="formTitle"> Address</div>
                      </div>
                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p> Addhar Card Number</p>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="aadhaar_number"
                            value={aadhaar_number}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p>Address </p>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="address"
                            value={address}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <StateCitySelector
                        user_city={City}
                        user_state={State}
                        set_user_state={(e) => setdata({ ...data, state: e })}
                        set_user_city={(e) => setdata({ ...data, city: e })}
                      />

                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p>PinCode </p>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="pin_code"
                            value={Pincode}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </>
                    <>
                      <div className="col-md-12">
                        <div className="formTitle">Center Details</div>
                      </div>
                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p>Center Name </p>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="center_name"
                            value={center_name}
                            onChange={handleChange}
                            readonly
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p>Center Address </p>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="center_address"
                            value={center_address}
                            onChange={handleChange}
                            readonly
                            required
                          />
                        </div>
                      </div>
                      <StateCitySelector
                        user_city={center_city}
                        user_state={center_state}
                        set_user_state={(e) =>
                          setdata({ ...data, center_state: e })
                        }
                        set_user_city={(e) =>
                          setdata({ ...data, center_city: e })
                        }
                      />

                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p>Examination Body Name </p>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="examination_body_name"
                            value={examination_body_name}
                            onChange={handleChange}
                            readonly
                            required
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p>Examination Body Address </p>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="examination_address"
                            value={examination_address}
                            onChange={handleChange}
                            readonly
                            required
                          />
                        </div>
                      </div>

                      <StateCitySelector
                        user_city={examination_city}
                        user_state={examination_state}
                        set_user_state={(e) =>
                          setdata({ ...data, examination_state: e })
                        }
                        set_user_city={(e) =>
                          setdata({ ...data, examination_city: e })
                        }
                      />
                    </>
                  </div>
                </div>
                <>
                  <div className="col-md-12">
                    <div className="formTitle">Documents</div>
                  </div>
                  <div className="col-md-4 col-12">
                    <div className="Fileboxbale">
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="customFiler7"
                          name="certificate_file"
                          accept=".jpg, .jpeg, .png, .pdf"
                          onChange={(e) => {
                            setcertificate_pic(e.target.files[0]);
                            // setcertificate_picpreview(
                            //   URL.createObjectURL(e.target.files[0])
                            // );
                            const file = e.target.files[0]; // Define 'file' here
                            setcertificate_pic(file);

                            if (file) {
                              if (file.type.startsWith("image/")) {
                                // It's an image file
                                setcertificate_picpreview(
                                  URL.createObjectURL(file)
                                );
                              } else if (file.type === "application/pdf") {
                                // It's a PDF file
                                setcertificate_picpreview("/images/pdf.png");
                              }
                            } else {
                              setcertificate_picpreview(null);
                            }
                          }}
                        />
                        <label className="custom-file2" for="customFiler7">
                          {certificate_picpreview ? (
                            <img
                              src={certificate_picpreview}
                              height={"100px"}
                              id="frontphoto"
                            />
                          ) : (
                            <>
                              <img
                                src="/images/dummy.png"
                                height={"100px"}
                              ></img>
                            </>
                          )}
                          <h4>Upload The Document you Choose</h4>
                        </label>
                      </div>
                    </div>
                  </div>
                </>

                {/* <div className="col-md-12">
                  <div className="captchaSection">
                    <h4>Verify you're human</h4>

                    <div className="captchaContent">
                      <div className="captchaDisplay">
                        {captchaText}
                        <button
                          type="button"
                          onClick={generateCaptcha}
                          className="refreshCaptchaBtn"
                        >
                          Refresh
                        </button>
                      </div>

                      <div className="captchaInput">
                        <input
                          type="text"
                          placeholder="Enter CAPTCHA"
                          value={userInput}
                          onChange={handleChange}
                          className="captchaTextBox"
                        />
                      </div>
                    </div>

                    <div className="captchaVerification">
                      <button
                        type="button"
                        onClick={checkCaptcha}
                        className="verifyCaptchaBtn"
                      >
                        Verify
                      </button>
                      {isValid && (
                        <p className="validCaptchaText">CAPTCHA is valid!</p>
                      )}
                      {!isValid && captchaerror && (
                        <p className="errorText">{captchaerror}</p>
                      )}
                    </div>
                  </div>
                </div> */}
                <div className="col-md-12">
                  <div className="check-box-div">
                    {termlist?.do_description && (
                      <>
                        {termlist.do_description}
                        <br />
                      </>
                    )}
                    {termlist?.donts_description && (
                      <>
                        {termlist.donts_description}
                        <br />
                      </>
                    )}
                    {termlist?.terms_condition && (
                      <>
                        {termlist.terms_condition}
                        <br />
                      </>
                    )}
                    {termlist?.do_description ||
                    termlist?.donts_description ||
                    termlist?.terms_condition ? (
                      <label
                        for="termsOfService"
                        style={{
                          cursor: "pointer",
                          display: "inline-block",
                          margin: "10px 0",
                        }}
                      >
                        <input
                          type="checkbox"
                          className="check-button"
                          name="is_accepted"
                          id="termsOfService"
                          onChange={() =>
                            setTermsService(termsService === 0 ? 1 : 0)
                          }
                        />
                        <span style={{ fontSize: "14px", marginLeft: "5px" }}>
                          I accept the Terms of Service
                        </span>
                      </label>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="captchaSection">
                    <button
                      className="captchaButton"
                      type="button"
                      onClick={generateCaptcha}
                    >
                      Generate CAPTCHA
                    </button>
                    <div className="captchaVerification">
                      <span>
                        CAPTCHA:
                        {captchaText.split("").map((char, index) => (
                          <span className="captchaCharacter" key={index}>
                            {char}
                          </span>
                        ))}
                      </span>
                      <input
                        type="text"
                        placeholder="Enter CAPTCHA"
                        name="userInput"
                        value={data.userInput || ""}
                        onChange={handleChange}
                        className="captchaInput"
                      />
                      <button
                        className="captchaButton"
                        type="button"
                        onClick={checkCaptcha}
                      >
                        Verify
                      </button>
                      {isValid && (
                        <p className="validCaptchaText">CAPTCHA is valid!</p>
                      )}
                    </div>
                    {!isValid && captchaerror && (
                      <p className="errorText">{captchaerror}</p>
                    )}
                  </div>
                </div>

                <div className="col-md-12">
                  <div
                    className="btn-groups mt-4 text-center"
                    role="group"
                    aria-label="First group"
                  >
                    <button
                      className="btn btn-Next"
                      type="submit"
                      disabled={submitting}
                    >
                      Submit
                      <span>
                        <svg
                          width="35"
                          height="12"
                          viewBox="0 0 35 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M34.8403 6.00003C34.8403 6.2151 34.7582 6.43013 34.5943 6.5941L29.4346 11.7538C29.1063 12.0821 28.5742 12.0821 28.2461 11.7538C27.918 11.4257 27.918 10.8937 28.2461 10.5654L32.8117 6.00003L28.2462 1.43461C27.9181 1.10639 27.9181 0.574383 28.2462 0.246316C28.5743 -0.0820704 29.1065 -0.0820704 29.4347 0.246316L34.5945 5.40596C34.7584 5.57001 34.8403 5.78505 34.8403 6.00003Z"
                            fill="white"
                          ></path>
                          <line
                            x1="33.25"
                            y1="5.94995"
                            x2="0.75"
                            y2="5.94995"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          ></line>
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              {showModal && (
                <Modal show={showModal} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Success!</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    This is Your Registration number :{" "}
                    <strong>{registerno}</strong>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={copyToClipboard}>
                      Copy Registration Number
                    </Button>
                  </Modal.Footer>
                </Modal>
              )}
            </form>
          )}

          {typeSelection === "other" && (
            <form onSubmit={secondHandleSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <>
                      <div className="col-md-12">
                        <div className="formTitle"> Details</div>
                      </div>

                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p>MemberShip Type</p>
                          <select
                            className="form-control"
                            name="membership_type"
                            value={secondData.membership_type}
                            onChange={secondhandleChange}
                          >
                            {list?.map((ele) => (
                              <option key={ele.id} value={ele?.id}>
                                {ele?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p>SubCategory</p>
                          <select
                            className="form-control"
                            name="subcategory"
                            value={secondData.subcategory}
                            onChange={secondhandleChange}
                          >
                            {sublist?.map((ele) => {
                              return (
                                <>
                                  <option key="" value="" disabled selected>
                                    Select subcategory
                                  </option>
                                  <option key={ele.id} value={ele?.id}>
                                    {ele?.name}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="Fileboxbale">
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input"
                              id="customFiler73"
                              name="otherstudent_image"
                              onChange={(e) => {
                                othersetstudent_pic(e.target.files[0]);
                                setotherstudent_picpreview(
                                  URL.createObjectURL(e.target.files[0])
                                );
                              }}
                            />
                            <label className="custom-file2" for="customFiler73">
                              {otherstudent_picpreview ? (
                                <img
                                  src={otherstudent_picpreview}
                                  height={"100px"}
                                  id="frontphoto"
                                />
                              ) : (
                                <>
                                  <img
                                    src="/images/dummy.png"
                                    height={"100px"}
                                  ></img>
                                </>
                              )}
                              <h4>Upload Student Image</h4>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p> Name</p>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="name"
                            value={secondData.name}
                            onChange={secondhandleChange}
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p>Provide brief information about your services*</p>
                          <textarea
                            rows="4"
                            cols="50"
                            className="form-control form-control-sm"
                            name="service_information"
                            value={service_information}
                            onChange={secondhandleChange}
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p> Person Name</p>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="contact_person_name"
                            value={contact_person_name}
                            onChange={secondhandleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p> Person Number</p>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="contact_person_number"
                            value={contact_person_number}
                            onChange={secondhandleChange}
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p>Major Achievements / Projects</p>
                          <textarea
                            type="textArea"
                            rows="4"
                            cols="10"
                            className="form-control form-control-sm"
                            name="achievements"
                            value={achievements}
                            onChange={secondhandleChange}
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="formTitle"> Address</div>
                      </div>
                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p>location</p>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="location"
                            value={location}
                            onChange={secondhandleChange}
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-4">
                        <div className="boxlabel">
                          <p>state</p>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="state"
                            value={state}
                            onChange={secondhandleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p>City </p>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="city"
                            value={city}
                            onChange={secondhandleChange}
                          />
                        </div>
                      </div> */}
                      <StateCitySelector
                        user_city={city}
                        user_state={state}
                        set_user_state={(e) =>
                          setSecondData({ ...secondData, state: e })
                        }
                        set_user_city={(e) =>
                          setSecondData({ ...secondData, city: e })
                        }
                      />
                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p> Pincode</p>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="pincode"
                            value={pincode}
                            onChange={secondhandleChange}
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="formTitle">Contact Detail</div>
                      </div>
                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p>Mobile Number</p>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="mobile"
                            value={secondMobile}
                            onChange={secondhandleChange}
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p>Email</p>
                          <input
                            type="email"
                            className="form-control form-control-sm"
                            name="email"
                            value={email}
                            onChange={secondhandleChange}
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="formTitle">Other Detail</div>
                      </div>

                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p>Document Type</p>
                          <select
                            className="form-control form-control-sm"
                            name="document_type"
                            value={document_type}
                            onChange={secondhandleChange}
                          >
                            <option value="" disabled selected>
                              Select Document Type
                            </option>
                            <option value="PAN">PAN</option>
                            <option value="TAN">TAN</option>
                            <option value="GST">GST</option>
                            <option value="CIN">CIN</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p> GST No./PAN No/TAN No./CIN No</p>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="document_number"
                            value={document_number}
                            onChange={secondhandleChange}
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p>website</p>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="website"
                            value={website}
                            onChange={secondhandleChange}
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p>Address of Correspondence</p>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="correspondence_address"
                            value={correspondence_address}
                            onChange={secondhandleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p>Registered Address </p>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="registered_address"
                            value={registered_address}
                            onChange={secondhandleChange}
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p>Location where you are providing your Services</p>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="services_location"
                            value={services_location}
                            onChange={secondhandleChange}
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p>Name of Directors/ Partners*</p>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="directors_name"
                            value={directors_name}
                            onChange={secondhandleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p>Year of Registration</p>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="registration_year"
                            value={registration_year}
                            onChange={secondhandleChange}
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="boxlabel">
                          <p>Number of Employees</p>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="total_employees"
                            value={total_employees}
                            onChange={secondhandleChange}
                          />
                        </div>
                      </div>
                    </>
                    <>
                      <div className="col-md-12">
                        <div className="formTitle">Document Details</div>
                      </div>

                      <div className="col-md-4 col-12">
                        <div className="Fileboxbale">
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input"
                              id="customFiler2"
                              name="ragistration_certificate"
                              onChange={(e) => {
                                const file = e.target.files[0]; // Define 'file' here
                                setanyragistration_pic(file);

                                if (file) {
                                  if (file.type.startsWith("image/")) {
                                    // It's an image file
                                    setanyragistration_picpreview(
                                      URL.createObjectURL(e.target.files[0])
                                    );
                                  } else if (file.type === "application/pdf") {
                                    // It's a PDF file
                                    setanyragistration_picpreview(
                                      "/images/pdf.png"
                                    ); // You can use your own PDF icon
                                  }
                                } else {
                                  setanyragistration_picpreview(null); // No file selected, set preview to null or display a placeholder image
                                }
                              }}
                            />
                            <label className="custom-file2" for="customFiler2">
                              {anyragistration_picpreview ? (
                                <img
                                  src={anyragistration_picpreview}
                                  height={"100px"}
                                  id="frontphoto"
                                />
                              ) : (
                                <>
                                  <img
                                    src="/images/dummy.png"
                                    height={"100px"}
                                  ></img>
                                  {/* <p>drag n drop photo here</p> */}
                                </>
                              )}
                              <h4>Upload Any Registration Certificate</h4>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="Fileboxbale">
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input"
                              id="customFiler3"
                              name="document_file"
                              onChange={(e) => {
                                const file = e.target.files[0]; // Define 'file' here
                                setdocument_pic(file);

                                if (file) {
                                  if (file.type.startsWith("image/")) {
                                    // It's an image file
                                    setdocument_picpreview(
                                      URL.createObjectURL(e.target.files[0])
                                    );
                                  } else if (file.type === "application/pdf") {
                                    // It's a PDF file
                                    setdocument_picpreview("/images/pdf.png"); // You can use your own PDF icon
                                  }
                                } else {
                                  setdocument_picpreview(null); // No file selected, set preview to null or display a placeholder image
                                }
                              }}
                            />
                            <label className="custom-file2" for="customFiler3">
                              {document_picpreview ? (
                                <img
                                  src={document_picpreview}
                                  height={"100px"}
                                  id="frontphoto"
                                />
                              ) : (
                                <>
                                  <img
                                    src="/images/dummy.png"
                                    height={"100px"}
                                  ></img>
                                  {/* <p>drag n drop photo here</p> */}
                                </>
                              )}
                              <h4> Upload GST No./PAN No/TAN No./CIN No</h4>
                            </label>
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="Fileboxbale">
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="customFiler7"
                        name="certificate_file"
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={(e) => {
                          setcertificate_pic(e.target.files[0]);
                          // setcertificate_picpreview(
                          //   URL.createObjectURL(e.target.files[0])
                          // );
                          const file = e.target.files[0]; // Define 'file' here
                          setcertificate_pic(file);

                          if (file) {
                            if (file.type.startsWith("image/")) {
                              // It's an image file
                              setcertificate_picpreview(
                                URL.createObjectURL(file)
                              );
                            } else if (file.type === "application/pdf") {
                              // It's a PDF file
                              setcertificate_picpreview("/images/pdf.png"); // You can use your own PDF icon
                            }
                          } else {
                            setcertificate_picpreview(null); // No file selected, set preview to null or display a placeholder image
                          }
                        }}
                      />
                      <label className="custom-file2" for="customFiler7">
                        {certificate_picpreview ? (
                          <img
                            src={certificate_picpreview}
                            height={"100px"}
                            id="frontphoto"
                          />
                        ) : (
                          <>
                            <img src="/images/dummy.png" height={"100px"}></img>
                          </>
                        )}
                        <h4>Upload The Document you Choose</h4>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="boxlabel">
                    <p>Why you want to be a NPVEC member ? </p>
                    <textarea
                      type="textArea"
                      rows="4"
                      cols="10"
                      className="form-control form-control-sm"
                      name="why_choose_npvec_member"
                      value={why_choose_npvec_member}
                      onChange={secondhandleChange}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="check-box-div">
                    {termlist?.do_description && (
                      <>
                        {termlist.do_description}
                        <br />
                      </>
                    )}
                    {termlist?.donts_description && (
                      <>
                        {termlist.donts_description}
                        <br />
                      </>
                    )}
                    {termlist?.terms_condition && (
                      <>
                        {termlist.terms_condition}
                        <br />
                      </>
                    )}
                    {termlist?.do_description ||
                    termlist?.donts_description ||
                    termlist?.terms_condition ? (
                      <label
                        for="termsOfService"
                        style={{
                          cursor: "pointer",
                          display: "inline-block",
                          margin: "10px 0",
                        }}
                      >
                        <input
                          type="checkbox"
                          className="check-button"
                          name="is_accepted"
                          id="termsOfService"
                          onChange={() =>
                            setTermsService(termsService === 0 ? 1 : 0)
                          }
                        />
                        <span style={{ fontSize: "14px", marginLeft: "5px" }}>
                          I accept the Terms of Service
                        </span>
                      </label>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="captchaSection">
                    <button
                      className="captchaButton"
                      type="button"
                      onClick={generateSecondCaptcha}
                    >
                      Generate CAPTCHA
                    </button>
                    <div className="captchaVerification">
                      <span>CAPTCHA: {captchaText}</span>
                      <input
                        type="text"
                        placeholder="Enter CAPTCHA"
                        name="userInput"
                        value={captcha}
                        onChange={secondhandleChange}
                        className="captchaInput"
                      />
                      <button
                        className="captchaButton"
                        type="button"
                        onClick={checkSecondCaptcha}
                      >
                        Verify
                      </button>
                      {issecValid && (
                        <p className="validCaptchaText">CAPTCHA is valid!</p>
                      )}
                    </div>
                    {!issecValid && captchaerror && (
                      <p className="errorText">{captchaerror}</p>
                    )}
                  </div>
                </div>

                <div className="col-md-12">
                  <div
                    className="btn-groups mt-4 text-center"
                    role="group"
                    aria-label="First group"
                  >
                    <button
                      className="btn btn-Next"
                      type="submit"
                      disabled={secondsubmitting}
                    >
                      Submit
                      <span>
                        <svg
                          width="35"
                          height="12"
                          viewBox="0 0 35 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M34.8403 6.00003C34.8403 6.2151 34.7582 6.43013 34.5943 6.5941L29.4346 11.7538C29.1063 12.0821 28.5742 12.0821 28.2461 11.7538C27.918 11.4257 27.918 10.8937 28.2461 10.5654L32.8117 6.00003L28.2462 1.43461C27.9181 1.10639 27.9181 0.574383 28.2462 0.246316C28.5743 -0.0820704 29.1065 -0.0820704 29.4347 0.246316L34.5945 5.40596C34.7584 5.57001 34.8403 5.78505 34.8403 6.00003Z"
                            fill="white"
                          ></path>
                          <line
                            x1="33.25"
                            y1="5.94995"
                            x2="0.75"
                            y2="5.94995"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          ></line>
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              {showModal && (
                <Modal show={showModal} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Success!</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    This is Your Registration number :{" "}
                    <strong>{registerno}</strong>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={copyToClipboard}>
                      Copy Registration Number
                    </Button>
                  </Modal.Footer>
                </Modal>
              )}
            </form>
          )}

          {formSelection === "npvce" && <Nvpce />}
        </div>
      </div>
    </>
  );
}
