import { getDataAPI } from "../../../services/apiWrapper";
import { exportToCSV } from "../../../utils/exportCsv";

export const enquiry = (type, page, course, date) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    const params = `${course ? `&course=${course}` : ""}${
      date ? `&date=${date}` : ""
    }&page=${page}`;
    const response = await dispatch(
      getDataAPI(`admin/${type}/enquiery?${params}`, token)
    );
    return response.data.data;
  } catch (error) {
    return false;
  }
};

export const downloadEnquiry = (type, course, date) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    const params = `${course ? `&course=${course}` : ""}${
      date ? `&date=${date}` : ""
    }`;
    const response = await dispatch(
      getDataAPI(`admin/${type}/enquiery?${params}`, token)
    );
    const dataToExport = [];
    if (type === "admission") {
      response.data.data.forEach((ele, index) => {
        dataToExport.push({
          Sno: index + 1,
          Course: ele.course?.name,
          Name: ele.name,
          Email: ele.email,
          Mobile: ele.mobile,
          Address: ele.address,
          State: ele.state,
          City: ele.city,
          Message: ele.message,
        });
      });
    } else {
      response.data.data.forEach((ele, index) => {
        dataToExport.push({
          Sno: index + 1,
          Trade: ele.trade?.name,
          Name: ele.name,
          Email: ele.email,
          Mobile: ele.mobile,
          Address: ele.address,
          state: ele.state,
          city: ele.city,
          Message: ele.message,
        });
      });
    }
    exportToCSV(dataToExport, "Enquiry");
  } catch (error) {
    return false;
  }
};
