import { postDataAPI } from "../../../services/apiWrapper";
import { PRACTICAL_DATA } from "../actionTypes";

export const addPracticalMarks = (data) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("centerToken");
      const response = await dispatch(
        postDataAPI("center/get-practical-data", data, token)
      );
      dispatch({
        type: PRACTICAL_DATA,
        payload: response.data.data,
      });
      return {
        isSuccess: true,
        data: response.data.data,
      };
    } catch (error) {
      dispatch({
        type: PRACTICAL_DATA,
        payload: false,
      });
      return false;
    }
  };
};

export const uploadPracticalMarks = (data) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("centerToken");
      const response = await dispatch(
        postDataAPI("center/add-practical-marks", data, token)
      );

      return true;
    } catch (error) {
      return false;
    }
  };
};

export const editPracticalMarks = (data) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("centerToken");
      const response = await dispatch(
        postDataAPI("center/edit-practical-marks", data, token)
      );

      return true;
    } catch (error) {
      return false;
    }
  };
};
