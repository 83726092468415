import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import CKEditor from "react-ckeditor-component";

import {
  addNews,
  getNewsDetail,
  updateNews,
} from "../../../store/actions/adminActions/newsAction";
import BreadCrumb from "../../BreadCrumb";

const AddNews = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [exist, setExist] = useState(true);
  const breadcrumbs = [
    {
      path: "/admin/dashboard",
      label: "Dashboard",
    },
    {
      // path: "/aboutus",
      label: "Add News",
    },
  ];

  const [file, setFile] = useState([]);

  const [data, setData] = useState({
    title: "",
    type: "news",
    link: "",
  });
  const [description, setdescription] = useState("");
  const onChange = (evt) => {
    //console.log("onChange fired with event info: ", evt);
    var newContent = evt.editor.getData();
    setdescription(newContent);
  };
  const { title, type, link } = data;

  useEffect(async () => {
    if (id) {
      const response = await dispatch(getNewsDetail(id));
      if (response.success) {
        setData({
          title: response.title,
          type: response.type,
          link: response.link || "",
        });
        setdescription(response.description);
      } else {
        setExist(false);
      }
    }
  }, []);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    for (let [key, value] of Object.entries(data)) {
      formdata.append(key, value);
    }
    formdata.append("description", description);
    formdata.append("file", file);
    if (!id) {
      const response = await dispatch(addNews(formdata));
      if (response) {
        history.push("/admin/view-news");
      }
    } else {
      formdata.append("id", id);
      const response = await dispatch(updateNews(formdata));
      if (response) {
        history.push("/admin/view-news");
      }
    }
  };
  return (
    <div>
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
          <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <BreadCrumb crumbs={breadcrumbs} />
            <div class="d-flex align-items-center">
              <Link
                to="/admin/view-news"
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                View News
              </Link>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8">
              {exist ? (
                <form onSubmit={handleSubmit}>
                  <div class="card-body row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>
                          Title <span class="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          name="title"
                          value={title}
                          onChange={handleChange}
                          class="form-control"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Image</label>
                        <input
                          type="file"
                          name="file"
                          onChange={(e) => setFile(e.target.files[0])}
                          class="form-control"
                        />
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group">
                        <label>
                          Select Type <span class="text-danger">*</span>
                        </label>
                        <select
                          class="form-control"
                          name="type"
                          value={type}
                          onChange={handleChange}
                        >
                          <option value="news">News</option>
                          <option value="notification">Notification</option>
                          <option value="link">Important Link </option>
                        </select>
                      </div>
                    </div>

                    {type == "link" ? (
                      <div class="col-md-12">
                        <div class="form-group">
                          <label>Link</label>
                          <input
                            type="text"
                            name="link"
                            value={link}
                            onChange={handleChange}
                            class="form-control"
                            required
                          />
                        </div>
                      </div>
                    ) : (
                      <div class="col-md-12">
                        <div class="form-group">
                          <label>Description</label>
                          <CKEditor
                            activeClass="p10"
                            content={description}
                            events={{
                              // blur: onBlur,
                              // afterPaste: afterPaste,
                              change: onChange,
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <button type="submit" class="btn btn-primary mr-2">
                    Submit
                  </button>
                  <button type="reset" class="btn btn-secondary">
                    Cancel
                  </button>
                </form>
              ) : (
                <h1>News doesn't exist</h1>
              )}
            </div>
          </div>
          <div class="col-md-2"></div>
        </div>
      </div>
    </div>
  );
};

export default AddNews;
