import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getMembershipIndividual,
  updateMembershipIndividual,
} from "../../../store/actions/printActions/printcategory";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";

function EditIndividualForm() {
  const dispatch = useDispatch();
  const [exist, setExist] = useState(true);
  const history = useHistory();
  // const { id } = useParams();
  const { memberAuth } = useSelector((state) => state.member);
  const id = memberAuth?.profile?.id;
  console.log("profile", memberAuth);
  const { categoryid } = useParams();
  const [certificate_picpreview, setcertificate_picpreview] = useState(null);
  const [certificate_pic, setcertificate_pic] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const [student_picpreview, setstudent_picpreview] = useState(null);
  const [student_pic, setstudent_pic] = useState([]);
  console.log("id====>", id);
  const [data, setData] = useState({
    aadhaar_number: "",
    address: "",
    center_address: "",
    center_city: "",
    center_name: "",
    center_state: "",
    certificate_file: "",
    certificate_number: "",
    city: "",
    course: "",
    diploma_certificate: "",
    examination_address: "",
    examination_body_name: "",
    examination_city: "",
    examination_state: "",
    father_name: "",
    fees: null,
    member_id: id,
    membership_type: 0,
    mobile: "",
    mother_name: "",
    passing_year: "",
    password: "",
    pin_code: "",
    ragistration_certificate: "",
    registration_number: "",
    roll_number: "",
    state: "",
    status: 0,
    student_image: "",
    student_name: "",
  });

  useEffect(async () => {
    if (id) {
      const response = await dispatch(getMembershipIndividual(id));
      console.log("response====>", response);
      if (response.success) {
        setData({
          aadhaar_number: response.data.aadhaar_number,
          address: response.data.address,
          center_address: response.data.center_address,
          center_city: response.data.center_city,
          center_name: response.data.center_name,
          center_state: response.data.center_state,
          certificate_file: response.data.certificate_file,
          certificate_number: response.data.certificate_number,
          city: response.data.city,
          course: response.data.course,
          diploma_certificate: response.data.diploma_certificate,
          examination_address: response.data.examination_address,
          examination_body_name: response.data.examination_body_name,
          examination_city: response.data.examination_city,
          examination_state: response.data.examination_state,
          father_name: response.data.father_name,
          fees: response.data.fees,
          id: response.data.id,
          membership_type: response.data.membership_type,
          mobile: response.data.mobile,
          mother_name: response.data.mother_name,
          passing_year: response.data.passing_year,
          password: response.data.password,
          pin_code: response.data.pin_code,
          ragistration_certificate: response.data.ragistration_certificate,
          registration_number: response.data.registration_number,
          roll_number: response.data.roll_number,
          state: response.data.state,
          status: response.data.status,
          student_image: response.data.student_image,
          student_name: response.data.student_name,
        });
      } else {
        setExist(false);
      }
    }
  }, [id]);
  const {
    aadhaar_number,
    address,
    center_address,
    center_city,
    center_name,
    center_state,
    certificate_file,
    certificate_number,
    city,
    course,
    diploma_certificate,
    examination_address,
    examination_body_name,
    examination_city,
    examination_state,
    father_name,
    fees,
    membership_type,
    mobile,
    mother_name,
    passing_year,
    password,
    pin_code,
    ragistration_certificate,
    registration_number,
    roll_number,
    state,
    status,
    student_image,
    student_name,
    member_id,
  } = data;

  const handleChange = (e) => {
    setData({
      ...data,
      member_id: id,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("data", data);
    var formdata = new FormData();

    for (let [key, value] of Object.entries(data)) {
      if (Array.isArray(value)) {
        formdata.append(key, JSON.stringify(value));
      } else {
        formdata.append(key, value);
      }
    }
    formdata.append("certificate_file", certificate_pic);
    formdata.append("student_image", student_pic);
    formdata.append("member_id", id);
    // formdata.append("ragistration_certificate", ragistration_pic);
    // formdata.append("diploma_certificate", diploma_pic);

    setSubmitting(true);
    const response = await dispatch(updateMembershipIndividual(id, formdata));
    if (response) {
      //   setData([]);
      //   setfacilityDetail([]);
      //   setStaffDetail([]);

      setSubmitting(false);
    } else {
      setSubmitting(false);
    }

    // dispatch(centerRegisterAction(formdata));
  };

  return (
    <div>
      <div class="lists-box">
        <div className="d-flex align-items-center mb-3">
          {/* <Link
            to={"/print/membership/individual"}
            class="btn btn-light-primary font-weight-bolder btn-sm"
          >
            View List
          </Link> */}
        </div>
        <div class="container">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <>
                    <div className="col-md-12">
                      <div className="formTitle"> Details</div>
                    </div>
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Memebrship Type</p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="membership_type"
                          value={membership_type}
                          // onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Student Name</p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="student_name"
                          value={student_name}
                          // onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-12">
                      <div className="Fileboxbale">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="customFiler73"
                            name="student_image"
                            // onChange={(e) => {
                            //   setstudent_pic(e.target.files[0]);
                            //   setstudent_picpreview(
                            //     URL.createObjectURL(e.target.files[0])
                            //   );
                            // }}
                          />
                          <label className="custom-file2" for="customFiler73">
                            {student_picpreview ? (
                              <img
                                src={student_picpreview}
                                height={"100px"}
                                id="frontphoto"
                              />
                            ) : (
                              <>
                                <img src={student_image} height={"100px"}></img>
                              </>
                            )}
                            <h4>Upload Student Image</h4>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Father Name</p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="father_name"
                          value={father_name}
                          // onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Mother Name</p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="mother_name"
                          value={mother_name}
                          // onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p> Mobile no</p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="mobile"
                          value={mobile}
                          //   onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Course</p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="course"
                          value={course}
                          // onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Roll Number</p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="roll_number"
                          value={roll_number}
                          // onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Certificate Number</p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="certificate_number"
                          value={certificate_number}
                          // onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Passing Year</p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="passing_year"
                          value={passing_year}
                          // onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="formTitle"> Address</div>
                    </div>
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p> Addhar Card Number</p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="aadhaar_number"
                          value={aadhaar_number}
                          // onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Address </p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="address"
                          value={address}
                          // onChange={handleChange}
                        />
                      </div>
                    </div>

                    {/* <StateCitySelector
                       user_city={City}
                      user_state={State}
                      set_user_state={(e) => setdata({ ...data, State: e })}
                       set_user_city={(e) => setdata({ ...data, City: e })}
                    /> */}

                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>State </p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="state"
                          value={state}
                          // onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>City </p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="city"
                          value={city}
                          // onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>PinCode </p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="pin_code"
                          value={pin_code}
                          // onChange={handleChange}
                        />
                      </div>
                    </div>
                  </>
                  <>
                    <div className="col-md-12">
                      <div className="formTitle">Center Details</div>
                    </div>
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Center Name </p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="center_name"
                          value={center_name}
                          // onChange={handleChange}
                          readonly
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Center Address </p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="center_address"
                          value={center_address}
                          // onChange={handleChange}
                          readonly
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Center State </p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="center_state"
                          value={center_state}
                          // onChange={handleChange}
                          readonly
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Center city </p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="center_city"
                          value={center_city}
                          // onChange={handleChange}
                          readonly
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Examination Body Name </p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="examination_body_name"
                          value={examination_body_name}
                          // onChange={handleChange}
                          readonly
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Examination Body Address </p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="examination_address"
                          value={examination_address}
                          // onChange={handleChange}
                          readonly
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Examination Body State </p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="examination_state"
                          value={examination_state}
                          // onChange={handleChange}
                          readonly
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Examination city </p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="examination_city"
                          value={examination_city}
                          // onChange={handleChange}
                          readonly
                          required
                        />
                      </div>
                    </div>
                  </>
                </div>
              </div>
              {/* <>
                <div className="col-md-12">
                  <div className="formTitle">Documents</div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="Fileboxbale">
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="customFiler7"
                        name="certificate_file"
                        onChange={(e) => {
                          setcertificate_pic(e.target.files[0]);
                          setcertificate_picpreview(
                            URL.createObjectURL(e.target.files[0])
                          );
                        }}
                      />
                      <label className="custom-file2" for="customFiler7">
                        {certificate_picpreview ? (
                          <img
                            src={certificate_picpreview}
                            height={"100px"}
                            id="frontphoto"
                          />
                        ) : (
                          <>
                            <img src={certificate_file} height={"100px"}></img>
                          </>
                        )}
                        <h4>Upload Certificate</h4>
                      </label>
                    </div>
                  </div>
                </div>
              </> */}

              {/* <div className="col-md-12">
                <div
                  className="btn-groups mt-4 text-center"
                  role="group"
                  aria-label="First group"
                >
                  <button
                    className="btn btn-Next"
                    type="submit"
                    disabled={submitting}
                  >
                    Submit
                    <span>
                      <svg
                        width="35"
                        height="12"
                        viewBox="0 0 35 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M34.8403 6.00003C34.8403 6.2151 34.7582 6.43013 34.5943 6.5941L29.4346 11.7538C29.1063 12.0821 28.5742 12.0821 28.2461 11.7538C27.918 11.4257 27.918 10.8937 28.2461 10.5654L32.8117 6.00003L28.2462 1.43461C27.9181 1.10639 27.9181 0.574383 28.2462 0.246316C28.5743 -0.0820704 29.1065 -0.0820704 29.4347 0.246316L34.5945 5.40596C34.7584 5.57001 34.8403 5.78505 34.8403 6.00003Z"
                          fill="white"
                        ></path>
                        <line
                          x1="33.25"
                          y1="5.94995"
                          x2="0.75"
                          y2="5.94995"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                        ></line>
                      </svg>
                    </span>
                  </button>
                </div>
              </div> */}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditIndividualForm;
