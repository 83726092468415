import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import {
  downloadCourses,
  getCourseList,
  getCourseListPagination,
} from "../../../../store/actions/councilActions/courseAction";
import Loader from "../../../Alert/Loader";
import BreadCrumb from "../../../BreadCrumb";
import Pagination from "../../../misc/Pagination";

export default function CourseList() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [activePage, setActivePage] = useState("1");
  const [data, setData] = useState(null);
  const { admin } = useSelector((state) => state);
  const { courses } = admin;

  const fetchdata = async () => {
    const response = await dispatch(getCourseListPagination(activePage));
    if (response) {
      setTotalPages(Math.ceil(response.total / response.per_page));
      setData(response.data);
      setLoading(false);
    } else {
      setData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchdata();
  }, [activePage]);

  const breadcrumbs = [
    {
      path: "/admin/dashboard",
      label: "Dashboard",
    },
    {
      // path: "/aboutus",
      label: "Course List",
    },
  ];
  const handleDownload = () => {
    dispatch(downloadCourses());
  };

  return (
    <div>
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
          <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <BreadCrumb crumbs={breadcrumbs} />
            <div class="d-flex align-items-center">
              <button
                onClick={handleDownload}
                className="btn btn-light-primary font-weight-bolder btn-sm"
              >
                Download list
              </button>
              <Link
                to={"/admin/add-course"}
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                Add Course
              </Link>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-12">
              <table
                class="table table-separate table-head-custom table-checkable"
                id="kt_datatable"
              >
                <thead>
                  <tr>
                    <th>Sr.No</th>
                    <th>Course</th>
                    <th>Subtrade</th>

                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td className="text-center" colSpan={5}>
                        <Spinner animation="border" role="status" size="lg">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </td>
                    </tr>
                  ) : data === null ? null : data.length == 0 ? (
                    <tr>
                      <td className="text-center" colSpan={5}>
                        No Record Found
                      </td>
                    </tr>
                  ) : (
                    data.map((course, index) => (
                      <tr>
                        <td>{(activePage - 1) * 10 + index + 1}</td>
                        <td>{course.name}</td>
                        <td>{course.sub_trade?.name}</td>
                        <td>
                          <Link to={`/admin/course/edit/${course.id}`}>
                            Edit
                          </Link>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              {totalPages && (
                <Pagination
                  setActivePage={(value) => setActivePage(value)}
                  totalPages={totalPages}
                />
              )}
            </div>
            <div class="col-md-2"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
