import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { uploadResultDate } from "../../../store/actions/councilActions/addMarks";
import { successAlert } from "../../../utils/alert";

const UploadExamDate = ({ show, onHide, studentData, onSuccess }) => {
  const dispatch = useDispatch();
  const [result_date, setresult_date] = useState("");
  console.log("studentData", studentData);
  const [isUploaded, setIsUploaded] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    const data = {
      result_date: result_date.replace("T", " ") + ":00",
      // max_marks,
      student_id: studentData.student_id,
      course_id: studentData.course_id,
      // subject_id: studentData.subId,
      semester: studentData.semester,

      // exam_type: 1 //manual
    };
    const response = await dispatch(uploadResultDate(data));
    if (response) {
      onHide();
      successAlert("Marks Uploaded");

      // setIsUploaded(true);
      onSuccess(result_date);
    }
  };
  console.log("result_date", result_date);
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>Upload Result Date </Modal.Header>
      <Modal.Body>
        <form onSubmit={submit}>
          <div className="form-group">
            <label>
              Result Date <span className="text-danger">*</span>
            </label>
            <input
              type="datetime-local"
              className="form-control"
              value={result_date}
              onChange={(e) => setresult_date(e.target.value)}
              required
            ></input>
          </div>
          <button className="btn btn-success" type={"submit"}>
            Upload
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UploadExamDate;
