import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

function PrivateRoute({ component: Component, roles, ...rest }) {
  const { admin } = useSelector((state) => state);
  const { loading, isAuth } = admin.adminAuth;
  return (
    <Route
      {...rest}
      render={(props) =>
        !loading && isAuth ? (
          <Component {...rest} {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/admin/login", state: { from: props.location } }}
          ></Redirect>
        )
      }
    />
  );
}

export default PrivateRoute;
