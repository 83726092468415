import {
  AUTH_FAIL,
  CENTER_AUTH_SUCCESS,
  CENTER_REGISTER_DETAILS,
  PRACTICAL_DATA,
  SET_STUDENTS,
} from "../../actions/actionTypes";

const initialState = {
  centerProfile: {},
  centerAuth: {
    isAuth: false,
    profile: null,
    loading: true,
    token: null,
  },
  practical: null,
};

const centerReducer = (state = initialState, action) => {
  switch (action.type) {
    case CENTER_REGISTER_DETAILS:
      return {
        ...state,
        centerProfile: {
          ...state.centerProfile,
          ...action.payload,
        },
      };
    case CENTER_AUTH_SUCCESS:
      return {
        ...state,
        centerAuth: {
          isAuth: true,
          profile: action.payload,
          loading: false,
        },
      };
    case AUTH_FAIL:
      return {
        centerProfile: {},
        centerAuth: {
          isAuth: false,
          profile: null,
          loading: false,
        },
      };
    case SET_STUDENTS:
      return {
        ...state,
        students: action.payload,
      };
    case PRACTICAL_DATA:
      return {
        ...state,
        practical: action.payload,
      };
    default:
      return state;
  }
};

export default centerReducer;
