import React from "react";
import BreadCrumb from "../BreadCrumb";
import About from "./About";
import VisionMission from "./VisionMission";
import Mission from "./Mission";
export default function AboutUs() {
  const breadcrumbs = [
    {
      path: "/",
      label: "Home",
    },
    {
      // path: "/aboutus",
      label: "About Us",
    },
  ];
  return (
    <>
      <div class="lists-box about">
        <div class="container p-0">
          <BreadCrumb crumbs={breadcrumbs} />
          {/* <div class="title-box">
            <h4>About-the-NPVEC</h4>
          </div> */}
          <section class="about-inner">
            <div class="container">
              <div class="row">
                <div class="about-box">
                  <div class="row">
                    <div class="col-md-12">
                      <About />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <VisionMission />
                </div>
                <div class="col-md-6">
                  <Mission />
                </div>
              </div>
            </div>
          </section>

          {/* <div class="row">
            <div class="col-md-8">
              <h1></h1>
              <h2></h2>
              <div>
                <ul>
                  <li>
                    <strong>
                      National Paramedical &amp; Vocational Education
                      Council&nbsp;(NPVEC)
                    </strong>
                    &nbsp;&nbsp;&nbsp;An –Autonomous Organization Register&nbsp;
                    Under Trust Act-1882 NCT Govt.Of India&nbsp; &nbsp;New
                    Delhi-Register No.04/044/219/234&nbsp;
                  </li>
                  <li>
                    <strong>
                      National Paramedical &amp; Vocational Education
                      Council&nbsp;(NPVEC)
                    </strong>
                    &nbsp;&nbsp;An-ISO&nbsp; 9001 : 2015 Certified
                    Organization&nbsp;
                  </li>
                  <li>
                    <strong>
                      National Paramedical &amp; Vocational Education
                      Council&nbsp;(NPVEC) Also Reg.in Department of Labour
                      Govt.NCT New Delhi Reg. No.20211133045
                    </strong>
                  </li>
                  <li>
                    <strong>
                      National Paramedical &amp; Vocational Education
                      Council&nbsp;(NPVEC) Also Reg.in&nbsp;
                    </strong>
                    National Career Service&nbsp;Ministry of Labour &amp;
                    Employment&nbsp;NCS ID. E16H86-1552263777709
                  </li>
                  <li>
                    <strong>
                      National Paramedical &amp; Vocational Education
                      Council&nbsp;(NPVEC)
                    </strong>
                    &nbsp;&nbsp;Under Certified by Quality Council of India.
                  </li>
                  <li>
                    <strong>
                      National Paramedical &amp; Vocational Education
                      Council&nbsp;(NPVEC)
                    </strong>
                    &nbsp;&nbsp;&nbsp;Approved By&nbsp;National Institute of
                    Transforming&nbsp; India Govt.Of India &nbsp;(NITI AYOG )
                    Reg.No. UP/2021/0286717
                  </li>
                  <li>
                    <strong>
                      National Paramedical &amp; Vocational Education
                      Council&nbsp;(NPVEC)
                    </strong>
                    &nbsp;&nbsp;Register Ministry of Micro, Small &amp; Medium
                    Enterprises Govt.Of India&nbsp; (MSME) Reg.NO.
                    UDYAM-DL-02-0013703
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-4">
              <div class="Abousimg">
                <img src="/images/logo.png" />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
