import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Table, Accordion, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import BreadCrumb from "../../BreadCrumb";
import { getExamSchedule } from "../../../store/actions/councilActions/scheduleActions";
const ViewSchedule = () => {
  const breadcrumbs = [
    {
      path: "/admin/dashboard",
      label: "Dashboard",
    },
    {
      label: "View Exam Schedule",
    },
  ];
  const dispatch = useDispatch();

  const { schedule } = useSelector((state) => state.admin);

  useEffect(() => {
    dispatch(getExamSchedule());
  }, []);
  const convertArrayToObject = (array, key) => {
    const hs = new Map();
    array.map((element) => {
      if (hs.get(element.subject_name?.semester)) {
        hs.set(element.subject_name?.semester, [
          ...hs.get(element.subject_name?.semester),
          element,
        ]);
      } else {
        hs.set(element.subject_name?.semester, [element]);
      }
    });
    return Object.fromEntries(hs);
  };
  // useEffect(() => {
  //   if (schedule) {
  //     convertArrayToObject(schedule);
  //   }
  // }, []);
  return (
    <div>
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
          <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <BreadCrumb crumbs={breadcrumbs} />
            <div class="d-flex align-items-center">
              <Link
                to="/admin/view-assignment-list"
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                View Exam Schedule
              </Link>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            {!schedule ? (
              <div className="text-center">
                <Spinner animation="border" role="status" size="lg">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              schedule.map((s, index) => (
                <Accordion className="mb-3">
                  <Accordion.Item eventKey={s.id}>
                    <Accordion.Header>{s.name}</Accordion.Header>
                    <Accordion.Body className="p-0">
                      {s.date_sheet.length == 0 ? (
                        <div className="text-center">No Exam Scheduled</div>
                      ) : (
                        <>
                          {s.exam_type == "course_wise" ? (
                            <Table
                              bordered
                              hover
                              striped
                              responsive
                              className="mb-0"
                            >
                              <thead>
                                <th>S.No</th>
                                <th>Exam</th>
                                <th>Center</th>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Batch</th>
                              </thead>
                              <tbody>
                                {s.date_sheet.map((element, index) => (
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{s.name}</td>
                                    <td>{element.center_name?.center_name}</td>
                                    <td>
                                      {moment(element.date).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td>
                                      {moment(element.time, "h:mm").format(
                                        "h:mm A"
                                      )}
                                    </td>
                                    <td>Batch {element.batch}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          ) : (
                            <>
                              {Object.entries(
                                convertArrayToObject(s.date_sheet)
                              )?.map(([key, value], index) => (
                                <Accordion className="mb-3">
                                  <Accordion.Item
                                    eventKey={key}
                                  ></Accordion.Item>
                                  <Accordion.Header>
                                    {s.course_type == "2"
                                      ? "Semester "
                                      : "Year "}
                                    {key}
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <Table
                                      bordered
                                      hover
                                      striped
                                      responsive
                                      className="mb-0"
                                    >
                                      <thead>
                                        <th>S.No</th>
                                        <th>Exam</th>
                                        <th>Center</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>Batch</th>
                                      </thead>
                                      <tbody>
                                        {value.map((v, index) => (
                                          <tr>
                                            <td>{index + 1}</td>
                                            <td>{v.subject_name?.name}</td>
                                            <td>{v.center_name?.center_name}</td>
                                            <td>
                                              {moment(v.date).format(
                                                "DD/MM/YYYY"
                                              )}
                                            </td>
                                            <td>
                                              {moment(v.time, "h:mm").format(
                                                "h:mm A"
                                              )}
                                            </td>
                                            <td>Batch {v.batch}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </Table>
                                  </Accordion.Body>
                                </Accordion>
                              ))}
                            </>
                          )}
                        </>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewSchedule;
