import React from "react";
import { Link } from "react-router-dom";
import "./css/footer.css";
export default function Footer() {
  return (
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-md-2 col-6 mb-2  col-sm-2">
            <div class="footer-data">
              <div class="footer-logo">
                <Link to={"/"} style={{ background: "none" }}>
                  <img src="/images/logo.png" alt="" />
                </Link>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-6 mb-2 col-sm-4">
            <div class="footer-data">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <Link to="/useful-links/downloads">Download</Link>
                </li>
                <li>
                  <Link to="/useful-links/contacts">Enquiry Numbers</Link>
                </li>
                <li>
                  <Link to="/useful-links/e-mail">Important e-mail</Link>
                </li>
                {/* <li>
                  <a href="#">Placement</a>
                </li> */}
                <li>
                  <Link to="/useful-links/rti">RTI</Link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-3 col-6 mb-2 col-sm-3">
            <div class="footer-data">
              <h4>EXPLORE</h4>
              <ul>
                <li>
                  <Link to="/colleges">College/Institute</Link>
                </li>
                <li>
                  <Link to="/affiliation">Affiliation Process</Link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-3 col-6 mb-2 col-sm-3">
            <div class="footer-data">
              <h4>Need Help?</h4>
              <ul>
                <li>
                  <Link to="/help-desk">Help Desk</Link>
                </li>
                <li>
                  <Link to="/track-complaint">Track Complaint</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
                <li>
                  <Link to="/contact-us">Send Enquiry</Link>
                </li>
                <li>
                  <Link to="/abouts/About-the-NPVEC">About Us</Link>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <p>
              @ 2023 International computer saksharta mission powered by
              NATIONAL PARAMEDICAL & VOCATIONAL EDUCATION COUNCIL ALL rights
              reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
