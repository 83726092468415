import moment from "moment";
import React from "react";
import { useState } from "react";
import { Button, Form, FormControl } from "react-bootstrap";
import CKEditor from "react-ckeditor-component";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { addComment } from "../../store/actions/helpdeskActions/complaint";
import "./chat.css";

const ComplaintChat = ({ replies, complain_number, fetch, name, active }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [file, setFile] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [description, setDescription] = useState("");
  const onChange = (evt) => {
    var newContent = evt.editor.getData();
    setDescription(newContent);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const formdata = new FormData();
    formdata.append("message", description);
    formdata.append("complain_number", complain_number);
    if (file) {
      formdata.append("image", file);
    }
    formdata.append(
      "created_by",
      history.location.pathname.includes("helpdesk") ? 1 : 0
    );
    const response = await dispatch(addComment(formdata));
    if (response) {
      setFile(null);
      setDescription("");
      fetch(complain_number);
      setSubmitting(false);
    } else {
      setSubmitting(false);
    }
  };

  const handleDownload = (e, file) => {
    e.preventDefault();

    const response = {
      file,
    };
    window.location.href = response.file;
  };

  return (
    <div className="container">
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <div className="panel panel-primary">
                <div className="panel-collapse" id="collapseOne">
                  <div className="panel-body">
                    {replies.length > 0 && (
                      <>
                        <div class="col-md-12">
                          <h2 class="text-color-tertiary">Replies</h2>
                          <hr class="my-2" />
                        </div>
                        <div className="container">
                          <ul className="chat">
                            {replies.map((reply) =>
                              reply.created_by == 1 ? (
                                <li
                                  className="clearfix"
                                  style={{
                                    width: "100%",
                                    background: "#ADD8E6",
                                    padding: "10px",
                                    borderRadius: "10px",
                                  }}
                                >
                                  <div className="chat-body clearfix">
                                    <div className="header">
                                      <small className=" text-muted">
                                        <span className="glyphicon glyphicon-time"></span>
                                        {moment(reply.created_at).fromNow()}
                                      </small>
                                      <strong className="pull-right primary-font">
                                        -Support
                                      </strong>
                                    </div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: reply.message,
                                      }}
                                    ></div>
                                    {reply.image && (
                                      <Button
                                        size="sm"
                                        variant="outline"
                                        onClick={(e) =>
                                          handleDownload(e, reply.image)
                                        }
                                      >
                                        Attachment
                                      </Button>
                                    )}
                                  </div>
                                </li>
                              ) : (
                                <li
                                  className="clearfix"
                                  style={{
                                    width: "100%",
                                    background: "#E5E4E2",
                                    padding: "10px",
                                    borderRadius: "10px",
                                  }}
                                >
                                  <div className="chat-body clearfix">
                                    <div className="header">
                                      <strong className="primary-font">
                                        {name}
                                      </strong>
                                      <small className="pull-right text-muted">
                                        <span className="glyphicon glyphicon-time"></span>
                                        {moment(reply.created_at).fromNow()}
                                      </small>
                                    </div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: reply.message,
                                      }}
                                    ></div>
                                    {reply.image && (
                                      <Button
                                        size="sm"
                                        variant="outline"
                                        onClick={(e) =>
                                          handleDownload(e, reply.image)
                                        }
                                      >
                                        Attachment
                                      </Button>
                                    )}
                                  </div>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </>
                    )}
                    {active == "0" && (
                      <div className="panel-footer">
                        <Form onSubmit={onSubmit}>
                          <div className="mb-3">
                            <CKEditor
                              activeClass="p10"
                              content={description}
                              events={{
                                change: onChange,
                              }}
                            />
                          </div>
                          <div className="mb-3">
                            <input
                              type="file"
                              onChange={(e) => setFile(e.target?.files?.[0])}
                            />
                          </div>
                          <Button
                            type="submit"
                            variant="outline-success"
                            disabled={submitting}
                          >
                            Send
                          </Button>
                        </Form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComplaintChat;
