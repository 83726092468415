import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import CKEditor from "react-ckeditor-component";

import {
  createTrade,
  editTrade,
  updateTrade,
} from "../../../../store/actions/councilActions/trade";
import BreadCrumb from "../../../BreadCrumb";
import { useParams } from "react-router-dom";

export default function AddTrade() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [exist, setExist] = useState(true);
  const breadcrumbs = [
    {
      path: "/admin/dashboard",
      label: "Dashboard",
    },
    {
      // path: "/aboutus",
      label: id ? "Edit Trade" : "Add Trade",
    },
  ];

  const [name, setname] = useState("");
  const [description, setDescription] = useState("");

  // const [data, setData] = useState({ name: "", description: "" });

  // const { name, description } = data;

  // const handleChange = (e) => {
  //   setData({
  //     ...data,
  //     [e.target.name]: e.target.value,
  //   });
  // };
  const onChange = (evt) => {
    //console.log("onChange fired with event info: ", evt);
    var newContent = evt.editor.getData();
    setDescription(newContent);
  };

  useEffect(async () => {
    if (id) {
      const response = await dispatch(editTrade(id));
      if (response) {
        setname(response.name);
        setDescription(response.description);
      } else {
        setExist(false);
      }
    }
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (id) {
      const response = await dispatch(updateTrade({ id, name, description }));
      if (response) {
        history.push("/admin/view-trade");
      }
    } else {
      const response = await dispatch(createTrade({ name, description }));
      if (response) {
        history.push("/admin/view-trade");
      }
    }
  };

  return (
    <div>
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
          <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <BreadCrumb crumbs={breadcrumbs} />

            <div class="d-flex align-items-center">
              <Link
                to="/admin/view-trade"
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                View Trade
              </Link>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8">
              <div class="table-responsive-lg">
                {exist ? (
                  <form onSubmit={handleSubmit}>
                    <div class="form-group">
                      <label>
                        Trade <span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter Trade Name "
                        name="name"
                        value={name}
                        onChange={(e) => setname(e.target.value)}
                        required
                      />
                      <span class="form-text text-muted mt-4"></span>
                    </div>
                    <div class="form-group">
                      <label>
                        Trade Description <span class="text-danger">*</span>
                      </label>
                      <CKEditor
                        activeClass="p10"
                        content={description}
                        events={{
                          // blur: onBlur,
                          // afterPaste: afterPaste,
                          change: onChange,
                        }}
                      />
                      <span class="form-text text-muted mt-4"></span>
                    </div>

                    <button type="submit" class="btn btn-primary mr-2">
                      Submit
                    </button>
                    <button type="reset" class="btn btn-secondary">
                      Cancel
                    </button>
                  </form>
                ) : (
                  <h5 className="text-center">Trade not Found</h5>
                )}
              </div>
            </div>
            <div class="col-md-2"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
