import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import {
  getTrades,
  getTradesPagination,
} from "../../../../store/actions/councilActions/trade";
import BreadCrumb from "../../../BreadCrumb";
import Pagination from "../../../misc/Pagination";

export default function ViewTrade() {
  const dispatch = useDispatch();
  const { admin } = useSelector((state) => state);
  const { trades } = admin;
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [activePage, setActivePage] = useState("1");
  const [data, setData] = useState(null);

  const fetchdata = async () => {
    const response = await dispatch(getTradesPagination(activePage));
    if (response) {
      setTotalPages(Math.ceil(response.total / response.per_page));
      setData(response.data);
      setLoading(false);
    } else {
      setData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchdata();
  }, [activePage]);

  const breadcrumbs = [
    {
      path: "/admin/dashboard",
      label: "Dashboard",
    },
    {
      // path: "/aboutus",
      label: "View Trade",
    },
  ];

  return (
    <div>
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
          <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <div class="d-flex align-items-center flex-wrap mr-1">
              <BreadCrumb crumbs={breadcrumbs} />
            </div>

            <div class="d-flex align-items-center">
              <Link
                to="/admin/view-sub-trade"
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                View Subtrade
              </Link>
              <Link
                to="/admin/add-trade"
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                Add Trade
              </Link>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-12">
              <div class="table-responsive table-hover">
                <table
                  class="table table-separate table-head-custom table-checkable"
                  id="kt_datatable"
                >
                  <thead>
                    <tr>
                      <th>Sr.No</th>
                      <th>Trade</th>
                      <th>Description</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td className="text-center" colSpan={5}>
                          <Spinner animation="border" role="status" size="lg">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </td>
                      </tr>
                    ) : data === null ? null : data.length == 0 ? (
                      <tr>
                        <td className="text-center" colSpan={4}>
                          No Record Found
                        </td>
                      </tr>
                    ) : (
                      data.map((trade, index) => (
                        <tr>
                          <td>{(activePage - 1) * 10 + index + 1}</td>
                          <td>{trade.name}</td>
                          <td>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: trade.description,
                              }}
                            ></div>
                          </td>

                          <td>
                            <Link to={`/admin/edit-trade/${trade.id}`}>
                              Edit
                            </Link>
                            <i class="la la-trash"></i> {/* </a> */}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
                {totalPages && (
                  <Pagination
                    setActivePage={(value) => setActivePage(value)}
                    totalPages={totalPages}
                  />
                )}
              </div>
            </div>
            <div class="col-md-2"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
