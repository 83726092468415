import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CENTER_REGISTER_DETAILS } from "../../../store/actions/actionTypes";

export default function StaffDetail() {
  const { center } = useSelector((state) => state);
  const { centerProfile } = center;
  const dispatch = useDispatch();

  const [staffDetail, setStaffDetail] = useState([
    { name: "", qualification: "", designation: "", date_of_joining: "" },
  ]);

  useEffect(() => {
    if (centerProfile.staff) {
      setStaffDetail(centerProfile.staff);
    }
  }, [centerProfile.staff]);

  const handleStaffDetailChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...staffDetail];
    list[index][name] = value;
    setStaffDetail(list);
    dispatch({
      type: CENTER_REGISTER_DETAILS,
      payload: { staff: list },
    });
  };
  // handle click event of the Remove button
  const handleStaffRemoveClick = (index) => {
    const list = [...staffDetail];
    list.splice(index, 1);
    setStaffDetail(list);
    dispatch({
      type: CENTER_REGISTER_DETAILS,
      payload: { staff: list },
    });
  };

  // handle click event of the Add button
  const handleStaffAddClick = () => {
    setStaffDetail([
      ...staffDetail,
      { name: "", qualification: "", designation: "", date_of_joining: "" },
    ]);
    dispatch({
      type: CENTER_REGISTER_DETAILS,
      payload: {
        staff: [
          ...staffDetail,
          { name: "", qualification: "", designation: "", date_of_joining: "" },
        ],
      },
    });
  };
  return (
    <>
      <div class="col-md-12">
        {" "}
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-sm-8 col-8">
              <h6 class="">Details of the Staff</h6>
            </div>
            <div className="col-sm-4 col-4">
              <button
                class="btn btn-success btn-sm btn-add"
                type="button"
                id="addstaff"
                onClick={handleStaffAddClick}
              >
                Add+
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="table-responsive">
          <table class="table">
            <thead class="thead-info">
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Qualification</th>
                <th scope="col">Designation</th>
                <th scope="col">Date of Joining</th>
                {staffDetail.length !== 1 && <th scope="col">Delete</th>}
              </tr>
            </thead>
            <tbody class="appendstaf">
              {staffDetail.map((staff, index) => (
                <>
                  <tr>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Ex Rahul"
                        name="name"
                        value={staff.name}
                        onChange={(e) => handleStaffDetailChange(e, index)}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="ex.MBA"
                        name="qualification"
                        value={staff.qualification}
                        onChange={(e) => handleStaffDetailChange(e, index)}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="ex. Manager"
                        name="designation"
                        value={staff.designation}
                        onChange={(e) => handleStaffDetailChange(e, index)}
                      />
                    </td>
                    <td>
                      <input
                        type="date"
                        class="form-control"
                        placeholder="ex. 12/02/2020"
                        name="date_of_joining"
                        value={staff.date_of_joining}
                        onChange={(e) => handleStaffDetailChange(e, index)}
                      />
                    </td>
                    {staffDetail.length !== 1 && (
                      <td>
                        <button
                          class="btn btn-danger btn-sm btn-add"
                          type="button"
                          onClick={() => handleStaffRemoveClick(index)}
                        >
                          Remove
                        </button>
                      </td>
                    )}
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
