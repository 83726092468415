import { getDataAPI, postDataAPI } from "../../../services/apiWrapper";
import {
  ALERT,
  ASSIGNMENT_LIST,
  GET_ASSIGNMENT_QUESTIONS,
} from "../actionTypes";

export const addQuestion = (data) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");

    const response = await dispatch(
      postDataAPI("admin/create/question", data, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });
    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({ type: ALERT, payload: { error: error.response.data.status } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};

export const updateQuestion = (data, id) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");

    const response = await dispatch(
      postDataAPI(`admin/edit/question/${id}`, data, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });
    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({ type: ALERT, payload: { error: error.response.data.status } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};

export const getQuestion = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    dispatch({ type: ALERT, payload: { loading: true } });

    const response = await dispatch(
      getDataAPI(`admin/edit/question/${id}`, token)
    );
    dispatch({ type: ALERT, payload: { loading: false } });

    return response.data.data;
  } catch (error) {
    return false;
  }
};

export const getAssignments = () => async (dispatch) => {
  try {
    // dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      getDataAPI("admin/assignment/subject", token)
    );
    dispatch({
      type: ASSIGNMENT_LIST,
      payload: response.data.data,
    });
    // dispatch({ type: ALERT, payload: { loading: false } });
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({ type: ALERT, payload: { error: error.response.data.status } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
  }
};

export const getAssignmentQuestions = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      getDataAPI(`admin/subject-questions/${id}`, token)
    );
    dispatch({
      type: GET_ASSIGNMENT_QUESTIONS,
      payload: response.data.data?.questions || [],
    });
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({ type: ALERT, payload: { error: error.response.data.status } });
  }
};
