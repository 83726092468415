import React, { useEffect, useState } from "react";
import { findDOMNode } from "react-dom";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  addGallery,
  getGallery,
  updateGallery,
} from "../../../store/actions/adminActions/addGallery";
import BreadCrumb from "../../BreadCrumb";

export default function AddGallery() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [category, setCategory] = useState("1");
  const [file, setFile] = useState([]);
  const [file_type, setfile_type] = useState("gallery");
  const [video_url, setvideo_url] = useState("");
  const [title, setTitle] = useState("");
  const breadcrumbs = [
    {
      path: "/admin/dashboard",
      label: "Dashboard",
    },
    {
      label: id ? "Edit Gallery" : "Add Gallery",
    },
  ];

  useEffect(async () => {
    if (id) {
      const response = await dispatch(getGallery(id));
      if (response) {
        setCategory(response.file ? "1" : "2");
        setvideo_url(response.video_url);
        setTitle(response.title);
      }
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("title", title);
    formdata.append("file_type", file_type);
    if (file_type == "gallery" || file_type == "slider") {
      formdata.append("file", file);
    } else formdata.append("video_url", video_url);

    if (id) {
      formdata.append("id", id);
      const response = await dispatch(updateGallery(formdata));
      if (response) {
        history.push("/admin/view-gallery");
      }
    } else {
      const response = await dispatch(addGallery(formdata));
      if (response) {
        history.push("/admin/view-gallery");
      }
    }
  };

  return (
    <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
      <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
        <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <BreadCrumb crumbs={breadcrumbs} />
          <div class="d-flex align-items-center">
            <Link
              to="/admin/view-gallery"
              class="btn btn-light-primary font-weight-bolder btn-sm"
            >
              View Gallery
            </Link>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <form onSubmit={handleSubmit}>
              <div class="card-body row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label>
                      Category <span class="text-danger">*</span>
                    </label>

                    <select
                      class="form-control"
                      name="type"
                      value={file_type}
                      onChange={(e) => setfile_type(e.target.value)}
                    >
                      <option value={"gallery"}>Image</option>
                      <option value={"video"}>Video</option>
                      <option value={"slider"}>Slider</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>
                      Title <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      class="form-control"
                      required
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    {file_type == "gallery" || file_type == "slider" ? (
                      <>
                        <label>
                          Photo <span class="text-danger">*</span>
                        </label>
                        <input
                          type="file"
                          name="image"
                          class="form-control"
                          onChange={(e) => setFile(e.target.files[0])}
                          required
                        />
                      </>
                    ) : (
                      <>
                        <label>
                          Video Url <span class="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={video_url}
                          onChange={(e) => setvideo_url(e.target.value)}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>

              <button type="submit" class="btn btn-primary mr-2">
                Submit
              </button>
              <Link
                to="/admin/view-gallery"
                type="reset"
                class="btn btn-secondary"
              >
                Cancel
              </Link>
            </form>
            {/* </div> */}
          </div>
          <div class="col-md-2"></div>
        </div>
      </div>
    </div>
  );
}
