import { Modal, ModalBody } from "react-bootstrap";
import React, { useState } from "react";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { useDispatch } from "react-redux";
import {
  uploadCertificate,
  uploadMarksheet,
} from "../../../../store/actions/centerActions/studentAction";

export default function UploadCertificate({ show, onHide, id }) {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [file, setFile] = useState([]);
  const [type, setType] = useState("");
  const year = [1, 2, 3, 4, 5, 6, 7, 8];
  const [data, setData] = useState({
    obtain_marks: "",
    total_marks: "",
    enrollment_no: "",
    status: "",
    semster_year: "",
    date: "",
  });
  const {
    obtain_marks,
    total_marks,
    status,
    semster_year,
    date,
    enrollment_no,
  } = data;

  const [certificate_reg_no, setcertificate_reg_no] = useState("");
  const [certificatedate, setcertificatedate] = useState("");
  const [pdf, setPdf] = useState([]);
  const handleCertificateupload = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("certificate_reg_no", certificate_reg_no);
    formdata.append("date", certificatedate);
    formdata.append("pdf", pdf);
    formdata.append("student_id", id);
    formdata.append("type", type);
    const response = dispatch(uploadCertificate(formdata));
    if (response) {
      onHide();
    }
  };
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const handleMarksheetupload = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    for (let [key, value] of Object.entries(data)) {
      if (Array.isArray(value)) {
        formdata.append(key, JSON.stringify(value));
      } else {
        formdata.append(key, value);
      }
    }
    formdata.append("pdf", file);
    formdata.append("student_id", id);
    const response = dispatch(uploadMarksheet(formdata));
    if (response) {
      onHide();
    }
  };
  return (
    <Modal show={show} onHide={onHide} centered>
      <ModalHeader>Upload Marksheet</ModalHeader>
      <ModalBody>
        <div class="form-group">
          <label>Select upload type</label>
          <select
            className="form-control mb-3"
            required
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              if (e.target.value == "2") {
                setType("1");
              } else if (e.target.value == "3") {
                setType("2");
              }
            }}
          >
            <option disabled value="">
              Select
            </option>
            <option value="1">Marksheet</option>
            <option value="2">Diploma/Certificate</option>
            <option value="3">Registration Certificate</option>
          </select>
        </div>
        {value == "1" && (
          <form onSubmit={handleMarksheetupload}>
            <div class="form-group">
              <label>
                Pdf <span class="text-danger">*</span>
              </label>
              <input
                type="file"
                class="form-control"
                //accept="application/pdf"
                placeholder="Enter course Subtrade "
                name="file"
                required
                onChange={(e) => setFile(e.target.files[0])}
              />
              <span class="form-text text-muted mt-4"></span>
            </div>
            <div class="form-group">
              <label>
                Select Year <span class="text-danger">*</span>
              </label>
              <select
                class="form-control"
                name="semster_year"
                value={semster_year}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  --Select--
                </option>
                {year.map((y) => (
                  <option value={y}>{y}</option>
                ))}
              </select>
            </div>
            <div class="form-group">
              <label>
                Month/Year <span class="text-danger">*</span>
              </label>
              <input
                type="month"
                class="form-control"
                name="date"
                value={date}
                required
                onChange={handleChange}
              ></input>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>
                    Total Marks<span class="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    name={"total_marks"}
                    value={total_marks}
                    onChange={handleChange}
                    required
                  ></input>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>
                    Obtained Marks<span class="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    name={"obtain_marks"}
                    value={obtain_marks}
                    onChange={handleChange}
                    required
                  ></input>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>
                Enrollment No<span class="text-danger">*</span>
              </label>
              <input
                type="text"
                class="form-control"
                name={"enrollment_no"}
                value={enrollment_no}
                onChange={handleChange}
                required
              ></input>
            </div>
            <div class="col-md-3">
              <div class="boxlabel">
                <p>Status</p>
                <label class="Radioc">
                  Pass
                  <input
                    type="radio"
                    checked={status == "1"}
                    name="status"
                    value="1"
                    onChange={handleChange}
                    required
                  />{" "}
                  <span class="checC"></span>{" "}
                </label>
                <label class="Radioc">
                  Fail
                  <input
                    type="radio"
                    checked={status == "2"}
                    name="status"
                    value="2"
                    onChange={handleChange}
                  />{" "}
                  <span class="checC"></span>{" "}
                </label>
              </div>
            </div>
            <button type="submit" class="btn btn-primary">
              Upload
            </button>
          </form>
        )}
        {(value == "2" || value == "3") && (
          <form onSubmit={handleCertificateupload}>
            <div class="form-group">
              <label>
                Pdf <span class="text-danger">*</span>
              </label>
              <input
                type="file"
                class="form-control"
                //accept="application/pdf"
                placeholder="Enter course Subtrade "
                name="file"
                required
                onChange={(e) => setPdf(e.target.files[0])}
              />
              <span class="form-text text-muted mt-4"></span>
            </div>
            <div class="form-group">
              <label>
                Month/Year <span class="text-danger">*</span>
              </label>
              <input
                type="date"
                class="form-control"
                name={certificatedate}
                value={certificatedate}
                required
                onChange={(e) => setcertificatedate(e.target.value)}
              ></input>
            </div>
            <div class="form-group">
              <label>
                Certificate no<span class="text-danger">*</span>
              </label>
              <input
                type="text"
                class="form-control"
                name={"certificate_reg_no"}
                value={certificate_reg_no}
                onChange={(e) => setcertificate_reg_no(e.target.value)}
                required
              ></input>
            </div>
            <button type="submit" class="btn btn-primary">
              Upload
            </button>
          </form>
        )}
        {/* {value == "1" && <h1></h1>} */}
      </ModalBody>
    </Modal>
  );
}
