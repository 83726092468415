import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import BreadCrumb from "../../BreadCrumb";

import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  addRti,
  editRti,
  updateRti,
} from "../../../store/actions/councilActions/rtiActions";
import StateCitySelector from "../../misc/StateCitySelector";
// import {
//   addContact,
//   editContact,
//   updateContact,
// } from "../../../store/actions/councilActions/contactActions";

const AddRti = () => {
  const [loading, setLoading] = useState(false);
  const [exist, setExist] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const breadcrumbs = [
    {
      path: "/admin/dashboard",
      label: "Dashboard",
    },
    {
      label: id ? "Edit RTI" : "Add RTI",
    },
  ];

  const initialState = {
    rti_type: 1,
    title: "",
    department: "",
    reference_number: "",
    posted_date: "",
    state: "",
    city: "",
  };
  const [data, setData] = useState(initialState);
  const [file, setFile] = useState([]);
  const {
    rti_type,
    title,
    department,
    reference_number,
    posted_date,
    state,
    city,
  } = data;
  const fetchData = async () => {
    setLoading(true);
    const response = await dispatch(editRti(id));
    if (response) {
      setData({
        rti_type: response.rti_type,
        title: response.title,
        department: response.department,
        reference_number: response.reference_number,
        posted_date: response.posted_date,
        state: response.state,
        city: response.city,
      });
      setLoading(false);
    } else {
      setLoading(false);
      setExist(false);
    }
  };
  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, []);
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    for (let [key, value] of Object.entries(data)) {
      if (Array.isArray(value)) {
        formdata.append(key, JSON.stringify(value));
      } else {
        formdata.append(key, value);
      }
    }
    formdata.append("file", file);
    if (id) {
      const response = await dispatch(
        updateRti({
          ...data,
          id: id,
        })
      );
      if (response) {
        setData(initialState);
        history.push(
          `/admin/rti/list/${rti_type == 1 ? "minister" : "department"}`
        );
      }
    } else {
      const response = await dispatch(addRti(formdata));
      if (response) {
        setData(initialState);
        history.push(
          `/admin/rti/list/${rti_type == 1 ? "minister" : "department"}`
        );
      }
    }
  };
  return (
    <div className="row">
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
          <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <BreadCrumb crumbs={breadcrumbs} />

            <div class="d-flex align-items-center">
              <Link
                to="/admin/rti/list/department"
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                Department RTI List
              </Link>
              <Link
                to="/admin/rti/list/minister"
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                Minister RTI List
              </Link>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8 ">
            <div class="card">
              {loading ? (
                <div className="text-center">
                  <Spinner animation="border" role="status" size="lg">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : exist ? (
                <>
                  <div className="card-header text-center">
                    {id ? "Edit RTI" : " Add RTI"}
                  </div>
                  <div class="myForm">
                    <form onSubmit={handleSubmit}>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-12">
                              <div
                                class="boxlabel justify-content-between"
                                value={rti_type}
                                onChange={(e) => {
                                  setData({
                                    ...data,
                                    rti_type: e.target.value,
                                  });
                                }}
                              >
                                <p>RTI Type</p>
                                <label class="Radioc w-50">
                                  Minister
                                  <input
                                    type="radio"
                                    name="enquiry_type"
                                    checked={rti_type == 1}
                                    value={1}
                                    id="afl"
                                  />
                                  <span class="checC"></span>
                                </label>{" "}
                                <label class="Radioc">
                                  Department Wise
                                  <input
                                    type="radio"
                                    name="enquiry_type"
                                    checked={rti_type == 2}
                                    value={2}
                                    id="adm"
                                  />
                                  <span class="checC"></span>
                                </label>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="boxlabel">
                                <p> Title</p>
                                <input
                                  type="text"
                                  name="title"
                                  value={title}
                                  onChange={handleChange}
                                  class="form-control"
                                  required
                                />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="boxlabel">
                                <p>Department</p>
                                <input
                                  type="text"
                                  name="department"
                                  value={department}
                                  onChange={handleChange}
                                  class="form-control"
                                  required
                                />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="boxlabel">
                                <p>Posted date</p>
                                <input
                                  type="date"
                                  name="posted_date"
                                  value={posted_date}
                                  onChange={handleChange}
                                  class="form-control"
                                  required
                                />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="boxlabel">
                                <p>Reference Number</p>
                                <input
                                  type="text"
                                  name="reference_number"
                                  value={reference_number}
                                  onChange={handleChange}
                                  class="form-control"
                                  required
                                />
                              </div>
                            </div>

                            <div class="col-md-12">
                              <div class="boxlabel">
                                <p>Download</p>
                                <input
                                  type="file"
                                  name="phone"
                                  accept="application/pdf"
                                  onChange={(e) => setFile(e.target.files[0])}
                                  class="form-control"
                                  required={id ? false : true}
                                />
                              </div>
                            </div>
                            {rti_type == 2 && (
                              <>
                                <StateCitySelector
                                  user_city={city}
                                  user_state={state}
                                  set_user_state={(e) =>
                                    setData({ ...data, state: e })
                                  }
                                  set_user_city={(e) =>
                                    setData({ ...data, city: e })
                                  }
                                />
                              </>
                            )}

                            <div class="col-md-12">
                              <div
                                class="btn-groups mt-4 text-right"
                                role="group"
                                aria-label="First group"
                              >
                                <button class="btn btn-Next" type="submit">
                                  Submit<span></span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              ) : (
                <h5>No Record Found</h5>
              )}
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </div>
  );
};

export default AddRti;
