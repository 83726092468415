import { getDataAPI, postDataAPI } from "../../../services/apiWrapper";
import {
  ALERT,
  AUTH_FAIL,
  SET_STUDENT_CERTIFICATE,
  STUDENT_AUTH_SUCCESS,
} from "../actionTypes";

export const studentLogin = (data) => async (dispatch) => {
  try {
    dispatch({
      type: ALERT,
      payload: { loading: true },
    });
    const response = await dispatch(postDataAPI("student/login", data));

    localStorage.setItem("studentToken", response.data.data.token);
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });
    dispatch({
      type: STUDENT_AUTH_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: { loading: false, error: error.response.data.message },
    });
    dispatch({
      type: AUTH_FAIL,
    });
  }
};

export const studentDashboard = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("studentToken");
    dispatch({
      type: ALERT,
      payload: { loading: true },
    });
    const response = await dispatch(getDataAPI("student/dashboard", token));
    dispatch({
      type: STUDENT_AUTH_SUCCESS,
      payload: response.data.data,
    });
    dispatch({
      type: ALERT,
      payload: { loading: false },
    });
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: { loading: false },
    });
    dispatch({
      type: AUTH_FAIL,
    });
  }
};

export const changePassword = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("studentToken");
    dispatch({
      type: ALERT,
      payload: { loading: true },
    });
    const response = await dispatch(
      postDataAPI("student/change-password", data, token)
    );
    dispatch({
      type: ALERT,
      payload: { success: response.data.message },
    });
    dispatch({
      type: ALERT,
      payload: { loading: false },
    });
    return true;
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: { loading: false },
    });
    dispatch({
      type: ALERT,
      payload: { loading: false, error: error.response.data.message },
    });
    return false;
  }
};

export const getStudentCertificates = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("studentToken");
    const response = await dispatch(
      getDataAPI("student/certificateDetails", token)
    );
    dispatch({
      type: SET_STUDENT_CERTIFICATE,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({
      type: SET_STUDENT_CERTIFICATE,
      payload: { error: "Something went wrong" },
    });
  }
};

export const getResults = (id) => async (dispatch) => {
  try {
    const response = await dispatch(getDataAPI(`student/result/${id}`));
    return response.data.data;
  } catch (error) {
    dispatch({
      type: SET_STUDENT_CERTIFICATE,
      payload: { error: error.response?.message || error.message },
    });
    return false;
  }
};

export const getResultsByRollno = (data) => async (dispatch) => {
  try {
    const response = await dispatch(postDataAPI(`student/result`, data));
    return response.data.data;
  } catch (error) {
    dispatch({
      type: SET_STUDENT_CERTIFICATE,
      payload: { error: error.response?.message || error.message },
    });
    return false;
  }
};
