import React from "react";
import "./css/testimonial.css";

export default function Testimonial() {
  return (
    <section className="container-fluid sitedata">
      <div className="container p-3">
        <div className="row">
          <div className="col-md-3 col-sm-6 col-12 p-2 ">
            <div className="col-site-data perbg">
              <div className="icon-data">100+</div>
              <h4> Paramedical Institutes </h4>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-12 p-2 ">
            <div className="col-site-data pinkbg">
              <div className="icon-data">500+</div>
              <h4> Computer Institutes</h4>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-12 p-2 ">
          <div className="col-site-data blugbg">
            <div className="icon-data">100+</div>
            <h4> Yoga Centers</h4>
          </div>
          </div>
          <div className="col-md-3 col-sm-6 col-12 p-2 ">
          <div className="col-site-data perbg">
            <div className="icon-data">150+</div>
            <h4> Vocational Centers</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
