import React, { useCallback, useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import Loader from "../Alert/Loader";
import {
  downloadBodies,
  getGeneralBodyList,
} from "../../store/actions/adminActions/bodyAction";
import Pagination from "../misc/Pagination";
import BreadCrumb from "../BreadCrumb";

const breadcrumbs = [
  {
    path: "/",
    label: "Home",
  },
  {
    // path: "/aboutus",
    label: "Governing Body",
  },
];

const GovernBody = () => {
  const dispatch = useDispatch();
  const [list, setList] = useState(null);
  const [activePage, setactivePage] = useState(1);
  const [totalPages, settotalPages] = useState(0);
  const fetchData = useCallback(async () => {
    const response = await dispatch(getGeneralBodyList(0, activePage));
    if (response) {
      setList(response.data);
      settotalPages(Math.ceil(response.total / response.per_page));
    } else {
      setList([]);
    }
  }, [activePage, dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDownload = () => {
    dispatch(downloadBodies(0));
  };

  return (
    <div>
      <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
        <div class="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <BreadCrumb crumbs={breadcrumbs} />

          <div class="d-flex align-items-center">
            <button
              onClick={handleDownload}
              className="btn btn-light-primary font-weight-bolder btn-sm"
            >
              Download list
            </button>
          </div>
        </div>
      </div>
      <div class="container mt-4 mb-3">
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-12">
            <table
              class="table table-separate table-head-custom table-checkable"
              id="kt_datatable"
            >
              <thead>
                <tr>
                  <th>Sr.No</th>
                  <th>Name</th>
                  <th>Email</th>

                  <th>Mobile</th>
                  <th>Designation</th>
                </tr>
              </thead>
              {list ? (
                list.length > 0 ? (
                  list.map((element, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{element.name}</td>
                      <td>{element.email}</td>
                      <td>{element.mobile}</td>
                      <td>{element.designation}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6}>{"No Record Found"}</td>
                  </tr>
                )
              ) : (
                <Loader active={true} />
              )}
            </table>
            {totalPages && Number(totalPages) !== 0 ? (
              <Pagination
                setActivePage={(value) => setactivePage(value)}
                totalPages={totalPages}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GovernBody;
