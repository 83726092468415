import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import BreadCrumb from "../../BreadCrumb";

import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  addEmail,
  editEmail,
  updateEmail,
} from "../../../store/actions/councilActions/emailActions";

const breadcrumbs = [
  {
    path: "/admin/dashboard",
    label: "Dashboard",
  },
  {
    label: "Add Email",
  },
];

const AddEmail = () => {
  const [loading, setLoading] = useState(false);
  const [exist, setExist] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const initialState = {
    name: "",
    designation: "",
    email_id: "",
  };
  const [data, setData] = useState(initialState);
  const fetchData = async () => {
    setLoading(true);
    const response = await dispatch(editEmail(id));
    if (response) {
      setData({
        name: response.name,
        designation: response.designation,
        email_id: response.email_id,
      });
      setLoading(false);
    } else {
      setLoading(false);
      setExist(false);
    }
  };
  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, []);
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const { name, designation, email_id } = data;
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (id) {
      const response = await dispatch(
        updateEmail({
          ...data,
          important_id: id,
        })
      );
      if (response) {
        setData(initialState);
        history.push("/admin/email/list");
      }
    } else {
      const response = await dispatch(addEmail(data));
      if (response) {
        setData(initialState);
        history.push("/admin/email/list");
      }
    }
  };
  return (
    <div className="row">
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
          <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <BreadCrumb crumbs={breadcrumbs} />

            <div class="d-flex align-items-center">
              <Link
                to="/admin/email/list"
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                Email List
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8 card">
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" role="status" size="lg">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : exist ? (
            <>
              <div className="card-header text-center">
                {id ? "Edit Email" : " Add Email"}
              </div>
              <div class="myForm">
                <form onSubmit={handleSubmit}>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="boxlabel">
                            <p> Name</p>
                            <input
                              type="text"
                              name="name"
                              value={name}
                              onChange={handleChange}
                              class="form-control"
                              required
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="boxlabel">
                            <p>Designation</p>
                            <input
                              type="text"
                              name="designation"
                              value={designation}
                              onChange={handleChange}
                              class="form-control"
                              required
                            />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="boxlabel">
                            <p>Email</p>
                            <input
                              type="email"
                              name="email_id"
                              value={email_id}
                              onChange={handleChange}
                              class="form-control"
                              required
                            />
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div
                            class="btn-groups mt-4 text-right"
                            role="group"
                            aria-label="First group"
                          >
                            <button class="btn btn-Next" type="submit">
                              Submit<span></span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </>
          ) : (
            <h5>No Record Found</h5>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddEmail;
