export const ALERT = "ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const AUTH_FAIL = "AUTH_FAIL";

//CENTER ACTION =====================>>>>>>>>>>>>>>>>>>>>>>>>>>>
export const CENTER_REGISTER_DETAILS = "CENTER_REGISTER_DETAILS";
export const CENTER_AUTH_SUCCESS = "CENTER_AUTH_SUCCESS";
export const CENTER_AUTH_FAIL = "CENTER_AUTH_FAIL";
export const SET_STUDENTS = "SET_STUDENTS";
export const PRACTICAL_DATA = "PRACTICAL_DATA";

//ADMIN ACTION ======================>>>>>>>>>>>>>>>>>>>>>>>>>>
export const ADMIN_AUTH_SUCCESS = "ADMIN_AUTH_SUCCESS";
export const ADMIN_AUTH_FAIL = "ADMIN_AUTH_FAIL";
export const VIEW_GALLERY = "VIEW_GALLERY";

//HELPDESK ACTION ======================>>>>>>>>>>>>>>>>>>>>>>>>>>
export const HELPDESK_AUTH_SUCCESS = "HELPDESK_AUTH_SUCCESS";
export const HELPDESK_AUTH_FAIL = "HELPDESK_AUTH_FAIL";

//STUDENT ACTION ======================>>>>>>>>>>>>>>>>>>>>>>>>>>
export const STUDENT_AUTH_SUCCESS = "STUDENT_AUTH_SUCCESS";
export const STUDENT_AUTH_FAIL = "STUDENT_AUTH_FAIL";
export const SET_STUDENT_CERTIFICATE = "SET_STUDENT_CERTIFICATE";
export const ASSESSMENT_QUESTIONS = "ASSESSMENT_QUESTIONS";
export const PRACTICE_EXAM = "PRACTICE_EXAM";

export const DATE_SHEET = "DATE_SHEET";
export const EXAM_QUESTIONS = "EXAM_QUESTIONS";
// export const VIEW_GALLERY = "VIEW_GALLERY";

//COUNCIL ACTION ====================>>>>>>>>>>>>>>>>>>>>>>>>>>>
export const SET_TRADE = "SET_TRADE";
export const SET_SUB_TRADE = "SET_SUB_TRADE";
export const SET_CENTER_LIST = "SET_CENTER_LIST";
export const SET_COURSE = "SET_COURSE";
export const SET_TRADE_COURSE = "SET_TRADE_COURSE";
export const SET_SUBJECTS = "SET_SUBJECTS";
export const SET_SUBJECT = "SET_SUBJECT";
export const ASSIGNMENT_LIST = "ASSIGNMENT_LIST";
export const GET_ASSIGNMENT_QUESTIONS = "GET_ASSIGNMENT_QUESTIONS";
export const CLEAR_ASSIGNMENT_QUESTION = "CLEAR_ASSIGNMENT_QUESTION";
export const GET_SCHEDULE = "GET_SCHEDULE";

///GLOBAL=================>>>>>>>>>>>>>>>>>>>>>
export const STUDENT_VERIFICATION = "STUDENT_VERIFICATION";

///PRINT ACTION ==================>>>>>>>>>>>>>>>>>
export const PRINT_AUTH_SUCCESS = "PRINT_AUTH_SUCCESS";
export const PRINT_STATUS = "PRINT_STATUS";
export const CATEGORY_LIST = "CATEGORY_LIST";

export const MEMBER_AUTH_SUCCESS = "MEMBER_AUTH_SUCCESS";
export const MEMBER_AUTH_FAIL = "MEMBER_AUTH_FAIL";
export const MEMBER_DATA = "MEMBER_DATA";
