import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Spinner, Table } from "react-bootstrap";
import BreadCrumb from "../../BreadCrumb";
import {
  deleteOffice,
  downloadOffices,
  officeList,
} from "../../../store/actions/councilActions/officeAction";
import Pagination from "../../misc/Pagination";

const breadcrumbs = [
  {
    path: "/admin/dashboard",
    label: "Dashboard",
  },
  {
    label: "Office List",
  },
];

const OfficeList = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [activePage, setActivePage] = useState("1");
  const fetchdata = async () => {
    const response = await dispatch(officeList(activePage));
    if (response) {
      setTotalPages(Math.ceil(response.total / response.per_page));
      setData(response.data);
      setLoading(false);
    } else {
      setData([]);
      setLoading(false);
    }
  };
  useEffect(() => {
    setLoading(true);
    fetchdata();
  }, []);
  const deleteAction = async (id) => {
    const response = await dispatch(deleteOffice(id));
    if (response) {
      fetchdata();
    }
  };
  const handleDownload = () => {
    dispatch(downloadOffices());
  };
  return (
    <div>
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
          <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <BreadCrumb crumbs={breadcrumbs} />

            <div class="d-flex align-items-center">
              <button
                onClick={handleDownload}
                className="btn btn-light-primary font-weight-bolder btn-sm"
              >
                Download list
              </button>
              <Link
                to="/admin/office/add"
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                Add Office
              </Link>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status" size="lg">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <Table bordered hover striped responsive className="mb-0">
            <thead>
              <th>S No</th>
              <th>Category</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Address</th>
              <th>Address Link</th>
              <th>Action</th>
            </thead>
            <tbody>
              {data.length == 0 ? (
                <tr>
                  <td colSpan={7} className="text-center text-danger">
                    No Record Found
                  </td>
                </tr>
              ) : (
                data.map((element, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{element.category}</td>
                    <td>{element.email}</td>
                    <td>{element.phone}</td>
                    <td>{element.address}</td>
                    <td>
                      {element.address_link ? (
                        <a target={"_blank"} href={element.address_link}>
                          Open in map
                        </a>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td>
                      <div className="d-flex justify-content-between">
                        <Link to={`/admin/office/edit/${element.id}`}>
                          <i className="fa fa-pencil"></i>
                        </Link>

                        <i
                          className="fa fa-trash"
                          onClick={() => deleteAction(element.id)}
                        ></i>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        )}
        {totalPages && (
          <Pagination
            setActivePage={(value) => setActivePage(value)}
            totalPages={totalPages}
          />
        )}
      </div>
    </div>
  );
};

export default OfficeList;
