import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import EditPic from "./EditPic";
import { editCenter } from "../../../store/actions/centerActions/centerAuth";
const MyProfile = () => {
  const dispatch = useDispatch();
  const { center } = useSelector((state) => state);
  const { profile } = center.centerAuth;

  const [show, setShow] = useState(false);
  const [data, setData] = useState();

    useEffect(() => {
    dispatch(editCenter());
  }, []);

  return (
    <div>
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="container ">
          <div class="row">
            <div class="col-md-12">
              <div class="card-body p-0 "></div>
              <div class="col-md-12">
                <div class="card" id="invoice">
                  {profile && (
                    <>
                      <div class="card-header bg-transparent header-elements-inline row">
                        <div className="col-md-6">
                          <h6 class="card-title text-primary">
                            Center Status :
                            {profile.status == "0" ? (
                              <span class="text-info">Pending</span>
                            ) : profile.status == "1" ? (
                              <span class="text-success"> Approved</span>
                            ) : (
                              <span class="text-danger">Rejected</span>
                            )}
                          </h6>
                          {profile.status == "1" && (
                            <h4 class="invoice-color mb-2 mt-md-2">
                              Center Code - <span>{profile.center_code}</span>
                            </h4>
                          )}
                        </div>
                        <div class="col-md-6 float-right my-auto">
                          <div class="Title-box">
                            {/* <div class="row"> */}
                            <Link to="/center/edit" class="btn btn-comman">
                              My Profile Edit
                            </Link>
                          </div>
                          {/* </div> */}
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="mb-4 pull-left"></div>
                          </div>
                          <div class="col-sm-6">
                            <div class="mb-4 ">
                              <div class="text-sm-right"></div>
                            </div>
                          </div>
                        </div>

                        <div class="d-md-flex flex-md-wrap mb-4">
                          <div class="col-md-6">
                            <span class="text-muted">Md Details : </span>
                            <ul class="list list-unstyled mb-0">
                              <li>
                                <span class="font-weight-semibold">
                                  <span class="sd">MD Name : </span>
                                  {profile.md_name}
                                </span>
                              </li>
                              <li>
                                <span class="font-weight-semibold">
                                  <span class="sd">MD Mobile No : </span>
                                  {profile.mobile}
                                </span>
                              </li>
                              <li>
                                <span class="sd">MD Email : </span>
                                {profile.email}
                              </li>
                              <li>
                                <span class="sd">MD Education : </span>
                                {profile.md_qualification}
                              </li>
                              <li>
                                <span class="sd">MD Dob : </span>
                                {profile.md_dob}
                              </li>
                              <li>
                                <span class="sd">MD Aadhar : </span>
                                {profile.md_adhar}
                              </li>
                              <li>
                                <span class="sd">MD Pan No : </span>
                                {profile.md_pan}
                              </li>

                              <li>
                                <span class="sd">MD Address : </span>
                                {`${profile.md_address}, ${profile.md_city}, ${profile.md_state}, ${profile.md_pin_code}`}
                              </li>
                            </ul>
                          </div>
                          <div class="col-md-6">
                            <span class="text-muted">Center Details</span>
                            <div class="d-flex flex-wrap wmin-md-400">
                              <ul class="list list-unstyled mb-0"></ul>

                              <ul class="list list-unstyled  mb-0 ">
                                <li>
                                  <span class="sd">Center name : </span>
                                  <span class="font-weight-semibold">
                                    {profile.center_name}
                                  </span>
                                </li>
                                <li>
                                  <span class="sd">Center Type : </span>
                                  {profile.center_type}
                                </li>
                                <li>
                                  <span class="sd">Center Trade : </span>
                                  {profile.center_trade}
                                </li>

                                <li>
                                  <span class="sd">Center Contact : </span>
                                  <span class="font-weight-semibold">
                                    {profile.center_mobile}
                                  </span>
                                </li>
                                <li>
                                  <span class="sd">Center Email : </span>
                                  <span class="font-weight-semibold">
                                    {profile.center_email}
                                  </span>
                                </li>
                                <li>
                                  <span class="sd">Center Address : </span>
                                  <span class="font-weight-semibold">
                                    {`${profile.center_address}, ${profile.center_city}, ${profile.center_state}, ${profile.center_pincode}`}
                                  </span>
                                </li>
                                {profile.status == "1" && (
                                  <li>
                                    <span class="sd">Total Seats : </span>
                                    <span class="font-weight-semibold">
                                      {profile.total_seats}
                                    </span>
                                  </li>
                                )}
                                <li>
                                  <span class="sd">Registration Date : </span>
                                  <span class="font-weight-semibold">
                                    {moment(center.created_at).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="table-responsive">
                        <h6 class="mb-0 ty">Staff Details</h6>
                        <table class="table table-lg text-center">
                          <tbody>
                            <tr>
                              <th>Name</th>
                              <th>Profile Pic</th>
                              <th>Qualification</th>
                              <th>Designation</th>
                              <th>Joindate</th>
                              <th>Action</th>
                              <th>Id Card</th>
                            </tr>
                            {profile.staff?.length > 0 ? (
                              profile.staff.map((s, index) => (
                                <tr>
                                  <td>{s.name}</td>
                                  <td>
                                    <img
                                      src={s.image || "/images/profile.png"}
                                      alt={s.name}
                                      height={"25px"}
                                    />
                                  </td>
                                  <td>{s.qualification}</td>
                                  <td>{s.designation}</td>
                                  <td>
                                    {moment(s.date_of_joining).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-sm btn-primary"
                                      onClick={() => {
                                        setShow(true);
                                        setData({
                                          name: s.name,
                                          id: s.id,
                                        });
                                      }}
                                    >
                                      Edit Profile Pic
                                    </button>
                                  </td>
                                  <td>
                                    <a
                                      href={`https://npvec.com/api/public/download/center/card/${s.id}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      View
                                    </a>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={4}>No Staff added</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        <div class="table-responsive">
                          <h6 class="mb-0 ty">Facility Details</h6>
                          <table class="table table-lg">
                            <tbody>
                              <tr>
                                <th>Name</th>
                                <th>Number</th>
                                <th>Area</th>
                                <th>Seats</th>
                              </tr>
                              {profile.facility?.length > 0 ? (
                                profile.facility.map((c, index) => (
                                  <tr>
                                    <td>{c.name}</td>
                                    <td>{c.number}</td>
                                    <td>{c.carpet_area}</td>
                                    <td>{c.seat_capicity}</td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={4}>No Facility added</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditPic show={show} onHide={() => setShow(false)} data={data} />
    </div>
  );
};

export default MyProfile;
