import React from "react";
import LinksMenu from "./LinksMenu";

const LinksLayout = ({ children }) => {
  return (
    <div className="container">
      <div className="row mt-4 mb-4">
        <div className="col-md-3">
          <LinksMenu />
        </div>
        <div className="col-md-9">{children}</div>
      </div>
    </div>
  );
};

export default LinksLayout;
