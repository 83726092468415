import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { COMPLAINT_BYS } from "../../utils/constant";
import CKEditor from "react-ckeditor-component";
import BreadCrumb from "./../BreadCrumb";
import { findMe, submitQuery } from "../../store/actions/helpdeskActions/complaint";
import { Link } from "react-router-dom";

export default function HelpDesk() {
  const [submmitting, setSubmitting] = useState(false);
  const breadcrumbs = [
    {
      path: "/",
      label: "Home",
    },
    {
      // path: "/aboutus",
      label: "Help Desk",
    },
  ];
  const initialState = {
    type: "0",
    roll_number: "",
    student_name: "",
    center_code: "",
    md_name: "",
    title: "",
    image: [],
  };
  const [data, setData] = useState(initialState);
  const [userData,setUserData]=useState(false)
  const [finding,setSetFinding]=useState(false)
  const [description, setDescription] = useState("");
  const {
    type,
    roll_number,
    student_name,
    center_code,
    md_name,
    title,
    image,
  } = data;
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const onChange = (evt) => {
    var newContent = evt.editor.getData();
    setDescription(newContent);
  };
  const dispatch = useDispatch();

  const handleSearch=async(e)=>{
    setSetFinding(true)
    e.preventDefault()
    const response  = await dispatch(
     findMe({type,roll_number,center_code})
    );
    if(response){
      setUserData(response)
      setData({
        ...data,
        student_name:response.name || '',
        md_name:response.md_name || ''
      })
      setSetFinding(false)
    }
    else{
      setSetFinding(false)
      setUserData(null)
    }
    
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const formdata = new FormData();
    formdata.append("type", type);
    formdata.append("roll_number", roll_number);
    formdata.append("student_name", student_name);
    formdata.append("md_name", md_name);
    formdata.append("center_code", center_code);
    formdata.append("title", title);
    formdata.append("description", description);
    for (let x = 0; x < image.length; x++) {
      formdata.append("image[]", image[x]);
    }
    dispatch(
      submitQuery(formdata, setSubmitting, (success) => {
        if (success) {
          setData(initialState);
          setDescription("");
        }
      })
    );
  };
  return (
    <>
      <div class=" ">
        <div class="container">
          <div
            class="content d-flex flex-column flex-column-fluid"
            id="kt_content"
          >
            <div
              class="subheader py-2 py-lg-6 subheader-solid"
              id="kt_subheader"
            >
              <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                <BreadCrumb crumbs={breadcrumbs} />

                <div class="d-flex align-items-center">
                  <Link
                    to="/track-complaint"
                    class="btn btn-light-primary font-weight-bolder btn-sm"
                  >
                    Track Complaint
                  </Link>
                </div>
              </div>
            </div>
            <div class="title-box">
              <h4>Help Desk</h4>
            </div>
            <div class="row">
              <div class="col-md-2 mb-4"></div>
              <div class="col-md-8 mb-4">
                <div class="card">
                  <div class="myForm">
                    <div class="row">
                        <div class="col-md-12">
                          <div
                            class="boxlabel"
                            value={type}
                            onChange={(e) => {
                              setUserData(false)
                              setData({
                                ...data,
                                type: e.target.value,
                              });
                            }}
                          >
                            <p>Complaint By</p>
                            <div class="row">
                              <div class="col-md-6">
                                <label class="Radioc">
                                  Center
                                  <input
                                    type="radio"
                                    name="type"
                                    checked={type === COMPLAINT_BYS.center}
                                    value={COMPLAINT_BYS.center}
                                    id="afl"
                                  />
                                  <span class="checC"></span>
                                </label>
                              </div>
                              <div class="col-md-6">
                                <label class="Radioc">
                                  Student
                                  <input
                                    type="radio"
                                    name="type"
                                    checked={type === COMPLAINT_BYS.student}
                                    value={COMPLAINT_BYS.student}
                                    id="adm"
                                  />
                                  <span class="checC"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {userData === null && <p>Please enter correct {type===COMPLAINT_BYS.center ? "center code" : "roll number"}</p>}
                        {type === COMPLAINT_BYS.student && (
                          <>
                            <div class="col-md-12">
                              <div class="boxlabel">
                                <p>Roll Number</p>
                                <input
                                  type="text"
                                  name="roll_number"
                                  value={roll_number}
                                  onChange={handleChange}
                                  class="form-control"
                                  required
                                />
                              </div>
                            </div>
                            <div class="col-md-12">
                                 <button
                                    class="btn btn-Next"
                                    type="button"
                                    onClick={handleSearch}
                                    disabled={finding}
                                  >
                                    Search
                                  </button>
                            </div>
                            {userData &&
                            <div class="col-md-12">
                              <div class="boxlabel">
                                <p>Student Name</p>
                                <input
                                  type="text"
                                  name="student_name"
                                  value={student_name}
                                  onChange={handleChange}
                                  class="form-control"
                                  required
                                />
                              </div>
                            </div>
}
                          </>
                        )}
                        {type === COMPLAINT_BYS.center && (
                          <>
                            <div class="col-md-12">
                              <div class="boxlabel">
                                <p>Center Code</p>
                                <input
                                  type="text"
                                  name="center_code"
                                  value={center_code}
                                  onChange={handleChange}
                                  class="form-control"
                                  required
                                />
                              </div>
                            </div>
                             <div class="col-md-12">
                                 <button
                                    class="btn btn-Next"
                                    type="button"
                                    onClick={handleSearch}
                                    disabled={finding}
                                  >
                                    Search
                                  </button>
                            </div>
                            {userData &&
                            <div class="col-md-12">
                              <div class="boxlabel">
                                <p>Name</p>
                                <input
                                  type="text"
                                  name="md_name"
                                  value={md_name}
                                  onChange={handleChange}
                                  class="form-control"
                                  required
                                />
                              </div>
                            </div>
}
                          </>
                        )}
                        </div>
                    <form onSubmit={handleSubmit}>
                      <div class="row">
                        {userData &&
                        <>
                        <div class="col-md-12">
                          <div class="boxlabel">
                            <p>Subject</p>
                            <input
                              type="text"
                              name="title"
                              value={title}
                              onChange={handleChange}
                              class="form-control"
                              required
                            />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="boxlabel">
                            <p>Description</p>
                            <CKEditor
                              activeClass="p10"
                              content={description}
                              events={{
                                change: onChange,
                              }}
                            />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="boxlabel">
                            <p>Attachment</p>
                            <input
                              type="file"
                              name="image"
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  image: e.target?.files || [],
                                })
                              }
                              multiple
                            />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div
                            class="btn-groups mt-4 text-right"
                            role="group"
                            aria-label="First group"
                          >
                            <button
                              class="btn btn-Next"
                              type="submit"
                              disabled={submmitting}
                            >
                              Submit<span></span>
                            </button>
                          </div>
                        </div>
                        </>}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
