import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Loader from '../../Alert/Loader';

const Studentcard = () => {
  const { studentAuth } = useSelector((state) => state.student);
  const { profile } = studentAuth;
  const [loading,setLoading]=useState(true)
  return (
     <div class="Title-box">
      <div class="row">
        <div class="">
          <div class="BigTitle">Student ICard</div>
          {loading ?<div className="text-center mt-5" >
              <Spinner animation="border" role="status" size="lg" style={{height:"6rem",width:"6rem"}}>
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div> : null}
           <iframe
                    src={`https://npvec.com/api/public/download/student/card/${profile.id}`}
                     onLoad={()=>setLoading(false)}
                    width={"100%"}
                    height={"100%"}
                  ></iframe>
          </div>
          </div>
          </div>
  )
}

export default Studentcard