import React from "react";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import MainSection from "./MainSection/MainSection";
import Section from "./Sections/Section";
import Sidebox from "./SideBox/Sidebox";
import Testimonial from "./Testimonial/Testimonial";

export default function Home() {
  return (
    <>
      {/* <Header /> */}
      <Sidebox />
      <Section />
      <MainSection />
      <Testimonial />
      {/* <Footer /> */}
    </>
  );
}
