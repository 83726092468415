import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getCourseDetail } from "../../store/actions/councilActions/courseAction";

import "./css/course.css";
import Loader from "../Alert/Loader";

const Course = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [subjects, setSubjects] = useState([]);
  useEffect(async () => {
    const response = await dispatch(getCourseDetail(id));
    setData(response);
  }, []);

  useEffect(() => {
    if (data) {
      const hs = new Map();
      data.subject.map((s) => {
        if (hs.get(s.semester)) {
          hs.set(s.semester, [...hs.get(s.semester), s.name]);
        } else {
          hs.set(s.semester, [s.name]);
        }
      });
      setSubjects(
        Array.from([...hs.entries()].sort(), (item) => ({ [item[0]]: item[1] }))
      );
    }
  }, [data]);

  return (
    <div>
      <section class="page-header mb-0">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <ul class="breadcrumb">
                <li>
                  <Link to="/">Home</Link>
                </li>
                /
                <li>
                  <span>Course</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {data === null ? (
        <Loader active={true} />
      ) : data ? (
        <section class="section">
          <div class="container">
            <div class="row">
              <div class="col-md-3 order-2 order-md-1">
                <div class="row">
                  <div class="col-12 mb-4">
                    <div class="image-frame">
                      <img
                        id="ContentPlaceHolder1_imgCourse"
                        class="img-fluid"
                        src={data.file}
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    {subjects.length > 0 && (
                      <>
                        <h2 class="text-color-tertiary">Subjects</h2>
                        {subjects.map((subject) => {
                          for (const [key, value] of Object.entries(subject)) {
                            return (
                              <>
                                <h5 class="font-weight-bold">
                                  {data.course_type == "1"
                                    ? `Year ${key}`
                                    : `Semester ${key}`}
                                </h5>
                                <hr class="my-2" />
                                <ol>
                                  {value.map((v, index) => (
                                    <li key={index}>{v}</li>
                                  ))}
                                </ol>
                              </>
                            );
                          }
                        })}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div class="col-md-9 order-1 order-md-2 mb-5 mb-md-0">
                <div
                  data-plugin-sticky
                  data-plugin-options="{'offset_top': 100}"
                >
                  <div class="row mb-3">
                    <div class="col">
                      <h2 class="font-weight-bold">
                        {`${data.name} (${data.duration})`}
                      </h2>
                      <p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data.description,
                          }}
                        ></div>
                      </p>
                      {/* <p>
                        5 Months Theory + 6 Months Practical Hospital Internship
                      </p> */}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <h2 class="text-color-tertiary">Course Details</h2>
                    </div>
                    <div class="col-md-6 mb-2">
                      <h5 class="font-weight-bold">Course Duration</h5>
                      <hr class="my-2" />
                      <span class="font-primary text-color-dark">
                        {data.duration}
                      </span>
                    </div>
                    <div class="col-md-6 mb-2">
                      <h5 class="font-weight-bold">Eligibility</h5>

                      <hr class="my-2" />
                      <span class="font-primary text-color-dark">
                        {data.eligibility}
                      </span>
                    </div>
                    {/* <div class="col-md-6 mb-2">
                      <h5 class="font-weight-bold">Classes</h5>
                      <hr class="my-2" />
                      <span class="font-primary text-color-dark">
                        5 Days a week (Monday to Friday)
                      </span>
                    </div> */}
                    {/* <div class="col-md-6 mb-2">
                      <h5 class="font-weight-bold">Classes Timings</h5>
                      <hr class="my-2" />
                      <span class="font-primary text-color-dark">
                        2 Hours Per Day
                      </span>
                    </div> */}
                    {/* <div class="col-md-12 mb-2">
                      <span class="font-primary text-color-dark">
                        Start on 12 June 2020
                      </span>
                    </div> */}
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <h2 class="text-color-tertiary">Fees Structure</h2>
                    </div>
                    <div class="col-md-6 mb-2">
                      <h5 class="font-weight-bold">Total Fees</h5>
                      <hr class="my-2" />
                      <span class="font-primary text-color-dark">
                        {data?.fee
                          ? +data.fee.course_fee +
                            +data.fee.registration_fee +
                            +data.fee.certificate_fee +
                            +data.fee.exam_fee +
                            +data.fee.annual_fee
                          : 0}
                      </span>
                    </div>
                    <div class="col-md-6 mb-2">
                      <h5 class="font-weight-bold">
                        Registration and Admission Fees
                      </h5>
                      <hr class="my-2" />
                      <span class="font-primary text-color-dark">
                        {`Rs. ${data.fee?.registration_fee}`}
                        {/* Rs. 5000/- (Rupees Five thousand only) DD in favour of
                        National Paramedical Education */}
                      </span>
                    </div>
                    <div class="col-md-6 mb-2">
                      <h5 class="font-weight-bold">Course Fees</h5>
                      <hr class="my-2" />
                      <span class="font-primary text-color-dark">
                        {`Rs. ${data.fee?.course_fee}`}
                      </span>
                    </div>
                    <div class="col-md-6 mb-2">
                      <h5 class="font-weight-bold">Examination Fees</h5>
                      <hr class="my-2" />
                      <span class="font-primary text-color-dark">
                        {`Rs. ${data.fee?.exam_fee}`}
                      </span>
                    </div>
                    <div class="col-md-6 mb-2">
                      <h5 class="font-weight-bold">Annual Fees</h5>
                      <hr class="my-2" />
                      <span class="font-primary text-color-dark">
                        {`Rs. ${data.fee?.annual_fee}`}
                      </span>
                    </div>
                    <div class="col-md-6 mb-2">
                      <h5 class="font-weight-bold">Certificate Fees</h5>
                      <hr class="my-2" />
                      <span class="font-primary text-color-dark">
                        {`Rs. ${data.fee?.certificate_fee}`}
                      </span>
                    </div>
                    {/* <div class="col-md-12 mb-2">
                      Course Fee includes Registration fee, Admission
                      fee,Tuition Fees, Study Materials, Apron, ID Card.
                    </div> */}
                  </div>
                  {/* <div class="row">
                    <div class="col-md-12">
                      <h5 class="font-weight-bold">Module</h5>
                      <hr class="my-2" />
                      <span class="font-primary text-color-dark">
                        OPERATION THEATER TECHNICIAN
                      </span>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          {/* <div class="container">
            <div class="row">
              <div class="col-md-12 order-2 order-md-1 mt-5">
                <h3 class="mb-4 text-danger">Related Courses</h3>

                <div
                  class="owl-carousel owl-theme nav-style-3"
                  data-plugin-options="{'responsive': {'0': {'items': 1}, '479': {'items': 1}, '768': {'items': 2}, '979': {'items': 3}, '1199': {'items': 4}}, 'dots': false, 'nav': true, 'animateIn': 'fadeIn', 'animateOut': 'fadeOut', 'margin': 30}"
                >
                  <div>
                    <a href="CourseDetails.aspx?Id=2">
                      <img
                        src="Course/WhatsAppImage2020-05-25at3.21.35PM.jpeg"
                        class="img-fluid"
                        alt=""
                      />
                    </a>
                  </div>

                  <div>
                    <a href="CourseDetails.aspx?Id=3">
                      <img
                        src="Course/WhatsAppImage2020-05-25at3.21.35PM(1).jpeg"
                        class="img-fluid"
                        alt=""
                      />
                    </a>
                  </div>

                  <div>
                    <a href="CourseDetails.aspx?Id=6">
                      <img
                        src="Course/WhatsAppImage2020-05-25at3.21.35PM(2).jpeg"
                        class="img-fluid"
                        alt=""
                      />
                    </a>
                  </div>

                  <div>
                    <a href="CourseDetails.aspx?Id=8">
                      <img
                        src="Course/WhatsAppImage2020-05-25at3.37.23PM(1).jpeg"
                        class="img-fluid"
                        alt=""
                      />
                    </a>
                  </div>

                  <div>
                    <a href="CourseDetails.aspx?Id=9">
                      <img
                        src="Course/Multipurpose.jpg"
                        class="img-fluid"
                        alt=""
                      />
                    </a>
                  </div>

                  <div>
                    <a href="CourseDetails.aspx?Id=14">
                      <img
                        src="Course/WhatsAppImage2020-05-25at3.21.34PM.jpeg"
                        class="img-fluid"
                        alt=""
                      />
                    </a>
                  </div>

                  <div>
                    <a href="CourseDetails.aspx?Id=16">
                      <img src="Course/sanitary.jpg" class="img-fluid" alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </section>
      ) : (
        <h1>No Course Found</h1>
      )}
    </div>
  );
};

export default Course;
