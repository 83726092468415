import React, { useEffect, useState } from "react";
import BreadCrumb from "../../BreadCrumb";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { addCategory, updateCategory, getCategoryDetail, addSubCategory, getSubCategoryDetail, updateSubCategory, getPageDetail, updatePage, } from "../../../store/actions/printActions/printcategory";
const EditPage= () => {
  const dispatch = useDispatch();
  const [exist, setExist] = useState(true);
  const history = useHistory();
  const { id } = useParams();

  console.log('id====>', id)
  const [data, setData] = useState({
    do_description: "",
    donts_description:"",
    id:"",
    category:0,
    subcategory:0,
    terms_condition:"",
  });
  useEffect(async () => {
    if (id) {
      const response = await dispatch(getPageDetail(id));
      console.log('response====>', response)
      if (response.success) {
        setData({
          do_description: response.data.do_description,
          donts_description: response.data.donts_description,
         category:response.data.category,
         subcategory:response.data.subcategory,
         terms_condition:response.data.terms_condition,
        });
      } else {
        setExist(false);
      }
    }
  }, [id]);
  const { do_description, donts_description ,category,subcategory,terms_condition} = data;
  const breadcrumbs = [
    // {
    //   path: "/admin/dashboard",
    //   label: "Dashboard",
    // },
    {
       path: "/print",
      label: "Add Page",
    },
  ];
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

  
      const response = await dispatch(updatePage({category:data.category,subcategory:data.subcategory, id:id, ...data }));
      if (response) {
        history.push("/print/page");

    
    }
  };
  return (
    <div>
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
          <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/* <BreadCrumb crumbs={breadcrumbs} /> */}

            <div class="d-flex align-items-center">
              <Link
                to={"/print/page"}
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                View List
              </Link>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-2"></div>

            <div class="col-md-8">
              {exist ? (
                <form onSubmit={handleSubmit}>
                 
                  <div class="form-group">
                    <label>
                      DO's Description <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Name "
                      name="do_description"
                      value={do_description}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label>
                      Don't Description <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Description "
                      name="donts_description"
                      value={donts_description}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label>
                        Term & Condition <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Description "
                      name="terms_condition"
                      value={terms_condition}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  
                  <button type="submit" class="btn btn-primary mr-2">
                    Submit
                  </button>
                  {/* <button type="reset" class="btn btn-secondary">
                  Cancel
                </button> */}
                </form>
              ) : (
                <h1>Record does not exist</h1>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPage;
