import React, { useEffect, useState } from "react";
import { Card, Table, Accordion, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// To make rows collapsible
import "bootstrap/js/src/collapse.js";
import { Link } from "react-router-dom";
import { getSubject } from "../../../store/actions/studentAction/assessmentActions";

const Assessments = () => {
  const { student } = useSelector((state) => state);
  const [passed, setPassed] = useState(false);
  const { assessment } = student;
  const { studentAuth } = student;
  const { profile } = studentAuth;
  const [locked, setLocked] = useState({
    subjectLock: "",
    semLock: "",
  });
  const [startLock, setStartlock] = useState("");
  const [lockSem, setLocksem] = useState("");
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const fetchData = async () => {
    const response = await dispatch(
      getSubject(studentAuth.profile.id, profile.course.id)
    );
  };
  useEffect(() => {
    if (profile.course.id) {
      fetchData();
    }
  }, [profile.course.id]);
  return (
    <div className="exam-list">
      <Card className="mb-3">
        <Card.Header>{profile.course.name}</Card.Header>
      </Card>
      {!assessment ? (
        <div className="text-center">
          <Spinner animation="border" role="status" size="lg">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          {assessment.exam_type == "course_wise" ? (
            <Table bordered hover striped responsive className="mb-0">
              <thead>
                <tr>
                  <th>S.NO.</th>
                  <th>Subject</th>
                  <th>No. of Questions</th>
                  <th>Marks Obtained</th>
                  <th>Exam Status</th>
                </tr>
              </thead>
              <tbody>
                {Object.values(assessment.semester)[0].map((sub, i) => (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{sub.name}</td>
                    <td>{sub.questions_count ? 10 : 0}</td>
                    {/* {sub.marks < 4 &&
                          !startLock &&
                          // setLocksem(index) &&
                          setStartlock(i)}
                        {sub.marks < 4 && !startLock && setLocksem(index)} */}
                    <td>{sub.marks}</td>
                    <td>
                      {/* {console.log(startLock)}
                          {!startLock >= i - 1 && !lockSem > index && ( */}
                      {sub.questions_count > 0 &&
                        (sub.exam_status == 1 ? (
                          sub.marks < 4 ? (
                            <Link
                              to={{
                                pathname: `/student/assessments/subject`,
                                state: {
                                  subject: sub.id,
                                  semester: 0,
                                  questions_count: 10,
                                },
                              }}
                              // to={`/student/assessments/subject-${btoa(
                              //   sub.id
                              // )}-${btoa(sem)}`}
                            >
                              <i class="fa fa-refresh" aria-hidden="true"></i>
                            </Link>
                          ) : (
                            <span>Completed</span>
                          )
                        ) : (
                          <i class="fa fa-lock" aria-hidden="true"></i>
                        ))}
                      {/* )} */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            Object.keys(assessment.semester).map((sem, index) => (
              <Accordion className="mb-3">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    {assessment.course_type == "2" ? "Semester " : "Year "}{" "}
                    {sem}
                  </Accordion.Header>
                  <Accordion.Body className="p-0">
                    {/* <Card.Body> */}
                    <Table bordered hover striped responsive className="mb-0">
                      <thead>
                        <tr>
                          <th>S.NO.</th>
                          <th>Subject</th>
                          <th>No. of Questions</th>
                          <th>Marks Obtained</th>
                          <th>Exam Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {assessment.semester[sem].map((sub, i) => (
                          <>
                            <tr>
                              <td>{i + 1}</td>
                              <td>{sub.name}</td>
                              <td>{sub.questions_count ? 10 : 0}</td>
                              <td>{sub.marks}</td>
                              <td>
                                {sub.questions_count > 0 &&
                                  (sub.exam_status == 1 ? (
                                    sub.marks < 4 ? (
                                      <Link
                                        to={{
                                          pathname: `/student/assessments/subject`,
                                          state: {
                                            subject: sub.id,
                                            semester: sem,
                                            questions_count: 10,
                                          },
                                        }}
                                      >
                                        <i
                                          class="fa fa-refresh"
                                          aria-hidden="true"
                                        ></i>
                                      </Link>
                                    ) : (
                                      <span>Completed</span>
                                    )
                                  ) : (
                                    <i
                                      class="fa fa-lock"
                                      aria-hidden="true"
                                    ></i>
                                  ))}
                                {/* )} */}
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ))
          )}
        </>
      )}
      {/* <Card>
    <Card.Body> */}
      {/* <Card.Header className="text-center"> */}

      {/* </Card.Body> */}
      {/* </Card.Body> */}
      {/* </Card> */}
    </div>
  );
};

export default Assessments;
