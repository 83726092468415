import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Spinner, Table } from "react-bootstrap";
import BreadCrumb from "../../BreadCrumb";

import {
  deleteRti,
  rtiList,
} from "../../../store/actions/councilActions/rtiActions";
import Pagination from "../../misc/Pagination";

const breadcrumbs = [
  {
    path: "/admin/dashboard",
    label: "Dashboard",
  },
  {
    label: "Minister RTI List",
  },
];

const MinisterRtiList = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [loadDelete, setLoadDelete] = useState(false);
  const [activePage, setactivePage] = useState(1);
  const [totalPages, settotalPages] = useState(0);

  const fetchdata = useCallback(async () => {
    const response = await dispatch(rtiList(1, activePage));
    if (response) {
      setData(response.data);
      setLoading(false);
      settotalPages(Math.ceil(response.total / response.per_page));
    } else {
      setLoading(false);
    }
  }, [activePage, dispatch]);
  useEffect(() => {
    setLoading(true);
    fetchdata();
  }, [fetchdata]);
  const deleteAction = async (id) => {
    setLoadDelete(true);
    const response = await dispatch(deleteRti(id));
    if (response) {
      fetchdata();
      setLoadDelete(true);
    }
  };
  return (
    <div>
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
          <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <BreadCrumb crumbs={breadcrumbs} />

            <div class="d-flex align-items-center">
              <Link
                to="/admin/rti/add"
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                Add RTI
              </Link>
              <Link
                to="/admin/rti/list/department"
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                Department RTI List
              </Link>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status" size="lg">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <Table bordered hover striped responsive className="mb-0">
            <thead>
              <th>S No</th>
              <th>Title</th>
              <th>Department</th>
              <th>Reference Number</th>
              <th>Posted Date</th>
              <th>File</th>
              <th>Action</th>
            </thead>
            <tbody>
              {data.length == 0 ? (
                <tr>
                  <td colSpan={7} className="text-center text-danger">
                    No Record Found
                  </td>
                </tr>
              ) : (
                data.map((element, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{element.title}</td>
                    <td>{element.department}</td>
                    <td>{element.reference_number}</td>
                    <td>{element.posted_date}</td>
                    <td>
                      <a href={element.file} download target={"_blank"}>
                        Download
                      </a>
                    </td>
                    <td>
                      {loadDelete ? (
                        <div className="text-center">
                          <Spinner animation="border" role="status" size="lg">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-between">
                          <Link to={`/admin/rti/edit/${element.id}`}>
                            <i className="fa fa-pencil"></i>
                          </Link>

                          <i
                            className="fa fa-trash"
                            onClick={() => deleteAction(element.id)}
                          ></i>
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        )}
        {totalPages && Number(totalPages) !== 0 ? (
          <Pagination
            setActivePage={(value) => setactivePage(value)}
            totalPages={totalPages}
          />
        ) : null}
      </div>
    </div>
  );
};

export default MinisterRtiList;
