import { getDataAPI, postDataAPI } from "../../../services/apiWrapper";
import { ALERT } from "../actionTypes";

export const addRti = (data) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      postDataAPI("admin/create/rti", data, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });

    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });

    dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};

export const updateRti = (data) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(postDataAPI("admin/edit/rti", data, token));
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });

    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });

    dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};

export const rtiList = (type, page) => async (dispatch) => {
  try {
    const response = await dispatch(
      getDataAPI(`admin/list/rti/${type}?page=${page}`)
    );
    return response.data.data;
  } catch (error) {
    return false;
  }
};

export const rtis = (type, title, page) => async (dispatch) => {
  try {
    const response = await dispatch(
      getDataAPI(`admin/search/rti/${type}/${title}?page=${page}`)
    );
    return response.data.data;
  } catch (error) {
    return false;
  }
};
export const rtiTitle = (type) => async (dispatch) => {
  try {
    const response = await dispatch(getDataAPI(`admin/rti/${type}`));
    return response.data.data;
  } catch (error) {
    return false;
  }
};

export const editRti = (id) => async (dispatch) => {
  const token = localStorage.getItem("adminToken");
  try {
    const response = await dispatch(getDataAPI(`admin/edit/rti/${id}`, token));
    return response.data.data;
  } catch (error) {
    return false;
  }
};

export const deleteRti = (id) => async (dispatch) => {
  const token = localStorage.getItem("adminToken");
  try {
    const response = await dispatch(
      getDataAPI(`admin/delete/rti/${id}`, token)
    );
    return true;
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: { error: error.response?.data?.message || error.message },
    });
    return false;
  }
};
