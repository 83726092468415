import React, { useCallback, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useLoadMore } from "../../Council/News/useLoadMore";

const News = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, data, setData, hasMore] = useLoadMore(
    "admin/news/list/news?page=",
    pageNumber
  );

  const observer = useRef();
  const lastDataElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );
  return (
    <>
      <div class="col-lg-3 col-md-12 col-sm-12 mb-2">
        <div class="NOtification">
          <h5>Important News</h5>
          <div class="notification-items">
            {data.length > 0 ? (
              data.map((n, index) => {
                if (data.length === index + 1) {
                  return (
                    <div class="upboxs" ref={lastDataElementRef} key={n}>
                      <div class="upDateimg">
                        <img src={n.file} />
                      </div>
                      <div class="upData">
                        <Link to={`/news/${n.id}`}>
                          <h4>
                            {" "}
                            <div
                              className="heading"
                              dangerouslySetInnerHTML={{
                                __html: n.title,
                              }}
                            ></div>
                          </h4>
                        </Link>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div class="upboxs">
                      <div class="upDateimg">
                        <img src={n.file} />
                      </div>
                      <div class="upData">
                        <Link to={`/news/${n.id}`}>
                          <h4>
                            {" "}
                            <div
                              className="heading"
                              dangerouslySetInnerHTML={{
                                __html: n.title,
                              }}
                            ></div>
                          </h4>
                        </Link>
                      </div>
                    </div>
                  );
                }
              })
            ) : (
              <div></div>
            )}
            {!loading && data.length == 0 && <div>No News Available</div>}
            {loading && <div>Loading...</div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default News;
