import React from "react";
import { useState } from "react";
import { Button, Form, FormControl, FormLabel, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { trackComplaint } from "../../store/actions/helpdeskActions/complaint";
import Details from "./Details";

const TrackComplaint = () => {
  const dispatch = useDispatch();
  const [complaint_number, setcomplaint_number] = useState("");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const fetchData = async () => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("complain_number", complaint_number);
    const response = await dispatch(trackComplaint(formdata));
    if (response) {
      console.log(response);
      setData(response);
      setLoading(false);
    } else {
      setLoading(false);
      setData(false);
    }
  };
  const searchComplaint = (e) => {
    e.preventDefault();
    fetchData();
  };

  return (
    <div className="container">
      <div class="container">
        <div class="row">
          <div class="card">
            <div class="myForm">
              <FormLabel>Search Complaint</FormLabel>
              <Form className="d-flex " onSubmit={searchComplaint}>
                <FormControl
                  type="search"
                  placeholder="Enter Tracking Number"
                  className="me-2"
                  aria-label="Search"
                  value={complaint_number}
                  onChange={(e) => setcomplaint_number(e.target.value)}
                  required
                />
                <Button type="submit" variant="outline-success">
                  Search
                </Button>
              </Form>
            </div>
            {loading ? (
              <div className="text-center">
                <Spinner animation="border" role="status" size="lg">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : data ? (
              <div className="">
                <Details data={data} fetchDet={fetchData} />
              </div>
            ) : data === false ? (
              "No Record Found"
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackComplaint;
