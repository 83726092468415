import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Spinner, Modal, Button } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useTimer } from "react-timer-hook";
import {
  getPracticeQuestions,
  submitAnswer,
} from "../../../store/actions/studentAction/assessmentActions";
import {
  getSubjectQuestions,
  submitExamAnswer,
} from "../../../store/actions/studentAction/examAction";

import "./css/assessment.css";
import StartExam from "./StartExam";
import MyTimer from "../../Timer";

const Exam = (props) => {
  const { student } = useSelector((state) => state);
  const [questions_count, setquestions_count] = useState("");
  const [max_marks, setmax_marks] = useState("");
  const { studentAuth } = student;
  const { profile } = studentAuth;
  const [arr, setArr] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [agree, setAgree] = useState(false);
  const [show, setShow] = useState(false);
  const [examType, setExamType] = useState("");
  const [practical, setPractical] = useState("");

  const [answerId, setAnswerid] = useState("");
  const confirm = useCallback(() => {
    setAgree(true);
  }, [agree]);
  const [questions, setQuestions] = useState(null);
  const fetch = async (id) => {
    const response = await dispatch(
      getSubjectQuestions(profile.id, profile.course.id, id)
    );
    if (response.isSuccess) {
      setQuestions(response.data);
    } else {
      history.push("/student/exams");
    }
  };
  const time = new Date();
  time.setSeconds(time.getSeconds() + questions_count * 60);

  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    if (props.location.state?.subject || props.location.state?.exam_type) {
      setquestions_count(props.location.state.questionCount);
      if (props.location.state?.exam_type) {
        fetch();
        setExamType(props.location.state.exam_type);
        setmax_marks(+props.location.state.questionCount * 10);
      } else {
        fetch(props.location.state.subject);
        if (props.location.state.practical == "0") {
          setPractical(0);
          setmax_marks(+props.location.state.questionCount * 2);
        } else {
          setPractical(1);
          setmax_marks(+props.location.state.questionCount);
        }
      }
    } else {
      history.push("/student/exams");
    }
  }, [props.location.state]);
  const filterData = (e, index) => {
    const check = arr;
    check[index] = "";
    setArr(check);
  };
  const pushData = (e, index) => {
    const check = arr;
    check[index] = e;
    setArr(check);
  };
  return (
    <>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Submit Test</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Are you sure you want to submit the test.</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => window.location.reload()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {!agree ? (
        <StartExam
          start={agree}
          setStart={confirm}
          count={questions_count}
          totalMarks={max_marks}
        />
      ) : questions ? (
        <div className="assessment">
          <br />
          <div className="time-left">
            <MyTimer expiryTimestamp={time} />
          </div>
          <br />
          <br />
          <p></p>
          <h3 style={{ fontSize: "16px" }}>
            Q{activePage}. {questions[activePage - 1]?.questions.question}
          </h3>
          <br />
          {questions[activePage - 1]?.questions.mcq.map((mcq) => (
            <>
              <div
              // value={arr[activePage - 1]}
              // onChange={(e) =>
              //   arr.includes(e.target.value)
              //     ? filterData(e.target.value, activePage - 1)
              //     : pushData(e.target.data, activePage - 1)
              // }
              ></div>
              <input
                type="radio"
                name="ans"
                value={mcq.id}
                onChange={(e) => {
                  pushData(e.target.value, activePage - 1);
                  setAnswerid(e.target.value);
                }}
                checked={arr[activePage - 1] == mcq.id ? true : false}
                required=""
                style={{ marginRight: "6px" }}
              />
              {mcq.answer}
              <br />
            </>
          ))}
          <br />
          {/* {activePage < questions_count && ( */}
          <button
            class="btn btn-info"
            type="submit"
            name="submit"
            value="Next Question"
            onClick={async () => {
              // if (activePage < 10) {
              const response = await dispatch(
                submitExamAnswer(
                  questions[activePage - 1].question_list_id,
                  answerId,
                  profile.id,
                  practical,
                  examType
                )
              );
              if (activePage < questions_count) {
                setActivePage(activePage + 1);
                setAnswerid("");
              } else {
                if (response) {
                  setShow(true);
                  // window.location.reload();
                }
              }
            }}
            // disabled={activePage == 10}
          >
            {activePage < questions_count ? "Next Question" : "Submit Test"}
          </button>
          {/* )} */}
          <p></p>
          <ReactPaginate
            className="pagination mt-5 justify-content-center"
            previousClassName="page-item"
            nextClassName="page-item"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            nextLinkClassName="page-link"
            previousLinkClassName="page-link"
            activeClassName="active"
            // marginPagesDisplayed={10}
            onPageChange={(e) => setActivePage(e.selected + 1)}
            pageCount={questions_count}
            forcePage={activePage - 1}

            // renderOnZeroPageCount={null}
          />
          <button
            className="float-right btn btn-sm btn-success"
            onClick={() => setShow(true)}
          >
            Finish Test
          </button>
        </div>
      ) : (
        // questions.map((question, index) => (
        //   <div className="assessment">
        //     <br />
        //     <div className="time-left">
        //       Time Left : <span class="blink_text">00:59</span>
        //     </div>
        //     <br />
        //     <br />

        //     <p></p>
        //     <h3 style={{ fontSize: "16px" }}>
        //       Q{index + 1}. {question.questions.question}
        //     </h3>
        //     <br />
        //     {}
        //     {question.questions.mcq.map((mcq) => (
        //       <>
        //         <input
        //           type="radio"
        //           name="ans"
        //           value="1"
        //           required=""
        //           style={{ marginRight: "6px" }}
        //         />
        //         {mcq.answer}
        //         <br />
        //       </>
        //     ))}
        //     <br />
        //     <button
        //       class="btn btn-info"
        //       type="submit"
        //       name="submit"
        //       value="Next Question"
        //       onClick={() => dispatch(submitAnswer(question.id, 493))}
        //     >
        //       Submit
        //     </button>
        //     <p></p>
        //     <ReactPaginate
        //       className="pagination mt-5 justify-content-center"
        //       previousClassName="page-item"
        //       nextClassName="page-item"
        //       pageClassName="page-item"
        //       pageLinkClassName="page-link"
        //       nextLinkClassName="page-link"
        //       previousLinkClassName="page-link"
        //       activeClassName="active"
        //       marginPagesDisplayed={2}
        //       onPageChange={setData((data) => data + 1)}
        //       pageCount={10}
        //       renderOnZeroPageCount={null}
        //     />
        //   </div>
        // ))
        <Spinner animation="border" role="status" size="lg">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
    </>
  );
};

export default Exam;
