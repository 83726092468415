import React, { useEffect, useState } from "react";
import BreadCrumb from "../../BreadCrumb";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  addCategory,
  updateCategory,
  getCategoryDetail,
  addSubCategory,
  getSubCategoryDetail,
  updateSubCategory,
  getFeedBack,
  getFeedBackDetail,
  updateFeedBack,
} from "../../../store/actions/printActions/printcategory";
const EditFeedBack = () => {
  const dispatch = useDispatch();
  const [exist, setExist] = useState(true);
  const history = useHistory();
  const { id } = useParams();
  const { categoryid } = useParams();
  console.log("id====>", id);
  const [data, setData] = useState({
    question: "",
    furst_option: "",
    third_option: "",
    fourth_option: "",
    id: "",
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (id) {
      const response = await dispatch(getFeedBackDetail(id));
      console.log("response====>", response);
      if (response) {
        setData({
          question: response.data.question,
          first_option: response.data.first_option,
          second_option: response.data.second_option,
          third_option: response.data.third_option,
          fourth_option: response.data.fourth_option,
        });
      } else {
        setExist(false);
      }
    }
  }, [id]);

  console.log("exit", exist);
  const { question, first_option, second_option, third_option, fourth_option } =
    data;
  const breadcrumbs = [
    // {
    //   path: "/admin/dashboard",
    //   label: "Dashboard",
    // },
    {
      path: "/print",
      label: "Add Category",
    },
  ];
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await dispatch(updateFeedBack({ id: id, ...data }));
    if (response) {
      history.push("/print/feedback");
    }
  };
  return (
    <div>
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
          <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/* <BreadCrumb crumbs={breadcrumbs} /> */}

            <div class="d-flex align-items-center">
              <Link
                to={"/print/feedback"}
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                View List
              </Link>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-2"></div>

            <div class="col-md-8">
              {exist ? (
                <form onSubmit={handleSubmit}>
                  <div class="form-group">
                    <label>
                      Question <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Question"
                      name="question"
                      value={question}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label>
                      Option 1 <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Option "
                      name="first_option"
                      value={first_option}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label>
                      Option 2 <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Option"
                      name="second_option"
                      value={second_option}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label>
                      Option 3 <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Option "
                      name="third_option"
                      value={third_option}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label>
                      Option 4 <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Option"
                      name="fourth_option"
                      value={fourth_option}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <button type="submit" class="btn btn-primary mr-2">
                    Submit
                  </button>
                  {/* <button type="reset" class="btn btn-secondary">
                  Cancel
                </button> */}
                </form>
              ) : (
                <h1>Record does not exist</h1>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditFeedBack;
