import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import "./css/studen-layout.css";

import { AUTH_FAIL } from "../../store/actions/actionTypes";

export default function HelpdeskLayout({ children }) {
  const dispatch = useDispatch();
  const handleLogout = () => {
    localStorage.removeItem("helpdeskToken");
    dispatch({ type: AUTH_FAIL });
  };
  const [expand, setExpand] = useState(false);

  const { helpdesk } = useSelector((state) => state);
  const { adminAuth } = helpdesk;
  const { profile } = adminAuth;

  useEffect(() => {
    // if (expand) {
    setExpand(false);
    // }
  }, [window.location.href]);

  return (
    <>
      <nav class="navbar navbar-dashboard fixed-top student-layout">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-9 col-9">
              <div class="menu">
                <div class="logo-dashboard">
                  <button class="barsicon" onClick={() => setExpand(!expand)}>
                    {expand ? (
                      <i class="fa fa-times" aria-hidden="true"></i>
                    ) : (
                      <i class="fa fa-bars"></i>
                    )}
                  </button>
                  <Link class="navbar-logo active" to="/">
                    <img src="/images/logo.png" />
                  </Link>
                  <div class="college-name m-auto">
                    <h1>Welcome : Support </h1>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-3" style={{ paddingTop: "16px" }}>
              <Button variant="primary float-right" onClick={handleLogout}>
                Logout
              </Button>
            </div>
          </div>
        </div>
      </nav>

      <div class="content-box">
        <div class={`sideBar ${expand && "d-block overflow-scroll"}`}>
          <div class="side-nav">
            <ul>
              <li>
                <Link to="/helpdesk/center" class="active">
                  <span>
                    <svg
                      width="42"
                      height="42"
                      viewBox="0 0 42 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M40.2832 18.5197C40.2823 18.5188 40.2813 18.5178 40.2804 18.5169L23.3925 1.62957C22.6726 0.909412 21.7156 0.512695 20.6976 0.512695C19.6796 0.512695 18.7225 0.909096 18.0024 1.62925L1.12331 18.508C1.11763 18.5137 1.11194 18.5197 1.10626 18.5254C-0.371955 20.0121 -0.369428 22.4243 1.11352 23.9073C1.79103 24.5851 2.68586 24.9777 3.64259 25.0188C3.68144 25.0226 3.7206 25.0245 3.76009 25.0245H4.43318V37.4525C4.43318 39.9117 6.43413 41.9127 8.89402 41.9127H15.5011C16.1707 41.9127 16.714 41.3697 16.714 40.6998V30.9563C16.714 29.834 17.6268 28.9212 18.7491 28.9212H22.6461C23.7684 28.9212 24.6812 29.834 24.6812 30.9563V40.6998C24.6812 41.3697 25.2241 41.9127 25.8941 41.9127H32.5012C34.9611 41.9127 36.962 39.9117 36.962 37.4525V25.0245H37.5861C38.6038 25.0245 39.5609 24.6281 40.2813 23.9079C41.7659 22.4224 41.7665 20.0061 40.2832 18.5197Z"
                        fill="#B2CDF0"
                      ></path>
                    </svg>
                  </span>
                  Center Complaints
                </Link>
              </li>

              <li>
                <Link to="/helpdesk/student">
                  <span>
                    <svg
                      width="42"
                      height="42"
                      viewBox="0 0 42 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0)">
                        <path
                          d="M16.474 7.25184C18.3557 7.25184 19.8812 5.7264 19.8812 3.84467C19.8812 1.96294 18.3557 0.4375 16.474 0.4375C14.5923 0.4375 13.0668 1.96294 13.0668 3.84467C13.0668 5.7264 14.5923 7.25184 16.474 7.25184Z"
                          fill="#B2CDF0"
                        ></path>
                        <path
                          d="M39.513 8.36882C39.4033 8.21616 39.1907 8.18131 39.038 8.29087L29.6593 15.0265L24.3666 15.2135C23.8397 13.7386 23.4227 12.5548 23.1236 11.6844C22.3609 9.46471 21.0818 8.33923 19.3222 8.33923C16.8527 8.33923 16.0743 8.33923 13.6256 8.33923C11.866 8.33923 10.5869 9.46471 9.82418 11.6844C9.49257 12.6493 9.25646 13.3267 8.93343 14.2336L6.81095 13.1533L5.34004 7.75316C5.31303 7.65427 5.23743 7.576 5.13943 7.54567L4.25733 7.27302L3.64466 5.02359C3.61507 4.91516 3.52725 4.83227 3.41728 4.80915C3.30732 4.78602 3.19355 4.82645 3.12279 4.91386L1.8233 6.5207L3.26131 11.8005C2.81609 11.9121 2.41924 12.207 2.19461 12.6481C1.78271 13.4573 2.10469 14.4473 2.91385 14.8592L9.10453 18.0108C9.98598 18.4595 11.0621 18.0331 11.3972 17.1028C11.7837 16.0302 12.284 14.6226 12.284 14.6226C12.284 14.6226 10.4701 24.1662 9.95056 26.851C9.84108 27.4165 10.2769 27.9391 10.8472 27.9391C11.2013 27.9391 11.6262 27.9391 12.1018 27.9391V39.8652C12.1018 40.9548 12.9851 41.8381 14.0748 41.8381C15.1645 41.8381 16.0478 40.9548 16.0478 39.8652V27.9392C16.3313 27.9392 16.616 27.9392 16.8995 27.9392V39.8653C16.8995 40.955 17.7828 41.8383 18.8725 41.8383C19.9622 41.8383 20.8455 40.955 20.8455 39.8653V27.9392C21.3212 27.9392 21.746 27.9392 22.1001 27.9392C22.6734 27.9392 23.1062 27.4161 22.9967 26.8512C22.0263 21.8291 20.6615 14.6082 20.6615 14.6082C20.6615 14.6082 21.2898 16.3783 21.6763 17.4548C21.9169 18.1251 22.5634 18.5674 23.2818 18.5424L29.8661 18.3097C30.7736 18.2776 31.4833 17.516 31.4512 16.6085C31.4408 16.3159 31.354 16.0445 31.2118 15.8112L39.4566 8.82721C39.5899 8.71344 39.617 8.51372 39.513 8.36882Z"
                          fill="#B2CDF0"
                        ></path>
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect
                            width="41.4"
                            height="41.4"
                            fill="white"
                            transform="translate(0 0.4375)"
                          ></rect>
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  Student Complaints
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {children}
      </div>
    </>
  );
}
