import { getDataAPI, postDataAPI } from "../../../services/apiWrapper";
import { exportToCSV } from "../../../utils/exportCsv";
import { ALERT, SET_TRADE, SET_CENTER_LIST } from "../actionTypes";

export const getCenterList = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(getDataAPI("admin/center/list", token));
    dispatch({ type: SET_CENTER_LIST, payload: response.data.data });
    return {
      success: true,
      data: response.data.data,
    };
  } catch (error) {
    dispatch({ type: ALERT, payload: { error: error.response.data.status } });
    return {
      success: false,
    };
  }
};

export const getCenterPaginate = (page, sub_trade_id) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    const params = `${
      sub_trade_id ? `&subtrade=${sub_trade_id}` : ""
    }&page=${page}
  `;

    const response = await dispatch(
      getDataAPI(`admin/center/list?${params}`, token)
    );
    return response.data.data;
  } catch (error) {
    dispatch({ type: ALERT, payload: { error: error.response.data.status } });
    return false;
  }
};

export const downloadCenterList = (sub_trade_id) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    const params = `${sub_trade_id ? `&subtrade=${sub_trade_id}` : ""}
  `;

    const response = await dispatch(
      getDataAPI(`admin/center/list?${params}`, token)
    );
    const dataToExport = [];
    response.data.data.forEach((center, index) => {
      dataToExport.push({
        Sno: index + 1,
        "Center Name": center.center_name,
        Address: center.center_address,
        City: center.center_city,
        State: center.center_state,
        "Contact No": center.center_mobile,
        Email: center.email,
        Trade: center.trade?.name || "",

        "Md Name": center.md_name,
        Status:
          center.status == "0"
            ? "Pending"
            : center.status == "1"
            ? "Approved"
            : center.status == "3"
            ? "Need Reapprove"
            : "Rejected",
      });
    });
    exportToCSV(dataToExport, "Center List");
  } catch (error) {
    dispatch({ type: ALERT, payload: { error: error.response.data.status } });
    return false;
  }
};

export const getCenterDetail = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      getDataAPI(`admin/center/details/${id}`, token)
    );
    return {
      success: true,
      details: response.data.data,
    };
  } catch (error) {
    dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    return {
      success: false,
    };
  }
};

export const approveCenter = (data, id) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(
      postDataAPI("admin/center/approwed", data, token)
    );
    // console.log("approved", response);
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });
    // dispatch(getCenterDetail(id));
    return true;
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: { loading: false, error: error.response.data.message },
    });
    return false;
  }
};
export const rejectCenter = (data, id) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(
      postDataAPI("admin/center/reject", data, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });
    // dispatch(getCenterDetail(id));
    return true;
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: { loading: false, error: error.response.data.message },
    });
    return false;
  }
};
