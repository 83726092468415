import { getDataAPI, postDataAPI } from "../../../services/apiWrapper";
import { exportToCSV } from "../../../utils/exportCsv";
import { ALERT, SET_STUDENTS } from "../actionTypes";

export const addStudent = (data) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("centerToken");
    const response = await dispatch(
      postDataAPI("center/student/create", data, token)
    );
    dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({ type: ALERT, payload: { success: "Student add successfully" } });
    if (response) {
      return response.data.data;
    }
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({ type: ALERT, payload: { error: error.response.message } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};

export const updateStudent = (data, id) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      postDataAPI(`admin/student/update/${id}`, data, token)
    );
    dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({
      type: ALERT,
      payload: { success: "Student update successfully" },
    });
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({ type: ALERT, payload: { error: error.response.message } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
  }
};

export const getStudent = (id) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      getDataAPI(`admin/student/get/${id}`, token)
    );
    dispatch({ type: ALERT, payload: { loading: false } });
    return { ...response.data.data, isSuccess: true };
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({ type: ALERT, payload: { error: error.response.message } });
    return { isSuccess: false };
  }
};

export const getStudents = (token, page, search, date) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    console.log(search, date);
    // const token = localStorage.getItem("centerToken");
    const params = `page=${page}${search ? `&search=${search}` : ""}${
      date ? `&date=${date}` : ""
    }
  `;
    const response = await dispatch(
      getDataAPI(`center/student/list?${params}`, token)
    );
    dispatch({ type: SET_STUDENTS, payload: response.data.data });
    dispatch({ type: ALERT, payload: { loading: false } });
    return response;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    // dispatch({ type: ALERT, payload: { error: error.response.message } });
  }
};

export const downloadStudentList =
  (token, search, date) => async (dispatch) => {
    try {
      const params = `${search ? `&search=${search}` : ""}${
        date ? `&date=${date}` : ""
      }`;
      const response = await dispatch(
        getDataAPI(`center/student/list?${params}`, token)
      );

      const dataToExport = [];
      response.data.data.forEach((ele, index) => {
        dataToExport.push({
          Sno: index + 1,
          "Student Name": ele.name,
          "Roll No": ele.roll_number,
          Course: ele.course?.name,
          Mobile: ele.student_details?.mobile,
          "Reg. Dat": ele.register_date || "",
          Fee: "Paid",
          Aadhar: ele.aadhar,
        });
      });
      exportToCSV(dataToExport, "Students");

      return response;
    } catch (error) {
      // dispatch({ type: ALERT, payload: { error: error.response.message } });
    }
  };

export const getStudentsAdmin = (page) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      getDataAPI(`admin/student/list?page=${page}`, token)
    );
    dispatch({ type: SET_STUDENTS, payload: response.data.data });
    dispatch({ type: ALERT, payload: { loading: false } });
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({ type: ALERT, payload: { error: error.response.message } });
  }
};

export const uploadMarksheet = (data) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      postDataAPI("center/student/marksheet", data, token)
    );
    dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({
      type: ALERT,
      payload: { success: "Marksheet upload successfully" },
    });
    //const token = localStorage.getItem("adminToken");

    //useEffect(() => {
    dispatch(getStudents(token));
    //}, []);
    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({ type: ALERT, payload: { error: error.response.message } });
    return false;
  }
};

export const uploadCertificate = (data) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      postDataAPI("center/student/certificate", data, token)
    );
    dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({
      type: ALERT,
      payload: { success: "Certificate upload successfully" },
    });
    dispatch(getStudents(token));
    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({ type: ALERT, payload: { error: error.response.message } });
    return false;
  }
};
