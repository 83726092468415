import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import CKEditor from "react-ckeditor-component";
import { addActivity } from "../../../store/actions/adminActions/activityActions";
import BreadCrumb from "../../BreadCrumb";

const AddActivity = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [exist, setExist] = useState(true);
  const breadcrumbs = [
    {
      path: "/admin/dashboard",
      label: "Dashboard",
    },
    {
      label: id ? "Edit Activity" : "Add Activity",
    },
  ];
  const [data, setData] = useState({
    title: "",
  });
  const [description, setdescription] = useState("");
  const { title } = data;
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const onChange = (evt) => {
    var newContent = evt.editor.getData();
    setdescription(newContent);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await dispatch(addActivity(data));
    if (response) {
      history.push("/admin/view-activity-list");
    }
  };

  const onBlur = (evt) => {};

  const afterPaste = (evt) => {};

  return (
    <div>
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
          <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <BreadCrumb crumbs={breadcrumbs} />

            <div class="d-flex align-items-center">
              <Link
                to="/admin/view-activity-list"
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                View Activity
              </Link>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div className="col-md-2"></div>
            <div class="col-md-8">
              <form onSubmit={handleSubmit}>
                {/* <div class="row">
                  <div class="col-md-4"> */}
                <div class="form-group">
                  <label>
                    Title <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="title"
                    value={title}
                    onChange={handleChange}
                    class="form-control"
                    required
                  />
                </div>
                {/* </div> */}

                {/* <div class="col-md-4"> */}
                <div class="form-group">
                  <label>
                    Description <span class="text-danger">*</span>
                  </label>
                  <CKEditor
                    activeClass="p10"
                    content={description}
                    events={{
                      blur: onBlur,
                      afterPaste: afterPaste,
                      change: onChange,
                    }}
                  />
                </div>
                {/* </div>
                </div> */}

                <div>
                  <button type="submit" class="btn btn-primary mr-2">
                    Submit
                  </button>
                  <button type="reset" class="btn btn-secondary">
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="col-md-2"></div>
        </div>
      </div>
    </div>
  );
};

export default AddActivity;
