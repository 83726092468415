import { getDataAPI, postDataAPI } from "../../../services/apiWrapper";
import {
  ALERT,
  MEMBER_AUTH_FAIL,
  MEMBER_AUTH_SUCCESS,
  CENTER_REGISTER_DETAILS,
} from "../actionTypes";


export const memberLogin = (data) => async (dispatch) => {
    try {
      dispatch({
        type: ALERT,
        payload: { loading: true },
      });
      const response = await dispatch(postDataAPI("member/login", data));
  
      localStorage.setItem("memberToken", response.data.data.token);
      dispatch({
        type: MEMBER_AUTH_SUCCESS,
        payload: response.data.data,
      });
      dispatch({
        type: ALERT,
        payload: { loading: false, success: response.data.message },
      });
    } catch (error) {
      dispatch({
        type: ALERT,
        payload: { loading: false, error: error.response.data.message },
      });
      dispatch({
        type: MEMBER_AUTH_FAIL,
      });
    }
  };
  
  export const memberDashboard = () => async (dispatch) => {
    try {
      const token = localStorage.getItem("memberToken");
      dispatch({
        type: ALERT,
        payload: { loading: true },
      });
      const response = await dispatch(getDataAPI("member/dashboard", token));
      dispatch({
        type: MEMBER_AUTH_SUCCESS,
        payload: response.data.data,
      });
      dispatch({
        type: ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      dispatch({
        type: ALERT,
        payload: { loading: false },
      });
      dispatch({
        type: MEMBER_AUTH_FAIL,
      });
    }
  };


  export const memberchangePassword = (data) => async (dispatch) => {
    try {
      const token = localStorage.getItem("memberToken");
      dispatch({
        type: ALERT,
        payload: { loading: true },
      });
      const response = await dispatch(
        postDataAPI("member/change-password", data, token)
      );
      dispatch({
        type: ALERT,
        payload: { success: response.data.message },
      });
      dispatch({
        type: ALERT,
        payload: { loading: false },
      });
      return true;
    } catch (error) {
      dispatch({
        type: ALERT,
        payload: { loading: false },
      });
      dispatch({
        type: ALERT,
        payload: { loading: false, error: error.response.data.message },
      });
      return false;
    }
  };