import React from "react";
import ReactPaginate from "react-paginate";

const Pagination = ({ page, setActivePage, totalPages }) => {
  return (
    <ReactPaginate
      className="pagination mt-5 justify-content-center"
      previousClassName="page-item"
      nextClassName="page-item"
      pageClassName="page-item"
      pageLinkClassName="page-link"
      nextLinkClassName="page-link"
      previousLinkClassName="page-link"
      activeClassName="active"
      //   marginPagesDisplayed={10}
      onPageChange={(e) => setActivePage(e.selected + 1)}
      pageCount={totalPages}
      // renderOnZeroPageCount={null}
    />
  );
};

export default React.memo(Pagination);
