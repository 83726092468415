import { ALERT, REMOVE_ALERT } from "../actions/actionTypes";

export const alertReducer = (state = {}, action) => {
  switch (action.type) {
    case ALERT:
      return { ...state, ...action.payload };
    case REMOVE_ALERT:
      return {};
    default:
      return state;
  }
};
