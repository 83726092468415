import React from "react";
import "./css/certificate.css";

export default function Certificates() {
  return (
    <>
      <div class="Title-box">
        <div class="row">
          <div class="col-md-6">
            <div class="BigTitle">My Students</div>
          </div>
          <div class="col-md-6">
            <div class="CommanForm d-flex"></div>
          </div>
        </div>
      </div>{" "}
      <div class="mid-content-box">
        <div class="Widthdata">
          <div class="Applicationdata s">
            <div class="row ">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Sr No. </th>
                      <th> Student Name </th>
                      <th>RollNo </th>
                      <th>Course </th>

                      <th> Documents </th>
                    </tr>
                  </thead>
                  {/* <tbody>
                    <tr>
                      <td>1</td>
                      <td>Mateen Ahmed</td>
                      <td>2021087</td>
                      <td>Diploma In Rural &amp; Urban Health Care (DRUHC)</td>

                      <td>
                        <a href="/center/certificates/19">Certificates</a>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>KM ALQAMAR</td>
                      <td>20200206</td>
                      <td>
                        Community Medical Service &amp; Essential Drugs
                        (CMS&amp;ED)
                      </td>

                      <td>
                        <a href="/center/certificates/22">Certificates</a>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Mohd Shamoon</td>
                      <td>20200209</td>
                      <td>
                        Community Medical Service &amp; Essential Drugs
                        (CMS&amp;ED)
                      </td>

                      <td>
                        <a href="/center/certificates/23">Certificates</a>
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Afsar Khan</td>
                      <td>20200207</td>
                      <td>
                        Community Medical Service &amp; Essential Drugs
                        (CMS&amp;ED)
                      </td>

                      <td>
                        <a href="/center/certificates/24">Certificates</a>
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Salman</td>
                      <td>20200811</td>
                      <td>
                        Community Medical Service &amp; Essential Drugs
                        (CMS&amp;ED)
                      </td>

                      <td>
                        <a href="/center/certificates/25">Certificates</a>
                      </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>SHAKIR ALI</td>
                      <td>2021088</td>
                      <td>Diploma In Rural &amp; Urban Health Care (DRUHC)</td>

                      <td>
                        <a href="/center/certificates/26">Certificates</a>
                      </td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Haider</td>
                      <td>2020083</td>
                      <td>Diploma In Rural &amp; Urban Health Care (DRUHC)</td>

                      <td>
                        <a href="/center/certificates/27">Certificates</a>
                      </td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>ZAHID TYAGI</td>
                      <td>2020042</td>
                      <td>Diploma In Rural &amp; Urban Health Care (DRUHC)</td>

                      <td>
                        <a href="/center/certificates/28">Certificates</a>
                      </td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>KAMAL SINGH</td>
                      <td>2018082</td>
                      <td>
                        Community Medical Service &amp; Essential Drugs
                        (CMS&amp;ED)
                      </td>

                      <td>
                        <a href="/center/certificates/29">Certificates</a>
                      </td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>Prem Chand</td>
                      <td>2018081</td>
                      <td>
                        Community Medical Service &amp; Essential Drugs
                        (CMS&amp;ED)
                      </td>

                      <td>
                        <a href="/center/certificates/30">Certificates</a>
                      </td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>BANTY SAINI</td>
                      <td>2016081</td>
                      <td>
                        Community Medical Service &amp; Essential Drugs
                        (CMS&amp;ED)
                      </td>

                      <td>
                        <a href="/center/certificates/31">Certificates</a>
                      </td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>MOHAMMAD SAMEER</td>
                      <td>2019122</td>
                      <td>
                        Community Medical Service &amp; Essential Drugs
                        (CMS&amp;ED)
                      </td>

                      <td>
                        <a href="/center/certificates/32">Certificates</a>
                      </td>
                    </tr>
                    <tr>
                      <td>13</td>
                      <td>ASHISH</td>
                      <td>2020081</td>
                      <td>Diploma In Rural &amp; Urban Health Care (DRUHC)</td>

                      <td>
                        <a href="/center/certificates/33">Certificates</a>
                      </td>
                    </tr>
                    <tr>
                      <td>14</td>
                      <td>MOH FAIJAN</td>
                      <td>2019121</td>
                      <td>
                        Community Medical Service &amp; Essential Drugs
                        (CMS&amp;ED)
                      </td>

                      <td>
                        <a href="/center/certificates/34">Certificates</a>
                      </td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>YOGESH</td>
                      <td>2020082</td>
                      <td>Diploma In Rural &amp; Urban Health Care (DRUHC)</td>

                      <td>
                        <a href="/center/certificates/35">Certificates</a>
                      </td>
                    </tr>
                    <tr>
                      <td>16</td>
                      <td>MOHD SHAHVEZ</td>
                      <td>2020041</td>
                      <td>Diploma in Medical Laboratory Technology (DMLT)</td>

                      <td>
                        <a href="/center/certificates/37">Certificates</a>
                      </td>
                    </tr>
                    <tr>
                      <td>17</td>
                      <td>YOGESH KUMAR</td>
                      <td>2016021</td>
                      <td>Diploma in Medical Laboratory Technology (DMLT)</td>

                      <td>
                        <a href="/center/certificates/41">Certificates</a>
                      </td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>INTJAR</td>
                      <td>2020022</td>
                      <td>
                        Community Medical Service &amp; Essential Drugs
                        (CMS&amp;ED)
                      </td>

                      <td>
                        <a href="/center/certificates/42">Certificates</a>
                      </td>
                    </tr>
                    <tr>
                      <td>19</td>
                      <td>PANKAJ PAL</td>
                      <td>2019041</td>
                      <td>Diploma In Dental Hygienist (DDH)</td>

                      <td>
                        <a href="/center/certificates/43">Certificates</a>
                      </td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>Nasir Khan</td>
                      <td>2020031</td>
                      <td>
                        Community Medical Service &amp; Essential Drugs
                        (CMS&amp;ED)
                      </td>

                      <td>
                        <a href="/center/certificates/44">Certificates</a>
                      </td>
                    </tr>
                    <tr>
                      <td>21</td>
                      <td>SHADAB KHAN</td>
                      <td>2020011</td>
                      <td>
                        Community Medical Service &amp; Essential Drugs
                        (CMS&amp;ED)
                      </td>

                      <td>
                        <a href="/center/certificates/47">Certificates</a>
                      </td>
                    </tr>
                    <tr>
                      <td>22</td>
                      <td> IMTIYAZ </td>
                      <td>2020012</td>
                      <td>
                        Community Medical Service &amp; Essential Drugs
                        (CMS&amp;ED)
                      </td>

                      <td>
                        <a href="/center/certificates/48">Certificates</a>
                      </td>
                    </tr>
                    <tr>
                      <td>23</td>
                      <td> AAYSHA</td>
                      <td>2020013</td>
                      <td>
                        Community Medical Service &amp; Essential Drugs
                        (CMS&amp;ED)
                      </td>

                      <td>
                        <a href="/center/certificates/49">Certificates</a>
                      </td>
                    </tr>
                    <tr>
                      <td>24</td>
                      <td>ABDUL QADIR</td>
                      <td>2020014</td>
                      <td>
                        Community Medical Service &amp; Essential Drugs
                        (CMS&amp;ED)
                      </td>

                      <td>
                        <a href="/center/certificates/57">Certificates</a>
                      </td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>SARTAJ ALI </td>
                      <td>2020026</td>
                      <td>
                        Community Medical Service &amp; Essential Drugs
                        (CMS&amp;ED)
                      </td>

                      <td>
                        <a href="/center/certificates/60">Certificates</a>
                      </td>
                    </tr>
                    <tr>
                      <td>26</td>
                      <td>VIRENDER KUMAR </td>
                      <td>2020027</td>
                      <td>
                        Community Medical Service &amp; Essential Drugs
                        (CMS&amp;ED)
                      </td>

                      <td>
                        <a href="/center/certificates/61">Certificates</a>
                      </td>
                    </tr>
                    <tr>
                      <td>27</td>
                      <td>MINTU KUMAR</td>
                      <td>2020028</td>
                      <td>
                        Community Medical Service &amp; Essential Drugs
                        (CMS&amp;ED)
                      </td>

                      <td>
                        <a href="/center/certificates/62">Certificates</a>
                      </td>
                    </tr>
                    <tr>
                      <td>28</td>
                      <td>Merajudeen</td>
                      <td>2019064</td>
                      <td>
                        Diploma in Naturopathy &amp; Yogic Sciences (DNYS)
                      </td>

                      <td>
                        <a href="/center/certificates/63">Certificates</a>
                      </td>
                    </tr>
                  </tbody> */}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
