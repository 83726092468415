import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getMembershipIndividual,
  getMembershipOther,
  updateMembershipIndividual,
  updateMembershipOther,
} from "../../../store/actions/printActions/printcategory";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";

function EditOther() {
  const dispatch = useDispatch();
  const [exist, setExist] = useState(true);
  const history = useHistory();
  const { id } = useParams();
  const { categoryid } = useParams();
  const [document_pic, setdocument_pic] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [document_picpreview, setdocument_picpreview] = useState(null);
  const [anyragistration_pic, setanyragistration_pic] = useState([]);
  const [anyragistration_picpreview, setanyragistration_picpreview] =
    useState(null);
  const [data, setData] = useState({
    achievements: "",
    city: "",
    contact_person_name: "",
    contact_person_number: "",
    correspondence_address: "",
    directors_name: "",
    document_file: "",
    document_number: "",
    document_type: "",
    email: "",
    location: "",
    membership_type: "",
    mobile: "",
    name: "",

    pincode: "",
    ragistration_certificate: "",
    registered_address: "",
    registration_year: "",
    service_information: "",
    services_location: "",
    state: "",
    status: 0,
    total_employees: "",
    is_accepted: 0,
    website: "",
    why_choose_npvec_member: "",
  });

  useEffect(async () => {
    if (id) {
      const response = await dispatch(getMembershipOther(id));
      console.log("response====>", response);
      if (response.success) {
        setData({
          achievements: response.data.achievements,
          city: response.data.city,
          contact_person_name: response.data.contact_person_name,
          contact_person_number: response.data.contact_person_number,
          correspondence_address: response.data.correspondence_address,
          directors_name: response.data.directors_name,
          document_file: response.data.document_file,
          document_number: response.data.document_number,
          document_type: response.data.document_type,
          email: response.data.email,
          location: response.data.location,
          membership_type: response.data.membership_type,
          mobile: response.data.mobile,
          name: response.data.name,
          is_accepted: response.data.is_accepted,
          pincode: response.data.pincode,
          ragistration_certificate: response.data.ragistration_certificate,
          registered_address: response.data.registered_address,
          registration_year: response.data.registration_year,
          service_information: response.data.service_information,
          services_location: response.data.service_information,
          state: response.data.state,
          status: response.data.status,
          total_employees: response.data.total_employees,

          website: response.data.website,
          why_choose_npvec_member: response.data.why_choose_npvec_member,
        });
      } else {
        setExist(false);
      }
    }
  }, [id]);
  const {
    achievements,
    city,
    contact_person_name,
    contact_person_number,
    correspondence_address,
    directors_name,
    document_file,
    document_number,
    document_type,
    email,
    location,
    membership_type,
    mobile,
    name,

    pincode,
    ragistration_certificate,
    registered_address,
    registration_year,
    service_information,
    services_location,
    state,
    status,
    total_employees,
    is_accepted,
    website,
    why_choose_npvec_member,
  } = data;

  const handleChange = (e) => {
    setData({
      ...data,
      member_id: id,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("data", data);
    var formdata = new FormData();

    for (let [key, value] of Object.entries(data)) {
      if (Array.isArray(value)) {
        formdata.append(key, JSON.stringify(value));
      } else {
        formdata.append(key, value);
      }
    }
    formdata.append("document_file", document_pic);
    formdata.append("ragistration_certificate", anyragistration_pic);

    formdata.append("member_id", id);

    setSubmitting(true);
    const response = await dispatch(updateMembershipOther(id, formdata));
    if (response) {
      setData(data);
      //   setfacilityDetail([]);
      //   setStaffDetail([]);
      setSubmitting(false);
    } else {
      setSubmitting(false);
    }

    // dispatch(centerRegisterAction(formdata));
  };

  return (
    <div>
      <div class="lists-box">
        <div className="d-flex align-items-center mb-3">
          <Link
            to={"/print/membership/others"}
            class="btn btn-light-primary font-weight-bolder btn-sm"
          >
            View List
          </Link>
        </div>
        <div class="container">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <>
                    <div className="col-md-12">
                      <div className="formTitle"> Details</div>
                    </div>

                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Memebrship Type</p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="membership_type"
                          value={membership_type}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      {/* <div className="boxlabel">
                          <p>SubCategory</p>
                          <select
                            className="form-control"
                            name="subcategory"
                            value={subcategory}
                            onChange={handleChange}
                          > */}
                      {/* {sublist?.map((ele) => {
                              return (
                                <>
                                  <option key="" value="">
                                    Select subcategory
                                  </option>
                                  <option key={ele.id} value={ele?.id}>
                                    {ele?.name}
                                  </option>
                                </>
                              );
                            })} */}
                      {/* </select>
                        </div> */}
                    </div>
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Name</p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="name"
                          value={name}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Provide brief information about your services*</p>
                        <textarea
                          rows="4"
                          cols="50"
                          className="form-control form-control-sm"
                          name="service_information"
                          value={service_information}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p> Person Name</p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="contact_person_name"
                          value={contact_person_name}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p> Person Number</p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="contact_person_number"
                          value={contact_person_number}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Major Achievements / Projects</p>
                        <textarea
                          type="textArea"
                          rows="4"
                          cols="10"
                          className="form-control form-control-sm"
                          name="achievements"
                          value={achievements}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="formTitle"> Address</div>
                    </div>
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>state</p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="state"
                          value={state}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>City </p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="city"
                          value={city}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>location</p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="location"
                          value={location}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p> Pincode</p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="pincode"
                          value={pincode}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="formTitle">Contact Detail</div>
                    </div>
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Mobile Number</p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="mobile"
                          value={mobile}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Email</p>
                        <input
                          type="email"
                          className="form-control form-control-sm"
                          name="email"
                          value={email}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="formTitle">Other Detail</div>
                    </div>

                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Document Type</p>
                        <input
                          className="form-control form-control-sm"
                          name="document_type"
                          value={document_type}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p> GST No./PAN No/TAN No./CIN No</p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="document_number"
                          value={document_number}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>website</p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="website"
                          value={website}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Address of Correspondence</p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="correspondence_address"
                          value={correspondence_address}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Registered Address </p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="registered_address"
                          value={registered_address}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Location where you are providing your Services</p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="services_location"
                          value={services_location}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Name of Directors/ Partners*</p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="directors_name"
                          value={directors_name}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Year of Registration</p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="registration_year"
                          value={registration_year}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Number of Employees</p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="total_employees"
                          value={total_employees}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </>
                  <>
                    <div className="col-md-12">
                      <div className="formTitle">Document Details</div>
                    </div>

                    <div className="col-md-4 col-12">
                      <div className="Fileboxbale">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="customFiler2"
                            name="ragistration_certificate"
                            onChange={(e) => {
                              setanyragistration_pic(e.target.files[0]);
                              setanyragistration_picpreview(
                                URL.createObjectURL(e.target.files[0])
                              );
                            }}
                          />
                          <label className="custom-file2" for="customFiler2">
                            {anyragistration_picpreview ? (
                              <img
                                src={anyragistration_picpreview}
                                height={"100px"}
                                id="frontphoto"
                              />
                            ) : (
                              <>
                                <img
                                  src={ragistration_certificate}
                                  height={"100px"}
                                ></img>
                                <p>drag n drop photo here</p>
                              </>
                            )}
                            <h4>Upload Any Registration Certificate</h4>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-12">
                      <div className="Fileboxbale">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="customFiler3"
                            name="document_file"
                            onChange={(e) => {
                              setdocument_pic(e.target.files[0]);
                              setdocument_picpreview(
                                URL.createObjectURL(e.target.files[0])
                              );
                            }}
                          />
                          <label className="custom-file2" for="customFiler3">
                            {document_picpreview ? (
                              <img
                                src={document_picpreview}
                                height={"100px"}
                                id="frontphoto"
                              />
                            ) : (
                              <>
                                <img src={document_file} height={"100px"}></img>
                                <p>drag n drop photo here</p>
                              </>
                            )}
                            <h4> Upload GST No./PAN No/TAN No./CIN No</h4>
                          </label>
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </div>
              <div className="col-md-6">
                <div className="boxlabel">
                  <p>Why you want to be a NPVEC member ? </p>
                  <textarea
                    type="textArea"
                    rows="4"
                    cols="10"
                    className="form-control form-control-sm"
                    name="why_choose_npvec_member"
                    value={why_choose_npvec_member}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div
                  className="btn-groups mt-4 text-center"
                  role="group"
                  aria-label="First group"
                >
                  <button
                    className="btn btn-Next"
                    type="submit"
                    //   disabled={secondsubmitting}
                  >
                    Submit
                    <span>
                      <svg
                        width="35"
                        height="12"
                        viewBox="0 0 35 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M34.8403 6.00003C34.8403 6.2151 34.7582 6.43013 34.5943 6.5941L29.4346 11.7538C29.1063 12.0821 28.5742 12.0821 28.2461 11.7538C27.918 11.4257 27.918 10.8937 28.2461 10.5654L32.8117 6.00003L28.2462 1.43461C27.9181 1.10639 27.9181 0.574383 28.2462 0.246316C28.5743 -0.0820704 29.1065 -0.0820704 29.4347 0.246316L34.5945 5.40596C34.7584 5.57001 34.8403 5.78505 34.8403 6.00003Z"
                          fill="white"
                        ></path>
                        <line
                          x1="33.25"
                          y1="5.94995"
                          x2="0.75"
                          y2="5.94995"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                        ></line>
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditOther;
