import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Alert/Loader";
import { deleteSUbCategory, getCategory, getSubCategory } from "../../../store/actions/printActions/printcategory";
import BreadCrumb from "../../BreadCrumb";

import { toast } from "react-toastify";

const PrintSubCategory = () => {
  const dispatch = useDispatch();
  const { admin } = useSelector((state) => state);
  const { adminAuth } = admin;
  const token = adminAuth?.profile?.admin?.token
  console.log('adminAuth', token)
  const [list, setList] = useState(null);
  useEffect(async () => {
    const response = await dispatch(getSubCategory());
    console.log('response', response)
    setList(response);
  }, []);

  const handleDelete = async (id) => {
    // Optimistically remove item from UI
    const updatedList = list.filter(item => item.id !== id);
    setList(updatedList);
    
    // Make delete request
    const success = await dispatch(deleteSUbCategory(id, token));
 
    toast.success("SubCategory deleted successfully!");
    // If delete operation failed, revert UI change
    if (!success) {
      setList(list);
    }
  };
  return (
    <div>
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
          <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/* <BreadCrumb  /> */}
            {/* <div class="d-flex align-items-center">
              <Link
                to={"/print/add-subcategory"}
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                Add body
              </Link>
            </div> */}
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-12">
            <table
              class="table table-separate table-head-custom table-checkable"
              id="kt_datatable"
            >
              <thead>
                <tr>
                  <th>Sr.No</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              {list ? (
                list.length > 0 ? (
                  list.map((element, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{element.name}</td>
                      <td>{element.description}</td>
                      <td>
                        <Link to={`/print/edit-subCategory/${element.category_id}/${element.id}`}>Edit</Link>
                      </td>
                      <td ><button onClick={() => handleDelete(element.id)}>Delete</button></td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6}>{"No Record Found"}</td>
                  </tr>
                )
              ) : (
                // <Loader active={true} />""
                ""
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintSubCategory;
