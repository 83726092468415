import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

function PrivatehelpDesk({ component: Component, roles, ...rest }) {
  const { helpdesk } = useSelector((state) => state);
  const { loading, isAuth } = helpdesk.adminAuth;
  return (
    <Route
      {...rest}
      render={(props) =>
        !loading && isAuth ? (
          <Component {...rest} {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/helpdesk/login",
              state: { from: props.location },
            }}
          ></Redirect>
        )
      }
    />
  );
}

export default PrivatehelpDesk;
