import { getDataAPI, postDataAPI } from "../../../services/apiWrapper";
import { ALERT, DATE_SHEET, EXAM_QUESTIONS } from "../actionTypes";

export const getScheduledExams = (is_update) => async (dispatch) => {
  try {
    const token = localStorage.getItem("studentToken");
    const response = await dispatch(
      getDataAPI(
        is_update == 0
          ? "student/schedule-exam"
          : "student/seperate-schedule-exam",
        token
      )
    );
    dispatch({ type: DATE_SHEET, payload: response.data.data || null });
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: { error: error.response?.data?.message },
    });
    dispatch({ type: DATE_SHEET, payload: false });
  }
};

export const getSubjectQuestions =
  (studentId, courseId, subjectId) => async (dispatch) => {
    try {
      const token = localStorage.getItem("studentToken");
      const response = await dispatch(
        subjectId
          ? getDataAPI(
              `student/exam-question/${studentId}/${courseId}/${subjectId}`,
              token
            )
          : getDataAPI(`student/exam-question/${studentId}/${courseId}`, token)
      );
      dispatch({
        type: EXAM_QUESTIONS,
        payload: response.data.data,
      });
      return {
        isSuccess: true,
        data: response.data.data,
      };
    } catch (error) {
      dispatch({
        type: ALERT,
        payload: { error: error.response.data?.message || error.message },
      });
      return {
        isSuccess: false,
      };
    }
  };

export const submitExamAnswer =
  (questionId, answerId, id, practical, exam_type) => async (dispatch) => {
    try {
      const token = localStorage.getItem("studentToken");
      const response = await dispatch(
        postDataAPI(
          `student/submit-examanswer`,
          {
            id: id,
            question_id: questionId,
            answer: answerId,
            course_type: exam_type,
            practical,
          },
          token
        )
      );
      if (response) {
        return true;
      }
    } catch (error) {
      dispatch({
        type: ALERT,
        payload: { error: error.message || "Something Went Wrong" },
      });
    }
  };
