import React from "react";
import { Link } from "react-router-dom";
import BreadCrumb from "../../BreadCrumb";

const EnquiryList = () => {
  const breadcrumbs = [
    {
      path: "/center/dashboard",
      label: "Dashboard",
    },
    {
      label: "View Enquiry List",
    },
  ];
  return (
    <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
      <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
        <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <BreadCrumb crumbs={breadcrumbs} />
          <div class="d-flex align-items-center">
            {/* <Link
              to="/center/add-activity"
              class="btn btn-light-primary font-weight-bolder btn-sm"
            >
              Add Activity
            </Link> */}
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-12">
            <table
              class="table table-separate table-head-custom table-checkable"
              id="kt_datatable"
            >
              <thead>
                <tr>
                  <th>Sr.No</th>
                  <th>Name</th>
                  <th>Contact</th>
                  <th>Query For</th>
                  <th>Message</th>

                  <th>Status</th>

                  <th>Actions</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnquiryList;
