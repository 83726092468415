import React, { useState } from "react";
import {
  Button,
  Form,
  FormControl,
  Spinner,
  Table,
  Accordion,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { addMarks } from "../../../store/actions/councilActions/addMarks";
import UploadExamDate from "./ResultDate";
import UploadMarks from "./UploadMarks";

const AddMarks = () => {
  const dispatch = useDispatch();
  const [upload_marks, setupload_marks] = useState(false);
  const [uploadDate, setUploadDate] = useState(false);
  const [uploadDates, setUploadDates] = useState({});
  const [resultDates, setResultDates] = useState({});
  const [resultDate, setResultDate] = useState("");
  const [roll_number, setroll_number] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [marksUploadData, setMarksUploadData] = useState({
    studentId: "",
    subId: "",
    courseId: "",
  });

  const [uploadDateData, setUploadDateData] = useState({
    student_id: "",
    course_id: "",
    semester: "",
  });
  const searchStudent = (e) => {
    e.preventDefault();
    setLoading(true);
    search();
  };

  const search = async (data) => {
    setResultDate(data);
    const response = await dispatch(addMarks({ roll_number: roll_number }));
    if (response) {
      setLoading(false);
      setData(response.data);
    } else {
      setLoading(false);
      setData(false);
    }
  };
  console.log("data========", data);
  // This is a hypothetical function that sets the upload date for a semester
  const updateUploadDateForSemester = (semester, date) => {
    setUploadDates((prevDates) => {
      const formattedDate = formatDate(date);
      return {
        ...prevDates,
        [semester]: formattedDate,
      };
    });
    console.log("uploadDates[semster]", uploadDates[semester]);
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed, add 1 to get the correct month
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`; // Formats the date as "YYYY-MM-DD"
  }

  const updateResultDateForSemester = (semester, date) => {
    setResultDates((prevDates) => ({
      ...prevDates,
      [semester]: date,
    }));
  };

  console.log("result_date", resultDate);

  const getMaxMarks = (practical) => {
    if (practical == 1) {
      return 50;
    } else if (practical == 0) {
      return 100;
    }
    return "NA";
  };

  const convertArrayToObject = (array, key) => {
    const hs = new Map();
    array.map((element) => {
      if (hs.get(element.semester)) {
        hs.set(element.semester, [...hs.get(element.semester), element]);
      } else {
        hs.set(element?.semester, [element]);
      }
    });
    return Object.fromEntries(hs);
  };

  let groupedBySemester = {};

  if (data) {
    groupedBySemester = data.subject.reduce((group, item) => {
      group[item.semester] = group[item.semester] || [];
      group[item.semester].push(item);

      return group;
    }, {});
    console.log("groupedBySemester ", groupedBySemester);
  }

  return (
    <div class="Title-box">
      {upload_marks && (
        <UploadMarks
          show={upload_marks}
          onHide={() => setupload_marks(false)}
          studentData={marksUploadData}
          roll_number={roll_number}
          onSuccess={search}
        />
      )}
      {setupload_marks && (
        <>
          <UploadExamDate
            show={uploadDate}
            onHide={() => setUploadDate(false)}
            studentData={uploadDateData}
            roll_number={roll_number}
            onSuccess={(date) => {
              updateUploadDateForSemester(uploadDateData.semester, date);
              search();
            }}
          />
        </>
      )}
      <div className="text-success">Date Uploaded: {uploadDate}</div>
      <div class="row">
        <div class="">
          <div class="BigTitle">Upload Student Marks</div>
          <div className="text-center w-50 mb-4">
            <Form className="d-flex mt-4 " onSubmit={searchStudent}>
              <FormControl
                type="search"
                placeholder="Enter Roll Number"
                className="me-2"
                aria-label="Search"
                value={roll_number}
                onChange={(e) => setroll_number(e.target.value)}
              />
              <Button type="submit" variant="outline-success">
                Search
              </Button>
            </Form>
          </div>
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" role="status" size="lg">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : data === false ? (
            <h3>Record not found</h3>
          ) : data ? (
            <>
              <Table bordered hover striped responsive className="mb-5">
                <thead>
                  <th>Roll Number</th>
                  <th>Course Name</th>
                  <th>Student Name</th>
                </thead>
                <tbody>
                  <tr>
                    <td>{data.studnt_id?.roll_number}</td>
                    <td>{data.name}</td>
                    <td>{data.studnt_id?.name}</td>
                  </tr>
                </tbody>
              </Table>
              {data?.exam_type === "subject_wise" ||
              data?.exam_type === "semester" ? (
                <>
                  {Object.entries(convertArrayToObject(data?.subject))?.map(
                    ([key, value], index) => (
                      <Accordion className="mb-3">
                        <Accordion.Item eventKey={key}></Accordion.Item>
                        <Accordion.Header>
                          {data?.course_type === "2" ? "Semester " : "Year "}
                          {console.log("key", key)}
                          {key}
                        </Accordion.Header>
                        <Accordion.Body>
                          <table
                            class="table table-separate table-head-custom table-checkable"
                            id="kt_datatable"
                          >
                            <thead>
                              <th>S.NO.</th>
                              <th>Subject</th>
                              <th>Subject Code</th>
                              <th>Max marks</th>
                              <th>Obtained Marks </th>
                              <th>Status</th>
                              <th>Action</th>
                            </thead>
                            <tbody>
                              {value.map((v, index) => (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{v.name}</td>
                                  <td>{v.code || "NA"}</td>
                                  <td>{getMaxMarks(v.practical)}</td>
                                  <td>{v.marks || "-"}</td>
                                  <td>
                                    {v.marks
                                      ? (v.marks / getMaxMarks(v.practical)) *
                                          100 >=
                                        40
                                        ? "Pass"
                                        : "Fail"
                                      : "NA"}
                                  </td>
                                  <td>
                                    {v.marks ? (
                                      "Completed"
                                    ) : (
                                      <button
                                        class="btn btn-sm btn-success"
                                        onClick={() => {
                                          setupload_marks(true);
                                          setMarksUploadData({
                                            subId: v.id,
                                            studentId: data.studnt_id?.id,
                                            courseId: data?.id,
                                            max_marks:
                                              v.practical == 1 ? 50 : 100,
                                          });
                                        }}
                                      >
                                        Upload Marks
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <Table
                            bordered
                            hover
                            striped
                            responsive
                            className="mb-0"
                          >
                            <thead>
                              <th>Exam Date</th>
                              <th>Action</th>
                            </thead>
                            <tbody>
                              <td></td>
                              <td>
                                <button
                                  class="btn btn-sm "
                                  onClick={() => {
                                    setUploadDate(true);

                                    setUploadDateData({
                                      course_id: data?.id,
                                      student_id: data.studnt_id?.id,
                                      semester: key,
                                    });
                                  }}
                                >
                                  Upload Result Date
                                </button>
                                {/* {result_date && ( */}

                                <div className="text-success">
                                  Date Uploaded: {uploadDates[key]}
                                </div>
                              </td>
                            </tbody>
                          </Table>
                        </Accordion.Body>
                      </Accordion>
                    )
                  )}
                </>
              ) : (
                <>
                  {Object.keys(groupedBySemester).map((semester) => (
                    <>
                      {console.log("semester", semester)}
                      <Accordion>
                        <Accordion.Item></Accordion.Item>
                        {semester === "null" ? (
                          <Accordion.Header></Accordion.Header>
                        ) : (
                          <Accordion.Header>Year {semester}</Accordion.Header>
                        )}

                        <Accordion.Body>
                          <Table
                            bordered
                            hover
                            striped
                            responsive
                            className="mb-0"
                          >
                            <thead>
                              <th>S.NO.</th>
                              <th>Subject</th>
                              <th>Subject Code</th>
                              <th>Max marks</th>
                              <th>Obtained Marks </th>
                              <th>Status</th>
                              <th>Action</th>
                            </thead>
                            <tbody>
                              {groupedBySemester[semester].map(
                                (subject, index) => (
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{subject.name}</td>
                                    <td>{subject.code || "NA"}</td>
                                    <td>{getMaxMarks(subject.practical)}</td>
                                    <td>{subject.marks}</td>
                                    <td>
                                      {subject.marks
                                        ? (subject.marks /
                                            getMaxMarks(subject.practical)) *
                                            100 >=
                                          40
                                          ? "Pass"
                                          : "Fail"
                                        : "NA"}
                                    </td>
                                    <td>
                                      {subject.marks ? (
                                        "Completed"
                                      ) : (
                                        <button
                                          class="btn btn-sm btn-success"
                                          onClick={() => {
                                            setupload_marks(true);

                                            setMarksUploadData({
                                              courseId: data?.id,
                                              subId: subject.id,
                                              studentId: data.studnt_id?.id,
                                              max_marks:
                                                subject.practical == 1
                                                  ? 50
                                                  : 100,
                                            });
                                          }}
                                        >
                                          Upload Marks
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                          <Table
                            bordered
                            hover
                            striped
                            responsive
                            className="mb-0"
                          >
                            <thead>
                              <th>Exam Date</th>
                              <th>Action</th>
                            </thead>
                            <tbody>
                              <td></td>
                              <td>
                                <button
                                  class="btn btn-sm"
                                  onClick={() => {
                                    setUploadDate(true);

                                    setUploadDateData({
                                      course_id: data?.id,
                                      student_id: data.studnt_id?.id,
                                      semester,
                                    });
                                  }}
                                >
                                  Upload Date
                                </button>
                                <div className="text-success">
                                  Date Uploaded: {uploadDates[semester]}
                                </div>
                              </td>
                            </tbody>
                          </Table>
                        </Accordion.Body>
                      </Accordion>
                    </>
                  ))}
                </>
              )}
            </>
          ) : (
            data?.exam_type === "course_wise" && (
              <>
                {Object.keys(groupedBySemester).map((semester) => (
                  <>
                    {console.log("semester", semester)}
                    <Accordion>
                      {/* <Accordion.Item></Accordion.Item> */}
                      {/* <Accordion.Header>Year {semester}</Accordion.Header> */}
                      <Accordion.Body>
                        <Table
                          bordered
                          hover
                          striped
                          responsive
                          className="mb-0"
                        >
                          <thead>
                            <th>S.NO.</th>
                            <th>Subject</th>
                            <th>Subject Code</th>
                            <th>Max marks</th>
                            <th>Obtained Marks </th>
                            <th>Status</th>
                            <th>Action</th>
                          </thead>
                          <tbody>
                            {groupedBySemester[semester].map(
                              (subject, index) => (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{subject.name}</td>
                                  <td>{subject.code || "NA"}</td>
                                  <td>{getMaxMarks(subject.practical)}</td>
                                  <td>{subject.marks}</td>
                                  <td>
                                    {subject.marks
                                      ? (subject.marks /
                                          getMaxMarks(subject.practical)) *
                                          100 >=
                                        40
                                        ? "Pass"
                                        : "Fail"
                                      : "NA"}
                                  </td>
                                  <td>
                                    {subject.marks ? (
                                      "Completed"
                                    ) : (
                                      <button
                                        class="btn btn-sm btn-success"
                                        onClick={() => {
                                          setupload_marks(true);

                                          setMarksUploadData({
                                            courseId: data?.id,
                                            subId: subject.id,
                                            studentId: data.studnt_id?.id,
                                            max_marks:
                                              subject.practical == 1 ? 50 : 100,
                                          });
                                        }}
                                      >
                                        Upload Marks
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                        <Table
                          bordered
                          hover
                          striped
                          responsive
                          className="mb-0"
                        >
                          <thead>
                            <th>Exam Date</th>
                            <th>Action</th>
                          </thead>
                          <tbody>
                            <td></td>
                            <td>
                              <button
                                class="btn btn-sm"
                                onClick={() => {
                                  setUploadDate(true);

                                  setUploadDateData({
                                    course_id: data?.id,
                                    student_id: data.studnt_id?.id,
                                    semester,
                                  });
                                }}
                              >
                                Upload Date
                              </button>
                              <div className="text-success">
                                Date Uploaded: {uploadDates[semester]}
                              </div>
                            </td>
                          </tbody>
                        </Table>
                      </Accordion.Body>
                    </Accordion>
                  </>
                ))}
              </>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default AddMarks;
