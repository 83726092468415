import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Spinner, Modal, Button } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useTimer } from "react-timer-hook";
import {
  getPracticeQuestions,
  submitAnswer,
} from "../../../store/actions/studentAction/assessmentActions";
import "./css/assessment.css";
import StartExam from "./StartExam";
import MyTimer from "../../Timer";
const Assessment = (props) => {
  const { id, sem } = useParams();
  const { student } = useSelector((state) => state);
  const { studentAuth } = student;
  const { profile } = studentAuth;
  const [subjectId, setSubjectid] = useState("");
  const [arr, setArr] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [agree, setAgree] = useState(false);
  const [show, setShow] = useState(false);

  const [answerId, setAnswerid] = useState("");

  // const [data, setData] = useState({});

  const confirm = useCallback(() => {
    setAgree(true);
  }, [agree]);
  const [questions, setQuestions] = useState(null);
  const fetch = async (id) => {
    const response = await dispatch(getPracticeQuestions(profile.id, id));
    if (response.isSuccess) {
      setQuestions(response.data);
    } else {
      // history.push("/student/assessments");
    }
  };
  const time = new Date();
  time.setSeconds(time.getSeconds() + 600);

  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    if (props.location.state?.subject) {
      fetch(props.location.state.subject);
    } else {
      history.push("/student/assessments");
    }
  }, [props.location.state]);
  const filterData = (e, index) => {
    const check = arr;
    check[index] = "";
    setArr(check);
  };
  const pushData = (e, index) => {
    const check = arr;
    check[index] = e;
    setArr(check);
  };

  const [answers, setAnswers] = useState([]);
  return (
    <div>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Submit Test</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Are you sure you want to submit the test.</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => window.location.reload()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {!agree ? (
        <StartExam
          start={agree}
          setStart={confirm}
          count={10}
          totalMarks={10}
        />
      ) : questions ? (
        <div className="assessment">
          <br />
          <div className="time-left">
            {/* Time Left : <span class="blink_text">00:59</span> */}
            <MyTimer expiryTimestamp={time} reloadTime={time * 1000} />
          </div>
          <br />
          <br />
          <p></p>
          <h3 style={{ fontSize: "16px" }}>
            Q{activePage}. {questions[activePage - 1]?.questions.question}
          </h3>
          <br />
          {questions[activePage - 1]?.questions.mcq.map((mcq) => (
            <>
              <div
              // value={arr[activePage - 1]}
              // onChange={(e) =>
              //   arr.includes(e.target.value)
              //     ? filterData(e.target.value, activePage - 1)
              //     : pushData(e.target.data, activePage - 1)
              // }
              ></div>
              <input
                type="radio"
                name="ans"
                value={mcq.id}
                onChange={(e) => {
                  pushData(e.target.value, activePage - 1);
                  setAnswerid(e.target.value);
                }}
                checked={arr[activePage - 1] == mcq.id ? true : false}
                required=""
                style={{ marginRight: "6px" }}
              />
              {mcq.answer}
              <br />
            </>
          ))}
          <br />
          {
            <button
              class="btn btn-info"
              type="submit"
              name="submit"
              value="Next Question"
              onClick={async () => {
                // if (activePage < 10) {
                const response = await dispatch(
                  submitAnswer(
                    questions[activePage - 1].question_list_id,
                    answerId,
                    profile.id
                  )
                );
                if (activePage < 10) {
                  setActivePage(activePage + 1);
                  setAnswerid("");
                } else {
                  if (response) {
                    setShow(true);
                    // window.location.reload();
                  }
                }
              }}
            >
              {activePage < 10 ? "Next Question" : "Submit Test"}
            </button>
          }
          <p></p>
          <ReactPaginate
            className="pagination mt-5 justify-content-center"
            previousClassName="page-item"
            nextClassName="page-item"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            nextLinkClassName="page-link"
            previousLinkClassName="page-link"
            activeClassName="active"
            // marginPagesDisplayed={10}
            onPageChange={(e) => setActivePage(e.selected + 1)}
            pageCount={10}
            forcePage={activePage - 1}
            // renderOnZeroPageCount={null}
          />
          <button
            className="float-right btn btn-sm btn-success"
            onClick={() => setShow(true)}
          >
            Finish Test
          </button>
        </div>
      ) : (
        <Spinner animation="border" role="status" size="lg">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
    </div>
  );
};

export default Assessment;
