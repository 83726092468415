import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner, Modal, Button } from "react-bootstrap";
import { getResults } from "../../../store/actions/studentAction/studentAuth";
import { useParams } from "react-router-dom";
import { courseType, examType } from "../../../utils/constant";

const Result = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { studentAuth } = useSelector((state) => state.student);
  const { profile } = studentAuth;
  const dispatch = useDispatch();
  const { id } = useParams();
  const fetchdata = async () => {
    const response = await dispatch(
      getResults(id ? id : studentAuth.profile.id)
    );
    if (response) {
      setData(response);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    setLoading(true);
    fetchdata();
  }, []);
  return (
    <div>
      <div className="mid-content-box">
        <div className="Widthdata">
          <div className="Applicationdata s">
            <div className="row ">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th> Roll Number </th>
                      <th> Course </th>
                      <th> Enroll. Fee </th>
                      <th> Exam Fee </th>
                      <th> Result</th>

                      <th>Mark sheet </th>
                      <th>Certificate </th>
                      <th> Status</th>
                    </tr>
                    {loading ? (
                      <tr>
                        <td colSpan={9}>
                          <div className="text-center">
                            <Spinner animation="border" role="status" size="lg">
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </Spinner>
                          </div>
                        </td>
                      </tr>
                    ) : data ? (
                      <tr>
                        <td>{data.studentData?.name}</td>
                        <td>{data.studentData?.roll_number}</td>
                        <td>{data.studentData?.course.name}</td>
                        {data.studentData?.course.exam_type == "course_wise" ? (
                          <>
                            <td>
                              {data.studentData.fees?.length > 0 ? (
                                data.studentData.course?.exam_type ===
                                examType.course_wise ? (
                                  <a
                                    className="btn btn-success btn-sm"
                                    href={data.studentData?.fees[0]?.link}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Receipt
                                  </a>
                                ) : (
                                  data.studentData.fees.map((fee) => (
                                    <a
                                      className="btn btn-success btn-sm"
                                      href={fee.link}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {data.studentData.course?.course_type ===
                                      courseType.semester
                                        ? "Semester"
                                        : "Year"}{" "}
                                      {fee.semester}
                                    </a>
                                  ))
                                )
                              ) : (
                                "Pending"
                              )}
                            </td>
                            <td>{data.fee || "Pending"}</td>
                            <td>
                              {data.result ? (
                                <a href={data.result} target="_blank">
                                  View
                                </a>
                              ) : (
                                "Pending"
                              )}
                            </td>
                            <td>
                              {data.Marksheet ? (
                                <a href={data.Marksheet} target="_blank">
                                  View
                                </a>
                              ) : (
                                "Pending"
                              )}
                            </td>
                            <td>
                              {data.CertificateData ? (
                                <a href={data.CertificateData} target="_blank">
                                  View
                                </a>
                              ) : (
                                "Pending"
                              )}
                            </td>
                          </>
                        ) : (
                          <>
                            <td>Pending</td>
                            <td>Pending</td>
                            <td>
                              {data.result ? (
                                <a href={data.result} target="_blank">
                                  View
                                </a>
                              ) : (
                                "Pending"
                              )}
                            </td>
                            <td>
                              <table border="1">
                                {data.Marksheet
                                  ? data.Marksheet.map((e, index) => (
                                      <tr>
                                        <td>
                                          {data.studentData?.course
                                            .course_type == "2"
                                            ? `Sem ${index + 1} `
                                            : `Year ${index + 1} `}
                                          <a
                                            href={Object.values(e)[0]}
                                            target="_blank"
                                          >
                                            View
                                          </a>
                                        </td>
                                      </tr>
                                    ))
                                  : "Pending"}
                              </table>
                            </td>
                          </>
                        )}
                        <td>
                          {data.CertificateData ? "Completed" : "Pending"}
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan={9}>No Record Found</td>
                      </tr>
                    )}
                  </thead>
                  <tbody></tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Result;
