import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { getResultsByRollno } from "../../store/actions/studentAction/studentAuth";

const StudentResult = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [pdfLoader, setPdfLoading] = useState(true);
  const [result, setResult] = useState(false);
  const [data, setData] = useState({
    roll_number: "",
    dob: "",
  });
  const { roll_number, dob } = data;
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const fetchdata = async () => {
    const response = await dispatch(getResultsByRollno(data));
    if (response) {
      setResult(response);
      setLoading(false);
    } else {
      setResult(null);
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setResult(false);
    setLoading(true);
    fetchdata();
  };

  return (
    <div className="gallery-box">
      <div className="container">
        <div className="title-box">
          <div className="col-md-12 inner">
            <section className="innerbanner">
              <h2>Examination Result</h2>
              <ol className="breadcrumb">
                <li>
                  <Link to="/" className="none">
                    Home
                  </Link>
                </li>
                <li>/ </li>
                <li className="active">Examination Result & Verification</li>
              </ol>
            </section>
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <div class="box_list_main">
                  <div class="srchbycenter" style={{ display: "block" }}>
                    <form class="selectbox mb-4" onSubmit={handleSubmit}>
                      {/* <div class="container mt-2">
                <div class="selectbox row mb-4"> */}
                      <div className="col-md-6">
                        <div class="boxlabel">
                          <label>Roll No.</label>
                          <input
                            class="form-control"
                            name="roll_number"
                            value={roll_number}
                            placeholder="Enter Roll No."
                            onChange={handleChange}
                            required
                          ></input>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div class="boxlabel">
                          <label>DOB</label>
                          <input
                            class="form-control"
                            name="dob"
                            type="date"
                            value={dob}
                            placeholder="Enter Title"
                            onChange={handleChange}
                            required
                          ></input>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <button class="btn btn-reset d-block mx-auto">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                {loading ? (
                  <div className="text-center">
                    <Spinner animation="border" role="status" size="lg">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                ) : result === false ? null : result ? (
                  <>
                    {pdfLoader ? (
                      <div className="text-center">
                        <Spinner animation="border" role="status" size="lg">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    ) : null}
                    <iframe
                      src={result.result}
                      width={"100%"}
                      height={"600px"}
                      onLoad={() => setPdfLoading(false)}
                    ></iframe>
                  </>
                ) : (
                  "No Record Found"
                )}
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentResult;
