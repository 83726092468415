import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { uploadMarks } from '../../../store/actions/councilActions/addMarks';
import { successAlert } from '../../../utils/alert';

const UploadMarks = ({ show, onHide, studentData, onSuccess }) => {
  const dispatch = useDispatch();
  const [obtained_marks, setobtained_marks] = useState('');
  console.log(studentData);
  const [max_marks, setmax_marks] = useState(studentData.max_marks);
  const submit = async (e) => {
    e.preventDefault();
    const data = {
      marks: obtained_marks,
      // max_marks,
      student_id: studentData.studentId,
      course_id: studentData.courseId,
      subject_id: studentData.subId,
      exam_type: 1 //manual
    };
    const response = await dispatch(uploadMarks(data));
    if (response) {
      onHide();
      successAlert('Marks Uploaded');
      onSuccess();
    }
  };
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>Upload Marks </Modal.Header>
      <Modal.Body>
        <form onSubmit={submit}>
          <div className="form-group">
            <label>
              Max Marks <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              className="form-control"
              value={studentData.max_marks}
              onChange={(e) => setmax_marks(e.target.value)}
              required></input>
          </div>
          <div className="form-group">
            <label>
              Obtained Marks <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              className="form-control"
              value={obtained_marks}
              onChange={(e) => setobtained_marks(e.target.value)}
              required></input>
          </div>
          <button className="btn btn-success" type={'submit'}>
            Upload
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UploadMarks;
