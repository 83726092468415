import {
  deleteDataAPI,
  getDataAPI,
  postDataAPI,
} from "../../../services/apiWrapper";
import { ALERT } from "../actionTypes";

export const addNews = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(
      postDataAPI("admin/news/create", data, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });
    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};

export const updateNews = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(
      postDataAPI("admin/news/update", data, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });
    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    return false;
  }
};
export const deleteNews = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(
      deleteDataAPI(`admin/news/delete/${id}`, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });
    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    return false;
  }
};

export const getNews = (page) => async (dispatch) => {
  try {
    const response = await dispatch(getDataAPI(`admin/news/list?page=${page}`));

    return {
      data: response.data.data?.data || [],
      last_page: response.data.data.last_page,
    };
  } catch (error) {
    // dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    return {
      data: [],
    };
  }
};

export const getNewsData = (type, page) => async (dispatch) => {
  try {
    const response = await dispatch(
      getDataAPI(`admin/news/list/${type}?page=${page}`)
    );

    return {
      data: response.data.data?.data || [],
      last_page: response.data.data.last_page,
    };
  } catch (error) {
    // dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    return {
      data: [],
    };
  }
};
//  return { data:response.data.data?.data || [],last_page:response.data?.data?.last_page};
//

export const getNewsDetail = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(
      getDataAPI(`admin/news/update/${id}`, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: false },
    });
    return { success: true, ...response.data.data };
  } catch (error) {
    dispatch({ type: ALERT, payload: { error: error.response.data?.message } });
    return { success: false };
  }
};
export const getNewsDetails = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      getDataAPI(`admin/news/details/${id}`, token)
    );
    return { success: true, data: response.data.data };
  } catch (error) {
    return { success: false };
  }
};
