import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, FormControl, Spinner, Table } from "react-bootstrap";
import {
  downloadEnquiry,
  enquiry,
} from "../../../store/actions/councilActions/enquiryAction";
import BreadCrumb from "../../BreadCrumb";
import Pagination from "../../misc/Pagination";
import { getCourseList } from "../../../store/actions/councilActions/courseAction";
import ReactPaginate from "react-paginate";
const breadcrumbs = [
  {
    path: "/admin/dashboard",
    label: "Dashboard",
  },
  {
    label: "Admission Enquiry",
  },
];
const AdmissionEnquiry = () => {
  const { admin } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [activePage, setactivePage] = useState(1);
  const [totalPages, settotalPages] = useState(0);
  const [course, setCourse] = useState("");
  const [courseName, setCourseName] = useState("");
  const [date, setDate] = useState("");

  const { courses } = admin;

  useEffect(() => {
    if (!courses) {
      dispatch(getCourseList());
    }
  }, []);

  const fetchData = useCallback(async () => {
    const response = await dispatch(
      enquiry("admission", activePage, course, date)
    );
    if (response) {
      setData(response.data);
      setLoading(false);
      settotalPages(Math.ceil(response.total / response.per_page));
    } else {
      setLoading(false);
    }
  }, [activePage, dispatch, course, date]);

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [fetchData]);

  const handleDownload = () => {
    dispatch(downloadEnquiry("admission", course, date));
  };

  const handleReset = () => {
    setCourse("");
    setactivePage(1);
    setDate("");
  };

  return (
    <div>
      <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
        <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <BreadCrumb crumbs={breadcrumbs} />

          <div class="d-flex align-items-center">
            <button
              onClick={handleDownload}
              className="btn btn-light-primary font-weight-bolder btn-sm"
            >
              Download list
            </button>
            <Link
              to="/admin/enquiry/affiliation"
              class="btn btn-light-primary font-weight-bolder btn-sm"
            >
              Affiliation Enquiry
            </Link>
          </div>
        </div>
        <div className="container">
          <div className=" mb-4">
            <div className="row">
              <div className="col-md-4">
                <select
                  class="form-control"
                  onChange={(e) => {
                    setCourse(e.target.value);
                    setCourseName(
                      e.target.selectedOptions[0].getAttribute("name")
                    );
                    setactivePage(1);
                  }}
                  value={course}
                  required
                >
                  <option value="">Select</option>
                  {courses &&
                    courses.map((course) => (
                      <option
                        key={course.name}
                        value={course.id}
                        name={course.name}
                      >
                        {course.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-md-4">
                <FormControl
                  type="date"
                  value={date}
                  onChange={(e) => {
                    setDate(e.target.value);
                    setactivePage(1);
                  }}
                ></FormControl>
              </div>
              <div className="col-md-2">
                <Button type="reset" onClick={handleReset}>
                  Reset
                </Button>
              </div>
            </div>
          </div>
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" role="status" size="lg">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <Table bordered hover striped responsive className="mb-0">
              <thead>
                <th>S No</th>
                <th>Course</th>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile</th>
                <th>Address</th>
                <th>City</th>
                <th>State</th>
                <th>Message</th>
              </thead>
              <tbody>
                {data.length === 0 ? (
                  <tr>
                    <td colSpan={9} className="text-center text-danger">
                      No Record Found
                    </td>
                  </tr>
                ) : (
                  data.map((e, index) => (
                    <tr>
                      <td>{(activePage - 1) * 10 + index + 1}</td>
                      <td>{e.course?.name}</td>
                      <td>{e.name}</td>
                      <td>{e.email}</td>
                      <td>{e.mobile}</td>
                      <td>{e.address}</td>
                      <td>{e.city}</td>
                      <td>{e.state}</td>
                      <td>{e.message}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          )}
          {totalPages && Number(totalPages) !== 0 ? (
            <ReactPaginate
              className="pagination mt-5 justify-content-center"
              previousClassName="page-item"
              nextClassName="page-item"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              nextLinkClassName="page-link"
              previousLinkClassName="page-link"
              activeClassName="active"
              //   marginPagesDisplayed={10}
              onPageChange={(e) => setactivePage(e.selected + 1)}
              pageCount={totalPages}
              forcePage={activePage - 1}
              // renderOnZeroPageCount={null}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AdmissionEnquiry;
