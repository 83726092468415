import React, { useState } from "react";
import { FormControl } from "react-bootstrap";

const Search = ({ setQuery }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);

  //debounce : search after 500ms of typing
  const handleSearchChange = (e) => {
    clearInterval(searchTimeout);
    setSearchTimeout(
      setTimeout(() => {
        setQuery(e.target.value.trimStart());
      }, 500)
    );
    setSearchQuery(e.target.value.trimStart());
  };

  return (
    <div className="search-wrapper">
      <FormControl
        type="search"
        name="search-comp"
        id="search-comp"
        placeholder={"Search"}
        className="small"
        value={searchQuery}
        onChange={handleSearchChange}
      />
    </div>
  );
};

export default Search;
