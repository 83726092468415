import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  Button,
  Col,
  Container,
  FormControl,
  Modal,
  Row,
} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Search from "../../Search/index";
import {
  downloadStudentList,
  getStudents,
} from "../../../store/actions/centerActions/studentAction";
import { courseType, examType } from "../../../utils/constant";

export default function Student() {
  const dispatch = useDispatch();
  const [modalOpen, setmodalOpen] = useState(false);
  const [adminCard, setAdminCard] = useState(false);
  const [studentModalId, setstudentModalId] = useState(false);
  const [totalPages, settotalPages] = useState(0);
  const [activePage, setactivePage] = useState(1);
  const [search, setSearch] = useState("");
  const [date, setDate] = useState("");
  const { center } = useSelector((state) => state);
  const { students } = center;

  const token = localStorage.getItem("centerToken");

  useEffect(() => {
    dispatch(getStudents(token, activePage, search, date));
  }, [activePage, search, date]);

  useEffect(() => {
    if (students) {
      settotalPages(Math.ceil(students.total / students.per_page));
    }
  }, [students]);

  const handleDownload = () => {
    dispatch(downloadStudentList(token, search, date));
  };

  return (
    <>
      <div class="Title-box">
        <div class="row">
          <div class="col-md-6">
            <div class="BigTitle">My Students</div>
          </div>
          <div class="col-md-6">
            <div class="CommanForm d-flex">
              <div class="btncomplt">
                <Link to="/center/add-student" class="btn btn-comman">
                  Add New Student
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mid-content-box">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <Search
              setQuery={(val) => {
                setSearch(val);
                setactivePage(1);
              }}
            />
          </div>
          <div className="col-md-4">
            <FormControl
              type="date"
              value={date}
              onChange={(e) => {
                setDate(e.target.value);
                setactivePage(1);
              }}
            />
          </div>
          <div className="col-md-2">
            <button
              onClick={handleDownload}
              className="btn btn-light-primary font-weight-bolder btn-sm"
            >
              Download list
            </button>
          </div>
        </div>
        <div class="Widthdata">
          <div class="Applicationdata s">
            <div class="row ">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Sr No. </th>
                      <th> Student Name </th>
                      <th>Roll /Pdf </th>
                      <th>Course </th>

                      <th>Mobile </th>
                      <th>Reg.Date </th>
                      <th>Photo </th>
                      <th>Fee</th>
                      <th> Result </th>
                      <th> I Card </th>
                      <th> Action</th>
                      <th>Hall Ticket</th>
                    </tr>
                  </thead>
                  <tbody>
                    {students && students?.data?.length > 0 ? (
                      students.data.map((student, index) => (
                        <tr>
                          <td>{(activePage - 1) * 10 + index + 1}</td>
                          <td>{student.name}</td>
                          <td>{student.roll_number}</td>
                          <td>{student.course?.name || student.course}</td>
                          <td>{student.student_details?.mobile}</td>
                          <td>
                            {student.is_update == "1"
                              ? moment(student.register_date).format(
                                  "DD/MM/yyyy"
                                )
                              : moment(student.created_at).format("DD/MM/yyyy")}
                          </td>
                          <td><a
                              title={student.profile_pic?.file}
                              target="_blank"
                              href={student.profile_pic?.file}
                              rel="noreferrer"
                            >
                              <img
                                height={"60px"}
                                width={"45px"}
                                src={student.profile_pic?.file}
                              />
                            </a></td>
                          <td>
                              {student.submitFees?.length > 0 ? (
                                student.course?.exam_type ===
                                examType.course_wise ? (
                                  <a
                                    className="btn btn-success btn-sm"
                                    href={student.submitFees[0]?.link}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Receipt
                                  </a>
                                ) : (
                                  <select onChange={handleDownload}>
                                    <option>--Select--</option>
                                    {student.submitFees.map((fee) => (
                                      <option value={fee.link}>
                                        {student.course?.course_type ===
                                        courseType.semester
                                          ? "Semester"
                                          : "Year"}{" "}
                                        {fee.semester}
                                      </option>
                                    ))}
                                  </select>
                                )
                              ) : (
                                "Pending"
                              )}
                            </td>
                          <td>
                            <Link to={`/center/students/result/${student.id}`}>
                              View
                            </Link>
                          </td>
                          <td>
                            <a
                             href={`https://npvec.com/api/public/download/student/card/${student.id}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              View
                            </a>
                          </td>

                          <td>
                            {" "}
                            <Button
                              onClick={() => {
                                setstudentModalId(btoa(student.id));
                                setmodalOpen(true);
                              }}
                              className="btn-sm"
                            >
                              Registration Form
                            </Button>
                          </td>
                          <td>
                            {" "}
                            <Button
                              onClick={() => {
                                setstudentModalId(btoa(student.id));
                                setAdminCard(true);
                              }}
                              className="btn-sm"
                            >
                              Hall Ticket
                            </Button>
                          </td>
                          {/* <td>
                            <Link to={`/center/edit-student/${student.id}`}>
                              Edit
                            </Link>
                          </td> */}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={10}>{"No student Found"}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <ReactPaginate
          className="pagination mt-5 justify-content-center"
          previousClassName="page-item"
          nextClassName="page-item"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          nextLinkClassName="page-link"
          previousLinkClassName="page-link"
          activeClassName="active"
          //   marginPagesDisplayed={10}
          onPageChange={(e) => setactivePage(e.selected + 1)}
          pageCount={totalPages}
          forcePage={activePage - 1}
          // renderOnZeroPageCount={null}
        />
      </div>
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalOpen}
        onHide={() => {
          setmodalOpen(false);
          studentModalId(false);
        }}
      >
        <Modal.Body>
          <Container>
            <Row>
              <Col xs={12} md={12} style={{ height: "80vh" }}>
                {studentModalId && (
                  <iframe
                    src={`https://npvec.com/api/public/ViewHallticket/${studentModalId}`}
                    width={"100%"}
                    height={"100%"}
                  ></iframe>
                )}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setmodalOpen(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={adminCard}
        onHide={() => {
          setAdminCard(false);
          studentModalId(false);
        }}
      >
        <Modal.Body>
          <Container>
            <Row>
              <Col xs={12} md={12} style={{ height: "80vh" }}>
                {studentModalId && (
                  <iframe
                    title="Exam form"
                    src={`https://npvec.com/api/public/exam-ticket/${studentModalId}`}
                    width={"100%"}
                    height={"100%"}
                  ></iframe>
                )}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setAdminCard(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
