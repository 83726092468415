import React from "react";
import Footer from "../Home/Footer/Footer";
import Header from "../Home/Header/Header";

export default function HomeLayout({ children }) {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
}
