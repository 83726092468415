import React from "react";
import { useTimer } from "react-timer-hook";

function MyTimer({ expiryTimestamp, reloadTime }) {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => window.location.reload(),
  });
  // setTimeout(() => {
  //   window.location.reload();
  // }, reloadTime || 30000);

  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ fontSize: "20px" }}>
        Time Left : <span className="blink_text">{minutes}</span>:
        <span className="blink_text">{seconds}</span>
      </div>
    </div>
  );
}

export default MyTimer;
