import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import {
  downloadSubjects,
  getSubjectList,
} from "../../../store/actions/councilActions/subjectAction";
import BreadCrumb from "../../BreadCrumb";
import Pagination from "../../misc/Pagination";
import { getCourseList } from "../../../store/actions/councilActions/courseAction";
import { useCallback } from "react";

export default function ViewSubject() {
  const { admin } = useSelector((state) => state);
  const [data, setdata] = useState(null);
  const [loading, setloading] = useState(false);
  const [activePage, setactivePage] = useState(1);
  const [totalPages, settotalPages] = useState(0);
  const [exist, setExist] = useState(true);
  const [course, setCourse] = useState("");
  const [courseName, setCourseName] = useState("");

  const { subjects, courses } = admin;

  const breadcrumbs = [
    {
      path: "/admin/dashboard",
      label: "Dashboard",
    },
    {
      // path: "/aboutus",
      label: "Subjects",
    },
  ];

  useEffect(() => {
    if (!courses) {
      dispatch(getCourseList());
    }
  }, []);

  const dispatch = useDispatch();
  const fetchdata = useCallback(async () => {
    setloading(true);
    const response = await dispatch(getSubjectList(activePage, course));
    if (response) {
      setdata(response.data);
      setloading(false);
      settotalPages(Math.ceil(response.total / response.per_page));
    } else {
      setdata([]);
      setloading(false);
    }
  }, [dispatch, activePage, course]);

  useEffect(() => {
    fetchdata();
  }, [fetchdata]);

  const handleDownload = () => {
    dispatch(downloadSubjects(course, courseName));
  };

  const handleReset = () => {
    setCourse("");
    setactivePage(1);
  };

  return (
    <div>
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
          <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <BreadCrumb crumbs={breadcrumbs} />

            <div class="d-flex align-items-center">
              <Link
                to="/admin/add-course"
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                Add Course
              </Link>
              <Link
                to="/admin/add-subject"
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                Add Subject
              </Link>
            </div>
          </div>
        </div>
        <div className=" mb-4">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-4">
              <select
                class="form-control"
                onChange={(e) => {
                  setCourse(e.target.value);
                  setCourseName(
                    e.target.selectedOptions[0].getAttribute("name")
                  );
                  setactivePage(1);
                }}
                value={course}
                required
              >
                <option value="">Select</option>
                {courses &&
                  courses.map((course) => (
                    <option
                      key={course.name}
                      value={course.id}
                      name={course.name}
                    >
                      {course.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-4">
              <Button type="reset" onClick={handleReset}>
                Reset
              </Button>
            </div>
            <div className="col-md-2">
              <button
                onClick={handleDownload}
                className="btn btn-light-primary font-weight-bolder btn-sm"
              >
                Download list
              </button>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-12">
              {loading ? (
                <div className="text-center">
                  <Spinner animation="border" role="status" size="lg">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : data === null ? null : (
                <table
                  class="table table-separate table-head-custom table-checkable"
                  id="kt_datatable"
                >
                  <thead>
                    <tr>
                      <th>Sr.No</th>
                      <th>Subject</th>
                      <th>Subject Code</th>

                      <th>Course</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 ? (
                      data.map((subject, index) => (
                        <tr>
                          <td>{(activePage - 1) * 10 + index + 1}</td>
                          <td>{subject.name}</td>
                          <td>{subject.code}</td>
                          <td>{subject.course_name?.name}</td>
                          <td>
                            <Link to={`/admin/edit-subject/${subject.id}`}>
                              Edit
                            </Link>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={4}>No Subject</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>

            <div class="col-md-2"></div>

            <ReactPaginate
              className="pagination mt-5 justify-content-center"
              previousClassName="page-item"
              nextClassName="page-item"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              nextLinkClassName="page-link"
              previousLinkClassName="page-link"
              activeClassName="active"
              //   marginPagesDisplayed={10}
              onPageChange={(e) => setactivePage(e.selected + 1)}
              pageCount={totalPages}
              forcePage={activePage - 1}
              // renderOnZeroPageCount={null}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
