import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import BreadCrumb from "../../BreadCrumb";
import {
  addOffice,
  editOffice,
  updateOffice,
} from "../../../store/actions/councilActions/officeAction";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

const breadcrumbs = [
  {
    path: "/admin/dashboard",
    label: "Dashboard",
  },
  {
    label: "Add Office",
  },
];

const AddOffice = () => {
  const [loading, setLoading] = useState(false);
  const [exist, setExist] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const initialState = {
    category: "",
    email: "",
    phone: "",
    address: "",
    address_link: "",
  };
  const [data, setData] = useState(initialState);
  const fetchData = async () => {
    setLoading(true);
    const response = await dispatch(editOffice(id));
    if (response) {
      setData({
        category: response.category,
        email: response.email,
        phone: response.phone,
        address: response.address,
        address_link: response.address_link,
      });
      setLoading(false);
    } else {
      setLoading(false);
      setExist(false);
    }
  };
  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, []);
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const { category, email, phone, address, address_link } = data;
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (id) {
      const response = await dispatch(
        updateOffice({
          ...data,
          office_id: id,
        })
      );
      if (response) {
        setData(initialState);
        history.push("/admin/office/list");
      }
    } else {
      const response = await dispatch(addOffice(data));
      if (response) {
        setData(initialState);
        history.push("/admin/office/list");
      }
    }
  };
  return (
    <div>
      {" "}
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
          <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <BreadCrumb crumbs={breadcrumbs} />

            <div class="d-flex align-items-center">
              <Link
                to="/admin/office/list"
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                Office List
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8 card">
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" role="status" size="lg">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : exist ? (
            <>
              <div className="card-header text-center">
                {id ? "Edit Office" : " Add Office"}
              </div>
              <div class="myForm">
                <form onSubmit={handleSubmit}>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="boxlabel">
                            <p> Category</p>
                            <input
                              type="text"
                              name="category"
                              value={category}
                              onChange={handleChange}
                              class="form-control"
                              required
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="boxlabel">
                            <p>Contact</p>
                            <input
                              type="text"
                              name="phone"
                              value={phone}
                              onChange={handleChange}
                              class="form-control"
                              required
                            />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="boxlabel">
                            <p> Email</p>
                            <input
                              type="email"
                              name="email"
                              class="form-control"
                              value={email}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="boxlabel">
                            <p>Address</p>
                            <textarea
                              class="form-control"
                              name="address"
                              value={address}
                              onChange={handleChange}
                              required
                            ></textarea>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="boxlabel">
                            <p>Address Link</p>
                            <textarea
                              class="form-control"
                              name="address_link"
                              value={address_link}
                              onChange={handleChange}
                            ></textarea>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div
                            class="btn-groups mt-4 text-right"
                            role="group"
                            aria-label="First group"
                          >
                            <button class="btn btn-Next" type="submit">
                              Submit<span></span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </>
          ) : (
            <h5>No Record Found</h5>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddOffice;
