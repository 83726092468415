import React, { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useLoadMore } from "./useLoadMore";

import {
  deleteNews,
  getNews,
} from "../../../store/actions/adminActions/newsAction";
import Loader from "../../Alert/Loader";
import BreadCrumb from "../../BreadCrumb";

const ViewNews = () => {
  const breadcrumbs = [
    {
      path: "/admin/dashboard",
      label: "Dashboard",
    },
    {
      // path: "/aboutus",
      label: "View News",
    },
  ];

  const dispatch = useDispatch();

  const [pageNumber, setPageNumber] = useState(1);
  const [loading, data, setData, hasMore] = useLoadMore(
    "admin/news/list?page=",
    pageNumber
  );

  const observer = useRef();
  const lastDataElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const deleteHandler = async (id) => {
    const response = await dispatch(deleteNews(id));
    if (response) {
      const data = await dispatch(getNews());
      setData(data.data);
    }
  };

  return (
    <>
      <div>
        <div
          class="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
            <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
              <BreadCrumb crumbs={breadcrumbs} />
              <div class="d-flex align-items-center">
                <Link
                  to="/admin/add-News"
                  class="btn btn-light-primary font-weight-bolder btn-sm"
                >
                  Add News
                </Link>
              </div>
            </div>
          </div>

          <div class="container" id="kt_datatable">
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-12">
                <div class="table-responsive" id="bottom_check">
                  <table class="table table-separate table-head-custom table-checkable">
                    <thead>
                      <tr>
                        <th>Sr.No</th>
                        <th>Title </th>
                        <th>Type</th>
                        <th>Description</th>
                        <th>Photo</th>
                        <th>Link</th>
                        <th>Actions</th>
                      </tr>
                    </thead>

                    <tbody>
                      {data === null ? (
                        <Loader active={true} />
                      ) : data?.length > 0 ? (
                        data.map((n, index) => {
                          if (data.length === index + 1) {
                            return (
                              <tr ref={lastDataElementRef} key={n}>
                                <td>{index + 1}</td>
                                <td>{n.title}</td>
                                <td>{n.type}</td>
                                <td>{n.description}</td>
                                <td align="center">
                                  {<img src={n.file} height={"60px"} />}
                                </td>
                                <td align="center">{n.link || "-"}</td>
                                <td align="center">
                                  <Link to={`/admin/edit-news/${n.id}`}>
                                    Edit
                                  </Link>
                                  <button
                                    class="btn btn-danger"
                                    onClick={() => deleteHandler(n.id)}
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            );
                          } else {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{n.title}</td>
                                <td>{n.type}</td>
                                <td>{n.description}</td>
                                <td align="center">
                                  {<img src={n.file} height={"60px"} />}
                                </td>
                                <td align="center">{n.link || "-"}</td>
                                <td align="center">
                                  <Link to={`/admin/edit-news/${n.id}`}>
                                    Edit
                                  </Link>
                                  <button
                                    class="btn btn-danger"
                                    onClick={() => deleteHandler(n.id)}
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            );
                          }
                        })
                      ) : (
                        <tr>{/* <td colSpan={5}>No News Added</td> */}</tr>
                      )}
                    </tbody>
                  </table>
                  <div>{loading && "Loading..."}</div>
                  {!loading && data.length == 0 && <div>No News Available</div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewNews;
