import { getDataAPI, postDataAPI } from "../../../services/apiWrapper";
import { exportToCSV } from "../../../utils/exportCsv";
import { ALERT } from "../actionTypes";

export const addOffice = (data) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      postDataAPI("admin/office/address/create", data, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });

    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });

    dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};

export const updateOffice = (data) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      postDataAPI("admin/office/address/edit", data, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });

    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });

    dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};

export const officeList = (page) => async (dispatch) => {
  try {
    const params = `${page ? `&page=${page}` : ""}`;
    const response = await dispatch(
      getDataAPI(`admin/office/address?${params}`)
    );
    return response.data.data;
  } catch (error) {
    return false;
  }
};

export const downloadOffices = (page) => async (dispatch) => {
  try {
    const params = `${page ? `&page=${page}` : ""}`;
    const response = await dispatch(
      getDataAPI(`admin/office/address?${params}`)
    );
    const dataToExport = [];
    response.data.data.forEach((ele, index) => {
      dataToExport.push({
        Sno: index + 1,
        Category: ele.category,
        Email: ele.email,
        Phone: ele.phone,
        Address: ele.address,
      });
    });
    exportToCSV(dataToExport, "Offices");
  } catch (error) {
    return false;
  }
};

export const editOffice = (id) => async (dispatch) => {
  const token = localStorage.getItem("adminToken");
  try {
    const response = await dispatch(
      getDataAPI(`admin/office/address/edit/${id}`, token)
    );
    return response.data.data;
  } catch (error) {
    return false;
  }
};

export const deleteOffice = (id) => async (dispatch) => {
  const token = localStorage.getItem("adminToken");
  try {
    const response = await dispatch(
      getDataAPI(`admin/office/address/delete/${id}`, token)
    );
    return true;
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: { error: error.response?.data?.message || error.message },
    });
    return false;
  }
};
