import React from "react";

export default function SvgTypes({ name }) {
  switch (name) {
    case "404":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="187.953"
          height="187.953"
          viewBox="0 0 187.953 187.953"
          role="presentation"
        >
          <g id="iconLikertVerySadDark" transform="translate(12 12)">
            <g
              id="iconLikertVerySadDark-2"
              data-name="iconLikertVerySadDark"
              transform="translate(-12 -12)"
            >
              <path
                id="Path_143"
                d="M93.977 0C42.289 0 0 42.289 0 93.977s42.289 93.977 93.977 93.977 93.977-42.289 93.977-93.977S145.664 0 93.977 0zm0 172.29a78.314 78.314 0 1178.313-78.313 78.544 78.544 0 01-78.313 78.313z"
                data-name="Path 143"
              />
              <circle
                id="Ellipse_3"
                cx="12"
                cy="12"
                r="12"
                data-name="Ellipse 3"
                transform="translate(117 54)"
              />
              <circle
                id="Ellipse_4"
                cx="12"
                cy="12"
                r="12"
                data-name="Ellipse 4"
                transform="translate(47 54)"
              />
              <path
                id="Path_144"
                d="M39.326 23.831A23.075 23.075 0 0162.82 47.326h7.831a31.326 31.326 0 00-62.651 0h7.831a23.075 23.075 0 0123.495-23.495z"
                data-name="Path 144"
                transform="translate(54.651 109.302)"
              />
            </g>
          </g>
        </svg>
      );
    default:
      return null;
  }
}
