import { getDataAPI, postDataAPI } from "../../../services/apiWrapper";
import { exportToCSV } from "../../../utils/exportCsv";
import { ALERT } from "../actionTypes";

export const addContact = (data) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      postDataAPI("admin/enquiry/numbers", data, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });

    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });

    dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};

export const updateContact = (data) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      postDataAPI("admin/enquiry/numbers/edit", data, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });

    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });

    dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};

export const contactList = (page) => async (dispatch) => {
  try {
    const response = await dispatch(
      getDataAPI(`admin/enquiry/numbers?page=${page}`)
    );
    return response.data.data;
  } catch (error) {
    return false;
  }
};

export const downloadContacts = () => async (dispatch) => {
  try {
    const response = await dispatch(getDataAPI(`admin/enquiry/numbers`));
    const dataToExport = [];
    response.data.data.forEach((ele, index) => {
      dataToExport.push({
        Sno: index + 1,
        Department: ele.department,
        "Department Designation": ele.depart_designation,
        "Name of the Head": ele.name,
        "Phone No": ele.phone,
      });
    });
    exportToCSV(dataToExport, "Contacts");
  } catch (error) {
    return false;
  }
};

export const getHelpline = () => async (dispatch) => {
  try {
    const response = await dispatch(getDataAPI("admin/helpline/number"));
    return response.data.data;
  } catch (error) {
    return false;
  }
};

export const updateGeneral = (data) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      postDataAPI("admin/helpline/number/edit", data, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });

    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });

    dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};

export const editGeneral = (id) => async (dispatch) => {
  const token = localStorage.getItem("adminToken");
  try {
    const response = await dispatch(
      getDataAPI(`admin/helpline/number/edit/${id}`, token)
    );
    return response.data.data;
  } catch (error) {
    return false;
  }
};
export const editContact = (id) => async (dispatch) => {
  const token = localStorage.getItem("adminToken");
  try {
    const response = await dispatch(
      getDataAPI(`admin/enquiry/numbers/edit/${id}`, token)
    );
    return response.data.data;
  } catch (error) {
    return false;
  }
};

export const deleteContact = (id) => async (dispatch) => {
  const token = localStorage.getItem("adminToken");
  try {
    const response = await dispatch(
      getDataAPI(`admin/enquiry/numbers/delete/${id}`, token)
    );
    return true;
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: { error: error.response?.data?.message || error.message },
    });
    return false;
  }
};
