import React from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  closeComplaint,
  complaintComments,
} from "../../store/actions/helpdeskActions/complaint";
import { COMPLAINT_BYS } from "../../utils/constant";
import ComplaintChat from "./ComplaintChat";

const Details = ({ data, fetchDet }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [replies, setReplies] = useState([]);
  const handleDownload = (e, files) => {
    e.preventDefault();
    files.forEach(function (value, idx) {
      const response = {
        file: value.image,
      };
      setTimeout(() => {
        window.location.href = response.file;
      }, idx * 100);
    });
  };

  const fetchChat = useCallback(
    async (id) => {
      const list = await dispatch(complaintComments(id));
      if (list) {
        setReplies(list);
      }
    },
    [dispatch]
  );

  const handleCloseTicket = async () => {
    const response = await dispatch(
      closeComplaint({ complain_number: data.complain_number })
    );
    if (response) {
      fetchDet();
    }
  };

  useEffect(() => {
    if (data.complain_number) {
      fetchChat(data.complain_number);
    }
  }, [data, fetchChat]);

  return (
    <div>
      <div class=" p-0">
        <div class="col-md-12 mb-4">
          <div class="card" id="invoice">
            <div class="card-header bg-transparent header-elements-inline d-flex justify-content-around">
              <h6 class="invoice-color mb-2 mt-md-2">
                Complaint Number - <span>{data.complain_number}</span>
              </h6>
              <h6 class=" text-primary invoice-color">
                Complaint Status:{" "}
                {data.is_active == "1" ? (
                  <span class="text-info">Closed</span>
                ) : (
                  <span class="text-danger">In Progress</span>
                )}
              </h6>
            </div>
            <div class="card-body">
              <div class="d-md-flex flex-md-wrap mb-4">
                <div className="col-md-12">
                  <div className="row">
                    <span class="text-muted">Complaint Details:</span>
                    <div class="col-md-6">
                      <ul class="list list-unstyled mb-0">
                        <li>
                          <span class="font-weight-semibold">
                            <span class="sd text-muted">
                              {data.type == COMPLAINT_BYS.center
                                ? "Center Code: "
                                : "Roll Number: "}{" "}
                            </span>
                            {data.type == COMPLAINT_BYS.center
                              ? data.center_code
                              : data.roll_number}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul class="list list-unstyled mb-0">
                        <li>
                          <span class="font-weight-semibold">
                            <span class="sd text-muted">Complaint By: </span>
                            {data.type == COMPLAINT_BYS.center
                              ? data.md_name
                              : data.student_name}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul class="list list-unstyled mb-0">
                        <li>
                          <span class="font-weight-semibold">
                            <span class="sd text-muted">Attachment: </span>
                            {data.image && data.image.length > 0 ? (
                              <Button
                                onClick={(e) => handleDownload(e, data.image)}
                                variant="outline-success"
                              >
                                Download
                              </Button>
                            ) : (
                              "No Attachment"
                            )}
                          </span>
                        </li>
                      </ul>
                    </div>
                    {history.location.pathname.includes("helpdesk") &&
                      data.is_active == "0" && (
                        <div className="col-md-6">
                          <ul class="list list-unstyled mb-0">
                            <li>
                              <span class="font-weight-semibold">
                                <span class="sd text-muted">Action: </span>
                                <Button
                                  onClick={handleCloseTicket}
                                  variant="outline-success"
                                >
                                  Close Ticket
                                </Button>
                              </span>
                            </li>
                          </ul>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <ul class="list list-unstyled mb-0">
                  <li>
                    <span class="font-weight-semibold">
                      <span class="sd text-muted">Complaint Subject: </span>
                      {data.title}
                    </span>
                  </li>
                </ul>
              </div>
              <div class="col-md-12">
                <div class="boxlabel">
                  <p>Complaint Description</p>

                  <div
                    style={{
                      width: "100%",
                      maxHeight: "300px",
                      overflow: "auto",
                      background: "#ADD8E6",
                      padding: "10px",
                      borderRadius: "10px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: data.description,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ComplaintChat
          replies={replies}
          complain_number={data.complain_number}
          fetch={fetchChat}
          name={
            data.type == COMPLAINT_BYS.center ? data.md_name : data.student_name
          }
          active={data.is_active}
        />
      </div>
    </div>
  );
};

export default Details;
