import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { getStudentVerification } from "../../store/actions/globalActions";
import "./css/styles.css";

export default function StudentVerification() {
  const [roll, setroll] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [student, setStudent] = useState(null);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await dispatch(getStudentVerification(roll));
    if (response) {
      setLoading(false);
      setStudent(response);
    } else {
      setStudent(false);
      setLoading(false);
    }
  };
  return (
    <div>
      <div className="gallery-box">
        <div className="container">
          <div className="title-box">
            <div className="col-md-12 inner">
              <section className="innerbanner">
                <h2>Examination Result</h2>
                <ol className="breadcrumb">
                  <li>
                    <Link to="/" className="none">
                      Home
                    </Link>
                  </li>
                  <li>/ </li>
                  <li className="active">Examination Result & Verification</li>
                </ol>
              </section>
              <div className="clear-fix"></div>
              <div className="col-lg-12">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-12">
                      {" "}
                      <h3>
                        Search by Month &amp; Year of Examination Held-In with
                        Roll Number
                      </h3>
                    </div>
                    <div className="col-lg-4 table-box">
                      Candidate's Roll Number
                    </div>
                    <div className="col-lg-8 table-box">
                      <input
                        type="text"
                        maxlength="10"
                        id="ContentPlaceHolder1_txtRollNo"
                        className="input-md textinput textInput form-control"
                        placeholder="Please Enter Roll Number"
                        name="roll"
                        value={roll}
                        onChange={(e) => setroll(e.target.value)}
                        style={{ width: "202px" }}
                        required
                      />
                    </div>
                    <div className="col-lg-4 table-box"></div>
                    <div className="col-lg-8 table-box">
                      <input
                        type="submit"
                        value="Search Result"
                        className="btn btn-primary btn btn-info"
                      />
                    </div>
                  </div>{" "}
                </form>
              </div>
              {loading ? (
                <div className="text-center">
                  <Spinner animation="border" role="status" size="lg">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : student === null ? null : student ? (
                <div className="col-lg-12">
                  <h3>Result</h3>
                  <div className="resultData">
                    <div className="row">
                      <div className="col-lg-4 table-box">Roll No</div>
                      <div className="col-lg-8 table-box">
                        {student.roll_number}
                      </div>

                      <div className="col-lg-4 table-box">Candidate Name</div>
                      <div className="col-lg-8 table-box">{student.name}</div>
                      <div className="col-lg-4 table-box">Father's Name</div>
                      <div className="col-lg-8 table-box">
                        {student.student_details &&
                          `${student.student_details.father_title} ${student.student_details.father_name}`}
                      </div>
                      <div className="col-lg-4 table-box">Traning Center</div>
                      <div className="col-lg-8 table-box">
                        {student.center?.center_name}
                      </div>
                      <div className="col-lg-4 table-box">Course</div>
                      <div className="col-lg-8 table-box">
                        {`${student.course?.name} (${student.course?.duration})`}
                      </div>
                      <div className="col-lg-4 table-box">Result</div>
                      <div className="col-lg-8 table-box">
                        <a
                          href={student.result.result}
                          target="_blank"
                          className="btn btn-info"
                        >
                          Download result
                        </a>
                      </div>
                    </div>
                  </div>
                  {student.result ? (
                    student.course.exam_type == "course_wise" ? (
                      <>
                        <div className="resultData mt-3">
                          {student.result.Marksheet?.map((mark) => (
                            <div className="row">
                              <div className="col-lg-12">
                                <h3>{"Marksheet"}</h3>
                              </div>
                              <div className="col-lg-4 table-box">Year</div>
                              <div className="col-lg-8 table-box">
                                {student.result.exam_date}
                              </div>
                              <div className="col-lg-4 table-box"> Marks</div>
                              <div className="col-lg-8 table-box">
                                Total Marks: {mark.total_marks}, Obtain Marks:{" "}
                                {mark.obt_marks}
                              </div>

                              <div className="col-lg-4 table-box"> Result</div>
                              <div className="col-lg-8 table-box">
                                {(mark.obt_marks / mark.total_marks) * 100 > 40
                                  ? "Pass"
                                  : "Fail"}
                              </div>
                              <div className="col-lg-4 table-box">Division</div>
                              <div className="col-lg-8 table-box">
                                {mark.division}
                              </div>
                              <div className="col-lg-4 table-box">
                                Enrollment Number
                              </div>
                              <div className="col-lg-8 table-box">
                                {student.enrollment}
                              </div>
                              <div className="col-lg-4 table-box">
                                {" "}
                                Marksheet
                              </div>
                              <div className="col-lg-8 table-box">
                                {mark.Marksheet ? (
                                  <a
                                    href={mark.Marksheet}
                                    target="_blank"
                                    className="btn btn-info"
                                  >
                                    Download Marksheet
                                  </a>
                                ) : (
                                  "Pending"
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="resultData mt-3">
                          <div className="row">
                            <div className="col-lg-12">
                              <h3>{`Course :  ${student.course.name}`}</h3>
                            </div>
                            <div className="col-lg-4 table-box">Year</div>
                            <div className="col-lg-8 table-box">
                              {student.result.exam_date}
                            </div>
                            <div className="col-lg-4 table-box">
                              {" "}
                              Certificate Number
                            </div>
                            <div className="col-lg-8 table-box">
                              {student.result.certificateNUmber}
                            </div>
                            <div className="col-lg-4 table-box">
                              {" "}
                              Certificate
                            </div>
                            <div className="col-lg-8 table-box">
                              {student.result.CertificateData ? (
                                <a
                                  href={student.result.CertificateData}
                                  target="_blank"
                                  className="btn btn-info"
                                >
                                  Download Certificate
                                </a>
                              ) : (
                                "Pending"
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {student.result.Marksheet
                          ? student.result.Marksheet.map((mark, index) => (
                              <div className="resultData mt-3">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <h3>
                                      {student.course.course_type == "2"
                                        ? `Semester ${index + 1}`
                                        : `Year ${index + 1}`}
                                    </h3>
                                  </div>
                                  <div className="col-lg-4 table-box">Year</div>
                                  <div className="col-lg-8 table-box">
                                    {mark.exam_date}
                                  </div>
                                  <div className="col-lg-4 table-box">
                                    {" "}
                                    Marks
                                  </div>
                                  <div className="col-lg-8 table-box">
                                    Total Marks: {mark.total_marks}, Obtain
                                    Marks: {mark.obt_marks}
                                  </div>

                                  <div className="col-lg-4 table-box">
                                    {" "}
                                    Result
                                  </div>
                                  <div className="col-lg-8 table-box">
                                    {(mark.obt_marks / mark.total_marks) * 100 >
                                    40
                                      ? "Pass"
                                      : "Fail"}
                                  </div>
                                  <div className="col-lg-4 table-box">
                                    Division
                                  </div>
                                  <div className="col-lg-8 table-box">
                                    {mark.division}
                                  </div>
                                  <div className="col-lg-4 table-box">
                                    Enrollment Number
                                  </div>
                                  <div className="col-lg-8 table-box">
                                    {student.enrollment}
                                  </div>
                                  <div className="col-lg-4 table-box">
                                    {" "}
                                    Marksheet
                                  </div>
                                  <div className="col-lg-8 table-box">
                                    <a
                                      href={mark.Marksheet}
                                      target="_blank"
                                      className="btn btn-info"
                                    >
                                      Download Marksheet
                                    </a>
                                  </div>
                                </div>
                              </div>
                            ))
                          : null}

                        <div className="resultData mt-3">
                          <div className="row">
                            <div className="col-lg-12">
                              <h3>{`Course :  ${student.course.name}`}</h3>
                            </div>
                            <div className="col-lg-4 table-box">
                              {" "}
                              Certificate Number
                            </div>
                            <div className="col-lg-8 table-box">
                              {student.result.certificateNUmber || "Pending"}
                            </div>
                            <div className="col-lg-4 table-box">
                              Certificate
                            </div>
                            <div className="col-lg-8 table-box">
                              {student.result.CertificateData ? (
                                <a
                                  href={student.result.CertificateData}
                                  target="_blank"
                                  className="btn btn-info"
                                >
                                  Download Certificate
                                </a>
                              ) : (
                                "Pending"
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  ) : (
                    <>
                      {student.marksheet?.length > 0 ? (
                        student.marksheet.map((mark) => (
                          <div className="resultData mt-3">
                            <div className="row">
                              <div className="col-lg-12">
                                <h3>{`Year ${mark.semster_year}`}</h3>
                              </div>
                              <div className="col-lg-4 table-box">Year</div>
                              <div className="col-lg-8 table-box">
                                {moment(mark.date).format("MMM-YYYY")}
                              </div>
                              <div className="col-lg-4 table-box"> Marks</div>
                              <div className="col-lg-8 table-box">
                                Total Marks: {mark.total_marks}, Obtain Marks:{" "}
                                {mark.obtain_marks}
                              </div>

                              <div className="col-lg-4 table-box"> Result</div>
                              <div className="col-lg-8 table-box">
                                {mark.status == "1" ? "Pass" : "Fail"}
                              </div>
                              <div className="col-lg-4 table-box">
                                {" "}
                                Enrollment Number
                              </div>
                              <div className="col-lg-8 table-box">
                                {mark.enrollment_no}
                              </div>
                              <div className="col-lg-4 table-box">
                                {" "}
                                Marksheet
                              </div>
                              <div className="col-lg-8 table-box">
                                <a
                                  href={mark.pdf}
                                  target="_blank"
                                  className="btn btn-info"
                                >
                                  Download Marksheet
                                </a>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <h1>No Marksheet</h1>
                      )}
                      {student.certificate?.length > 0 ? (
                        student.certificate.map((cer) => (
                          <div className="resultData">
                            <div className="row">
                              <div className="col-lg-12">
                                <h3>Diploma</h3>
                              </div>

                              <div className="col-lg-4 table-box">Year</div>
                              <div className="col-lg-8 table-box">
                                {moment(cer.date).format("DD-MMM-YYYY")}
                              </div>
                              <div className="col-lg-4 table-box">
                                {" "}
                                Certificate Number
                              </div>
                              <div className="col-lg-8 table-box">
                                {cer.certificate_reg_no}
                              </div>
                              <div className="col-lg-4 table-box">
                                {" "}
                                Certificate
                              </div>
                              <div className="col-lg-8 table-box">
                                <a
                                  href={cer.pdf}
                                  target="_blank"
                                  className="btn btn-info"
                                >
                                  Download Certificate
                                </a>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <h3>No record Found</h3>
                      )}
                      {student.reg?.length > 0 ? (
                        student.reg.map((cer) => (
                          <div className="resultData">
                            <div className="row">
                              <div className="col-lg-12">
                                <h3>Registration</h3>
                              </div>
                              <div className="col-lg-4 table-box">Year</div>
                              <div className="col-lg-8 table-box">
                                {moment(cer.date).format("DD-MMM-YYYY")}
                              </div>
                              <div className="col-lg-4 table-box">
                                {" "}
                                Certificate Number
                              </div>
                              <div className="col-lg-8 table-box">
                                {cer.certificate_reg_no}
                              </div>
                              <div className="col-lg-4 table-box">
                                {" "}
                                Certificate
                              </div>
                              <div className="col-lg-8 table-box">
                                <a
                                  href={cer.pdf}
                                  target="_blank"
                                  className="btn btn-info"
                                >
                                  Download Certificate
                                </a>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <h3>No record Found</h3>
                      )}
                    </>
                  )}

                  <p>
                    <br />
                    <strong>Disclaimer : </strong>National Paramedical &
                    Vocational Education Council (New Delhi) is not responsible
                    for any inadvertent error that may have crept in the result
                    being published on NET. The result published on net is for
                    immediate information to the candidates. These cannot be
                    treated as original result.
                  </p>
                </div>
              ) : (
                <h1>No Record Found</h1>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
