import moment from 'moment/moment'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Spinner, Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getStudentFees } from '../../../store/actions/studentAction/feesAction'
import { courseType, examType, getSemester, getYears } from '../../../utils/constant'

const StudentFees = () => {
  const [exist,setExist]=useState(false)
  const [loading,setLoading]=useState(true)
  const [data,setData]=useState(null)
    const [paidSems, setPaidSems] = useState([]);
  const dispatch=useDispatch()
    const { studentAuth } = useSelector((state) => state.student);
    const { profile } = studentAuth;
    const {course} = profile
  useEffect(()=>{
    dispatch(getStudentFees(setLoading,setExist,setData))
  },[dispatch])

  useEffect(()=>{
    if(data){
       const list = [];
      data?.map((fee) => {
        return list.push(+fee.semester);
      });
      setPaidSems(list);
    }
  },[data])
  //  <td>
  //                             {student.submitFees?.length > 0 ? (
  //                               student.course?.exam_type ===
  //                               examType.course_wise ? (
  //                                 <a
  //                                   className="btn btn-success btn-sm"
  //                                   href={student.submitFees[0]?.link}
  //                                   target="_blank"
  //                                   rel="noreferrer"
  //                                 >
  //                                   Receipt
  //                                 </a>
  //                               ) : (
  //                                 <select onChange={handleDownload}>
  //                                   <option>--Select--</option>
  //                                   {student.submitFees.map((fee) => (
  //                                     <option value={fee.link}>
  //                                       {student.course?.course_type ===
  //                                       courseType.semester
  //                                         ? "Semester"
  //                                         : "Year"}{" "}
  //                                       {fee.semester}
  //                                     </option>
  //                                   ))}
  //                                 </select>
  //                               )
  //                             ) : (
  //                               "Pending"
  //                             )}
  //                           </td>
  return (
    <div className="Title-box">
        <div className="row">
          <div className="col-md-6">
            <div className="BigTitle">Fees Status</div>
          </div>
        </div>
        {loading ?  <div className="text-center">
                    <Spinner animation="border" role="status" size="lg">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div> : !exist ? "Fees Not Paid" :  course.exam_type === examType.course_wise ? (
                          <>
                            <Table>
                              <thead>
                                <th>Fees</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th>Receipt</th>
                              </thead>
                              <tbody>
                                {!data?.length ? (
                                  <tr>
                                    <td>
                                      --
                                    </td>
                                    <td>--</td>
                                    <td>
                                      Pending
                                    </td>
                                     <td>
                                      NA
                                    </td>
                                  </tr>
                                ) : (
                                  data?.map((fee) => (
                                    <tr>
                                      <td>{fee.total_fees}</td>
                                      <td>
                                        {moment(fee.createdAt).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </td>
                                      <td>Paid</td>
                                      <td><a target="_blank" rel="noreferrer"  href={fee.link}>View</a></td>
                                    </tr>
                                  ))
                                )}
                              </tbody>
                            </Table>
                          </>
                        ) : course.course_type == courseType.semester ? (
                          <>
                            <Table>
                              <thead>
                                <th>Fees</th>
                                <th>Semester</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th>Receipt</th>
                              </thead>
                              <tbody>
                                {data?.map((fee) => (
                                  <tr>
                                    <td>{fee.total_fees}</td>
                                    <td>{fee.semester}</td>
                                    <td>
                                      {moment(fee.createdAt).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td>Paid</td>
                                     <td><a target="_blank" rel="noreferrer"  href={fee.link}>View</a></td>
                                  </tr>
                                ))}
                                {Array.from(
                                  { length: getSemester(course.duration) },
                                  (_, i) => i + 1
                                )
                                  .filter((x) => !paidSems.includes(x))
                                  ?.map((sem) => (
                                    <tr>
                                      <td>
                                        --
                                      </td>
                                      <td>{sem}</td>
                                      <td>--</td>
                                      <td>
                                        Pending
                                      </td>
                                      <td>NA</td>
                                    </tr>
                                  ))}
                              </tbody>
                            </Table>
                          </>
                        ) : (
                          <>
                            <Table>
                              <thead>
                                <th>Fees</th>
                                <th>Year</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th>Receipt</th>
                              </thead>

                              {data?.map((fee) => (
                                <tr>
                                  <td>{fee.total_fees}</td>
                                  <td>{fee.semester}</td>
                                  <td>
                                    {moment(data.createdAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                  <td>Paid</td>
                                  <td><a target="_blank" rel="noreferrer"  href={fee.link}>View</a></td>
                                </tr>
                              ))}
                              {Array.from(
                                { length: getYears(data.duration) },
                                (_, i) => i + 1
                              )
                                .filter((x) => !paidSems.includes(x))
                                ?.map((sem) => (
                                  <tr>
                                    <td>
                                      --
                                    </td>
                                    <td>{sem}</td>
                                    <td>--</td>
                                    <td>Pending</td>
                                    <td>NA</td>
                                  </tr>
                                ))}
                            </Table>
                          </>
                        )}
      </div>

  )
}

export default StudentFees