import { postDataAPI } from '../../../services/apiWrapper';
import { ALERT } from '../actionTypes';

export const addMarks = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem('adminToken');
    const response = await dispatch(
      postDataAPI('admin/get-offline-exam', data, token)
    );
    return response.data || false;
  } catch (error) {
    return false;
  }
};

export const uploadMarks = (data) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('adminToken');
      const response = await dispatch(
        postDataAPI('admin/upload-marks', data, token)
      );
      if (response) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export const uploadResultDate = (data) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('adminToken');
      const response = await dispatch(
        postDataAPI('admin/update-date', data, token)
      );
      if (response) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error.response);
      if (error.response?.data?.message == 'Validation errors') {
        Object.entries(error.response.data.data).map((d) => {
          dispatch({
            type: ALERT,
            payload: { error: d[1][0] }
          });
        });
      } else {
        dispatch({
          type: ALERT,
          payload: { loading: false, error: error.response?.data?.message }
        });
      }
      return false;
    }
  };
};
