import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import BreadCrumb from "../BreadCrumb";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { complaintDetails } from "../../store/actions/helpdeskActions/complaint";
import moment from "moment";
import Details from "./Details";

const ComplaintDetails = () => {
  const breadcrumbs = [
    {
      path: "/helpdesk/dashboard",
      label: "Dashboard",
    },
    {
      label: "Complaints details",
    },
  ];

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { id } = useParams();

  const fetchdata = useCallback(async () => {
    const response = await dispatch(complaintDetails(id));
    if (response) {
      setData(response);
      setLoading(false);
    } else {
      setData(false);
      setLoading(false);
    }
  }, [id, dispatch]);
  useEffect(() => {
    setLoading(true);
    fetchdata();
  }, [fetchdata]);

  return (
    <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
      <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
        <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <BreadCrumb crumbs={breadcrumbs} />
          <div class="d-flex align-items-center">
            {data && (
              <p>Created: {moment(data.created_at).format("DD/MM/YYYY")}</p>
            )}
          </div>
        </div>
      </div>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status" size="lg">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : data ? (
        <div className="">
          <Details data={data} fetchDet={fetchdata} />
        </div>
      ) : data === false ? (
        "No Record Found"
      ) : null}
    </div>
  );
};

export default ComplaintDetails;
