import { PRINT_STATUS, STUDENT_VERIFICATION, VIEW_GALLERY } from "../actions/actionTypes";

const initialState = {};

export const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case STUDENT_VERIFICATION:
      return {
        ...state,
        student: action.payload,
      };
    case VIEW_GALLERY:
      return {
        ...state,
        gallery: action.payload,
      };
      case PRINT_STATUS:
        return{
          ...state,
          gallery:action.payload,
        };
    default:
      return state;
  }
};
