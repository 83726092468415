import { getDataAPI, postDataAPI } from "../../../services/apiWrapper";
import { ALERT } from "../actionTypes";
import { exportToCSV } from "../../../utils/exportCsv";

export const addEmail = (data) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      postDataAPI("admin/important/emails/create", data, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });

    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });

    dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};

export const updateEmail = (data) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      postDataAPI("admin/important/emails/edit", data, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });

    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });

    dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};

export const emailList = (page) => async (dispatch) => {
  try {
    const response = await dispatch(
      getDataAPI(`admin/important/emails/list?page=${page}`)
    );
    return response.data.data;
  } catch (error) {
    return false;
  }
};

export const downloadEmail = () => async (dispatch) => {
  try {
    const response = await dispatch(getDataAPI(`admin/important/emails/list`));
    const dataToExport = [];
    response.data.data.forEach((ele, index) => {
      dataToExport.push({
        Sno: index + 1,
        Name: ele.name,
        Designation: ele.designation,
        Email: ele.email_id,
      });
    });
    exportToCSV(dataToExport, "Email");
  } catch (error) {
    return false;
  }
};

export const editEmail = (id) => async (dispatch) => {
  const token = localStorage.getItem("adminToken");
  try {
    const response = await dispatch(
      getDataAPI(`admin/important/emails/edit/${id}`, token)
    );
    return response.data.data;
  } catch (error) {
    return false;
  }
};

export const deleteEmail = (id) => async (dispatch) => {
  const token = localStorage.getItem("adminToken");
  try {
    const response = await dispatch(
      getDataAPI(`admin/important/emails/delete/${id}`, token)
    );
    return true;
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: { error: error.response?.data?.message || error.message },
    });
    return false;
  }
};
