import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import moment from "moment";

export default function Dashboard() {
  const dispatch = useDispatch();
  const { member } = useSelector((state) => state);
  const { memberAuth } = member;
  const { profile } = memberAuth;
  const member_type = localStorage.getItem("member_type");
  // const fetchData = async () => {
  //   const response = await dispatch(getSubject(profile.id, profile.course.id));
  // };

  // useEffect(() => {
  //   if (profile) {
  //     fetchData();
  //   }
  // }, [profile]);

  return (
    <div class="mid-content-box">
      <div class="box-welcome">
        <div class="row">
          <div class="col-md-6">
            <div class="box-status">
              <div class="box-statusImg">
                <img src="/images/girl.png" />
              </div>
              <div class="box-statusData">
                <h2>
                  WELCOME <span> {profile?.student_name}</span>
                </h2>
              </div>
            </div>
          </div>
          {/* <div class="col-md-6">
            <ul class="selectDrops">
              <li>
                <Link to="/center/add-student" class="addNewchild">
                  Add Student
                </Link>
              </li>
            </ul>
          </div> */}
        </div>
      </div>

      {/* <div class="grid-box">
        <div class="row">
          <div class="col-lg-2 col-md-4 col-sm-6">
            <div class="grid-data gcolor1">
              <div class="img-data">
                <img src="/images/student.png" />
              </div>
              <div class="grid-content">
                <h5>Category</h5>
            
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6">
            <div class="grid-data gcolor2">
              <div class="img-data">
                <img src="/images/student.png" />
              </div>
              <div class="grid-content">
                <h5>SubCategory</h5>
          
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6">
            <div class="grid-data gcolor3">
              <div class="img-data">
                <img src="/images/student.png" />
              </div>
              <div class="grid-content">
                <h5>Pages</h5>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div class="Title-box">
        <div class="row">
          <div class="col-md-6"></div>
        </div>
      </div>
      {/* <div class="Widthdata">
        <div class="Applicationdata">
          <div class="cartitems">
            <div class="cartitembox">
              {" "}
              <div class="PaymentStatus">
                <a
                  href="/colleges/N-K-Paramedical---Medical-Research-Center-Meerut-U-P-/7"
                  target="_blank"
                  class="btn btn-makepay"
                >
                  {" "}
                  View
                </a>
              </div>
              <div class="cartTop row">
                <div class="cartThumb col-md-5">
                  <div class="cartImg">
                    <img height={"100%"} src={profile.md_pic} />
                  </div>
                </div>
                <div class="cartCentent col-md-7">
                  <div class="quick-names">
                    <div class="QVasName">{profile.center_name}</div>
                    <div class="QLocationmark">
                      {`${profile.center_address}  ${profile.center_city} ${profile.center_state}`}
                    </div>
                  </div>

                  <div class="cartAdate">
                    <div class="col-Dates">
                      <h5>Md Name</h5>
                      <h6>{profile.md_name}</h6>
                    </div>

                    <div class="col-Dates">
                      <h5>Contact Number</h5>
                      <h6>{profile.mobile}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="Title-box">
        <div className="row">
          <div className="col-md-6">
            <div className="BigTitle">My Profile</div>
          </div>
          <div className="col-md-6">
            <div className="CommanForm d-flex">
              <div className="btncomplt">
                <Link to="/student/assessments" className="btn btn-comman">
                  Assessments{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Widthdata">
        <div className="Applicationdata">
          <div className="cartitems">
            <div className="cartitembox">
              {" "}
              <div className="PaymentStatus"></div>
              <div className="cartTop row">
                <div className=" col-lg-3">
                  <div className="text-center">
                    <div className="cartImg m-auto">
                      <img src={profile?.student_image} />
                    </div>
                  </div>
                </div>
                <div className=" col-lg-9">
                  <div className="">
                    <div className="quick-names text-center">
                      <div className="QVasName text-center ">
                        {profile?.student_name}
                      </div>
                      <div className="QLocationmark">
                        {`${profile?.address}, ${profile?.city}, ${profile?.state}`}
                      </div>
                      <div className="CenterName">
                        <i className="fa fa-building-o" aria-hidden="true"></i>
                        {"  "}

                        {`${profile?.center_name}, ${profile?.center_city}, ${profile?.center_state}`}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-6">
                <div className="col-md-3 text-center profile-item">
                  <h5>Course</h5>
                  <h6>{profile?.course}</h6>
                </div>
                <div className="col-md-3 text-center profile-item">
                  <h5>Certificate Member</h5>
                  <h6>{profile?.roll_number}</h6>
                </div>
                <div className="col-md-3 text-center profile-item ">
                  <h5>Contact Number</h5>
                  <h6>{profile?.mobile}</h6>
                </div>
                <div className="col-md-3 text-center profile-item ">
                  <h5>Registration Number</h5>
                  <h6>{profile?.registration_number}</h6>
                </div>
                {/* <div className="col-md-3 text-center profile-item">
                  <h5>Father Name</h5>
                  <h6>{` ${profile?.father_name}`}</h6>
                </div> */}

                <div className="col-md-3 text-center profile-item">
                  <h5>Certificate</h5>
                  {/* <h6>{` ${profile?.father_name}`}</h6> */}
                  {member_type === "Individual" ? (
                    <a
                      href={`https://npvec.com/api/public/Individual/MemberData/${profile?.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => {
                        window.open(
                          `https://npvec.com/api/public/Individual/MemberData/${profile?.id}`,
                          "_blank"
                        );
                      }}
                    >
                      View Certificate
                    </a>
                  ) : (
                    <a
                      href={`https://npvec.com/api/public/MemberData/${profile?.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => {
                        window.open(
                          `https://npvec.com/api/public/MemberData/${profile?.id}`,
                          "_blank"
                        );
                      }}
                    >
                      View Certificate
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
