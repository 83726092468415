import React, { useEffect, useState } from "react";
import BreadCrumb from "../../BreadCrumb";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  addCategory,
  updateCategory,
  getCategoryDetail,
  getCategory,
  addPage,
  getSubCategory,
  getsubCategoryId,
} from "../../../store/actions/printActions/printcategory";

const AddPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [exist, setExist] = useState(true);
  const [list, setList] = useState([]);
  const [sublist, setSubList] = useState([]);
  const [data, setData] = useState({
    category: 0,
    subcategory: 0,
    do_description: "",
    donts_description: "",
    terms_condition:"",
  });

  useEffect(async () => {
    if (id) {
      const response = await dispatch(getCategoryDetail(id));
      if (response.success) {
        setData({
          do_description: response.data.do_description,
         donts_description: response.data.donts_description,
         terms_condition: response.data.terms_condition,
        });
      } else {
        setExist(false);
      }
    }

    const catResponse = await dispatch(getCategory());
    setList(catResponse.data);
 
  }, [id, dispatch]);

  const handleChange = async (e) => {
    const { name, value } = e.target;

    if (name === "category") {
      setData((prevData) => ({
        ...prevData,
        // subcategory:data.subcategory, // Reset subcategory
        [name]: value,
         
      }));

      // Fetch subcategories when the category is changed
      const subresponse = await dispatch(getsubCategoryId(value));
      console.log('subresponse', subresponse)
        
      // setSubList(subresponse.data[0].id|| "");
      setSubList(subresponse.data || []);
    } else {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  console.log('sublist', sublist)

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      ...data,
       category: data.category,
      subcategory: data.subcategory,

    };
    
    if (id) {
      const response = await dispatch(updateCategory(payload));
      if (response) {
        history.push("/print/page");
      }
    } else {
      const response = await dispatch(addPage(payload));
      if (response) {
        history.push("/print/page");
      }
    }
  };

  return (
    <div>
      <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div className="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
          <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <div className="d-flex align-items-center">
              <Link to={"/print/page"} className="btn btn-light-primary font-weight-bolder btn-sm">
                View List
              </Link>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              {exist ? (
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label>
                      Category <span className="text-danger">*</span>
                    </label>
                    <select className="form-control" name="category" value={data.category} onChange={handleChange}>
                      {list?.map((ele) => (
                        <option key={ele.id} value={ele?.id}>
                          {ele?.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group">
                    <label>
                      SubCategory <span className="text-danger">*</span>
                    </label>
                    <select className="form-control" name="subcategory" value={data.subcategory} onChange={handleChange}>
                      {sublist?.map((ele) => {
                        return(
                          <>
                          <option key=""value="">Select subcategory</option>
                           <option key={ele.id} value={ele?.id}>
                          {ele?.name}
                        </option>
                          </>
                        )
                       
                        })}
                    </select>
                  </div>
               
                  <div className="form-group">
                    <label>
                      DO's <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name "
                      name="do_description"
                      value={data.do_description}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      DON't <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Description "
                      name="donts_description"
                      value={data.donts_description}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      Terms and Conditions <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name "
                      name="terms_condition"
                      value={data.terms_condition}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <button type="submit" className="btn btn-primary mr-2">
                    Submit
                  </button>
                </form>
              ) : (
                <h1>Record does not exist</h1>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPage;
