import axios from "axios";
import { ALERT, AUTH_FAIL, REMOVE_ALERT } from "../store/actions/actionTypes";
const api = "https://npvec.com/api/public/api/";

export const getDataAPI = (url, token) => async (dispatch) => {
  try {
    const res = await axios.get(`${api}${url}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setTimeout(() => {
      dispatch({
        type: REMOVE_ALERT,
      });
    }, 5000);
    return res;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch({
        type: AUTH_FAIL,
      });
      // dispatch({
      //   type: ALERT,
      //   payload: { error: error.response.data?.status },
      // });
    }
    setTimeout(() => {
      dispatch({
        type: REMOVE_ALERT,
      });
    }, 5000);
    throw error;
  }
};

export const postDataAPI = (url, post, token) => async (dispatch) => {
  try {
    const res = await axios.post(`${api}${url}`, post, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setTimeout(() => {
      dispatch({
        type: REMOVE_ALERT,
      });
    }, 5000);
    return res;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch({
        type: AUTH_FAIL,
      });
      dispatch({
        type: ALERT,
        payload: { error: error.response.data?.status },
      });
      setTimeout(() => {
        dispatch({
          type: REMOVE_ALERT,
        });
      }, 5000);
    }

    setTimeout(() => {
      dispatch({
        type: REMOVE_ALERT,
      });
    }, 5000);
    throw error;
  }
};

export const putDataAPI = async (url, post, token) => {
  const res = await axios.put(`${api}${url}`, post, {
    headers: { Authorization: token },
  });
  return res;
};

export const patchDataAPI = async (url, post, token) => {
  const res = await axios.patch(`${api}${url}`, post, {
    headers: { Authorization: token },
  });
  return res;
};

export const deleteDataAPI = (url, token) => async (dispatch) => {
  try {
    const res = await axios.delete(`${api}${url}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setTimeout(() => {
      dispatch({
        type: REMOVE_ALERT,
      });
    }, 5000);
    return res;
  } catch (error) {
    if (error.response.status === 401) {
      dispatch({
        type: AUTH_FAIL,
      });
      dispatch({
        type: ALERT,
        payload: { error: error.response.data?.status },
      });
    }
    setTimeout(() => {
      dispatch({
        type: REMOVE_ALERT,
      });
    }, 5000);
    throw error;
  }
};
