import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import "./css/login.css";
import { ALERT } from "../../../store/actions/actionTypes";
import { useHistory } from "react-router-dom";
import { adminDashboard, adminLogin } from "../../../store/actions/adminActions/adminAuth";

export default function PrintPortalLogin(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { admin } = useSelector((state) => state);
  console.log('admin', admin)
  const { adminAuth } = admin;

  useEffect(() => {
    adminAuth.isAuth &&
      history.push(props.location.state?.from.pathname || "/print/dashboard");
  }, [adminAuth.isAuth]);

  // useEffect(() => {
  //   dispatch(adminDashboard());
  // }, []);

  const [data, setData] = useState({ email: "", password: "", user_type: "4" });
  const { email, password, user_type } = data;
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(adminLogin(data));
    dispatch({
      type: ALERT,
      payload: { loading: true },
    });
    // adminAuth.isAuth &&
    //  history.push( "/print");
  };
  return (
    <>
      <div className="sc-VigVT kCeVIa sc-gZMcBi jxPJzF"></div>
      <section className="logindesign">
        <div className="container">
          <div className="Formboxlogin">
            <div className="innerForm">
              <div className="Login-header">Login to your account</div>

              <form 
               onSubmit={handleSubmit}
              >
                <div className="telBoxform">
                  <div className="boxlabel"></div>

                  <div className="formBox2">
                    <input
                      type="email"
                      placeholder="Enter Your Email*"
                      name="email"
                      value={email}
                      onChange={handleChange}
                      className="form-control"s
                    />
                  </div>

                  <div className="formBox2">
                    <input
                      type="password"
                      placeholder="Password*"
                      name="password"
                      value={password}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="buttonSub">
                  <button className="btn btn-continues" type="submit">
                    Login
                  </button>
                </div>
              </form>
              <div className="My-help">
                Have trouble logging in? <span>Get help</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
