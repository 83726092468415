import { getDataAPI, postDataAPI } from "../../../services/apiWrapper";
import { ALERT, ASSESSMENT_QUESTIONS, PRACTICE_EXAM } from "../actionTypes";

export const getSubject = (studentId, id) => async (dispatch) => {
  try {
    const token = localStorage.getItem("studentToken");
    const response = await dispatch(
      getDataAPI(`student/subjectList/${studentId}/${id}`, token)
    );
    dispatch({ type: ASSESSMENT_QUESTIONS, payload: response.data.data });
  } catch (error) {
    dispatch({ type: ALERT, payload: { error: error.message } });
  }
};

export const getPracticeQuestions =
  (studentId, subjectId) => async (dispatch) => {
    try {
      const token = localStorage.getItem("studentToken");
      const response = await dispatch(
        getDataAPI(`student/practice-question/${studentId}/${subjectId}`, token)
      );
      dispatch({
        type: PRACTICE_EXAM,
        payload: response.data.data,
      });
      return {
        isSuccess: true,
        data: response.data.data,
      };
    } catch (error) {
      dispatch({ type: ALERT, payload: { error: error.message } });
      return {
        isSuccess: false,
      };
    }
  };

export const submitAnswer = (questionId, answerId, id) => async (dispatch) => {
  try {
    const token = localStorage.getItem("studentToken");
    const response = await dispatch(
      postDataAPI(
        `student/submit-practiceanswer`,
        {
          id: id,
          question_id: questionId,
          answer: answerId,
        },
        token
      )
    );
    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { error: error.message } });
  }
};
