import { getDataAPI, postDataAPI } from "../../../services/apiWrapper";
import { ALERT } from "../actionTypes";

export const submitQuery =
  (data, setSubmitting, success) => async (dispatch) => {
    try {
      dispatch({
        type: ALERT,
        payload: { loading: true },
      });
      const token = localStorage.getItem("helpdeskToken");
      const response = await dispatch(postDataAPI("send/query", data, token));

      dispatch({
        type: ALERT,
        payload: { loading: false, success: response.data.message },
      });
      setSubmitting(false);
      if (response) {
        success(true);
      }
    } catch (error) {
      dispatch({
        type: ALERT,
        payload: { loading: false, error: error.response.data.message },
      });
      setSubmitting(false);
      if (error.response?.data?.message == "Validation errors") {
        Object.entries(error.response.data.data).map((d) => {
          dispatch({
            type: ALERT,
            payload: { error: d[1][0] },
          });
        });
      }
    }
  };

export const centerComplaintslist = (page) => async (dispatch) => {
  try {
    const token = localStorage.getItem("helpdeskToken");
    const response = await dispatch(
      getDataAPI(`admin/center/query?page=${page}`, token)
    );
    return response.data.data;
  } catch (error) {
    return false;
  }
};

export const findMe = (data) => async (dispatch) => {
  try {
    const response = await dispatch(
      postDataAPI(`admin/valid-user`, data)
    );
    return response.data.data;
  } catch (error) {
    return false;
  }
};

export const studentComplaintslist = (page) => async (dispatch) => {
  try {
    const token = localStorage.getItem("helpdeskToken");
    const response = await dispatch(
      getDataAPI(`admin/student/query?page=${page}`, token)
    );
    return response.data.data;
  } catch (error) {
    return false;
  }
};

export const complaintDetails = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem("helpdeskToken");
    const response = await dispatch(
      getDataAPI(`admin/query/details/${id}`, token)
    );
    return response.data.data;
  } catch (error) {
    return false;
  }
};

export const addComment = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("helpdeskToken");
    const response = await dispatch(
      postDataAPI(`admin/add/comments`, data, token)
    );
    return response.data.data;
  } catch (error) {
    return false;
  }
};

export const complaintComments = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem("helpdeskToken");
    const response = await dispatch(
      getDataAPI(`admin/comment/list/${id}`, token)
    );
    return response.data.data;
  } catch (error) {
    return false;
  }
};

export const trackComplaint = (data) => async (dispatch) => {
  try {
    const response = await dispatch(postDataAPI(`admin/get/comments`, data));
    return response.data.data;
  } catch (error) {
    return false;
  }
};

export const closeComplaint = (data) => async (dispatch) => {
  try {
    const response = await dispatch(postDataAPI(`admin/close/query`, data));
    return true;
  } catch (error) {
    return false;
  }
};
