import {  postDataAPI } from "../../../services/apiWrapper";
import {
  ALERT,

} from "../actionTypes";

export const memberRegisterAction = (data) => async (dispatch) => {

  
  try {

    dispatch({ type: ALERT, payload: { loading: true } });


  
      const response = await dispatch(postDataAPI("admin/individual/member/registration",data));
  
      return {
        data: response.data.data || [],
      };
    } catch (error) {
      // dispatch({ type: ALERT, payload: { error: error.response.data.message } });
      return {
        data: [],
      };
    }
};

export const memberOtherRegisterAction = (data) => async (dispatch) => {

  try {

    dispatch({ type: ALERT, payload: { loading: true } });


  
      const response = await dispatch(postDataAPI("admin/other/member/registration",data));
  
      return {
        data: response.data.data || [],
      };
    } catch (error) {
      // dispatch({ type: ALERT, payload: { error: error.response.data.message } });
      return {
        data: [],
      };
    }
};

// export const changePassword = (data) => async (dispatch) => {
//   try {
//     const token = localStorage.getItem("centerToken");
//     dispatch({
//       type: ALERT,
//       payload: { loading: true },
//     });
//     const response = await dispatch(
//       postDataAPI("center/change-password", data, token)
//     );
//     dispatch({
//       type: ALERT,
//       payload: { success: response.data.message },
//     });
//     dispatch({
//       type: ALERT,
//       payload: { loading: false },
//     });
//     return true;
//   } catch (error) {
//     dispatch({
//       type: ALERT,
//       payload: { loading: false },
//     });
//     dispatch({
//       type: ALERT,
//       payload: { loading: false, error: error.response.data.message },
//     });
//     return false;
//   }
// };

// export const centerUpdate = (data) => async (dispatch) => {
//   try {
//     const token = localStorage.getItem("centerToken");
//     dispatch({ type: ALERT, payload: { loading: true } });
//     const response = await dispatch(
//       postDataAPI("center/edit-center", data, token)
//     );
//     dispatch({
//       type: ALERT,
//       payload: { loading: false, success: response.data.message },
//     });
//     dispatch({ type: ALERT, payload: { loading: false } });
//     return true;
//   } catch (error) {
//     dispatch({ type: ALERT, payload: { loading: false } });
//     if (error.response?.data?.message == "Validation errors") {
//       Object.entries(error.response.data.data).map((d) => {
//         dispatch({
//           type: ALERT,
//           payload: { error: d[1][0] },
//         });
//       });
//     }
//     return false;
//   }
// };

// export const updateStaffPic = (data) => async (dispatch) => {
//   try {
//     const token = localStorage.getItem("centerToken");
//     dispatch({ type: ALERT, payload: { loading: true } });
//     const response = await dispatch(
//       postDataAPI("center/update/staff/image", data, token)
//     );
//     dispatch({
//       type: ALERT,
//       payload: { loading: false, success: response.data.message },
//     });
//     dispatch({ type: ALERT, payload: { loading: false } });
//     return true;
//   } catch (error) {
//     dispatch({ type: ALERT, payload: { loading: false } });
//     if (error.response?.data?.message == "Validation errors") {
//       Object.entries(error.response.data.data).map((d) => {
//         dispatch({
//           type: ALERT,
//           payload: { error: d[1][0] },
//         });
//       });
//     }
//     return false;
//   }
// };

// export const centerLogin = (data) => async (dispatch) => {
//   try {
//     dispatch({
//       type: ALERT,
//       payload: { loading: true },
//     });
//     const response = await dispatch(postDataAPI("center/login", data));

//     localStorage.setItem("centerToken", response.data.data.token);
//     dispatch({
//       type: CENTER_AUTH_SUCCESS,
//       payload: response.data.data,
//     });
//     dispatch({
//       type: ALERT,
//       payload: { loading: false, success: response.data.message },
//     });
//   } catch (error) {
//     dispatch({
//       type: ALERT,
//       payload: { loading: false, error: error.response.data.message },
//     });
//     dispatch({
//       type: CENTER_AUTH_FAIL,
//     });
//   }
// };

// export const centerDashboard = () => async (dispatch) => {
//   try {
//     const token = localStorage.getItem("centerToken");
//     dispatch({
//       type: ALERT,
//       payload: { loading: true },
//     });
//     const response = await dispatch(getDataAPI("center/dashboard", token));
//     dispatch({
//       type: CENTER_AUTH_SUCCESS,
//       payload: response.data.data,
//     });
//     dispatch({
//       type: ALERT,
//       payload: { loading: false },
//     });
//   } catch (error) {
//     dispatch({
//       type: ALERT,
//       payload: { loading: false },
//     });
//     dispatch({
//       type: CENTER_AUTH_FAIL,
//     });
//   }
// };
// export const editCenter = () => async (dispatch) => {
//   try {
//     const token = localStorage.getItem("centerToken");
//     dispatch({
//       type: ALERT,
//       payload: { loading: true },
//     });
//     const response = await dispatch(getDataAPI("center/profileGet", token));
//     dispatch({
//       type: CENTER_REGISTER_DETAILS,
//       payload: response.data.data,
//     });
//     dispatch({
//       type: ALERT,
//       payload: { loading: false },
//     });
//   } catch (error) {
//     dispatch({
//       type: ALERT,
//       payload: { loading: false },
//     });
//     // dispatch({
//     //   type: CENTER_AUTH_FAIL,
//     // });
//   }
// };
