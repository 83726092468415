import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getNews } from "../../../store/actions/adminActions/newsAction";
import { getDataAPI } from "../../../services/apiWrapper";

export function useLoadMore(url, pageNumber) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const dispatch = useDispatch();

  useEffect(async () => {
    setLoading(true);
    setError(false);

    try {
      const response = await dispatch(getDataAPI(`${url}${pageNumber}`));
      // if (response) {
      setHasMore(true);
      setLoading(false);
      setData((prevData) => {
        return [...prevData, ...(response.data.data?.data || [])];
      });
      //   });
      // } else {
      // }

      // return {
      //   data: response.data.data?.data || [],
      //   last_page: response.data.data.last_page,
      // };
    } catch (error) {
      // const response = await dispatch(getNews(pageNumber));

      // dispatch({ type: ALERT, payload: { error: error.response.data.message } });
      setLoading(false);
      setHasMore(false);
    }
  }, [pageNumber]);
  return [loading, data, setData, hasMore];
}
