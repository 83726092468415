import React, { useEffect, useState, useCallback, useRef } from "react";
import { useLoadMore } from "../../Council/News/useLoadMore";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  deleteGallery,
  viewGallery,
} from "../../../store/actions/adminActions/addGallery";
import BreadCrumb from "../../BreadCrumb";
import Loader from "../../Alert/Loader";

export default function ViewGallery() {
  const dispatch = useDispatch();
  const { global } = useSelector((state) => state);
  const { gallery } = global;
  const breadcrumbs = [
    {
      path: "/admin/dashboard",
      label: "Dashboard",
    },
    {
      // path: "/aboutus",
      label: "View Gallery",
    },
  ];
  // useEffect(() => {
  //   dispatch(viewGallery());
  // }, []);

  const [pageNumber, setPageNumber] = useState(1);
  const [loading, data, setData, hasMore] = useLoadMore(
    "admin/gallery/list?page=",
    pageNumber
  );

  const observer = useRef();
  const lastDataElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const handleDelete = async (id) => {
    const response = await dispatch(deleteGallery(id));
    if (response) {
      const data = await dispatch(viewGallery());
      setData(data.data);
    }
  };

  // const deleteHandler = async (id) => {
  //   const response = await dispatch(deleteNews(id));
  //   if (response) {
  //     const data = await dispatch(getNews());
  //     setData(data.data);
  //   }
  // };

  return (
    <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
      <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
        <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <BreadCrumb crumbs={breadcrumbs} />

          <div class="d-flex align-items-center">
            <Link
              to="/admin/add-gallery"
              class="btn btn-light-primary font-weight-bolder btn-sm"
            >
              Add Gallery
            </Link>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-12">
            <table
              class="table table-separate table-head-custom table-checkable"
              id="kt_datatable"
            >
              <thead>
                <tr>
                  <th>Sr.No</th>
                  <th>Title</th>
                  <th>Image/Video</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data === null ? (
                  <Loader active={true} />
                ) : data.length > 0 ? (
                  data.map((gal, index) => {
                    if (data.length === index + 1) {
                      return (
                        <tr ref={lastDataElementRef} key={gal}>
                          <td>{index + 1}</td>
                          <td>{gal.title}</td>
                          <td>
                            {gal.file ? (
                              <img
                                src={gal.file}
                                width={"200px"}
                                height={"100px"}
                              />
                            ) : (
                              <a target="_blank" href={gal.video_url}>
                                Video
                              </a>
                            )}
                          </td>
                          <td>
                            <button
                              class="btn btn-danger"
                              onClick={() => handleDelete(gal.id)}
                            >
                              Delete
                            </button>
                            <Link
                              class="btn btn-info"
                              to={`/admin/edit-gallery/${gal.id}`}
                            >
                              Edit
                            </Link>
                            {/* <button class="btn btn-info">Update</button> */}
                          </td>
                        </tr>
                        // <tr ref={lastDataElementRef} key={n}>
                        //   <td>{index + 1}</td>
                        //   <td>{n.title}</td>
                        //   <td>{n.type}</td>
                        //   <td>{n.description}</td>
                        //   <td align="center">
                        //     {<img src={n.file} height={"60px"} />}
                        //   </td>
                        //   <td align="center">{n.link || "-"}</td>
                        //   <td align="center">
                        //     <Link to={`/admin/edit-news/${n.id}`}>Edit</Link>
                        //     <button
                        //       class="btn btn-danger"
                        //       onClick={() => deleteHandler(n.id)}
                        //     >
                        //       Delete
                        //     </button>
                        //   </td>
                        // </tr>
                      );
                    } else {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{gal.title}</td>
                          <td>
                            {gal.file ? (
                              <img
                                src={gal.file}
                                width={"200px"}
                                height={"100px"}
                              />
                            ) : (
                              <a target="_blank" href={gal.video_url}>
                                Video
                              </a>
                            )}
                          </td>
                          <td>
                            <button
                              class="btn btn-danger"
                              onClick={() => handleDelete(gal.id)}
                            >
                              Delete
                            </button>
                            <Link
                              class="btn btn-info"
                              to={`/admin/edit-gallery/${gal.id}`}
                            >
                              Edit
                            </Link>
                            {/* <button class="btn btn-info">Update</button> */}
                          </td>
                        </tr>
                      );
                    }
                  })
                ) : (
                  <tr>{/* <td colSpan={5}>No News Added</td> */}</tr>
                )}
                {/* </tbody>
                  </table> */}
                <div>{loading && "Loading..."}</div>
                {!loading && data.length == 0 && <div>No News Available</div>}
              </tbody>
            </table>
          </div>
          <div class="col-md-2"></div>
        </div>
      </div>
    </div>
  );
}
