import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import "./css/login.css";
import { ALERT } from "../../../store/actions/actionTypes";
import { useHistory } from "react-router-dom";
import {
  adminDashboard,
  adminLogin,
} from "../../../store/actions/adminActions/adminAuth";
import {
  memberDashboard,
  memberLogin,
} from "../../../store/actions/MemberAction/memberAuth";

export default function MembersghipPortalLogin(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { admin } = useSelector((state) => state);
  const { member } = useSelector((state) => state);

  const { memberAuth } = member;
  const { adminAuth } = admin;

  useEffect(() => {
    memberAuth.isAuth &&
      history.push(
        props.location.state?.from.pathname || "/membership/dashboard"
      );
  }, [memberAuth.isAuth]);

  // useEffect(() => {
  //   dispatch(adminDashboard());
  // }, []);

  const [data, setData] = useState({
    login_code: "",
    password: "",
    member_type: "",
  });
  const { login_code, password, member_type } = data;

  useEffect(() => {
    dispatch(memberDashboard());
  }, []);

  const handleChange = (e) => {
    // setData({
    //   ...data,
    //   [e.target.name]: e.target.value,
    // });
    const { name, value } = e.target;
    if (name === "member_type") {
      setData({
        ...data,
        [name]: value,
      });
    } else {
      setData({
        ...data,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(memberLogin(data));
    dispatch({
      type: ALERT,
      payload: { loading: true },
    });
    localStorage.setItem("member_type", data.member_type);
  };

  return (
    <>
      <div className="sc-VigVT kCeVIa sc-gZMcBi jxPJzF"></div>
      <section className="logindesign">
        <div className="container">
          <div className="Formboxlogin">
            <div className="innerForm">
              <div className="Login-header">Login to your account</div>

              <form onSubmit={handleSubmit}>
                <div className="telBoxform">
                  <div className="boxlabel"></div>

                  <div className="formBox2">
                    <input
                      type="text"
                      placeholder="Enter Your Login Code*"
                      name="login_code"
                      value={login_code}
                      onChange={handleChange}
                      className="form-control"
                      s
                    />
                  </div>

                  <div className="formBox2">
                    <input
                      type="password"
                      placeholder="Password*"
                      name="password"
                      value={password}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>

                  <div className="formBox2">
                    {/* <input
                      type="text"
                      placeholder="Enter Your Member_type*"
                      name="member_type"
                      value={member_type}
                      onChange={handleChange}
                      className="form-control"s
                    /> */}
                    <select
                      className="form-control form-control-sm"
                      onChange={handleChange}
                      name="member_type"
                      value={member_type}
                    >
                      <option value="">Choose option</option>
                      <option value="Individual">Individual</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                </div>
                <div className="buttonSub">
                  <button className="btn btn-continues" type="submit">
                    Login
                  </button>
                </div>
              </form>
              <div className="My-help">
                Have trouble logging in? <span>Get help</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
