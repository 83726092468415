import React, { useEffect } from "react";
import { Card, Table, Accordion, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// To make rows collapsible
import "bootstrap/js/src/collapse.js";
import { Link } from "react-router-dom";
import { getScheduledExams } from "../../../store/actions/studentAction/examAction";
const Exams = () => {
  const { student } = useSelector((state) => state);
  const { dateSheet } = student;
  const { studentAuth } = student;
  const { profile } = studentAuth;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getScheduledExams(profile.is_update));
  }, []);
  return (
    <div className="exam-list">
      <Card className="mb-3">
        <Card.Header>{profile.course.name}</Card.Header>
      </Card>
      {/* <Card>
        <Card.Body> */}
      {/* <Card.Header className="text-center"> */}
      {dateSheet === false ? (
        <h6>Exam Form Not Filled</h6>
      ) : Array.isArray(dateSheet) && dateSheet.length === 0 ? (
        "No exam scheduled"
      ) : !dateSheet ? (
        <div className="text-center">
          <Spinner animation="border" role="status" size="lg">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : dateSheet.exam_type == "course_wise" ? (
        <Table bordered hover striped responsive className="mb-0">
          <thead>
            <tr>
              <th>Course Name</th>
              <th>Exam Date</th>
              <th>Exam Time</th>
              <th>Exam Center</th>
              <th>Fee Status</th>
              <th>Exam Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{dateSheet.course_name}</td>
              <td>
                {dateSheet.exam_time
                  ? moment(dateSheet.exam_time?.date).format("DD/MM/YYYY")
                  : ""}
              </td>
              <td>
                {dateSheet.exam_time
                  ? moment(dateSheet.exam_time?.time, "hh:mm").format("hh:mm A")
                  : ""}
              </td>
              <td>
                {dateSheet.exam_time
                  ? dateSheet.exam_time.center_name?.center_name
                  : "-"}
              </td>
              <td>{dateSheet.fess_status}</td>
              <td>
                {dateSheet.fess_status ==="Paid" ? dateSheet.exam_time ? (
                  dateSheet.is_update == "1" ? (
                    dateSheet.is_attempted == 0 ? (
                      <Link
                        to={{
                          pathname: `/student/exam/subject`,
                          state: {
                            exam_type: dateSheet.exam_type,
                            questionCount: dateSheet.question_count,
                          },
                        }}
                      >
                        <i class="fa fa-refresh" aria-hidden="true"></i>
                      </Link>
                    ) : 
                    moment(new Date()).isBetween(
                        moment(dateSheet.attemp_time).add("330", "minutes"),
                        moment(dateSheet.attemp_time)
                          .add("330", "minutes")
                          .add(dateSheet.question_count, "minutes")
                      ) ? (
                      <Link
                        to={{
                          pathname: `/student/exam/subject`,
                          state: {
                            exam_type: dateSheet.exam_type,
                            questionCount: dateSheet.question_count,
                          },
                        }}
                      >
                        <i class="fa fa-refresh" aria-hidden="true"></i>
                      </Link>
                    ) : (
                      "Completed"
                    )
                  ) : // moment(

                  moment(
                      moment(
                        dateSheet.exam_time.date +
                          " " +
                          dateSheet.exam_time.time
                      ).add(dateSheet.question_count, "minutes")
                    ).isBefore(moment(new Date())) ? (
                    <>{dateSheet.is_attempted == 1 ? "Completed" : "Absent"}</>
                  ) : moment(new Date()).isBetween(
                      moment(
                        dateSheet.exam_time.date +
                          " " +
                          dateSheet.exam_time.time
                      ).subtract("5", "minutes"),
                      moment(new Date()).add(
                        dateSheet.question_count,
                        "minutes"
                      )
                    ) ? (
                    <Link
                      to={{
                        pathname: `/student/exam/subject`,
                        state: {
                          exam_type: dateSheet.exam_type,
                          questionCount: dateSheet.question_count,
                        },
                      }}
                    >
                      <i class="fa fa-refresh" aria-hidden="true"></i>
                    </Link>
                  ) : (
                    "Pending"
                  )
                ) : (
                  "Pending"
                ):"Fees Not Paid" }
              </td>
            </tr>
          </tbody>
        </Table>
      ) : (
        Object.keys(dateSheet.exam_time).map((exam) => (
          <Accordion className="mb-3">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {profile.course.course_type == "2" ? "Semester " : "Year "}
                {exam}
              </Accordion.Header>
              <Accordion.Body className="p-0">
                {/* <Card.Body> */}
                <Table bordered hover striped responsive className="mb-0">
                  <thead>
                    <tr>
                      <th>S.NO.</th>
                      <th>Subject</th>
                      <th>Exam Date</th>
                      <th>Exam Time</th>
                      <th>Exam Center</th>
                      <th>Fee Status</th>
                      <th>Exam Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dateSheet.exam_time[exam].map((sub, i) => (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{sub.name}</td>
                        <td>
                          {sub.exam_time
                            ? moment(sub.exam_time?.date).format("DD/MM/YYYY")
                            : ""}
                        </td>
                        <td>
                          {sub.exam_time
                            ? moment(sub.exam_time?.time, "hh:mm").format(
                                "hh:mm A"
                              )
                            : ""}
                        </td>
                        <td>
                          {sub.exam_time
                            ? sub.exam_time.center_name?.center_name
                            : "-"}
                        </td>
                        <td>
                          {sub.fess_status}
                        </td>
                        <td>
                          {sub.fess_status==="Paid"? sub.exam_time ? (
                            dateSheet.is_update == "1" ? (
                              <Link
                                to={{
                                  pathname: `/student/exam/subject`,
                                  state: {
                                    subject: sub.id,
                                    questionCount: 50,
                                    practical: sub.practical,
                                  },
                                }}
                              >
                                <i class="fa fa-refresh" aria-hidden="true"></i>
                              </Link>
                            ) : moment(
                                moment(
                                  sub.exam_time.date + " " + sub.exam_time.time
                                ).add(50, "minutes")
                              ).isBefore(moment(new Date())) ? (
                              "Completed"
                            ) : moment(new Date()).isBetween(
                                moment(
                                  sub.exam_time.date + " " + sub.exam_time.time
                                ).subtract("5", "minutes"),
                                moment(
                                  sub.exam_time.date + " " + sub.exam_time.time
                                ).add(50, "minutes")
                              ) ? (
                              <Link
                                to={{
                                  pathname: `/student/exam/subject`,
                                  state: {
                                    subject: sub.id,
                                    questionCount: 50,
                                    practical: sub.practical,
                                  },
                                }}
                              >
                                <i class="fa fa-refresh" aria-hidden="true"></i>
                              </Link>
                            ) : (
                              "Pending"
                            )
                          ) : (
                            "Pending"
                          ):"Fees Not Paid" }
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        ))
      )}

      {/* </Card.Body> */}
      {/* </Card.Body> */}
      {/* </Card> */}
    </div>
  );
};

export default Exams;
