import { postDataAPI } from "../../../services/apiWrapper";
import { ALERT } from "../actionTypes";

export const payStudentFee = (data) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("centerToken");
    const response = await dispatch(postDataAPI("pay", data, token));
    dispatch({ type: ALERT, payload: { loading: false } });
    if (response) {
      return response.data.data;
    }
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({ type: ALERT, payload: { error: error.response.message } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};

export const updatePaymentStatus = (data) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("centerToken");
    const response = await dispatch(postDataAPI("pay-success", data, token));
    dispatch({ type: ALERT, payload: { loading: false } });
    if (response) {
      return response.data;
    }
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({ type: ALERT, payload: { error: error.response.message } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};
