import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Spinner, Table } from "react-bootstrap";
import BreadCrumb from "../../BreadCrumb";
import {
  deleteEmail,
  downloadEmail,
  emailList,
} from "../../../store/actions/councilActions/emailActions";
import Pagination from "../../misc/Pagination";

const breadcrumbs = [
  {
    path: "/admin/dashboard",
    label: "Dashboard",
  },
  {
    label: "Email List",
  },
];

const EmailList = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [activePage, setactivePage] = useState(1);
  const [totalPages, settotalPages] = useState(0);
  const fetchdata = useCallback(async () => {
    const response = await dispatch(emailList(activePage));
    if (response) {
      setData(response.data);
      settotalPages(Math.ceil(response.total / response.per_page));
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [activePage, dispatch]);
  useEffect(() => {
    setLoading(true);
    fetchdata();
  }, [fetchdata]);
  const deleteAction = async (id) => {
    const response = await dispatch(deleteEmail(id));
    if (response) {
      fetchdata();
    }
  };

  const handleDownload = () => {
    dispatch(downloadEmail());
  };

  return (
    <div>
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
          <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <BreadCrumb crumbs={breadcrumbs} />

            <div class="d-flex align-items-center">
              <button
                onClick={handleDownload}
                className="btn btn-light-primary font-weight-bolder btn-sm"
              >
                Download list
              </button>
              <Link
                to="/admin/email/add"
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                Add Email
              </Link>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status" size="lg">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <Table bordered hover striped responsive className="mb-0">
            <thead>
              <th>S No</th>
              <th>Name</th>
              <th>Designation</th>
              <th>Email</th>
              <th>Action</th>
            </thead>
            <tbody>
              {data.length == 0 ? (
                <tr>
                  <td colSpan={5} className="text-center text-danger">
                    No Record Found
                  </td>
                </tr>
              ) : (
                data.map((element, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{element.name}</td>
                    <td>{element.designation}</td>
                    <td>{element.email_id}</td>
                    <td>
                      <div className="d-flex justify-content-between">
                        <Link to={`/admin/email/edit/${element.id}`}>
                          <i className="fa fa-pencil"></i>
                        </Link>

                        <i
                          className="fa fa-trash"
                          onClick={() => deleteAction(element.id)}
                        ></i>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        )}
        {totalPages && Number(totalPages) !== 0 ? (
          <Pagination
            setActivePage={(value) => setactivePage(value)}
            totalPages={totalPages}
          />
        ) : null}
      </div>
    </div>
  );
};

export default EmailList;
