import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CENTER_REGISTER_DETAILS } from "../../../store/actions/actionTypes";

export default function MdDetails() {
  const { center } = useSelector((state) => state);
  const dispatch = useDispatch();

  const {
    md_name,
    mobile,
    email,
    md_qualification,
    md_adhar,
    md_pan,
    md_address,
    md_city,
    md_state,
    md_pin_code,
    md_dob,
  } = center.centerProfile;

  const handleChange = (e) => {
    dispatch({
      type: CENTER_REGISTER_DETAILS,
      payload: { [e.target.name]: e.target.value },
    });
  };

  return (
    <>
      <div className="col-md-12">
        <div className="formTitle">MD Details</div>
      </div>
      <div className="col-md-6">
        <div className="boxlabel">
          <p>Md Name</p>
          <input
            type="text"
            className="form-control form-control-sm"
            name="md_name"
            value={md_name}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="boxlabel">
          <p> Mobile no</p>
          <input
            type="text"
            className="form-control form-control-sm"
            name="mobile"
            value={mobile}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="col-md-4">
        <div className="boxlabel">
          <p>Md email</p>
          <input
            type="text"
            className="form-control form-control-sm"
            name="email"
            value={email}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="col-md-4">
        <div className="boxlabel">
          <p>Mid Qualification</p>
          <input
            type="text"
            className="form-control form-control-sm"
            name="md_qualification"
            value={md_qualification}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="col-md-4">
        <div className="boxlabel">
          <p>Md DOB</p>
          <input
            type="date"
            className="form-control form-control-sm"
            name="md_dob"
            value={md_dob}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="col-md-4">
        <div className="boxlabel">
          <p>Md Addhar Card Number</p>
          <input
            type="text"
            className="form-control form-control-sm"
            name="md_adhar"
            value={md_adhar}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="col-md-4">
        <div className="boxlabel">
          <p>Md Pan Number</p>
          <input
            type="text"
            className="form-control form-control-sm"
            name="md_pan"
            value={md_pan}
            onChange={handleChange}
          />
        </div>
      </div>
      {/* <hr className="w="></hr> */}
      <div className="col-md-12">
        <div className="formTitle">Md Address</div>
      </div>
      <div className="col-md-4">
        <div className="boxlabel">
          <p>Address </p>
          <input
            type="text"
            className="form-control form-control-sm"
            name="md_address"
            value={md_address}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="col-md-4">
        <div className="boxlabel">
          <p>City </p>
          <input
            type="text"
            className="form-control form-control-sm"
            name="md_city"
            value={md_city}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="col-md-4">
        <div className="boxlabel">
          <p>State </p>
          <input
            type="text"
            className="form-control form-control-sm"
            name="md_state"
            value={md_state}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="col-md-4">
        <div className="boxlabel">
          <p>Pincode </p>
          <input
            type="text"
            className="form-control form-control-sm"
            name="md_pin_code"
            value={md_pin_code}
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  );
}
