import { getDataAPI, postDataAPI } from "../../../services/apiWrapper";
import { ALERT } from "../actionTypes";

export const getCollegeList =
  (trade = "", page, filters) =>
  async (dispatch) => {
    try {
      const { subtrade, state, center_code, trade } = filters;

      const postData = {};
      if (subtrade) {
        postData.subtrade = subtrade;
      }
      if (state) {
        postData.state = state;
      }
      if (center_code) {
        postData.code = center_code;
      }
      if (trade) {
        postData.trade = trade;
      }

      const params = `page=${page}`;

      const response = await dispatch(
        postDataAPI(`center/college?${params}`, postData)
      );
      return {
        success: true,
        data: response.data.data,
      };
    } catch (error) {
      // dispatch({ type: ALERT, payload: { error: error.response.data.status } });
      return {
        success: false,
      };
    }
  };

export const contactUs = (data) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(postDataAPI("contact-us", data));
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });

    return true;
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: { loading: false, error: error.response.message },
    });
    return false;
  }
};

export const getStudentDetails = (data) => async (dispatch) => {
  try {
    const response = await dispatch(postDataAPI("exam-form-data", data));
    return response.data.data;
  } catch (error) {
    return false;
  }
};
export const checkExamFormFilled = (data) => async (dispatch) => {
  try {
    const response = await dispatch(
      postDataAPI("exam-form-data-semester", data)
    );

    return true;
  } catch (error) {
    return false;
  }
};
export const fillForm = (data) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(postDataAPI("exam-form", data));
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });
    return response.data.data;
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: {
        loading: false,
        success: error.response?.data?.message || error.message,
      },
    });
    return false;
  }
};
