import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "./App.css";
// import "./styles/bootstrap.css";
import StudentLogin from "./Components/Student/Auth/Login";
import StudentLayout from "./Components/Layouts/StudentLayout";
import StudentDashboard from "./Components/Student/Dashboard/Index";
import Certificates from "./Components/Student/Dashboard/Certificates";

import CenterLogin from "./Components/Center/Auth/Login";
import CenterRegister from "./Components/Center/Auth/Register";
import CenterPrivate from "./Components/Center/PrivateRouter";
import CenterDashboard from "./Components/Center/Dashboard/Dashboard";
import Student from "./Components/Center/Students/Student";
import CenterProfile from "./Components/Center/Profile/Profile";
import CenterCertificates from "./Components/Center/Certificates/Certificates";
import ManageWebsite from "./Components/Center/ManageWebsite/ManageWebsite";
import AddStudent from "./Components/Center/Students/AddStudent";

import Home from "./Components/Home/Home";
import CenterLayout from "./Components/Layouts/CenterLayout";
import DownloadCertificate from "./Components/Center/Certificates/DownloadCertificate";

import CouncilLogin from "./Components/Council/Auth/Login";

import AdminLayout from "./Components/Council/AdminLayout/AdminLayout";
import PrivateAdmin from "./Components/Council/PrivateRoute";
import ViewTrade from "./Components/Council/components/Trade/ViewTrade";
import AddTrade from "./Components/Council/components/Trade/AddTrade";
import SubTrade from "./Components/Council/components/Trade/SubTrade";
import StudentList from "./Components/Council/components/Students/StudentList";
import AdminDashboard from "./Components/Council/Dashboard/Dashboard";
import CenterList from "./Components/Council/components/Center/CenterList";

import ContactUs from "./Components/ContactUs/ContactUs";
import HomeLayout from "./Components/Layouts/HomeLayout";
import Affiliation from "./Components/Affiliation/Affiliation";
import AboutUs from "./Components/AboutUs/AboutUs";
import VisionMission from "./Components/AboutUs/VisionMission";
import Gallery from "./Components/Gallery/Gallery";
import Alert from "./Components/Alert/Alert";
import SubTradeList from "./Components/Council/components/Trade/SubTradeList";
import CourseList from "./Components/Council/components/Course/CourseList";
import AddCourse from "./Components/Council/components/Course/AddCourse";
import ViewSubject from "./Components/Council/Subjects/ViewSubject";
import AddSubject from "./Components/Council/Subjects/AddSubject";
import StudentVerification from "./Components/StudentVerification/StudentVerification";
import AddGallery from "./Components/Council/Gallery/AddGallery";
import ViewGallery from "./Components/Council/Gallery/ViewGallery";
import AddNews from "./Components/Council/News/AddNews";
import ViewNews from "./Components/Council/News/ViewNews";
import ViewCenter from "./Components/Council/components/Center/ViewCenter";
import VideoGallery from "./Components/Gallery/VideoGallery";
import StaticPages from "./Components/Council/StaticPages/StaticPages";
import StaticPage from "./Components/Council/StaticPages/StaticPage";
import NotFound from "./404";
import SorryPage from "./Components/404/404Page";
import Course from "./Components/Course/Course";
import Courses from "./Components/Course/Courses";
import NewsDetails from "./Components/News/NewsDetails";
import AdminCard from "./Components/Student/PDFs/AdminCard";
import Activities from "./Components/Activities/Activities";
import Fee from "./Components/Center/Fee/Fee";
import GoverningBody from "./Components/Council/GoverningBody/GoverningBody";
import BodyList from "./Components/Council/GoverningBody/BodyList";
import GovernBody from "./Components/Bodies/GoverningBody";
import GeneralBody from "./Components/Bodies/GeneralBody";
import ViewActivity from "./Components/Council/Activity/ViewActivity";
import AddActivity from "./Components/Council/Activity/AddActivity";
import PrivateStudent from "./Components/Student/PrivateRoute";
import UpdatePassword from "./Components/Student/Update/UpdatePassword";
import Centers from "./Components/Centers/Centers";
import CenterDetails from "./Components/Centers/CenterDetails";
import AssignmentList from "./Components/Council/Assignment/AssignmentList";
import AddAssignment from "./Components/Council/Assignment/AddAssignment";
import ViewAssignment from "./Components/Council/Assignment/ViewAssignment";
import EnquiryList from "./Components/Centers/Enquiry/EnquiryList";
import MyProfile from "./Components/Center/Profile/MyProfile";
import Assessment from "./Components/Student/Assessment/Assessment";
import MyCertificates from "./Components/Center/Certificates/MyCertificates";
import Exams from "./Components/Student/Assessment/Exams";
import Assessments from "./Components/Student/Assessment/Assessments";
import AddExamSchedule from "./Components/Council/ExamSchedule/AddExamSchedule";
import ViewSchedule from "./Components/Council/ExamSchedule/ViewSchedule";
import ExamForm from "./Components/Student/PDFs/ExamForm";
import FillExamForm from "./Components/Student/Assessment/FillExamForm";
import StudentExamForm from "./Components/ExamForm/ExamForm";
import Exam from "./Components/Student/Assessment/Exam";
import AddPracticalMarks from "./Components/Center/Practical/AddPracticalMarks";
import AddOffice from "./Components/Council/AddOffice/AddOffice";
import OfficeList from "./Components/Council/AddOffice/OfficeList";
import AffiliationEnquiry from "./Components/Council/Enquiry/AffiliationEnquiry";
import AdmissionEnquiry from "./Components/Council/Enquiry/AdmissionEnquiry";
import AddDownload from "./Components/Council/Downloads/AddDownload";
import ViewDownload from "./Components/Council/Downloads/ViewDownload";
import EmailList from "./Components/Council/ImportantEmail/EmailList";
import AddEmail from "./Components/Council/ImportantEmail/AddEmail";
import AddContact from "./Components/Council/Contacts/AddContact";
import ContactList from "./Components/Council/Contacts/ContactList";
import Downloads from "./Components/UsefulLinks/Downloads";
import LinksLayout from "./Components/UsefulLinks/LinksLayout";
import ImpEmail from "./Components/UsefulLinks/ImpEmail";
import ImpContacts from "./Components/UsefulLinks/ImpContacts";
import EditHelpline from "./Components/Council/Contacts/EditHelpline";
import AddRti from "./Components/Council/RTI/AddRti";
import MinisterRtiList from "./Components/Council/RTI/MinisterRtiList";
import DepartmentRtiList from "./Components/Council/RTI/DepartmentRtiList";
import RtiDepartment from "./Components/UsefulLinks/RtiDepartment";
import RtiMinister from "./Components/UsefulLinks/RtiMinister";
import RTI from "./Components/UsefulLinks/RTI";
import ChangePassword from "./Components/Center/Auth/ChangePassword";
import Result from "./Components/Student/Results/Result";
import StudentResult from "./Components/StudentVerification/StudentResult";
import AddMarks from "./Components/Council/UploadMarks/AddMarks";
import AddStudentFees from "./Components/Council/AddFees/AddStudentFees";
import HelpDesk from "./Components/HelpDesk";
import Payment from "./Components/Center/Students/Payment";
import LoginHelpDesk from "./Components/HelpDesk/LoginHelpDesk";
import TrackComplaint from "./Components/HelpDesk/TrackComplaint";
import HelpdeskLayout from "./Components/Layouts/HelpdeskLayout";
import PrivatehelpDesk from "./Components/HelpDesk/PrivateHelpDesk";
import HelpDeskDashboard from "./Components/HelpDesk/Dashboard";
import CenterComplaints from "./Components/HelpDesk/CenterComplaints";
import StudentComplaints from "./Components/HelpDesk/StudentComplaints";
import ComplaintDetails from "./Components/HelpDesk/ComplaintDetails";
import Mdcard from "./Components/Center/IdCard/index";
import StudentFees from "./Components/Student/Fees";
import Studentcard from "./Components/Center/IdCard/index";
import PrintPortalLogin from "./Components/PrintPortal/Auth/PrintPortalLogin";
import Print from "./Components/PrintPortal/Print";
import PrintLayout from "./Components/PrintPortal/Layouts/PrintLayout";
import PrintPrivate from "./Components/PrintPortal/PrivateRoute";
import PrintCategory from "./Components/PrintPortal/Category/PrintCategory";
import PrintSubCategory from "./Components/PrintPortal/SubCategory/printSubCategory";
import AddCategory from "./Components/PrintPortal/Category/AddCategory";
import { addSubCategory } from "./store/actions/printActions/printcategory";
import AddSubCategory from "./Components/PrintPortal/SubCategory/AddSubCategory";
import EditSubCategory from "./Components/PrintPortal/SubCategory/EditSubDesription";
import Register from "./Components/MmemberShip/Register";
import Page from "./Components/PrintPortal/Page/Page";
import AddPage from "./Components/PrintPortal/Page/AddPage";
import EditPage from "./Components/PrintPortal/Page/EditPage";
import PrintDashboard from "./Components/PrintPortal/Dashboard/Dashboard";
import Individual from "./Components/PrintPortal/Membership/Individual";
import Other from "./Components/PrintPortal/Membership/Other";
import EditIndividual from "./Components/PrintPortal/Membership/EditIndividual";
import EditOther from "./Components/PrintPortal/Membership/EditOther";
import MembersghipPortalLogin from "./Components/membershipPortal/Auth/MembershipPortalLogin";
import MemberShipLayout from "./Components/membershipPortal/Layouts/MemberShipLayout";
import MemebrshipDashboard from "./Components/membershipPortal/Dashboard/Dashboard";
import MembershipStatus from "./Components/PrintPortal/Membership/MembershipStatus";
import MemberChangePassword from "./Components/membershipPortal/Auth/MemberChangePassword";
import AddFeedBack from "./Components/PrintPortal/FeedBack/AddFeedBack";
import FeedBack from "./Components/PrintPortal/FeedBack/FeedBack";
import EditFeedBack from "./Components/PrintPortal/FeedBack/EditFeedBack";
import EditIndividualForm from "./Components/membershipPortal/MemberShipForm/EditIndividualForm";

function App() {
  return (
    <Router>
      <Alert />
      <Switch>
        <Route exact path={"/student/login"} component={StudentLogin}></Route>
        <PrivateStudent
          exact
          path={"/student/assessments/subject"}
          component={Assessment}
        />
        <PrivateStudent exact path={"/student/exam/subject"} component={Exam} />
        <Route path={["/student"]}>
          {/* <Route exact path={"/student/login"} component={StudentLogin}></Route> */}
          <StudentLayout>
            <Switch>
              <PrivateStudent
                exact
                path={"/student"}
                component={StudentDashboard}
              />
              <PrivateStudent
                exact
                path={"/student/id-card"}
                component={Studentcard}
              />
              {/* <PrivateStudent
                exact
                path={"/student/exam-form"}
                // component={ExamForm}
              /> */}
              <PrivateStudent
                exact
                path={"/student/fees"}
                component={StudentFees}
              />
              <PrivateStudent
                exact
                path={"/student/assessments"}
                component={Assessments}
              />
              <PrivateStudent
                exact
                path={"/student/results"}
                component={Result}
              />
              <PrivateStudent exact path={"/student/exams"} component={Exams} />
              <PrivateStudent
                exact
                path={"/student/dashboard"}
                component={StudentDashboard}
              />
              <PrivateStudent
                exact
                path={"/student/admit-card"}
                component={AdminCard}
              />
              <PrivateStudent
                exact
                path={"/student/certificates"}
                component={Certificates}
              />{" "}
              <PrivateStudent
                exact
                path={"/student/exam-form"}
                component={ExamForm}
              />{" "}
              <PrivateStudent
                exact
                path={"/student/fill-exam-form"}
                component={FillExamForm}
              />{" "}
              <PrivateStudent
                exact
                path={"/student/change-password"}
                component={UpdatePassword}
              />
              <PrivateStudent
                exact
                path={"/student/*"}
                render={() => <Redirect to="/student/dashboard" />}
              />
            </Switch>
          </StudentLayout>
        </Route>
        <Route exact path={"/center/login"} component={CenterLogin} />
        <Route exact path={["/center/register"]}>
          <HomeLayout>
            <Route exact path={"/center/register"} component={CenterRegister} />
          </HomeLayout>
        </Route>
        <Route
          exact
          path={"/center/student/download/:id"}
          component={DownloadCertificate}
        />
        <Route path={["/center"]}>
          {/* <CenterLayout> */}
          <CenterLayout>
            <Switch>
              <CenterPrivate exact path="/center" component={CenterDashboard} />
              <CenterPrivate exact path="/center/fee" component={Fee} />
              <CenterPrivate exact path="/center/md-id" component={Mdcard} />
              <CenterPrivate
                exact
                path="/center/dashboard"
                component={CenterDashboard}
              />
              <CenterPrivate
                exact
                path="/center/students/result/:id"
                component={Result}
              />
              <CenterPrivate
                exact
                path="/center/change-password"
                component={ChangePassword}
              />
              <CenterPrivate
                exact
                path="/center/add-practical-marks"
                component={AddPracticalMarks}
              />

              <CenterPrivate
                exact
                path="/center/enquiry-list"
                component={EnquiryList}
              />
              <CenterPrivate
                exact
                path={"/center/students"}
                component={Student}
              />
              <CenterPrivate
                exact
                path={"/center/myprofile"}
                component={MyProfile}
              />
              <CenterPrivate
                exact
                path={"/center/edit"}
                component={CenterProfile}
              />
              <CenterPrivate
                exact
                path={"/center/students-certificate"}
                component={CenterCertificates}
              />
              {/* <CenterPrivate
                exact
                path={"/center/my-certificate"}
                component={MyCertificates}
              /> */}
              {/* <CenterPrivate
                  exact
                  path={"/center/websitemanage"}
                  component={ManageWebsite}
                /> */}
              <CenterPrivate
                exact
                path={"/center/add-student"}
                component={AddStudent}
              />
              <CenterPrivate
                exact
                path={"/center/payment"}
                component={Payment}
              />
              <CenterPrivate
                exact
                path={"/center/edit-student/:id"}
                component={AddStudent}
              />
              <CenterPrivate
                exact
                path={"/center/student/edit/:id"}
                component={AddStudent}
              />
              <Route
                exact
                path={"/center/*"}
                render={() => <Redirect to="/center/dashboard" />}
              />
            </Switch>
          </CenterLayout>
        </Route>
        {/* <Route exact path="/admin/tables" component={Tables} /> */}

        {/* ------------print poratal------------- */}

        <Route exact path={"/print/login"} component={PrintPortalLogin}></Route>
        <Route path={["/print"]}>
          <PrintLayout>
            <Switch>
              <Route exact path="/print/dashboard" component={PrintDashboard} />
              <Route exact path={"/print"} component={Print} />
              <Route exact path={"/print/category"} component={PrintCategory} />
              <Route
                exact
                path={"/print/subCategory"}
                component={PrintSubCategory}
              />
              <Route
                exact
                path={"/print/addCategory"}
                component={AddCategory}
              />

              <PrivateAdmin
                exact
                path="/print/edit-Category/:id"
                component={AddCategory}
              />

              <Route
                exact
                path={"/print/add-subcategory/:id"}
                component={AddSubCategory}
              />
              <Route
                exact
                path={"/print/edit-subCategory/:categoryid/:id"}
                component={EditSubCategory}
              />
              <Route exact path={"/print/page"} component={Page} />
              <Route exact path={"/print/addPage"} component={AddPage} />
              <PrivateAdmin
                exact
                path="/print/edit-page/:id"
                component={EditPage}
              />

              <Route
                exact
                path={"/print/membership/individual"}
                component={Individual}
              />

              <Route
                exact
                path={"/print/membership/others"}
                component={Other}
              />

              <Route
                exact
                path={"/print/edit-Membership-Individual/:id"}
                component={EditIndividual}
              />
              <Route
                exact
                path={"/print/edit-membership-other/:id"}
                component={EditOther}
              />

              <Route exact path={"/print/feedback"} component={FeedBack} />

              <Route
                exact
                path={"/print/add-feedback"}
                component={AddFeedBack}
              />
              <Route
                exact
                path={"/print/edit-feedback/:id"}
                component={EditFeedBack}
              />
            </Switch>
          </PrintLayout>
        </Route>

        {/* --------------------------------------- */}
        <Route
          exact
          path={"/membership/login"}
          component={MembersghipPortalLogin}
        ></Route>
        <Route path={["/membership"]}>
          <MemberShipLayout>
            <Switch>
              <Route
                exact
                path="/membership/dashboard"
                component={MemebrshipDashboard}
              />
              <Route
                exact
                path="/membership/change-password"
                component={MemberChangePassword}
              />

              <Route
                exact
                path="/membership/membershipForm"
                component={EditIndividualForm}
              />

              <Route
                exact
                path="/membership/status"
                component={MembershipStatus}
              />
            </Switch>
          </MemberShipLayout>
        </Route>
        {/* ---------------Membership----------------------- */}
        {/* <HomeLayout> */}
        {/* <Route exact path={"/member/register"} component={Register}></Route> */}
        <Route exact path={["/member/register"]}>
          <HomeLayout>
            <Route exact path={"/member/register"} component={Register} />
          </HomeLayout>
        </Route>

        <Route exact path={["/member/status"]}>
          <HomeLayout>
            <Route exact path={"/member/status"} component={MembershipStatus} />
          </HomeLayout>
        </Route>
        {/* </HomeLayout> */}

        {/* ------------------------------------------------- */}
        <Route exact path={"/admin/login"} component={CouncilLogin} />
        <Route path={["/admin"]}>
          <AdminLayout>
            <Switch>
              <PrivateAdmin exact path="/admin/add-news" component={AddNews} />
              <PrivateAdmin
                exact
                path="/admin/add-body"
                component={GoverningBody}
              />
              <PrivateAdmin
                exact
                path="/admin/add-student-fees"
                component={AddStudentFees}
              />
              <PrivateAdmin
                exact
                path="/admin/add-exam-schedule"
                component={AddExamSchedule}
              />
              <PrivateAdmin
                exact
                path="/admin/view-exam-schedule"
                component={ViewSchedule}
              />

              <PrivateAdmin
                exact
                path="/admin/edit-body/:id"
                component={GoverningBody}
              />
              <PrivateAdmin
                exact
                path="/admin/view-body-list"
                component={BodyList}
              />
              <PrivateAdmin
                exact
                path="/admin/view-assignment-list"
                component={AssignmentList}
              />
              <PrivateAdmin
                exact
                path="/admin/add-assignment"
                component={AddAssignment}
              />
              <PrivateAdmin
                exact
                path="/admin/view-assignment/:id"
                component={ViewAssignment}
              />
              <PrivateAdmin
                exact
                path="/admin/edit-question/:id"
                component={AddAssignment}
              />
              <PrivateAdmin
                exact
                path="/admin/office/add"
                component={AddOffice}
              />
              <PrivateAdmin
                exact
                path="/admin/office/list"
                component={OfficeList}
              />
              <PrivateAdmin
                exact
                path="/admin/add/marks"
                component={AddMarks}
              />
              <PrivateAdmin
                exact
                path="/admin/office/edit/:id"
                component={AddOffice}
              />

              <PrivateAdmin
                exact
                path="/admin/edit-news/:id"
                component={AddNews}
              />
              <PrivateAdmin
                exact
                path="/admin/view-news"
                component={ViewNews}
              />
              <PrivateAdmin
                exact
                path="/admin/view-trade"
                component={ViewTrade}
              />
              <PrivateAdmin
                exact
                path="/admin/add-gallery"
                component={AddGallery}
              />
              <PrivateAdmin
                exact
                path="/admin/edit-gallery/:id"
                component={AddGallery}
              />
              <PrivateAdmin
                exact
                path="/admin/view-gallery"
                component={ViewGallery}
              />
              <PrivateAdmin
                exact
                path="/admin/create-static-page"
                component={StaticPages}
              />
              <PrivateAdmin
                exact
                path="/admin/static-page/edit/:id"
                component={StaticPages}
              />
              <PrivateAdmin
                exact
                path="/admin/static-page/:id"
                component={StaticPage}
              />
              <PrivateAdmin
                exact
                path="/admin/students"
                component={StudentList}
              />
              <PrivateAdmin
                exact
                path="/admin/edit-student/:id"
                component={AddStudent}
              />
              <PrivateAdmin
                exact
                path="/admin/add-trade"
                component={AddTrade}
              />
              <PrivateAdmin
                exact
                path="/admin/edit-trade/:id"
                component={AddTrade}
              />
              <PrivateAdmin
                exact
                path="/admin/dashboard"
                component={AdminDashboard}
              />
              <PrivateAdmin
                exact
                path="/admin/add-sub-trade"
                component={SubTrade}
              />
              <PrivateAdmin
                exact
                path="/admin/edit-sub-trade/:id"
                component={SubTrade}
              />
              <PrivateAdmin
                exact
                path="/admin/view-sub-trade"
                component={SubTradeList}
              />
              <PrivateAdmin
                exact
                path="/admin/courses"
                component={CourseList}
              />
              <PrivateAdmin
                exact
                path="/admin/view-activity-list"
                component={ViewActivity}
              />
              <PrivateAdmin
                exact
                path="/admin/add-activity"
                component={AddActivity}
              />
              <PrivateAdmin
                exact
                path="/admin/edit-activity/:id"
                component={AddActivity}
              />
              <PrivateAdmin
                exact
                path="/admin/course/edit/:id"
                component={AddCourse}
              />
              <PrivateAdmin
                exact
                path="/admin/centers"
                component={CenterList}
              />
              <PrivateAdmin
                exact
                path="/admin/centers/:id"
                component={ViewCenter}
              />

              <PrivateAdmin
                exact
                path="/admin/add-course"
                component={AddCourse}
              />
              <PrivateAdmin
                exact
                path="/admin/subjects"
                component={ViewSubject}
              />
              <PrivateAdmin
                exact
                path="/admin/add-subject"
                component={AddSubject}
              />
              <PrivateAdmin
                exact
                path="/admin/enquiry/affiliation"
                component={AffiliationEnquiry}
              />
              <PrivateAdmin
                exact
                path="/admin/download/add"
                component={AddDownload}
              />
              <PrivateAdmin
                exact
                path="/admin/download/edit/:id"
                component={AddDownload}
              />
              <PrivateAdmin
                exact
                path="/admin/download/list"
                component={ViewDownload}
              />
              <PrivateAdmin
                exact
                path="/admin/email/list"
                component={EmailList}
              />
              <PrivateAdmin
                exact
                path="/admin/email/add"
                component={AddEmail}
              />
              <PrivateAdmin
                exact
                path="/admin/rti/edit/:id"
                component={AddRti}
              />
              <PrivateAdmin
                exact
                path="/admin/email/edit/:id"
                component={AddEmail}
              />
              <PrivateAdmin
                exact
                path="/admin/contact/add"
                component={AddContact}
              />
              <PrivateAdmin exact path="/admin/rti/add" component={AddRti} />
              <PrivateAdmin
                exact
                path="/admin/rti/list/minister"
                component={MinisterRtiList}
              />
              <PrivateAdmin
                exact
                path="/admin/rti/list/department"
                component={DepartmentRtiList}
              />

              <PrivateAdmin
                exact
                path="/admin/contact/edit/:id"
                component={AddContact}
              />
              <PrivateAdmin
                exact
                path="/admin/contact/list"
                component={ContactList}
              />
              <PrivateAdmin
                exact
                path="/admin/helpline/edit/:id"
                component={EditHelpline}
              />
              <PrivateAdmin
                exact
                path="/admin/enquiry/admission"
                component={AdmissionEnquiry}
              />
              <PrivateAdmin
                exact
                path="/admin/edit-subject/:id"
                component={AddSubject}
              />
              <Route
                exact
                path={"/admin/*"}
                render={() => <Redirect to="/admin/dashboard" />}
              />
            </Switch>
          </AdminLayout>
        </Route>
        <Route exact path={"/helpdesk/login"} component={LoginHelpDesk} />
        <Route path={["/helpdesk"]}>
          <HelpdeskLayout>
            <Switch>
              <PrivatehelpDesk
                exact
                path="/helpdesk/dashboard"
                component={HelpDeskDashboard}
              />
              <PrivatehelpDesk
                exact
                path="/helpdesk/center"
                component={CenterComplaints}
              />
              <PrivatehelpDesk
                exact
                path="/helpdesk/student"
                component={StudentComplaints}
              />
              <PrivatehelpDesk
                exact
                path="/helpdesk/details/:id"
                component={ComplaintDetails}
              />
              <Route
                exact
                path={"/helpdesk/*"}
                render={() => <Redirect to="/helpdesk/dashboard" />}
              />
            </Switch>
          </HelpdeskLayout>
        </Route>

        {/* <Route exact path={"/council/login"} component={CouncilLogin} /> */}
        <Route exact path={["*"]}>
          <HomeLayout>
            <Switch>
              {" "}
              <Route exact path="/" component={Home} />
              <Route
                exact
                path={"/center/register"}
                component={CenterRegister}
              />
              <Route exact path="/news/:id" component={NewsDetails} />
              <Route exact path="/contact-us" component={ContactUs} />
              <Route exact path={"/affiliation"} component={Affiliation} />
              <Route
                exact
                path={"/abouts/About-the-NPVEC"}
                component={AboutUs}
              />
              <Route
                exact
                path={"/abouts/Vission---Mission"}
                component={VisionMission}
              />
              <Route exact path={"/gallery"} component={Gallery} />
              <Route exact path={"/colleges"} component={Centers} />
              <Route exact path={"/colleges/:id"} component={CenterDetails} />
              <Route exact path={"/governing-body"} component={GovernBody} />
              <Route exact path={"/general-body"} component={GeneralBody} />
              <Route exact path={"/video-gallery"} component={VideoGallery} />
              <Route exact path={"/activities"} component={Activities} />
              <Route exact path={"/results"} component={StudentResult} />
              <Route exact path={"/help-desk"} component={HelpDesk} />
              <Route
                exact
                path={"/student-verification"}
                component={StudentVerification}
              />
              <Route
                exact
                path={"/student-exam-form"}
                component={StudentExamForm}
              />
              <Route exact path={"/course/:id"} component={Course} />
              <Route exact path={"/courses/:id"} component={Courses} />
              <Route
                exact
                path={"/track-complaint"}
                component={TrackComplaint}
              />
              <Route path={["/useful-links"]}>
                <LinksLayout>
                  <Route
                    exact
                    path="/useful-links/downloads"
                    component={Downloads}
                  />
                  <Route
                    exact
                    path="/useful-links/rti/department"
                    component={RtiDepartment}
                  />
                  <Route exact path="/useful-links/rti" component={RTI} />
                  <Route
                    exact
                    path="/useful-links/rti/minister"
                    component={RtiMinister}
                  />
                  <Route
                    exact
                    path="/useful-links/e-mail"
                    component={ImpEmail}
                  />
                  <Route
                    exact
                    path="/useful-links/contacts"
                    component={ImpContacts}
                  />
                </LinksLayout>
              </Route>
              <Route exact component={SorryPage} />
            </Switch>
          </HomeLayout>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
