import React from "react";

const  MemebrDashboard = ({ data }) => {
  return (
    <div>
      <div class="mid-content-box">
        <div class="box-welcome">
          <div class="row">
            <div class="col-md-6">
              <div class="box-status">
                <div class="box-statusImg">
                  <img src="/images/girl.png" />
                </div>
                <div class="box-statusData">
                  <h2>
                    Welcome <span>{"Member"}</span>
                  </h2>
                </div>
              </div>
            </div>
            {/* <div class="col-md-6">
              <ul class="selectDrops">
                <li>
                  <Link to="/center/add-student" class="addNewchild">
                    Add Student
                  </Link>
                </li>
              </ul>
            </div> */}
          </div>
        </div>

        <div class="grid-box">
          <div class="row">
            <div class="col-md-4 mb-4">
              <div class="grid-data gcolor1">
                <div class="img-data">
                  <img src="/images/student.png" />
                </div>
                <div class="grid-content">
                  <h5>Total Centers</h5>
                  <h6>{data.center}</h6>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="grid-data gcolor2">
                <div class="img-data">
                  <img src="/images/student.png" />
                </div>
                <div class="grid-content">
                  <h5>Total students</h5>
                  <h6>{data.student}</h6>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="grid-data gcolor3">
                <div class="img-data">
                  <img src="/images/student.png" />
                </div>
                <div class="grid-content">
                  <h5>Daily Enquiry</h5>
                  {/* <h6>{profile.student_count}</h6> */}
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="grid-data gcolor4">
                <div class="img-data">
                  {" "}
                  <img src="/images/teacher.png" />
                </div>
                <div class="grid-content">
                  <h5>Active courses</h5>
                  <h6>{data.course}</h6>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="grid-data gcolor2">
                <div class="img-data">
                  <img src="/images/student.png" />
                </div>
                <div class="grid-content">
                  <h5>Total Trade</h5>
                  <h6>{data.trade_count}</h6>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="grid-data gcolor2">
                <div class="img-data">
                  <img src="/images/student.png" />
                </div>
                <div class="grid-content">
                  <h5>Total Sub Trade</h5>
                  <h6>{data.sub_trade_count}</h6>
                </div>
              </div>
            </div>
            {/* <div class="col-md-2">
              <div class="grid-data gcolor5">
                <div class="img-data">
                  {" "}
                  <img src="/images/wallet.png" />
                </div>
                <div class="grid-content">
                  <h5>My Certificates</h5>
                  <h6>0</h6>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div class="Title-box">
          <div class="row">
            {/* <div class="col-md-6">
              <Link to="/center/myprofile" class="btn btn-comman">
                My Profile Edit
              </Link>
            </div> */}
            {/* <div class="col-md-6">
          <div class="CommanForm d-flex">
            <div class="btncomplt">
              <Link to="/center/myprofile" class="btn btn-comman">
                My Profile Edit
              </Link>
            </div>
          </div>
        </div> */}
          </div>
        </div>
        {/* <div class="Widthdata">
      <div class="Applicationdata">
        <div class="cartitems">
          <div class="cartitembox">
            {" "}
            <div class="PaymentStatus">
              <a
                href="/colleges/N-K-Paramedical---Medical-Research-Center-Meerut-U-P-/7"
                target="_blank"
                class="btn btn-makepay"
              >
                {" "}
                View
              </a>
            </div>
            <div class="cartTop row">
              <div class="cartThumb col-md-5">
                <div class="cartImg">
                  <img height={"100%"} src={profile.md_pic} />
                </div>
              </div>
              <div class="cartCentent col-md-7">
                <div class="quick-names">
                  <div class="QVasName">{profile.center_name}</div>
                  <div class="QLocationmark">
                    {`${profile.center_address}  ${profile.center_city} ${profile.center_state}`}
                  </div>
                </div>

                <div class="cartAdate">
                  <div class="col-Dates">
                    <h5>Md Name</h5>
                    <h6>{profile.md_name}</h6>
                  </div>

                  <div class="col-Dates">
                    <h5>Contact Number</h5>
                    <h6>{profile.mobile}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
      </div>
    </div>
  );
};

export default  MemebrDashboard;
