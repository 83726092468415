import React, { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { updatePaymentStatus } from "../../../store/actions/centerActions/payment";

const Payment = () => {
  const dispatch = useDispatch();
  const { payment_id, payment_status, payment_request_id } = useParams();
  const [data, setData] = useState("Processing...");

  const fetchDetails = useCallback(async () => {
    const formdata = new FormData();
    formdata.append("payment_id", payment_id);
    formdata.append("payment_status", payment_status);
    formdata.append("payment_request_id", payment_request_id);
    const response = await dispatch(updatePaymentStatus(formdata));
    if (response) {
      setData(response.message);
    }
  }, [payment_id, payment_status, payment_request_id, dispatch]);

  useEffect(() => {
    fetchDetails();
  }, [fetchDetails]);

  return <div>{data}</div>;
};

export default Payment;
