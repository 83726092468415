export const feesMode = {
  offline: "offline",
  online: "online",
  both: "both",
};

export const courseType = {
  semester: "2",
  year: "1",
};

export const examType = {
  course_wise: "course_wise",
  subject_wise: "subject_wise",
  semester: "semester",
  yearly: "yearly",
};

export const COMPLAINT_BYS = {
  center: "0",
  student: "1",
};

export const getSemester = (duration) => {
  switch (duration) {
    case "6 months":
      return 1;
    case "1 years":
      return 2;
    case "1.5 years":
      return 3;
    case "2 years":
      return 4;
    case "3 years":
      return 6;
    default:
      return 1;
  }
};

export const getYears = (duration) => {
  switch (duration) {
    case "1 years":
      return 1;
    case "2 years":
      return 2;
    case "3 years":
      return 3;
    default:
      return 1;
  }
};
