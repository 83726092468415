import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { ALERT } from "../../../store/actions/actionTypes";
import {
  uploadPracticalMarks,
  addPracticalMarks,
  editPracticalMarks,
} from "../../../store/actions/centerActions/practicalMarks";

const EditPractical = ({
  show,
  onHide,
  subject_id,
  student_id,
  obtained_marks,
  roll_number,
  edit,
  practical_id,
}) => {
  const [marks, setMarks] = useState(obtained_marks);
  const dispatch = useDispatch();
  const submit = async (e) => {
    e.preventDefault();
    const data = {
      subject_id,
      student_id,
      max_marks: 50,
      obtained_marks: marks,
    };
    const response = await dispatch(
      edit
        ? editPracticalMarks({ ...data, practical_id: practical_id })
        : uploadPracticalMarks(data)
    );
    if (response) {
      dispatch(addPracticalMarks({ roll_number: roll_number }));
      onHide();
      dispatch({
        type: ALERT,
        payload: { success: "Practical Marks uploaded" },
      });
    }
  };
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>Upload Marks </Modal.Header>
      <Modal.Body>
        <form onSubmit={submit}>
          <div className="form-group">
            <label>
              Obtained Marks <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              className="form-control"
              value={marks}
              onChange={(e) => setMarks(e.target.value)}
              required
            ></input>
          </div>
          <button className="btn btn-success" type={submit}>
            Upload
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default EditPractical;
