import React, { useState } from "react";
import { useSelector } from "react-redux";
import { state_arr } from "../misc/StateCitySelector";
import "./css/filters.css";

const Filters = ({ filter, setFilter }) => {
  const { admin } = useSelector((state) => state);
  const { trades } = admin;
  const [subtrades, setSubtrades] = useState([]);
  const [filters, setFilters] = useState(filter);
  const { filterType, trade, subtrade, state, center_code } = filters;
  const selectTrade = (e) => {
    setFilters({
      ...filters,
      subtrade: "",
      trade: e.target.value,
    });
    const myTrades = trades.find((trade) => trade.id == e.target.value);
    setSubtrades(myTrades.subtrade || []);
  };

  const handleApply = (e) => {
    e.preventDefault();
    setFilter(filters);
  };

  return (
    <>
      <div
        class="listSS"
        value={filterType}
        onChange={(e) =>
          setFilters({
            trade: "",
            subtrade: "",
            state: "",
            center_code: "",
            filterType: e.target.value,
          })
        }
      >
        <label class={`year_srch ${filterType == 1 && "active"} `}>
          <input type="radio" name="srch" value={1} />{" "}
          <span>Search by Trade</span>
        </label>
        <label class={`center_srch ${filterType == 2 && "active"}`}>
          <input type="radio" name="srch" value={2} />{" "}
          <span>Search by Location</span>
        </label>
      </div>
      <div class="box_list_main">
        {filterType == "1" && (
          <div class="srchbyyear" style={{ display: "block" }}>
            <form class="selectbox" onSubmit={handleApply}>
              {/* <div class="container mt-2">
                <div class="selectbox row"> */}

              <div class="boxes">
                <label>Select Trade</label>
                <select
                  class="form-control"
                  name="master_fran"
                  value={trade}
                  onChange={selectTrade}
                >
                  <option selected value="">
                    Select
                  </option>
                  {trades == null ? (
                    <span>Loading...</span>
                  ) : trades.length > 0 ? (
                    trades.map((trade, index) => (
                      <option value={trade.id}>{trade.name}</option>
                    ))
                  ) : (
                    <option disabled value={""}>
                      {" "}
                      No Trade Added
                    </option>
                  )}
                </select>
              </div>
              <div class="boxes">
                <label>Select Sub Trade</label>
                <select
                  class="form-control"
                  name="master_fran"
                  value={subtrade}
                  onChange={(e) =>
                    setFilters({ ...filters, subtrade: e.target.value })
                  }
                >
                  <option>Select</option>
                  {subtrades.map((subt) => (
                    <option value={subt.id}>{subt.name}</option>
                  ))}
                </select>
              </div>
              <div class="boxes align-items-center d-flex  btn_yp">
                <label></label>
                <button class="btn btn-reset" type="submit">
                  Submit
                </button>
              </div>
            </form>
            {/* </div>
              </div> */}
          </div>
        )}
        {filterType == 2 && (
          <div class="srchbycenter" style={{ display: "block" }}>
            {/* <div class="container mt-2">
                <div class="selectbox row mb-4"> */}
            <form class="selectbox" onSubmit={handleApply}>
              <div class="boxes">
                <label>State</label>
                <select
                  class="form-control"
                  name="state"
                  id="stateId"
                  value={state}
                  onChange={(e) =>
                    setFilters({ ...filters, state: e.target.value })
                  }
                >
                  <option value={""}>Select State</option>
                  {state_arr.map((state, index) => (
                    <option value={state} key={index}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>

              <div class="boxes">
                <label>Center Code</label>
                <input
                  type="text"
                  name="center_code"
                  class="form-control"
                  placeholder="Type Center Code"
                  value={center_code}
                  onChange={(e) =>
                    setFilters({ ...filters, center_code: e.target.value })
                  }
                />
              </div>
              <div class="boxes align-items-center d-flex  btn_yp">
                <label></label>
                <button class="btn btn-reset">Submit</button>
              </div>
            </form>
            {/* </div>
              </div> */}
          </div>
        )}
      </div>
    </>
  );
};

export default Filters;
