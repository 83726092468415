import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import CKEditor from "react-ckeditor-component";

import { getDataAPI } from "../../../services/apiWrapper";
import { getCourseList } from "../../../store/actions/councilActions/courseAction";
import {
  addSubject,
  getSubjectDetails,
  updateSubject,
} from "../../../store/actions/councilActions/subjectAction";
import {
  getSubTrades,
  getTrades,
} from "../../../store/actions/councilActions/trade";
import BreadCrumb from "../../BreadCrumb";
import { useParams } from "react-router-dom";

export default function AddSubject() {
  const dispatch = useDispatch();
  const [exist, setExist] = useState(true);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const [data, setData] = useState({
    trade_id: "",
    sub_trade_id: "",
    course_id: "",
    name: "",
    semester: "",
    practical: "0",
    code:""
  });
  const [desription, setdesription] = useState("");
  const { trade_id, sub_trade_id, course_id, name,code, semester, practical } = data;
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [subTradeList, setsubTradeList] = useState(null);
  const [courseList, setcourseList] = useState(null);
  const { admin } = useSelector((state) => state);
  const { courses, trades, subtrades } = admin;
  const breadcrumbs = [
    {
      path: "/admin/dashboard",
      label: "Dashboard",
    },
    {
      // path: "/aboutus",
      label: "Add Subject",
    },
  ];
  useEffect(() => {
    if (!courses) {
      dispatch(getCourseList());
    }
    if (!subtrades) {
      dispatch(getSubTrades());
    }
    if (!trades) {
      dispatch(getTrades());
    }
  }, []);
  const fetchData = async () => {
    const response = await dispatch(getSubjectDetails(id));
    if (response) {
      setData({
        trade_id: response.trade_id,
        sub_trade_id: response.sub_trade_id,
        course_id: response.course_id,
        name: response.name,
        semester: response.semester,
        practical: response.practical,
        code:response.code
      });
      setdesription(response.desription);
      setLoading(false);
    } else {
      setExist(false);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (id) {
      setLoading(true);
      fetchData();
    }
  }, [id]);
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const handleTradeSelect = async (id) => {
    const token = `Bearer ${localStorage.getItem("adminToken")}`;
    try {
      const response = await dispatch(
        getDataAPI(`admin/subtrade/list?tradeID=${id}`, token)
      );
      setsubTradeList(response.data.data);
    } catch (error) {
      setsubTradeList([]);
    }
  };
  useEffect(() => {
    if (trade_id) {
      handleTradeSelect(trade_id);
    }
  }, [trade_id]);
  useEffect(() => {
    if (sub_trade_id) {
      handleSubTradeSelect(sub_trade_id);
    }
  }, [sub_trade_id]);
  const handleSubTradeSelect = async (e) => {
    const token = `Bearer ${localStorage.getItem("adminToken")}`;
    try {
      const response = await dispatch(
        getDataAPI(`admin/course/list?subTradeID=${e}`, token)
      );
      setcourseList(response.data.data);
    } catch (error) {
      setcourseList([]);
    }
  };
  const onChange = (evt) => {
    //console.log("onChange fired with event info: ", evt);
    var newContent = evt.editor.getData();
    setdesription(newContent);
  };
  const handleCourseSelect = (e) => {
    setData({
      ...data,
      course_id: e.target.value,
    });
    const arr = courseList.find((ele) => ele.id == e.target.value);
    setSelectedCourse(arr);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log(data);
    if (id) {
      const response = await dispatch(
        updateSubject({ ...data, desription: desription, subject_id: id })
      );
      if (response) {
        history.push("/admin/subjects");
      }
    } else {
      const response = await dispatch(
        addSubject({ ...data, desription: desription })
      );
      if (response) {
        history.push("/admin/subjects");
      }
    }
  };

  return (
    <div>
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
          <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <BreadCrumb crumbs={breadcrumbs} />

            <div class="d-flex align-items-center">
              <Link
                to="/admin/subjects"
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                View Subject
              </Link>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8">
              {loading ? (
                <div className="text-center">
                  <Spinner animation="border" role="status" size="lg">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : exist ? (
                <form onSubmit={handleSubmit}>
                  <div class="form-group">
                    <label>
                      Trade <span class="text-danger">*</span>
                    </label>
                    <select
                      class="form-control"
                      name="trade_id"
                      value={trade_id}
                      onChange={handleChange}
                    >
                      <option value="">Select</option>
                      {trades &&
                        trades.map((trade) => (
                          <option
                            key={trade.id}
                            value={trade.id}
                            onSelect={() => {
                              setsubTradeList(trade.subtrade);
                            }}
                          >
                            {trade.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div class="form-group">
                    <label>
                      Subtrade <span class="text-danger">*</span>
                    </label>
                    <select
                      class="form-control"
                      name="sub_trade_id"
                      value={sub_trade_id}
                      onChange={handleChange}
                    >
                      <option value="">Select</option>
                      {subTradeList == null ? (
                        <option disabled>Loading...</option>
                      ) : subtrades.length > 0 ? (
                        subTradeList.map((subtrade) => (
                          <option
                            key={subtrade.id}
                            value={subtrade.id}
                            onSelect={() => {
                              setcourseList(subtrade.course);
                            }}
                          >
                            {subtrade.name}
                          </option>
                        ))
                      ) : (
                        <option disabled>Sub Trade not exists</option>
                      )}
                    </select>
                  </div>
                  <div class="form-group">
                    <label>
                      Course <span class="text-danger">*</span>
                    </label>
                    <select
                      class="form-control"
                      name="course_id"
                      value={course_id}
                      onChange={handleCourseSelect}
                    >
                      <option value="">Select</option>
                      {courseList == null ? (
                        <option disabled>Loading...</option>
                      ) : courseList.length > 0 ? (
                        courseList.map((course) => (
                          <option
                            key={course.id}
                            id={JSON.stringify(course)}
                            value={course.id}
                          >
                            {course.name}
                          </option>
                        ))
                      ) : (
                        <option disabled>No Course exist</option>
                      )}
                    </select>
                  </div>

                  <div class="form-group">
                    <label>
                      Subject <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Subject Name "
                      name="name"
                      value={name}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="form-group">
                    <label>
                      Subject Code <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Subject Code "
                      name="code"
                      value={code}
                      onChange={handleChange}
                    />
                  </div>
                  {selectedCourse && selectedCourse.course_type == "2" && (
                    <div class="form-group" id="sem">
                      <label>
                        Select Semester <span class="text-danger">*</span>
                      </label>
                      <select
                        class="form-control"
                        name="semester"
                        value={semester}
                        onChange={handleChange}
                      >
                        <option value="">Select Semester</option>

                        <option value="1">1 Sem</option>
                        <option value="2">2 Sem</option>
                        <option value="3">3 Sem</option>
                        <option value="4">4 Sem</option>
                        <option value="5">5 Sem</option>
                        <option value="6">6 Sem</option>
                      </select>
                    </div>
                  )}
                  {selectedCourse && selectedCourse.course_type == "1" && (
                    <div class="form-group" id="years">
                      <label>
                        Select Year <span class="text-danger">*</span>
                      </label>
                      <select
                        class="form-control"
                        name="semester"
                        value={semester}
                        onChange={handleChange}
                      >
                        <option value="" disabled selected>
                          Select Year
                        </option>
                        <option value="1">1 Year</option>
                        <option value="2">2 Year</option>
                        <option value="3">3 Year</option>
                      </select>
                    </div>
                  )}
                  <div class="form-group">
                    <input
                      type="checkbox"
                      id="vehicle1"
                      name="practical"
                      value={"1"}
                      checked={practical == "1"}
                      onChange={() =>
                        setData({
                          ...data,
                          practical: practical == "1" ? "0" : "1",
                        })
                      }
                    />
                    <label for="practical"> Practical</label>
                    <br></br>
                  </div>

                  <div class="form-group">
                    <label>
                      Description
                      <span class="text-danger">*</span>
                    </label>
                    <CKEditor
                      activeClass="p10"
                      content={desription}
                      events={{
                        // blur: onBlur,
                        // afterPaste: afterPaste,
                        change: onChange,
                      }}
                    />

                    {/* <span class="form-text text-muted mt-4"> <%if(message){%><%=message%><%}%></span> */}
                  </div>

                  <button type="submit" class="btn btn-primary mr-2">
                    Submit
                  </button>
                  <button type="reset" class="btn btn-secondary">
                    Cancel
                  </button>
                </form>
              ) : (
                <h3>Subject Not Found</h3>
              )}
            </div>
            <div class="col-md-2"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
