import {
  deleteDataAPI,
  getDataAPI,
  postDataAPI,
} from "../../../services/apiWrapper";
import { ALERT, VIEW_GALLERY } from "../actionTypes";

export const addGallery = (data) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      postDataAPI("admin/gallery/create", data, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });
    if (response) {
      return true;
    }
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: { loading: false, error: error.response.data },
    });
    return false;
  }
};
export const updateGallery = (data) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      postDataAPI("admin/gallery/update", data, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });
    if (response) {
      return true;
    }
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: { loading: false, error: error.response.data },
    });
    return false;
  }
};

export const getGallery = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      getDataAPI(`admin/gallery/update/${id}`, token)
    );
    return response.data.data;
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: { loading: false, error: error.response.data?.message },
    });
    return null;
  }
};

export const viewGallery = () => async (dispatch) => {
  try {
    // dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(getDataAPI("admin/gallery/list"));
    dispatch({ type: VIEW_GALLERY, payload: response.data.data?.data });
    return {
      data: response.data.data?.data || [],
      last_page: response.data.data.last_page,
    };
    // dispatch({
    //   type: ALERT,
    //   payload: { loading: false },
    // });
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: { loading: false, error: error.response.data },
    });
    return {
      data: [],
    };
  }
};

export const viewPhotoGallery = () => async (dispatch) => {
  try {
    // dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(getDataAPI("admin/gallery/photo"));
    return { success: true, data: response.data.data };
    // dispatch({
    //   type: ALERT,
    //   payload: { loading: false },
    // });
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: { loading: false, error: error.response.data },
    });
    return {
      success: false,
      data: [],
    };
  }
};
export const viewVideoGallery = () => async (dispatch) => {
  try {
    // dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(getDataAPI("admin/gallery/video"));
    return { success: true, data: response.data.data };
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: { loading: false, error: error.response.data },
    });
    return {
      success: false,
      data: [],
    };
  }
};

export const viewSlider = () => async (dispatch) => {
  try {
    // dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(getDataAPI("admin/gallery/slider"));
    return { success: true, data: response.data.data };
  } catch (error) {
    return {
      success: false,
      data: [],
    };
  }
};

export const deleteGallery = (id) => async (dispatch) => {
  try {
    // dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      deleteDataAPI(`admin/gallery/delete/${id}`, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });
    return true;
    // dispatch(viewGallery());
    // dispatch({
    //   type: ALERT,
    //   payload: { loading: false },
    // });
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: { loading: false, error: error.response.data },
    });
    return false;
  }
};
