import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import TradeSubtradeSelector from "../../misc/TradeSubtradeSelector";
import BreadCrumb from "../../BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import {
  getCourses,
  getSubjects,
} from "../../../store/actions/councilActions/courseAction";
import {
  addQuestion,
  getQuestion,
  updateQuestion,
} from "../../../store/actions/councilActions/assignmentAction";
import { useParams } from "react-router-dom";

const AddAssignment = () => {
  const { id } = useParams();
  const [exist, setExist] = useState(true);
  const breadcrumbs = [
    {
      path: "/admin/dashboard",
      label: "Dashboard",
    },
    {
      label: "Add Assignment",
    },
  ];
  const dispatch = useDispatch();

  const initialState = {
    trade: "",
    sub_trade: "",
    course: "",
    subject: "",
    question_type: 1,
    question: "",
  };
  const [data, setData] = useState(initialState);
  const { trade, sub_trade, course, subject, question_type, question } = data;
  const { admin } = useSelector((state) => state);
  const { tradeCourses, courseSubjects } = admin;
  // const [question_type, setquestion_type] = useState(2);
  // useEffect(() => {
  //   if (question_type == 1) {
  //     setData({
  //       ...data,
  //     });
  //   }
  // });
  const options = [0, 1, 2, 3];
  const initialMcq = [
    { answer: "", is_correct: "0" },
    { answer: "", is_correct: "0" },
    { answer: "", is_correct: "0" },
    { answer: "", is_correct: "0" },
  ];
  const [mcq, setMcq] = useState(initialMcq);
  const handleOptionChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...mcq];
    list[index][name] = value;
    setMcq(list);
  };
  const handleAnswerChange = (e, index) => {
    let list = mcq;
    list = mcq.map((l, i) => {
      if (i == index) {
        return { ...l, is_correct: 1 };
      } else return { ...l, is_correct: 0 };
    });
    setMcq(list);
  };
  const [answer, setAnswer] = useState("");
  const initialTf = [
    { answer: "true", is_correct: "0" },
    { answer: "false", is_correct: "0" },
  ];
  const [tf, settf] = useState(initialTf);

  const handleTFAnswer = (value) => {
    let list = tf;
    list = tf.map((l, i) => {
      if (i == value) {
        return { ...l, is_correct: 1 };
      } else return { ...l, is_correct: 0 };
    });
    settf(list);
  };

  useEffect(() => {
    if (sub_trade) {
      dispatch(getCourses(sub_trade));
    }
  }, [sub_trade]);

  useEffect(() => {
    if (course) {
      dispatch(getSubjects(course));
    }
  }, [course]);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    for (let [key, value] of Object.entries(data)) {
      formdata.append(key, value);
    }
    formdata.append(
      "answer",
      question_type == "1" ? JSON.stringify(mcq) : JSON.stringify(tf)
    );
    // const bodyData = {
    //   ...data,
    //   answer: question_type == "1" ? mcq : tf,
    // };
    if (id) {
      // formdata.append("id", id);
      const response = await dispatch(updateQuestion(formdata, id));
      if (response) {
        setData(initialState);
        setMcq(initialMcq);
        settf(initialTf);
        setAnswer("");
      }
      return;
    }
    const response = await dispatch(addQuestion(formdata));
    if (response) {
      setData(initialState);
      setMcq(initialMcq);
      settf(initialTf);
      setAnswer("");
    }
  };
  const fetchData = async () => {
    const response = await dispatch(getQuestion(id));
    if (response) {
      setData({
        trade: response.trade,
        sub_trade: response.sub_trade,
        course: response.course,
        subject: response.subject,
        question_type: response.question_type,
        question: response.question,
      });
      response.question_type == "1"
        ? setMcq(response.mcq?.length > 0 ? response.mcq : initialMcq)
        : settf(response.mcq?.length > 0 ? response.mcq : initialTf);
    } else {
      setExist(false);
    }
  };
  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);
  return (
    <div>
      {" "}
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
          <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <BreadCrumb crumbs={breadcrumbs} />

            <div class="d-flex align-items-center">
              <Link
                to="/admin/view-assignment-list"
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                View Assigment
              </Link>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8">
              {/* <h3 class="card-title">Add Assigment</h3> */}
              {!exist ? (
                <h3>Question does not exist</h3>
              ) : (
                <form onSubmit={handleSubmit}>
                  <div class="card-body">
                    <TradeSubtradeSelector data={data} setData={setData} />
                    {sub_trade && (
                      <div class="col-md-12">
                        <div class="boxlabel">
                          <p>Course Name </p>
                          <select
                            class="form-control"
                            name="course"
                            value={course}
                            onChange={handleChange}
                            required
                          >
                            <option value="" disabled selected>
                              Select Course
                            </option>
                            {!tradeCourses ? (
                              <option value={""} disabled>
                                Loading...
                              </option>
                            ) : tradeCourses.length > 0 ? (
                              tradeCourses.map((course) => (
                                <option value={course.id}>{course.name}</option>
                              ))
                            ) : (
                              <option value={""} disabled>
                                No Course Found
                              </option>
                            )}
                          </select>
                        </div>
                      </div>
                    )}
                    {course && (
                      <div class="col-md-12">
                        <div class="boxlabel">
                          <p>Subject Name </p>
                          <select
                            class="form-control"
                            name="subject"
                            value={subject}
                            onChange={handleChange}
                            required
                          >
                            <option value="" selected>
                              Select subject
                            </option>
                            {!courseSubjects ? (
                              <option value={""} disabled>
                                Loading...
                              </option>
                            ) : courseSubjects.length > 0 ? (
                              courseSubjects.map((course) => (
                                <option value={course.id}>{course.name}</option>
                              ))
                            ) : (
                              <option value={""} disabled>
                                No Subject Found
                              </option>
                            )}
                          </select>
                        </div>
                      </div>
                    )}

                    <div class="col-md-12">
                      <div class="boxlabel">
                        <div class="form-group">
                          <p>
                            Question Type<span class="text-danger">*</span>
                          </p>
                          <div className="d-inline">
                            <div
                              className="boxlabel"
                              value={question_type}
                              onChange={handleChange}
                              // value={center_type}
                              // onChange={handleChange}
                            >
                              <label className="Radioc">
                                MCQ
                                <input
                                  type="radio"
                                  name="question_type"
                                  value={1}
                                  checked={question_type == 1}
                                  // onChange={() => setquestion_type(1)}
                                  required
                                />
                                <span className="checC"></span>
                              </label>
                              <label className="Radioc">
                                TRUE / FALSE
                                <input
                                  type="radio"
                                  name="question_type"
                                  value={2}
                                  checked={question_type == 2}
                                  // onChange={() => setquestion_type(2)}
                                />
                                <span className="checC"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="boxlabel">
                        <div class="form-group">
                          <p>
                            Question <span class="text-danger">*</span>
                          </p>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Question "
                            name="question"
                            value={question}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    {question_type == 1 ? (
                      <>
                        {options.map((option, index) => (
                          <div class="col-md-12">
                            <div class="boxlabel">
                              <div class="form-group">
                                <p>
                                  Option({index + 1}){" "}
                                  <span class="text-danger">*</span>
                                </p>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="option1"
                                  name="answer"
                                  value={mcq[index]?.answer}
                                  onChange={(e) => handleOptionChange(e, index)}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                        <div class="col-md-12">
                          <div class="boxlabel">
                            <div class="form-group">
                              <p>
                                Answer <span class="text-danger">*</span>
                              </p>

                              <select
                                class="form-control"
                                name="status"
                                // value={a}
                                onChange={(e) =>
                                  handleAnswerChange(e, +e.target.value - 1)
                                }
                                required
                              >
                                <option value={""} selected>
                                  --Select--
                                </option>
                                <option value="1">Option 1</option>
                                <option value="2">Option 2</option>
                                <option value="3">Option 3</option>
                                <option value="4">Option 4</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div class="col-md-12">
                        <div class="boxlabel">
                          <div class="form-group">
                            <p>
                              Answer<span class="text-danger">*</span>
                            </p>
                            <div className="d-inline">
                              <div
                                className="boxlabel"
                                value={answer}
                                onChange={(e) => {
                                  setAnswer(e.target.value);
                                  handleTFAnswer(+e.target.value - 1);
                                }}
                              >
                                <label className="Radioc">
                                  True
                                  <input
                                    type="radio"
                                    name="center_type"
                                    value={"1"}
                                    checked={answer == "1"}
                                    // onChange={handleChange}
                                    required
                                  />
                                  <span className="checC"></span>
                                </label>
                                <label className="Radioc">
                                  False
                                  <input
                                    type="radio"
                                    name="center_type"
                                    value={"2"}
                                    checked={answer == "2"}
                                    // onChange={handleChange}
                                  />
                                  <span className="checC"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* <div> */}
                  <button type="submit" class="btn btn-primary mr-2">
                    Submit
                  </button>
                  <button type="reset" class="btn btn-secondary">
                    Cancel
                  </button>
                  {/* </div> */}
                </form>
              )}
            </div>
            <div class="col-md-2"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAssignment;
