import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import StateCitySelector from "../../misc/StateCitySelector";
import { useDispatch, useSelector } from "react-redux";

import {
  getCourseList,
  getCourses,
} from "../../../store/actions/councilActions/courseAction";
import {
  addStudent,
  getStudent,
  updateStudent,
} from "../../../store/actions/centerActions/studentAction";
import { useParams } from "react-router-dom";
import TradeSubtradeSelector from "../../misc/TradeSubtradeSelector";
import Loader from "../../Alert/Loader";
import { payStudentFee } from "../../../store/actions/centerActions/payment";

export default function AddStudent() {
  const initialState = {
    mobile: "",
    alt_mobile: "",
    email: "",
    father_title: "",
    father_name: "",
    mother_title: "",
    mother_name: "",
    country: "",
    state: "",
    city: "",
    address: "",
    name: "",
    dob: "",
    course: "",
    gender: "",
    aadhar: "",
    merital_status: "",
    category: "",
    trade: "",
    sub_trade: "",
    feesMode: "",
  };
  const [registration_date, setregistration_date] = useState("");
  const [data, setData] = useState(initialState);
  const {
    mobile,
    alt_mobile,
    email,
    father_title,
    father_name,
    mother_title,
    mother_name,
    category,
    country,
    state,
    city,
    address,
    name,
    dob,
    course,
    gender,
    aadhar,
    merital_status,
    trade,
    sub_trade,
    feesMode,
  } = data;
  const dispatch = useDispatch();
  const { id } = useParams();
  const { admin, center } = useSelector((state) => state);
  const [roll_number, setroll_number] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [certificate10, setcertificate10] = useState([]);
  const [certificate12, setcertificate12] = useState([]);
  const [signature, setsignature] = useState([]);
  const [photo, setphoto] = useState([]);
  const [photoPreview, setphotoPreview] = useState(null);
  const [signaturePreview, setsignaturePreview] = useState(null);
  const [certificate10Preview, setcertificate10Preview] = useState(null);
  const [certificate12Preview, setcertificate12Preview] = useState(null);
  const { tradeCourses } = admin;
  const { centerAuth } = center;
  const [qualification, setqualification] = useState([
    {
      course: "",
      stream: "",
      board: "",
      year: "",
      marks_obtained: "",
      percentage: "",
    },
  ]);
  const handleQualificationDetailChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...qualification];
    list[index][name] = value;
    setqualification(list);
  };
  const handleQualificationRemoveClick = (index) => {
    const list = [...qualification];
    list.splice(index, 1);
    setqualification(list);
  };
  const handleQualificationAddClick = () => {
    setqualification([
      ...qualification,
      {
        course: "",
        stream: "",
        board: "",
        year: "",
        marks_obtained: "",
        percentage: "",
      },
    ]);
  };
  useEffect(() => {
    if (sub_trade) {
      dispatch(getCourses(sub_trade));
    }
  }, [sub_trade]);

  useEffect(async () => {
    if (id) {
      const response = await dispatch(getStudent(id));
      if (response.isSuccess) {
        setData({
          ...data,
          mobile: response.student_details?.mobile,
          alt_mobile: response.student_details?.alt_mobile,
          email: response.student_details?.email,
          father_title: response.student_details?.father_title,
          father_name: response.student_details?.father_name,
          mother_title: response.student_details?.mother_title,
          mother_name: response.student_details?.mother_name,
          country: response.student_details?.country,
          state: response.student_details?.state,
          address: response.student_details?.address,
          city: response.student_details?.city,
          name: response.name,
          dob: response.dob,
          gender: response.gender,
          category: response.category,
          merital_status: response.merital_status,
          aadhar: response.aadhar,
          course: response.course?.id,
          trade: response.trade,
          sub_trade: response.sub_trade,
          feesMode: response.feesMode,

          // trade:response.stu
        });
        setroll_number(response.roll_number);
        setphotoPreview(
          response.student_file?.find(
            (file) => file.file_type == "Student Signature"
          )?.file || null
        );
        // setcertificate10Preview(
        //   response.student_file?.find(
        //     (file) => file.file_type == "10th Certificate"
        //   )?.file || null
        // );
        // setcertificate12Preview(
        //   response.student_file?.find(
        //     (file) => file.file_type == "12th Certificate"
        //   )?.file || null
        // );
        // setsignaturePreview(
        //   response.student_file?.find(
        //     (file) => file.file_type == "Student Signature"
        //   )?.file || null
        // );

        setqualification(response.qualification);
      }
    }
  }, []);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    var formdata = new FormData();
    setSubmitting(true);

    for (let [key, value] of Object.entries(data)) {
      if (Array.isArray(value)) {
        formdata.append(key, JSON.stringify(value));
      } else {
        formdata.append(key, value);
      }
    }
    if (id) {
      formdata.append("roll_number", roll_number);
      formdata.append("student_id", id);
      if (photo) {
        formdata.append("photo", photo);
      }
      if (registration_date) {
        formdata.append("registration_date", registration_date);
      }
      dispatch(updateStudent(formdata, id));
    } else {
      // formdata.append("certificate10", certificate10);
      // formdata.append("certificate12", certificate12);
      // formdata.append("signature", signature);
      formdata.append("photo", photo);
      formdata.append("qualification", JSON.stringify(qualification));
      const response = await dispatch(addStudent(formdata));
      if (response) {
        const feeFormdata = new FormData();
        feeFormdata.append(
          "amount",
          +response[0].registration_fee + +response[0].exam_fee
        );
        feeFormdata.append("buyer_name", response[0].id);
        feeFormdata.append("email", response[0].email || "test@gmail.com");
        feeFormdata.append("phone", response[0].mobile || 9999999999);
        if (feesMode === "online") {
          const feesResponse = await dispatch(payStudentFee(feeFormdata));
          if (feesResponse) {
            window.location.replace(feesResponse.longurl);
          }
        }
        setSubmitting(false);
        setData(initialState);
        setphotoPreview(null);
        setphoto([]);
        setqualification([
          {
            course: "",
            stream: "",
            board: "",
            year: "",
            marks_obtained: "",
            percentage: "",
          },
        ]);
      } else {
        setSubmitting(false);
      }
    }
  };

  return (
    <div class="myForm">
      <div class="formTitle">Add Student</div>
      <Loader active={submitting} />
      <form onSubmit={handleSubmit}>
        <div class="row">
          <div class="col-md-9">
            <div class="row">
              <div class="col-md-4">
                <div class="boxlabel">
                  <p>Student Full Name</p>
                  <input
                    type="text"
                    class="form-control"
                    name="name"
                    value={name}
                    onChange={handleChange}
                    required
                  />{" "}
                </div>
              </div>

              {id && (
                <div class="col-md-4">
                  <div class="boxlabel">
                    <p>Student Roll No</p>
                    <input
                      type="text"
                      class="form-control"
                      name="roll_number"
                      value={roll_number}
                      onChange={(e) => setroll_number(e.target.value)}
                      required
                    />{" "}
                  </div>
                </div>
              )}
              <div class="col-md-4">
                <div class="boxlabel">
                  <p>Registration Date</p>
                  <input
                    type="date"
                    class="form-control"
                    name="registration_date"
                    value={registration_date}
                    onChange={(e) => setregistration_date(e.target.value)}
                    // required
                  />{" "}
                </div>
              </div>

              <div class="col-md-12">
                <div class="formTitle">Father’s/Husband Name</div>
              </div>
              <div class="col-md-4">
                <div class="boxlabel">
                  <p>Title </p>
                  <select
                    class="form-control"
                    name="father_title"
                    value={father_title}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select</option>
                    <option value="mr">Mr.</option>
                    <option value="dr">Dr.</option>
                    <option value="late">Late</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="boxlabel">
                  <p>Name </p>
                  <input
                    type="text"
                    class="form-control"
                    name="father_name"
                    value={father_name}
                    onChange={handleChange}
                    required
                  />{" "}
                </div>
              </div>
              <div class="col-md-12">
                <div class="formTitle">Mother Name</div>
              </div>
              <div class="col-md-4">
                <div class="boxlabel">
                  <p>Title </p>
                  <select
                    class="form-control"
                    name="mother_title"
                    value={mother_title}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select</option>
                    <option value="mrs">Mrs.</option>
                    <option value="ms">Ms.</option>
                    <option value="dr">Dr.</option>
                    <option value="late">Late</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="boxlabel">
                  <p>Name </p>
                  <input
                    type="text"
                    class="form-control"
                    name="mother_name"
                    value={mother_name}
                    onChange={handleChange}
                    required
                  />{" "}
                </div>
              </div>
              <div class="col-md-12"></div>
              <div class="col-md-4">
                <div class="boxlabel">
                  <p>Student Date of Birth</p>
                  <input
                    type="date"
                    class="form-control"
                    name="dob"
                    value={dob}
                    required
                    onChange={handleChange}
                  />{" "}
                </div>
              </div>
              <TradeSubtradeSelector data={data} setData={setData} />
              {sub_trade && (
                <div class="col-md-4">
                  <div class="boxlabel">
                    <p>Course Name </p>
                    <select
                      class="form-control"
                      name="course"
                      value={course}
                      onChange={handleChange}
                      required
                    >
                      <option value="" disabled selected>
                        Select Course
                      </option>
                      {!tradeCourses ? (
                        <option value={""} disabled>
                          Loading...
                        </option>
                      ) : tradeCourses.length > 0 ? (
                        tradeCourses.map((course) => (
                          <option value={course.id}>{course.name}</option>
                        ))
                      ) : (
                        <option value={""} selected>
                          No Course Found
                        </option>
                      )}
                    </select>
                  </div>
                </div>
              )}

              <div class="row">
                <div class="col-md-3">
                  <div class="boxlabel">
                    <p>Gender</p>
                    <label class="Radioc">
                      Male
                      <input
                        type="radio"
                        checked={gender == "1"}
                        name="gender"
                        value="1"
                        onChange={handleChange}
                        required
                      />{" "}
                      <span class="checC"></span>{" "}
                    </label>
                    <label class="Radioc">
                      Female
                      <input
                        type="radio"
                        checked={gender == "2"}
                        name="gender"
                        value="2"
                        onChange={handleChange}
                      />{" "}
                      <span class="checC"></span>{" "}
                    </label>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="boxlabel">
                    <p>Category</p>
                    <label class="Radioc">
                      GEN
                      <input
                        type="radio"
                        checked={category == "1"}
                        name="category"
                        value="1"
                        onChange={handleChange}
                        required
                      />{" "}
                      <span class="checC"></span>{" "}
                    </label>
                    <label class="Radioc">
                      OBC
                      <input
                        type="radio"
                        checked={category == "2"}
                        name="category"
                        value="2"
                        onChange={handleChange}
                      />{" "}
                      <span class="checC"></span>{" "}
                    </label>
                    <label class="Radioc">
                      SC
                      <input
                        type="radio"
                        checked={category == "3"}
                        name="category"
                        value="3"
                        onChange={handleChange}
                      />{" "}
                      <span class="checC"></span>{" "}
                    </label>
                    <label class="Radioc">
                      ST
                      <input
                        type="radio"
                        checked={category == "4"}
                        name="category"
                        value="4"
                        onChange={handleChange}
                      />{" "}
                      <span class="checC"></span>{" "}
                    </label>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="boxlabel">
                    <p>Marital Status</p>
                    <label class="Radioc">
                      Married
                      <input
                        type="radio"
                        checked={merital_status == "1"}
                        name="merital_status"
                        value="1"
                        onChange={handleChange}
                        required
                      />{" "}
                      <span class="checC"></span>{" "}
                    </label>
                    <label class="Radioc">
                      Unmarried
                      <input
                        type="radio"
                        name="merital_status"
                        value="2"
                        checked={merital_status == "2"}
                        onChange={handleChange}
                      />{" "}
                      <span class="checC"></span>{" "}
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="boxlabel">
                  <p>Email </p>
                  <input
                    type="text"
                    class="form-control"
                    name="email"
                    value={email}
                    required
                    onChange={handleChange}
                  />{" "}
                </div>
              </div>
              <div class="col-md-4">
                <div class="boxlabel">
                  <p>Contact No </p>
                  <input
                    type="text"
                    class="form-control"
                    name="mobile"
                    value={mobile}
                    required
                    onChange={handleChange}
                  />{" "}
                </div>
              </div>
              <div class="col-md-4">
                <div class="boxlabel">
                  <p>Alternate Number </p>
                  <input
                    type="text"
                    class="form-control"
                    name="alt_mobile"
                    value={alt_mobile}
                    // required
                    onChange={handleChange}
                  />{" "}
                </div>
              </div>

              <div class="col-md-8">
                <div class="boxlabel">
                  <p>Permanent Address </p>
                  <input
                    type="text"
                    class="form-control"
                    name="address"
                    value={address}
                    required
                    onChange={handleChange}
                  />{" "}
                </div>
              </div>
              <div class="col-md-4">
                <div class="boxlabel">
                  <p>Country </p>
                  <input
                    type="text"
                    class="form-control"
                    name="country"
                    value={country}
                    required
                    onChange={handleChange}
                  />{" "}
                </div>
              </div>
              <StateCitySelector
                user_city={city}
                user_state={state}
                set_user_state={(e) => setData({ ...data, state: e })}
                set_user_city={(e) => setData({ ...data, city: e })}
              />
              <div class="col-md-6">
                <div class="boxlabel">
                  <p>Aadhar Card No </p>
                  <input
                    type="text"
                    class="form-control"
                    name="aadhar"
                    maxlength="12"
                    value={aadhar}
                    onChange={handleChange}
                    // required
                  />{" "}
                </div>
              </div>
              <>{console.log()}</>
              <div class="col-md-6">
                <div class="boxlabel">
                  <p>Fees Mode </p>
                  <select
                    value={feesMode}
                    onChange={handleChange}
                    class="form-control"
                    name="feesMode"
                    required
                  >
                    <option value={""}>--Select--</option>

                    {centerAuth?.profile?.payment_mode === "offline" ? (
                      <option value="offline">Offline</option>
                    ) : centerAuth?.profile?.payment_mode === "online" ? (
                      <option value={"online"}>Online</option>
                    ) : (
                      <>
                        <option value={"online"}>Online</option>
                        <option value="offline">Offline</option>
                      </>
                    )}
                  </select>
                </div>
              </div>
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table">
                    <thead class="thead-info">
                      <tr>
                        <th scope="col">Examination Passed </th>
                        <th scope="col">Name of Stream </th>
                        <th scope="col">Board/University </th>
                        <th scope="col">Year of Passing</th>
                        <th scope="col">Marks Obtained </th>
                        <th scope="col">%of Marks </th>
                        {qualification.length !== 1 && (
                          <th scope="col">Delete</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {qualification.map((qualify, index) => (
                        <tr>
                          <td>
                            <select
                              name="course"
                              value={qualify.course}
                              class="form-control"
                              onChange={(e) =>
                                handleQualificationDetailChange(e, index)
                              }
                            >
                              <option value={""}>--Select--</option>
                              <option value="10">10th</option>
                              <option value={"12"}>12th</option>

                              <option value={"ug"}>Graduation</option>
                              <option value={"pg"}>Post Graduation</option>
                            </select>
                          </td>
                          <td>
                            <input
                              type="text"
                              class="form-control"
                              name="stream"
                              value={qualify.stream}
                              onChange={(e) =>
                                handleQualificationDetailChange(e, index)
                              }
                              placeholder="ex. science"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              class="form-control"
                              name="board"
                              value={qualify.board}
                              onChange={(e) =>
                                handleQualificationDetailChange(e, index)
                              }
                              placeholder="Board/Univeristy"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              max={9999}
                              class="form-control"
                              name="year"
                              value={qualify.year}
                              onChange={(e) =>
                                handleQualificationDetailChange(e, index)
                              }
                              placeholder="ex. 2012"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              class="form-control"
                              name="marks_obtained"
                              value={qualify.marks_obtained}
                              onChange={(e) =>
                                handleQualificationDetailChange(e, index)
                              }
                              placeholder="ex. 500"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              class="form-control"
                              name="percentage"
                              onChange={(e) =>
                                handleQualificationDetailChange(e, index)
                              }
                              value={qualify.percentage}
                              placeholder="ex. 300"
                            />
                          </td>
                          {qualification.length !== 1 && (
                            <td>
                              <button
                                class="btn btn-add"
                                type="button"
                                onClick={() =>
                                  handleQualificationRemoveClick(index)
                                }
                              >
                                Remove
                              </button>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div class="appendbtn text-right">
                    <button
                      class="btn btn-add"
                      type="button"
                      id="addstaff"
                      onClick={handleQualificationAddClick}
                    >
                      Add+
                    </button>
                  </div>
                </div>
              </div>

              {/* <div class="col-md-4">
                <div class="Fileboxbale">
                  <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="customFile2"
                      name="teenth"
                      // required
                      onChange={(e) => {
                        setcertificate10(e.target.files[0]);
                        setcertificate10Preview(
                          URL.createObjectURL(e.target.files[0])
                        );
                      }}
                    />
                    <label className="custom-file2" for="customFile2">
                      {certificate10Preview ? (
                        <img
                          src={certificate10Preview}
                          height={"100px"}
                          id="frontphoto"
                        />
                      ) : (
                        <>
                          <img src="/images/dummy.png" height={"100px"}></img>
                        </>
                      )}
                      <h4>10th Certificate</h4>
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="Fileboxbale">
                  <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="customFile3"
                      name="inter"
                      onChange={(e) => {
                        setcertificate12(e.target.files[0]);
                        setcertificate12Preview(
                          URL.createObjectURL(e.target.files[0])
                        );
                      }}
                    />
                    <label className="custom-file2" for="customFile3">
                      {certificate12Preview ? (
                        <img
                          src={certificate12Preview}
                          height={"100px"}
                          id="frontphoto"
                        />
                      ) : (
                        <>
                          <img src="/images/dummy.png" height={"100px"}></img>
                        </>
                      )}
                      <h4>12th Certificate</h4>
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="Fileboxbale">
                  <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="customFile5"
                      name="signphoto"
                      // required
                      onChange={(e) => {
                        setsignature(e.target.files[0]);
                        setsignaturePreview(
                          URL.createObjectURL(e.target.files[0])
                        );
                      }}
                    />
                    <label className="custom-file2" for="customFile5">
                      {signaturePreview ? (
                        <img
                          src={signaturePreview}
                          height={"100px"}
                          id="frontphoto"
                        />
                      ) : (
                        <>
                          <img src="/images/dummy.png" height={"100px"}></img>
                        </>
                      )}
                      <h4>Student Signature</h4>
                    </label>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div class="col-md-3">
            <div class="Fileboxbale">
              <div class="custom-file">
                <input type="hidden" value="" name="classphoto_old" />
                <input
                  type="file"
                  class="custom-file-input"
                  id="customFile"
                  name="studentpic"
                  // required
                  onChange={(e) => {
                    setphoto(e.target.files[0]);
                    setphotoPreview(URL.createObjectURL(e.target.files[0]));
                  }}
                />
                <label className="custom-file2" for="customFile">
                  {photoPreview ? (
                    <img src={photoPreview} height={"100px"} id="frontphoto" />
                  ) : (
                    <>
                      <img src="/images/dummy.png" height={"100px"}></img>
                    </>
                  )}
                  <h4>Student Photo</h4>
                </label>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div
              class="btn-groups mt-4 text-right"
              role="group"
              aria-label="First group"
            >
              <button class="btn btn-Next" type="submit" disabled={submitting}>
                Submit
                <span>
                  <svg
                    width="35"
                    height="12"
                    viewBox="0 0 35 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M34.8403 6.00003C34.8403 6.2151 34.7582 6.43013 34.5943 6.5941L29.4346 11.7538C29.1063 12.0821 28.5742 12.0821 28.2461 11.7538C27.918 11.4257 27.918 10.8937 28.2461 10.5654L32.8117 6.00003L28.2462 1.43461C27.9181 1.10639 27.9181 0.574383 28.2462 0.246316C28.5743 -0.0820704 29.1065 -0.0820704 29.4347 0.246316L34.5945 5.40596C34.7584 5.57001 34.8403 5.78505 34.8403 6.00003Z"
                      fill="white"
                    ></path>
                    <line
                      x1="33.25"
                      y1="5.94995"
                      x2="0.75"
                      y2="5.94995"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    ></line>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
