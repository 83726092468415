import React, { useCallback, useEffect, useRef, useState } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useDispatch } from "react-redux";
import { viewSlider } from "../../../store/actions/adminActions/addGallery";
import { useLoadMore } from "../../Council/News/useLoadMore";
import "./css/slidebox.css";
import News from "./News";
import Notifications from "./Notifications";

export default function Sidebox() {
  const images = [
    {
      original:
        "/uploads/1625905115122photo-1579165466991-467135ad3110-(1).jpg",
      thumbnail:
        "/uploads/1625905115122photo-1579165466991-467135ad3110-(1).jpg",
    },
    {
      original: "/uploads/16259150632451590756965n3.jpg",
      thumbnail: "/uploads/16259150632451590756965n3.jpg",
    },
    {
      original: "/uploads/1625915215735unnamed.jpg",
      thumbnail: "/uploads/1625915215735unnamed.jpg",
    },
  ];

  const [list, setlist] = useState(null);
  const dispatch = useDispatch();
  useEffect(async () => {
    const response = await dispatch(viewSlider());
    const arr = response.data.data.map((d) => {
      return {
        original: d.file,
      };
    });
    setlist(arr || []);
  }, []);

  return (
    <>
      <div class="SlideBoxs">
        <div class="container">
          <div class="row">
            <Notifications />
            <div class="col-lg-6 col-md-12 pl-2 pr-2 col-sm-12 mb-2">
              <ImageGallery
                items={list || []}
                showThumbnails={false}
                autoPlay={true}
                showPlayButton={false}
                showFullscreenButton={false}
                // showBullets={true}
              />
              {/* <div class="owl-carousel owl-theme owl-single owl-drag">
                <div class="item">
                  <div class="homebanner">
                    <img src="/uploads/1625905115122photo-1579165466991-467135ad3110-(1).jpg" />
                  </div>
                </div>

                <div class="item">
                  <div class="homebanner">
                    <img src="/uploads/16259150632451590756965n3.jpg" />
                  </div>
                </div>

                <div class="item">
                  <div class="homebanner">
                    <img src="/uploads/16259151088099.jpg" />
                  </div>
                </div>

                <div class="item">
                  <div class="homebanner">
                    <img src="/uploads/1625915215735unnamed.jpg" />
                  </div>
                </div>
              </div> */}
            </div>
            <News />

            {/* <div class="col-lg-3 col-md-12 col-sm-12 mb-2">
              <div class="NOtification">
                <h5>Important News</h5>
                <div class="notification-items">
                  {data.length > 0 ? (
                    data.map((n, index) => {
                      if (data.length === index + 1) {
                        return (
                          <div class="upboxs" ref={lastDataElementRef} key={n}>
                            <div class="upDateimg">
                              <img src={n.file} />
                            </div>
                            <div class="upData">
                              <a href="/news/<%=notifi.id%>">
                                <h4>{n.title}</h4>
                              </a>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div class="upboxs">
                            <div class="upDateimg">
                              <img src={n.file} />
                            </div>
                            <div class="upData">
                              <a href="/news/<%=notifi.id%>">
                                <h4>{n.title}</h4>
                              </a>
                            </div>
                          </div>
                        );
                      }
                    })
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div class="container">
        <div class="notifgsec">
          <div class="row">
            <div class="col-lg-2 col-md-3 col-sm-12 col-12 align-self-center">
              <div class="notifg">IMPORTANT NOTICE</div>
            </div>
            <div class="col-lg-10 col-md-9 col-sm-12 col-12">
              <ul className="row">
                <li className="col-md-4 text-sm-center text-md-start col-sm-12 col-12">
                  <a href="#">
                    <img src="/images/BlinkingNew.gif" /> Teacher Registration
                    for Practical Exam
                  </a>
                </li>
                <li className="col-md-4 text-sm-center text-md-start col-sm-12 col-12">
                  <a href="#"> Application Portal for Academic Position-2020</a>
                </li>
                <li className="col-md-4 text-sm-center text-md-start col-sm-12 col-12">
                  <a href="#">
                    <img src="/images/BlinkingNew.gif" /> Teacher Registration
                    for Practical Exam
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
