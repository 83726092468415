import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ALERT } from "../../store/actions/actionTypes";
import {
  adminDashboard,
  adminLogin,
} from "../../store/actions/helpdeskActions/auth";

export default function LoginHelpDesk(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { helpdesk } = useSelector((state) => state);
  const { adminAuth } = helpdesk;

  useEffect(() => {
    adminAuth.isAuth &&
      history.push(
        props.location.state?.from.pathname || "/helpdesk/dashboard"
      );
  }, [adminAuth.isAuth]);

  useEffect(() => {
    dispatch(adminDashboard());
  }, []);

  const [data, setData] = useState({ email: "", password: "", user_type: "2" });
  const { email, password } = data;
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(adminLogin(data));
    dispatch({
      type: ALERT,
      payload: { loading: true },
    });
  };
  return (
    <>
      <div class="sc-VigVT kCeVIa sc-gZMcBi jxPJzF"></div>
      <section class="logindesign">
        <div class="container">
          <div class="Formboxlogin">
            <div class="innerForm">
              <div class="Login-header">Login to your account</div>

              <form onSubmit={handleSubmit}>
                <div class="telBoxform">
                  <div class="formBox2">
                    <input
                      type="text"
                      placeholder="Enter UserName*"
                      name="email"
                      value={email}
                      onChange={handleChange}
                      class="form-control"
                      required
                    />
                  </div>

                  <div class="formBox2">
                    <input
                      type="password"
                      placeholder="Password*"
                      name="password"
                      value={password}
                      onChange={handleChange}
                      class="form-control"
                      required
                    />
                  </div>
                </div>
                <div class="buttonSub">
                  <button class="btn btn-continues" type="submit">
                    Login
                  </button>
                </div>
              </form>
              <div class="Termslink mt-4">
                Forgot your password? <a href="#">Reset here</a>
              </div>
              <div class="My-help">
                Have trouble logging in? <span>Get help</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
