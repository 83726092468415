import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./css/login.css";
import {
  studentDashboard,
  studentLogin,
} from "../../../store/actions/studentAction/studentAuth";
import { useHistory } from "react-router-dom";

export default function Login(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [data, setData] = useState({
    roll_number: "",
    password: "",
  });
  const { roll_number, password } = data;
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const { student } = useSelector((state) => state);
  const { studentAuth } = student;

  useEffect(() => {
    dispatch(studentDashboard());
  }, []);

  useEffect(() => {
    studentAuth.isAuth &&
      history.push(props.location.state?.from.pathname || "/student/dashboard");
  }, [studentAuth.isAuth]);
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(studentLogin(data));
  };
  return (
    <>
      <div className="sc-VigVT kCeVIa sc-gZMcBi jxPJzF"></div>
      <section className="logindesign">
        <div className="container">
          <div className="Formboxlogin">
            <div className="innerForm">
              <div className="Login-header">Login to your account</div>

              <form onSubmit={handleSubmit}>
                <div className="telBoxform">
                  <div className="boxlabel"></div>

                  <div className="formBox2">
                    <input
                      type="text"
                      placeholder="Enter Your Roll Number*"
                      name="roll_number"
                      value={roll_number}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>

                  <div className="formBox2">
                    <input
                      type="password"
                      placeholder="Password*"
                      name="password"
                      value={password}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="buttonSub">
                  <button className="btn btn-continues" type="submit">
                    Login
                  </button>
                </div>
              </form>
              <div className="My-help">
                Have trouble logging in? <span>Get help</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
