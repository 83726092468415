import React from "react";
import { useSelector } from "react-redux";

const ExamForm = () => {
  const { studentAuth } = useSelector((state) => state.student);
  const { profile } = studentAuth;
  return (
    <div className="row">
      <div className="col-md-2"></div>
      <div className="col-md-8">
        <iframe
          src={`https://npvec.com/api/public/ExamForm/${profile.id}`}
          width={"100%"}
          height={"800px"}
        ></iframe>
      </div>
    </div>
  );
};

export default ExamForm;
