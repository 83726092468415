import React, { useState } from "react";
import {
  Form,
  FormControl,
  Button,
  Spinner,
  Table,
  Accordion,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { addPracticalMarks } from "../../../store/actions/centerActions/practicalMarks";
import EditPractical from "./EditPractical";

const AddPracticalMarks = () => {
  const [roll_number, setroll_number] = useState("");
  const [upload_marks, setupload_marks] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { practical, centerAuth } = useSelector((state) => state.center);
  const [data, setData] = useState(null);
  const [subject_id, setsubject_id] = useState("");
  const [student_id, setstudent_id] = useState("");
  const [obtained_marks, setobtained_marks] = useState("");
  const [practical_id, setpractical_id] = useState("");
  const [edit, setEdit] = useState(false);
  const searchStudent = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await dispatch(
      addPracticalMarks({ roll_number: roll_number })
    );
    if (response) {
      setLoading(false);
      setData(response.data);
    } else {
      setLoading(false);
      setData(false);
    }
  };
  const handleUpload = (student, sub, marks, edit) => {
    setupload_marks(true);
    setstudent_id(student);
    setsubject_id(sub);
    setobtained_marks(marks);
    setEdit(edit);
  };
  return (
    <div class="Title-box">
      <EditPractical
        show={upload_marks}
        onHide={() => setupload_marks(false)}
        subject_id={subject_id}
        student_id={student_id}
        obtained_marks={obtained_marks}
        roll_number={roll_number}
        edit={edit}
        practical_id={practical_id}
      />
      <div class="row">
        <div class="">
          <div class="BigTitle">Add Practical Marks</div>
          <div className="text-center w-50 mb-4">
            <Form className="d-flex mt-4 " onSubmit={searchStudent}>
              <FormControl
                type="search"
                placeholder="Enter Roll Number"
                className="me-2"
                aria-label="Search"
                value={roll_number}
                onChange={(e) => setroll_number(e.target.value)}
              />
              <Button type="submit" variant="outline-success">
                Search
              </Button>
            </Form>
          </div>
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" role="status" size="lg">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : practical === false ? (
            <h3>Record not found</h3>
          ) : practical ? (
            practical.exam_type == "course_wise" ? (
              <h3>No practical exam for this student.</h3>
            ) : centerAuth.profile.id == practical.getCenterData.id ? (
              Object.keys(practical.semester).map((sem, index) => (
                <Accordion className="mb-3">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      {practical.course_type == "2" ? "Semester " : "Year "}{" "}
                      {sem}
                    </Accordion.Header>
                    <Accordion.Body className="p-0">
                      {/* <Card.Body> */}
                      <Table bordered hover striped responsive className="mb-0">
                        <thead>
                          <tr>
                            <th>S.NO.</th>
                            <th>Subject</th>
                            <th>Max marks</th>
                            <th>Obtained Marks </th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {practical.semester[sem].map((sub, i) => (
                            <>
                              <tr>
                                <td>{i + 1}</td>
                                <td>{sub.name}</td>
                                {sub.practical == "0" ? (
                                  <td
                                    colSpan={4}
                                    className="text-center text-danger"
                                  >
                                    No Practical
                                  </td>
                                ) : (
                                  <>
                                    <td>{50}</td>
                                    <td>
                                      {sub.practical_marks
                                        ? sub.practical_marks.obtained_marks
                                        : "Pending"}
                                    </td>
                                    <td>
                                      {sub.practical_marks
                                        ? sub.practical_marks.obtained_marks >
                                          20
                                          ? "Pass"
                                          : "Fail"
                                        : "Pending"}
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-sm btn-success"
                                        onClick={() =>
                                          handleUpload(
                                            sub.student_id,
                                            sub.id
                                            // sub.practical_marks?.obtained_marks
                                          )
                                        }
                                      >
                                        Upload Marks
                                      </button>
                                      {/* !sub.exam_done ? (
                                        !sub.practical_marks ? (
                                          <button
                                            className="btn btn-sm btn-success"
                                            onClick={() =>
                                              handleUpload(
                                                sub.student_id,
                                                sub.id
                                                // sub.practical_marks?.obtained_marks
                                              )
                                            }
                                          >
                                            Upload Marks
                                          </button>
                                        ) : (
                                          // ) : (
                                          <button
                                            className="btn btn-sm btn-danger"
                                            onClick={() => {
                                              setpractical_id(
                                                sub.practical_marks.id
                                              );
                                              setobtained_marks(
                                                sub.practical_marks
                                                  .obtained_marks
                                              );

                                              handleUpload(
                                                sub.student_id,
                                                sub.id,
                                                sub.practical_marks
                                                  .obtained_marks,
                                                true
                                              );
                                            }}
                                          >
                                            Edit
                                          </button>
                                        )
                                      ) : (
                                        "Exam Done"
                                      )}
                                      {/* )} */}
                                    </td>
                                  </>
                                )}
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ))
            ) : (
              <h3>You cannot upload practical marks of this candidate.</h3>
            )
          ) : null}
          <form>
            {/* <div></div> */}
            {/* <div className="row">
              <div className="col-md-5">
                <div className="row d-flex">
                  <label className="col-md-3">Roll No.</label>
                  <input
                    className="col-md-8"
                    placeholder="Enter Roll No"
                    //   value={roll_number}
                    //   onChange={(e) => setroll_number(e.target.value)}
                  />
                </div>
              </div>
            </div> */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddPracticalMarks;
