import { getDataAPI, postDataAPI } from "../../../services/apiWrapper";
import { exportToCSV } from "../../../utils/exportCsv";
import { ALERT, SET_SUBJECT } from "../actionTypes";

export const getSubjectList = (page, course) => async (dispatch) => {
  try {
    // dispatch({ type: ALERT, payload: { loading: true } });
    const params = `${course ? `&course=${course}` : ""}&page=${page}
  `;
    const response = await dispatch(getDataAPI(`admin/subject/list?${params}`));
    // dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({ type: SET_SUBJECT, payload: response.data.data });
    return response.data.data;
  } catch (error) {
    // dispatch({ type: ALERT, payload: { loading: false } });
    return false;
  }
};

export const getsubjectList = (course) => async (dispatch) => {
  try {
    // dispatch({ type: ALERT, payload: { loading: true } });
    const params = `${course ? `course=${course}` : ""}
  `;
    const response = await dispatch(getDataAPI(`admin/subject/list?${params}`));
    // dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({ type: SET_SUBJECT, payload: response.data.data });
    return response.data.data;
  } catch (error) {
    // dispatch({ type: ALERT, payload: { loading: false } });
    return false;
  }
};

export const downloadSubjects = (course, courseName) => async (dispatch) => {
  try {
    const params = `${course ? `&course=${course}` : ""}`;
    const response = await dispatch(getDataAPI(`admin/subject/list?${params}`));
    const dataToExport = [];
    response.data.data.forEach((ele, index) => {
      dataToExport.push({
        Sno: index + 1,
        Subject: ele.name,
        "Subject Code": ele.code,
        Course: ele.course_name?.name || "",
      });
    });
    exportToCSV(dataToExport, courseName || "Subjects");
  } catch (error) {
    return false;
  }
};

export const getSubjectDetails = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      getDataAPI(`admin/subject/edit/${id}`, token)
    );
    return response.data.data;
  } catch (error) {
    return false;
  }
};

export const addSubject = (data) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      postDataAPI("admin/subject/create", data, token)
    );
    dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({ type: ALERT, payload: { success: response.data.message } });
    if (response) {
      return true;
    }
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};

export const updateSubject = (data) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      postDataAPI("admin/subject/edit", data, token)
    );
    dispatch({ type: ALERT, payload: { loading: false } });
    dispatch({ type: ALERT, payload: { success: response.data.message } });
    if (response) {
      return true;
    }
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};
