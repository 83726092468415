import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import LinksMenu from "./LinksMenu";
import BreadCrumb from "../BreadCrumb";
import { Link } from "react-router-dom";
import { Spinner, Table } from "react-bootstrap";
import { downloadList } from "../../store/actions/councilActions/downloadActions";
import Pagination from "../misc/Pagination";

const Downloads = () => {
  const breadcrumbs = [
    {
      path: "/",
      label: "Home",
    },
    {
      label: "Download List",
    },
  ];

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [activePage, setactivePage] = useState(1);
  const [totalPages, settotalPages] = useState(0);
  const fetchdata = useCallback(async () => {
    const response = await dispatch(downloadList(activePage));
    if (response) {
      setData(response.data);
      settotalPages(Math.ceil(response.total / response.per_page));
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [activePage]);
  useEffect(() => {
    setLoading(true);
    fetchdata();
  }, [fetchdata]);

  return (
    <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
      <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
        <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <BreadCrumb crumbs={breadcrumbs} />
        </div>
      </div>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status" size="lg">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <Table bordered hover striped responsive className="mb-0">
          <thead>
            <th>S No</th>
            <th>Title</th>
            <th>Posted Date</th>
            <th>File</th>
          </thead>
          <tbody>
            {data.length == 0 ? (
              <tr>
                <td colSpan={5} className="text-center text-danger">
                  No Record Found
                </td>
              </tr>
            ) : (
              data.map((element, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{element.title}</td>
                  <td>{element.posted_date}</td>
                  <td>
                    <a href={element.file} download target={"_blank"}>
                      Download
                    </a>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      )}
      {totalPages && Number(totalPages) !== 0 ? (
        <Pagination
          setActivePage={(value) => setactivePage(value)}
          totalPages={totalPages}
        />
      ) : null}
    </div>
  );
};

export default Downloads;
