import React, { useCallback, useRef, useState, useEffect } from "react";
import { useLoadMore } from "../Council/News/useLoadMore";
import { useDispatch, useSelector } from "react-redux";
import {
  viewGallery,
  viewPhotoGallery,
} from "../../store/actions/adminActions/addGallery";
import Loader from "../Alert/Loader";

export default function Gallery() {
  const dispatch = useDispatch();
  const { global } = useSelector((state) => state);
  const { gallery } = global;
  const [images, setImages] = useState(null);
  const [videos, setVideos] = useState([]);

  const [pageNumber, setPageNumber] = useState(1);
  const [loading, data, setData, hasMore] = useLoadMore(
    "admin/gallery/photo?page=",
    pageNumber
  );

  const observer = useRef();
  const lastDataElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  var regExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;

  useEffect(async () => {
    const response = await dispatch(viewPhotoGallery());
    setImages([...response.data.data]);
  }, [dispatch]);

  return (
    <>
      <div className="gallery-box">
        <div className="container">
          <div className="title-box">
            <h4>Our Gallery</h4>
          </div>
          <div className="row my-4">
            {data == null ? (
              <Loader active={true} />
            ) : data.length > 0 ? (
              data.map((gal, index) => {
                if (data.length === index + 1) {
                  return (
                    <div
                      key={index}
                      className="col-12 col-sm-6 mt-3 col-md-3"
                      ref={lastDataElementRef}
                    >
                      <div className="galleryBox text-center p-2">
                        <a
                          title={gal.title}
                          target="_blank"
                          href={gal.file}
                          rel="noreferrer"
                        >
                          <figure class="figure mb-0">
                            <img
                              class="figure-img img-fluid rounded"
                              src={gal.file}
                              alt={gal.title}
                            />
                            <figcaption class="figure-caption  text-white text-center">
                              {gal.title}
                            </figcaption>
                          </figure>
                        </a>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={index}
                      className="col-12 col-sm-6 mt-3 col-md-3"
                      ref={lastDataElementRef}
                    >
                      <div className="galleryBox text-center p-2">
                        <a
                          title={gal.title}
                          target="_blank"
                          href={gal.file}
                          rel="noreferrer"
                        >
                          <figure class="figure mb-0">
                            <img
                              class="figure-img img-fluid rounded"
                              src={gal.file}
                              alt={gal.title}
                            />
                            <figcaption class="figure-caption  text-white text-center">
                              {gal.title}
                            </figcaption>
                          </figure>
                        </a>
                      </div>
                    </div>
                  );
                }
              })
            ) : (
              <h1>No Gallery found</h1>
            )}
          </div>

          {/* {data.length > 0 ? (
            data.map((gal, index) => {
              if (data.length === index + 1) {
                return (
                  <div
                    className="col-md-5 col-sm-12"
                    ref={lastDataElementRef}
                    key={gal}
                  >
                    <div className="galleryBox">
                      <img src={gal.file} alt={gal.title} />
                      <h6 className="text-center">{gal.title}</h6>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="col-md-5 col-sm-12">
                    <div className="galleryBox">
                      <img src={gal.file} alt={gal.title} />
                      <h6 className="text-center">{gal.title}</h6>
                    </div>
                  </div>
                );
              }
            })
          ) : (
            <div></div>
          )}
          {!loading && data.length == 0 && <div>No News Available</div>}
          {loading && <div>Loading...</div>} */}

          {/* <div className="row">
            {images == null ? (
              <Loader active={true} />
            ) : images.length > 0 ? (
              images.map((gal) => (
                <div className="col-md-5 col-sm-12">
                  <div className="galleryBox">
                    <img src={gal.file} alt={gal.title} />
                    <h6 className="text-center">{gal.title}</h6>
                  </div>
                </div>
              ))
            ) : (
              <h1>No Gallery found</h1>
            )}
          </div> */}
          {/* </div> */}
          {/* <div className="row"> */}
          {/* <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="row">
                {videos.map((video) => (
                  <div className="col-md-6 col-sm-12">
                    <div className="galleryBox">
                      <iframe
                        width={"100%"}
                        height={"100%"}
                        src={`//www.youtube.com/embed/${
                          video.video_url.match(regExp) &&
                          video.video_url.match(regExp)[2].length == 11 &&
                          video.video_url.match(regExp)[2]
                        }`}
                        allowFullScreen
                      ></iframe>
                      <h6 className="text-center">{video.title}</h6>
                    </div>
                  </div>
                ))}
              </div>
            </div> */}
        </div>
      </div>
    </>
  );
}
