import { getDataAPI } from "../../services/apiWrapper";
import { ALERT, STUDENT_VERIFICATION } from "./actionTypes";

export const getStudentVerification = (data) => async (dispatch) => {
  try {
    // dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      getDataAPI(`center/student/verify?roll_number=${data}`, token)
    );
    dispatch({ type: STUDENT_VERIFICATION, payload: response.data.data });
    // dispatch({ type: ALERT, payload: { loading: false } });
    return response.data.data;
  } catch (error) {
    // dispatch({ type: ALERT, payload: { loading: false } });
    return false;
  }
};
