import React from "react";
import { Link } from "react-router-dom";
import "./../styles/breadcrumb.css";

export default function BreadCrumb({ crumbs }) {
  let output = [];
  if (crumbs) {
    crumbs.forEach((crumb, index) => {
      output.push(
        <li key={index} class="breadcrumb-item">
          {crumb.path && (
            <Link to={crumb.path} class="black-text">
              {crumb.label}
            </Link>
          )}
          {!crumb.path && crumb.label}
          <i class="fa fa-angle-right mx-2" aria-hidden="true"></i>
          {/* {crumbs.length - 1 !== index && <span>/</span>} */}
        </li>
      );
    });
  }

  return (
    <div class="breadcm">
      <ol class="breadcrumb purple lighten-4">{output}</ol>
    </div>
    // <div>
    //   <section
    //     class="inner-banner flex-center"
    //     style={{ backgroundImage: "/img/bg-img.png" }}
    //   >
    //     <div class="container">
    //       <div class="row">
    //         <div class="col-lg-7 text-center text-lg-left">
    //           <h3>{crumbs[crumbs.length - 1].label}</h3>
    //         </div>
    //         <div class="col-lg-5 text-center text-lg-right">
    //           <ul class="inner-bnr-nav">
    //             {output}
    //             {/* <li>
    //               <a href="#">Home </a>
    //             </li>
    //             <li>About Us</li> */}
    //           </ul>
    //         </div>
    //       </div>
    //     </div>
    //   </section>
    // </div>
  );
}
