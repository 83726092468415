import React, { useEffect, useState } from "react";
import BreadCrumb from "../../BreadCrumb";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { addCategory, updateCategory, getCategoryDetail, addSubCategory, getSubCategoryDetail, updateSubCategory, } from "../../../store/actions/printActions/printcategory";
const AddSubCategory = () => {
  const dispatch = useDispatch();
  const [exist, setExist] = useState(true);
  const history = useHistory();
  const { id } = useParams();

  console.log('id====>', id)
  const [data, setData] = useState({
    name: "",
    description:"",

  });
//   useEffect(async () => {
//     if (id) {
//       const response = await dispatch(getSubCategoryDetail(id));
//       console.log('response====>', response)
//       if (response.success) {
//         setData({
//           name: response.data.name,
//           description: response.data.description,
         
//         });
//       } else {
//         setExist(false);
//       }
//     }
//   }, [id]);
  const { name, description } = data;
  const breadcrumbs = [
    {
      path: "/admin/dashboard",
      label: "Dashboard",
    },
    {
      // path: "/aboutus",
      label: "Add Bodies",
    },
  ];
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (id) {
      const response = await dispatch(addSubCategory({category_id: id, ...data }));
      if (response) {
        history.push("/print/subcategory");
      }
    } else {
      const response = await dispatch(addSubCategory(data));
      if (response) {
        history.push("/print/subcategory");
      }
    }
  };
  return (
    <div>
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
          <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <BreadCrumb crumbs={breadcrumbs} />

            <div class="d-flex align-items-center">
              <Link
                to={"/admin/view-body-list"}
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                View List
              </Link>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-2"></div>

            <div class="col-md-8">
              {exist ? (
                <form onSubmit={handleSubmit}>
                 
                  <div class="form-group">
                    <label>
                      Name <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Name "
                      name="name"
                      value={name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label>
                     Description <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Description "
                      name="description"
                      value={description}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  
                  <button type="submit" class="btn btn-primary mr-2">
                    Submit
                  </button>
                  {/* <button type="reset" class="btn btn-secondary">
                  Cancel
                </button> */}
                </form>
              ) : (
                <h1>Record does not exist</h1>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSubCategory;
