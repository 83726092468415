import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addExamSchedule } from "../../../store/actions/councilActions/scheduleActions";

const ExamSchedule = ({ Sn, id, name, code, course_id, course_type, batch, semester }) => {
    const dispatch = useDispatch();
    const [ExamForm, setExamForm] = useState({subject_id:id,course_id,course_type,batch,semester});
    const [disabled, setDisabled] = useState(false);
    const handleChange = (e)=>{
        e.preventDefault();
        setExamForm((prev)=> ({...prev,[e.target.name]:e.target.value}));
    }

    const onsubmit = async (e) => {
        e.preventDefault();
        console.log( { ExamForm } );
        setDisabled(true);
        // const temp = selectedList.map((s) => s.id);
        const formdata = new FormData();
        for (const key in ExamForm) {
            if (Object.hasOwnProperty.call(ExamForm, key)) {
                formdata.append(key, ExamForm[key]);
            }
        }
        // formdata.append("course_type", course_type);
        // formdata.append("course_id", course);
        // formdata.append("subject_id", subject);
        // formdata.append("date", date);
        // formdata.append("time", time);
        // formdata.append("batch", batch);
        // formdata.append("center_id", center_id);
        // formdata.append("student_id", JSON.stringify(temp));
        // formdata.append("subject_id", data.subject_id);
        // const obj = {
        //   course_type: course_type,
        //   course_id: course,
        //   subject_id: subject,
        //   date,
        //   time,
        //   batch,
        //   center_id,
        //   student_id: temp.toString(),
        //   subject_id,
        // };
        // console.log("obje", obj);
        const response = await dispatch(addExamSchedule(formdata));
        console.log({response});
        // if (response) {
        //   setData(initialState);
        //   setSemester("");
        //   setUserSubject(null);
        //   setcourse_type("");
        //   setcenter_id("");
        // }
      };

  return (
    <tr>
      <td>{Sn}</td>
      <td>{name}</td>
      <td>{code || "NA"}</td>
      <td></td>
      <td>
        {" "}
        <div class="col-md-12">
          <div class="boxlabel">
            <p>Select Exam Date </p>
            <input
              type={"date"}
              // min={moment(new Date()).format("YYYY-MM-DD")}
              name="date"
              className="form-control"
            //   value={data.date}
              onChange={handleChange}
            ></input>
          </div>
        </div>
      </td>

      <td>
        <div class="col-md-12">
          <div class="boxlabel">
            <p>Select Exam Time </p>
            <input
              type={"time"}
              min={new Date()}
              name="time"
              className="form-control"
            //   value={data.time}
              onChange={handleChange}
            ></input>
          </div>
        </div>{" "}
      </td>
      <td>
        <div class="boxlabel">
          <input
            className="btn-success btn btn-lg"
            type={"button"}
            // name="Submit"
            value={'Submit'}
            disabled={disabled}
            onClick={onsubmit}
          />
        </div>
      </td>
    </tr>
  );
};

export default React.memo(ExamSchedule);
