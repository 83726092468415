import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import {
  centerDashboard,
  updateStaffPic,
} from "../../../store/actions/centerActions/centerAuth";

const EditPic = ({ data, show, onHide }) => {
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("image", file);
    formdata.append("staff_id", data.id);
    const response = await dispatch(updateStaffPic(formdata));
    if (response) {
      onHide();
      setFile(null);
      dispatch(centerDashboard());
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton={true}>Edit Profile pic </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          {file && (
            <div className="text-center">
              <img
                src={URL.createObjectURL(file)}
                class="rounded-circle mb-4"
                style={{ width: "150px" }}
                height={"150px"}
                alt="Avatar"
              />
            </div>
          )}
          <div className="form-group">
            <label>
              Upload Profile Pic <span className="text-danger">*</span>
            </label>
            <input
              type="file"
              className="form-control"
              onChange={(e) => setFile(e.target.files[0])}
              required
            ></input>
          </div>
          <button className="btn btn-success" type="submit">
            Upload
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default EditPic;
