import React, { useEffect, useState } from "react";
import "./css/header.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTrades } from "../../../store/actions/councilActions/trade";

export default function Header() {
  const [expand, setExpand] = useState(false);
  const dispatch = useDispatch();
  const { admin } = useSelector((state) => state);
  const { trades } = admin;

  useEffect(() => {
    dispatch(getTrades());
  }, []);

  const hideNav = () => {
    setExpand(false);
  };

  return (
    <header>
      <div class="minimenu">
        <div class="container">
          <nav class="navbar">
            <ul>
              <li>
                <a href="#">Council E-mail </a>
              </li>
              <li>
                <Link to="/useful-links/downloads">Download </Link>
              </li>
              <li>
                <Link to="/useful-links/contacts">Enquiry Numbers </Link>
              </li>
              <li>
                <Link to="/useful-links/e-mail">Important e-mail </Link>
              </li>
              <li>
                <Link to="/help-desk">Help Desk</Link>
              </li>
              <li>
                <Link to="/track-complaint">Track Complaint</Link>
              </li>
              <li>
                <Link to="/useful-links/rti">RTI </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div class="container search-d">
        <div className="row">
          <div class="col-sm-12 align-self-center text-center col-md-2 col-lg-2 pr-4">
            <Link to="/">
              <img className="logo" src="/images/logo.png" />
            </Link>
          </div>
          <div class="col-sm-12 col-md-7 col-lg-7 usersignup py-3">
            <h5>राष्ट्रीय पैरामेडिकल और व्यवसायिक शिक्षा परिषद (नई दिल्ली)</h5>
            <h4>
              National paramedical & vocational Education Council (New Delhi)
            </h4>
            <h6>AN-ISO -9001:2015 Certified Organization</h6>
          </div>
          <div class="col-md-3 d-sm-none d-md-block col-lg-3 align-self-center  ">
            <div class="search-box css-fvnb3i">
              <img src="/images/skill.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="minimenu2">
        <button class="barsicon" onClick={() => setExpand(true)}>
          <i class="fa fa-bars"></i>
        </button>
        <div class="justify-content-center d-flex">
          <nav
            class={`navbar ${expand && "d-block overflow-visible"}`}
            // style={{ display: "block", overflowY: "scroll" }}
          >
            <button
              type="button"
              class={`btn-close ${expand && "d-block overflow-visible"}`}
              onClick={() => setExpand(false)}
            ></button>

            <ul>
              <li>
                <Link to="/" onClick={hideNav}>
                  {" "}
                  Home{" "}
                </Link>
              </li>

              <li class="dropdownhover">
                <Link to="/abouts/About-the-NPVEC" onClick={hideNav}>
                  {" "}
                  About{" "}
                </Link>
                <div class="Dropdowns">
                  <ul>
                    <li>
                      {" "}
                      <Link to={"/abouts/About-the-NPVEC"} onClick={hideNav}>
                        About the NPVEC
                      </Link>{" "}
                    </li>
                    {/* <li>
                      <Link to={"/abouts/Vission---Mission"} onClick={hideNav}>
                        Vission & Mission
                      </Link>
                    </li> */}
                  </ul>
                </div>

                <div class="Dropdowns" id="aboutus"></div>
              </li>
              <li class="dropdownhover">
                <div class="Dropdowns">
                  <ul>
                    {trades && trades.length > 0 ? (
                      trades.map((trade, index) => (
                        <li key={index} class="subdropdownhover">
                          <span
                            className="text-white text-center px-3"
                            role={"button"}
                          >
                            {trade.name}
                            <div class="SubDropdowns">
                              <ul>
                                {trade.subtrade && trade.subtrade.length > 0 ? (
                                  trade.subtrade.map((subt) => (
                                    <Link
                                      to={`/courses/${subt.id}`}
                                      onClick={hideNav}
                                    >
                                      <li key={subt.id}>{subt.name}</li>
                                    </Link>
                                  ))
                                ) : (
                                  <span>No Sub trade added</span>
                                )}
                              </ul>
                            </div>
                          </span>
                        </li>
                      ))
                    ) : (
                      <li>No Trade Added</li>
                    )}
                    {/* <li>
                      <Link to="/center/login" onClick={hideNav}>
                        Center Login
                      </Link>
                    </li> */}
                  </ul>
                </div>
                <a onClick={(e) => e.preventDefault()}>
                  {" "}
                  Courses <i class="fa fa-angle-down"></i>{" "}
                </a>

                <div class="Dropdowns" id="trades"></div>
              </li>
              <li>
                <Link to="/colleges" onClick={hideNav}>
                  {" "}
                  College/Institutes{" "}
                </Link>
              </li>
              <li>
                <Link to="/affiliation" onClick={hideNav}>
                  Affiliation Process{" "}
                </Link>
              </li>
              <li class="dropdownhover">
                <Link to="/center/register" onClick={hideNav}>
                  Registration <i class="fa fa-angle-down"></i>{" "}
                </Link>
                <div class="Dropdowns">
                  <ul>
                    <li>
                      <Link to="/center/register" onClick={hideNav}>
                        Center Reg.
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact-us" onClick={hideNav}>
                        {" "}
                        Student Reg.
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact-us" onClick={hideNav}>
                        {" "}
                        Placement Reg.
                      </Link>
                    </li>
                    <li>
                      <Link to="/member/register" onClick={hideNav}>
                        {" "}
                        Member Reg.
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="dropdownhover">
                <Link to="/member/register" onClick={hideNav}>
                  Membership <i class="fa fa-angle-down"></i>{" "}
                </Link>
                <div class="Dropdowns">
                  <ul>
                    <li>
                      <Link to="/member/register" onClick={hideNav}>
                        {" "}
                        Member Reg.
                      </Link>
                    </li>
                    <li>
                      <Link to="/member/status" onClick={hideNav}>
                        {" "}
                        Member status
                      </Link>
                    </li>
                    <li>
                      <Link to="/membership/login" onClick={hideNav}>
                        {" "}
                        Membership Portal Login
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="dropdownhover">
                <a href="#">
                  Login <i class="fa fa-angle-down"></i>{" "}
                </a>
                <div class="Dropdowns">
                  <ul>
                    <li>
                      <Link to="/center/login" onClick={hideNav}>
                        Center Login
                      </Link>
                    </li>
                    <li>
                      <Link to="/student/login" onClick={hideNav}>
                        {" "}
                        Student Login
                      </Link>
                    </li>
                    <li>
                      <Link to="/admin/login" onClick={hideNav}>
                        {" "}
                        Council Login
                      </Link>
                    </li>
                    <li>
                      <Link to="/helpdesk/login" onClick={hideNav}>
                        {" "}
                        Helpdesk Login
                      </Link>
                    </li>
                    <li>
                      <Link to="/print/login" onClick={hideNav}>
                        {" "}
                        Print Portal Login
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="dropdownhover">
                <Link to="/gallery" onClick={hideNav}>
                  Gallery{" "}
                </Link>{" "}
                <div class="Dropdowns" id="gallery">
                  <ul>
                    <li>
                      <Link to="/gallery" onClick={hideNav}>
                        Photo Gallery
                      </Link>
                      <Link to="/video-gallery" onClick={hideNav}>
                        Video Gallery
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <Link to="/contact-us" onClick={hideNav}>
                  Contact
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}
