import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTrades } from "../../store/actions/councilActions/trade";

const TradeSubtradeSelector = ({ data, setData }) => {
  const [subTrades, setSubtrades] = useState(null);
  const { admin } = useSelector((state) => state);
  const { trades } = admin;
  const dispatch = useDispatch();
  useEffect(() => {
    if (!trades) {
      dispatch(getTrades());
    }
  }, []);

  useEffect(() => {
    if (trades) {
      const subtrades =
        trades.find((trade) => trade.id == data.trade)?.subtrade || [];
      setSubtrades(subtrades);
    }
  }, [data.trade, data.trade]);

  return (
    <>
      <div class="col-md-12">
        <div class="boxlabel">
          <p>Trade </p>
          <select
            class="form-control"
            name="trade"
            value={data.trade}
            onChange={(e) =>
              setData({
                ...data,
                trade: e.target.value,
                sub_trade: "",
              })
            }
            required
          >
            <option disabled selected value="">
              Select Trade
            </option>
            {!trades ? (
              <option value="" disabled>
                Loading...
              </option>
            ) : trades.length > 0 ? (
              trades.map((trade, index) => (
                <option value={trade.id} key={index}>
                  {trade.name}
                </option>
              ))
            ) : (
              <option disabled>No Trade Found</option>
            )}
          </select>
        </div>
      </div>
      {data.trade && (
        <div class="col-md-12">
          <div class="boxlabel">
            <p>Sub Trade</p>
            <select
              class="form-control"
              name="trade"
              value={data.sub_trade}
              onChange={(e) =>
                setData({
                  ...data,
                  sub_trade: e.target.value,
                  course: "",
                })
              }
              required
            >
              <option disabled selected value="">
                Select Sub Trade
              </option>
              {!subTrades ? (
                <option value="" disabled>
                  Loading...
                </option>
              ) : subTrades.length > 0 ? (
                subTrades.map((trade, index) => (
                  <option value={trade.id} key={index}>
                    {trade.name}
                  </option>
                ))
              ) : (
                <option disabled value={""} selected>
                  No Sub Trade Found
                </option>
              )}
            </select>
          </div>
        </div>
      )}
    </>
  );
};

export default TradeSubtradeSelector;
