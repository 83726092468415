import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CouncilDashboard from "./CouncilDashboard";
import MemebrDashboard from "./MemberDashboard";

export default function Dashboard() {
  const [user_type, setuser_type] = useState("");
  const { admin } = useSelector((state) => state);
  const { adminAuth } = admin;
  const { profile } = adminAuth;

  useEffect(() => {
    if (profile) {
      setuser_type(profile.admin.user_type);
    }
  }, [profile]);

  return (
    <div className="row">
      {user_type == 0 ? (
        <CouncilDashboard data={profile} />
      ) : (
        <h1>Admin Dashboard</h1>
      )}
      {user_type == 1 ?(
        <MemebrDashboard data ={profile}/>
      ) :("")}
      {/* <CouncilDashboard /> */}
      {/* Admin Dashboard
      <Link to="/admin/create-static-page">Create Static Pages</Link> */}
    </div>
  );
}
