import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { viewStaticPage } from "../../store/actions/adminActions/staticPages";
import Loader from "../Alert/Loader";
import "./css/about.css";

const About = () => {
  const dispatch = useDispatch();
  const [readMore, setReadMore] = useState(true);
  const [data, setdata] = useState(null);
  useEffect(async () => {
    const response = await dispatch(viewStaticPage(5));
    setdata(response.data);
  }, []);

  return (
    <>
      {" "}
      {data ? (
        <div class="charmain-det">
          <h1>{data.page_title}</h1>
          <div
            id="readMore"
            dangerouslySetInnerHTML={{
              __html: readMore
                ? data.page_description.slice(0, 600)
                : data.page_description,
            }}
          ></div>
          {data.page_description.length > 600 && (
            <button
              class="float-right button-link"
              onClick={() => setReadMore(!readMore)}
            >
              {readMore ? "Read More >>" : "Read Less <<"}
            </button>
          )}
        </div>
      ) : null}
    </>
  );
};

export default About;
