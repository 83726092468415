import React from "react";

const Fee = () => {
  return (
    <div>
      <div class="">
        <div class="Title-box">
          <div class="row">
            <div class="col-md-6">
              <div class="BigTitle">{"<%=item.name%> "} Fee's </div>
            </div>
            <div class="col-md-6">
              <div class="CommanForm d-flex">
                <div class="btncomplt">
                  <a href="/center/add-student" class="btn btn-comman">
                    Add New Student
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        <div class="mid-content-box">
          <div class="Widthdata">
            <div class="Applicationdata s">
              <div class="row ">
                <div class="col-md-3">
                  <div class="feechekd <%=item.type=='regfee'?'pais':''%>">
                    <i class="fa fa-check"></i>
                    <h5>Registration fee</h5>
                    <h6>{"<%=item.regfee%>"} Rs</h6>

                    {"<%if(item.type=='regfee')"}
                    <div class="paychk">
                      Payment Mode: {"<%=item.paymentmode%>"}
                    </div>
                    <div class="paychk"> Success</div>

                    {/* // }else{
                        //  <a href="javascript:void(0);" class="paynow" data-toggle="modal" data-target="#regfee" data-fee="<%=Number(item.regfee)%>" data-type="regfee"> Pay Now   </a>
                        // <%}%> */}
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="feechekd">
                    <i class="fa fa-user"></i>
                    <h5>Exam fee</h5>
                    <h6>{"<%=item.examfee%>"} Rs</h6>
                    <a
                      href="javascript:void(0);"
                      class="paynow"
                      data-toggle="modal"
                      data-target="#regfee"
                      data-fee="<%=Number(item.examfee)%>"
                      data-type="examfee"
                    >
                      {" "}
                      Pay Now{" "}
                    </a>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="feechekd">
                    <i class="fa fa-user"></i>
                    <h5>Annual fee</h5>
                    <h6>{"<%=item.otherfee%>"} Rs</h6>
                    <a
                      href="javascript:void(0);"
                      class="paynow"
                      data-toggle="modal"
                      data-target="#regfee"
                      data-fee="<%=Number(item.otherfee)%>"
                      data-type="otherfee"
                    >
                      {" "}
                      Pay Now{" "}
                    </a>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="feechekd">
                    <i class="fa fa-user"></i>
                    <h5>Certificate fee</h5>
                    <h6>{"<%=item.cerfee%>"} Rs</h6>
                    <a
                      href="javascript:void(0);"
                      class="paynow"
                      data-toggle="modal"
                      data-target="#regfee"
                      data-fee="<%=Number(item.cerfee)%>"
                      data-type="cerfee"
                    >
                      {" "}
                      Pay Now{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id="regfee"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  {" "}
                  Fee Submit
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              {/* <div class="modal-body">
        <form  method="POST" action="/center/pay">
        <div class="form-group">
            <input type="hidden" name="centerid" value="<%=item.centerid%>"/>
            <input type="hidden" name="studentid" value="<%=item.id%>">
            <input type="hidden" name="type" id="type">
          <input
            type="text"
            class="form-control"
            required
            name="name"
            id=""
                 value="<%=item.name%>"
                 readonly
            placeholder="Your Name"
          />
        </div>
        <div class="form-group">
          <input
            type="email"
            class="form-control"
            required
            name="email"
            id=""
            placeholder="email"
          />
        </div>
        <div class="form-group">
            <input
              type="number"
              class="form-control"
              required
              name="amount"
              id="numerLs"
                   
              placeholder="Amount"
                   readonly
            />
          </div>
            
               <div class="form-group">
            <input
              type="number"
              class="form-control"
              required
              name="phone"
              id=""
              placeholder="Phone Number"
            />
          </div>
        <div class="form-group">
            <button class="btn btn-danger btn-block">
                Create Payment 
            </button>
        </div>
      </form>
      </div>
     
    </div> */}
            </div>
          </div>
          {/* <%})%> */}
        </div>
      </div>
    </div>
  );
};
export default Fee;
