import React from "react";
import moment from "moment";
import "./css/exam-form.css";

const FillExamForm = () => {
  return (
    <div className="row exam-form">
      <div className="col-md-2"></div>
      <div className="col-md-8">
        <table className="w-100 table">
          <tbody>
            <tr>
              <td className="w-50">Process</td>
              <td className="w-50">Fill Exam Form</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>Exam Form</td>
            </tr>
            <tr>
              <td>Start Date</td>
              <td>{moment(new Date()).format("DD/MM/YYYY")}</td>
            </tr>
            <tr>
              <td>End Date</td>
              <td>{moment(new Date()).format("DD/MM/YYYY")}</td>
            </tr>
            <tr>
              <td>Action</td>
              <td>Actin</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FillExamForm;
