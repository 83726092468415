import React, { useCallback, useRef, useState } from "react";
import { useLoadMore } from "../../Council/News/useLoadMore";

const ImpLinks = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, data, setData, hasMore] = useLoadMore(
    "admin/news/list/link?page=",
    pageNumber
  );

  const observer = useRef();
  const lastDataElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );
  return (
    <>
      <div class="col-md-3 col-sm-12 mobile-nodn">
        <div class="Team-lefatbar notification-items NOtification">
          <h3 class="text-center">Important Links</h3>
          <ul>
            {data.length > 0 ? (
              data.map((n, index) => {
                if (data.length === index + 1) {
                  return (
                    <li ref={lastDataElementRef} key={n}>
                      <a href={n.link} target="_blank">
                        {n.title}
                      </a>
                    </li>
                  );
                } else {
                  return (
                    <li>
                      <a href={n.link} target="_blank">
                        {n.title}
                      </a>
                    </li>
                  );
                }
              })
            ) : (
              <div></div>
            )}
            {!loading && data.length == 0 && <div>No Links</div>}
            {loading && <div>Loading... </div>}
          </ul>
        </div>
      </div>
    </>
  );
};

export default ImpLinks;
