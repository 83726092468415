import React from "react";
import { Accordion, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

const LinksMenu = () => {
  return (
    <div>
      <Accordion className="mb-4" defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Useful Links</Accordion.Header>
          <Accordion.Body className="p-0">
            <Table bordered hover responsive className="mb-0">
              <tbody>
                <tr>
                  <td>
                    <Link to="/useful-links/downloads">Downloads </Link>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Link to="/useful-links/e-mail">Important e-mail</Link>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Link to="/useful-links/contacts">Enquiry Numbers</Link>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Link to="/useful-links/rti">RTI</Link>
                  </td>
                </tr>

                <tr>
                  <td>
                    <Link to="/center/register">Register Center</Link>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Link to="/contact-us">Contact Us</Link>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Link to="/affiliation">Affiliation</Link>
                  </td>
                </tr>
               <tr>
                  <td>
                     <Link to="/help-desk">Help Desk</Link>
                  </td>
                </tr> <tr>
                  <td>
                    <Link to="/track-complaint">Track Complaint</Link>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default LinksMenu;
