import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Spinner, Table } from "react-bootstrap";
import BreadCrumb from "../../BreadCrumb";
import {
  contactList,
  deleteContact,
  downloadContacts,
  getHelpline,
} from "../../../store/actions/councilActions/contactActions";
import Pagination from "../../misc/Pagination";

const breadcrumbs = [
  {
    path: "/admin/dashboard",
    label: "Dashboard",
  },
  {
    label: "Contact List",
  },
];

const ContactList = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [generalloading, setgeneralLoading] = useState(false);
  const [generaldata, setgeneralData] = useState([]);
  const [activePage, setactivePage] = useState(1);
  const [totalPages, settotalPages] = useState(0);

  const [data, setData] = useState([]);
  const fetchdata = useCallback(async () => {
    const response = await dispatch(contactList(activePage));
    if (response) {
      setData(response.data);
      settotalPages(Math.ceil(response.total / response.per_page));
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [dispatch, activePage]);

  useEffect(() => {
    setLoading(true);
    fetchdata();
  }, [fetchdata]);

  const deleteAction = async (id) => {
    const response = await dispatch(deleteContact(id));
    if (response) {
      fetchdata();
    }
  };
  const fetchGeneral = useCallback(async () => {
    const response = await dispatch(getHelpline());
    if (response) {
      setgeneralData([response]);
      setgeneralLoading(false);
    } else {
      setgeneralLoading(false);
    }
  }, [dispatch]);
  useEffect(() => {
    setgeneralLoading(true);
    fetchGeneral();
  }, [fetchGeneral]);

  const handleDownload = () => {
    dispatch(downloadContacts());
  };

  return (
    <div>
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
          <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <BreadCrumb crumbs={breadcrumbs} />

            <div class="d-flex align-items-center">
              <button
                onClick={handleDownload}
                className="btn btn-light-primary font-weight-bolder btn-sm"
              >
                Download list
              </button>
              <Link
                to="/admin/contact/add"
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                Add Contact
              </Link>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status" size="lg">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <div className="text-center text-white py-2 bg-secondary">
              Contacts
            </div>
            <Table bordered hover striped responsive className="mb-0">
              <thead>
                <th>S No</th>
                <th>Department</th>
                <th>Department Designation</th>
                <th>Name of the Head</th>
                <th>Phone No.</th>
                <th>Action</th>
              </thead>
              <tbody>
                {data.length == 0 ? (
                  <tr>
                    <td colSpan={6} className="text-center text-danger">
                      No Record Found
                    </td>
                  </tr>
                ) : (
                  data.map((element, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{element.department}</td>
                      <td>{element.depart_designation}</td>
                      <td>{element.name}</td>
                      <td>{element.phone}</td>
                      <td>
                        <div className="d-flex justify-content-between">
                          <Link to={`/admin/contact/edit/${element.id}`}>
                            <i className="fa fa-pencil"></i>
                          </Link>

                          <i
                            className="fa fa-trash"
                            onClick={() => deleteAction(element.id)}
                          ></i>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
            {totalPages && Number(totalPages) !== 0 ? (
              <Pagination
                setActivePage={(value) => setactivePage(value)}
                totalPages={totalPages}
              />
            ) : null}
          </>
        )}
        <div class="mt-5">
          {generalloading ? (
            <div className="text-center">
              <Spinner animation="border" role="status" size="lg">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
              <div className="text-center text-white py-2 bg-secondary">
                Helpline
              </div>
              <Table bordered hover striped responsive className="mb-0">
                <thead>
                  <th>S No</th>
                  <th>Helpline</th>
                  <th>Action</th>
                </thead>
                <tbody>
                  {generaldata.length == 0 ? (
                    <tr>
                      <td colSpan={6} className="text-center text-danger">
                        No Record Found
                      </td>
                    </tr>
                  ) : (
                    generaldata.map((element, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{element.mobile}</td>
                        <td>
                          <div className="d-flex justify-content-between">
                            <Link to={`/admin/helpline/edit/${element.id}`}>
                              <i className="fa fa-pencil"></i>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactList;
