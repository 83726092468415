import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { viewStaticPage } from "../../store/actions/adminActions/staticPages";
import Loader from "../Alert/Loader";
import "./css/about.css";

export default function Mission() {
  const dispatch = useDispatch();
  const [data, setdata] = useState(null);
  const [readMore, setReadMore] = useState(true);
  useEffect(async () => {
    const response = await dispatch(viewStaticPage(10));
    setdata(response.data);
  }, []);
  return (
    <>
      {data ? (
        <>
          <div class="vission-mission card">
            <h4>{data.page_title}</h4>
            <div
              id="content"
              dangerouslySetInnerHTML={{
                __html: readMore
                  ? data.page_description.slice(0, 300)
                  : data.page_description,
              }}
            ></div>
            {data.page_description.length > 300 && (
              <button
                class="text-end button-link"
                onClick={() => setReadMore(!readMore)}
              >
                {readMore ? "Read More >>" : "Read Less <<"}
              </button>
            )}
          </div>
        </>
      ) : null}
    </>
  );
}
