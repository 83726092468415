import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { viewStaticPage } from "../../store/actions/adminActions/staticPages";
import Loader from "../Alert/Loader";
import "../AboutUs/css/about.css";
import BreadCrumb from "../BreadCrumb";

export default function Affiliation() {
  const breadcrumbs = [
    {
      path: "/",
      label: "Home",
    },
    {
      // path: "/aboutus",
      label: "Affiliation",
    },
  ];
  const dispatch = useDispatch();
  const [data, setdata] = useState(null);
  useEffect(async () => {
    const response = await dispatch(viewStaticPage(6));
    setdata(response.data);
  }, []);

  return (
    <>
      <div class="lists-box">
        <div class="container">
          <BreadCrumb crumbs={breadcrumbs} />
          <div class="title-box">
            <h4>Affiliation</h4>
          </div>

          <div class="col-md-12">
            {/* <h2> </h2> */}
            {data == null ? (
              <></>
            ) : data ? (
              <>
                {/* {data.file && (
                  <figure>
                    <img src={data.file}></img>
                  </figure>
                )} */}

                <div
                  dangerouslySetInnerHTML={{ __html: data.page_description }}
                ></div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
