import React from "react";
import { Link } from "react-router-dom";
import "./css/section.css";

export default function Section() {
  return (
    <>
      <section className="Boxsec container-fluid">
        <div className="container">
          <div className="section-content wow fadeInUp">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-6 col-12 py-sm-2 ">
                <div className="icon-boxMain  ">
                  <h4 className="icon-box-title">Council Section</h4>
                  <div className="row pl-10 ">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-4 ">
                      <div className="icon-box ">
                        {" "}
                        <Link className="/council" href="#">
                          <img src="/images/group15.png" />

                          <h5 className="TxtBox">Faculty Info</h5>
                        </Link>{" "}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-4 ">
                      <div className="icon-box ">
                        {" "}
                        <Link className="" to="/council">
                          <img src="/images/user(2).png" />
                          <h5 className="TxtBox">Council Login</h5>
                        </Link>{" "}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-4 ">
                      <div className="icon-box ">
                        {" "}
                        <Link className="#" to="/governing-body">
                          <img src="/images/government-building.png" />
                          <h5 className="TxtBox">Governing Body</h5>
                        </Link>{" "}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-4  ">
                      <div className="icon-box ">
                        {" "}
                        <Link
                          className=""
                          to="/general-body"
                          title="For Campus Students Only"
                        >
                          <img src="/images/profile.png" />
                          <h5 className="TxtBox">General Body</h5>
                        </Link>{" "}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-4  ">
                      <div className="icon-box ">
                        {" "}
                        <Link className="" to="/offices">
                          <img src="/images/company.png" />
                          <h5 className="TxtBox">Reg. Office </h5>
                        </Link>{" "}
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 col-4 ">
                      <div className="icon-box ">
                        {" "}
                        <Link className="" to="/activity">
                          <img src="/images/tasks.png" />

                          <h5 className="TxtBox">Activities</h5>
                        </Link>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 order-sm-last order-md-2 col-12 py-sm-2">
                <div className="icon-boxMain icon-boxMain2">
                  <h4 className="icon-box-title">College/Institute Section</h4>
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-4 col-4 ">
                      <div className="icon-box ">
                        {" "}
                        <Link className="" to="/colleges">
                          {" "}
                          <img src="/images/college.png" />
                          <h5 className="TxtBox">College List</h5>
                        </Link>{" "}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-4 col-4 ">
                      <div className="icon-box ">
                        {" "}
                        <Link className="" to="/courses/1">
                          {" "}
                          <img src="/images/test.png" />
                          <h5 className="TxtBox">Courses</h5>
                        </Link>{" "}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-4 col-4 ">
                      <div className="icon-box ">
                        {" "}
                        <Link className="" to="/affiliation">
                          {" "}
                          <img src="/images/affiliate.png" />
                          <h5 className="TxtBox">Affiliation</h5>
                        </Link>{" "}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-4 col-4 ">
                      <div className="icon-box ">
                        {" "}
                        <Link className="" to="#">
                          {" "}
                          <img src="/images/presentation.png" />
                          <h5 className="TxtBox">Teacher Reg.</h5>
                        </Link>{" "}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-4 col-4 ">
                      <div className="icon-box ">
                        {" "}
                        <Link className="" to="/center/login">
                          {" "}
                          <img src="/images/user(2).png" />
                          <h5 className="TxtBox">Center Login</h5>
                        </Link>{" "}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-4 col-4 ">
                      <div className="icon-box ">
                        {" "}
                        <a className="" href="#">
                          {" "}
                          <img src="/images/more.png" />
                          <h5 className="TxtBox">More</h5>
                        </a>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4  col-sm-6 order-sm-2 order-md-last py-sm-2 col-12">
                <div className="icon-boxMain icon-boxMain3">
                  <h4 className="icon-box-title   ">Student Section</h4>
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-4 ">
                      <div className="icon-box ">
                        {" "}
                        <Link className="" to="/student">
                          {" "}
                          <img src="/images/id-card.png" />
                          <h5 className="TxtBox">Reg./Admission</h5>
                        </Link>{" "}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-4 ">
                      <div className="icon-box ">
                        {" "}
                        <Link className="" to="/student-exam-form">
                          {" "}
                          <img src="/images/checklist.png" />
                          <h5 className="TxtBox">Examination</h5>
                        </Link>{" "}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-4 ">
                      <div className="icon-box ">
                        {" "}
                        <Link className="" to="/results">
                          {" "}
                          <img src="/images/medical-results.png" />
                          <h5 className="TxtBox">Result</h5>
                        </Link>{" "}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-4 ">
                      <div className="icon-box ">
                        {" "}
                        <Link className="" to="/student-verification">
                          {" "}
                          <img src="/images/check.png" />
                          <h5 className="TxtBox">Verification</h5>
                        </Link>{" "}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-4 ">
                      <div className="icon-box ">
                        {" "}
                        <Link className="" to="/student/login">
                          {" "}
                          <img src="/images/user(2).png" />
                          <h5 className="TxtBox">Login</h5>
                        </Link>{" "}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-4  ">
                      <div className="icon-box ">
                        {" "}
                        <a className="" href="#">
                          {" "}
                          <img src="/images/more.png" />
                          <h5 className="TxtBox" title="Fullmore">
                            More
                          </h5>
                        </a>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
