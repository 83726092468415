import React from "react";
import { Link } from "react-router-dom";
import "../../Student/Dashboard/css/dashboard.css";

export default function DownloadCertificate() {
  return (
    <div
      class="content-box"
      style={{
        padding: "0px",
        width: "94%",
        margin: "25px auto",
        maxWidth: "1000px",
      }}
    >
      <div class="myForm" id="invoice">
        <div class="container" id="search-d">
          <div class="container4" id="search-d">
            <div class="row">
              <div class="col-md-9 d-flex">
                <div class="logo3 pr-4">
                  <Link to="/">
                    <img src="/images/logo.png" />
                  </Link>
                </div>
                <div class="userSignup">
                  <h5>
                    राष्ट्रीय पैरामेडिकल और व्यवसायिक शिक्षा परिषद (नई दिल्ली)
                  </h5>
                  <h4>
                    National paramedical &amp; vocational Education Council (New
                    Delhi)
                  </h4>
                  <h6>AN-ISO -9001:2015 Certified Organization</h6>
                </div>
              </div>
              <div class="col-md-3">
                <div class="search-box css-fvnb3i">
                  <img src="/images/skill.png" />
                </div>
              </div>
            </div>
          </div>
          <div class="emaillist">
            <div class="webs">
              <Link to="/">www.npvec.com</Link>
            </div>
            <div class="emails">
              <a href="mailto:info@npvec.com">Email id: info@npvec.com</a>
            </div>
          </div>
        </div>
        <h5
          style={{
            textAlign: "center",
            fontSize: "28px",
            color: "#9100ff",
            margin: "23px 0 0",
            letterSpacing: "1px",
          }}
        >
          Student Registration Form
        </h5>

        <div class="centername">
          <div class="centerNas">
            N.K Paramedical &amp; Medical Research Center Meerut U.P{" "}
          </div>
        </div>
        <table
          style={{
            width: "100%",
            margin: "20px auto",
            border: "0px",
            padding: "15px",
            fontWeight: "700",
            fontFamily: "system-ui",
            background: "#fff",
          }}
          /* border-bottom: 6px solid #000; */
        >
          <tbody>
            <tr>
              <td style={{ backgroundRepeat: "no-repeat", background: "#fff" }}>
                <table class="certificate-table" cellSpacing="3" border="0">
                  <tbody>
                    <tr>
                      <td>
                        <table class="certificate-table-data">
                          <tbody>
                            <tr>
                              <td>
                                <div class="certificate-table-td">
                                  {" "}
                                  Roll No.-2021087
                                </div>
                              </td>{" "}
                              <td>
                                <div class="certificate-table-td-border">
                                  Center Code:UP20200701
                                </div>
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  width: "180px",
                                }}
                              >
                                <img
                                  src="/students/1629460628276MATEEN.jpeg"
                                  style={{
                                    width: "150px",
                                    height: "150px",
                                  }}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table
                          class="certificateTable"
                          cellSpacing="3"
                          cellPadding="10"
                          border="1"
                        >
                          <tbody>
                            <tr>
                              <td>Name</td> <td>Mateen Ahmed</td>
                            </tr>

                            <tr>
                              {" "}
                              <td>Father’S /Husband Name-</td>{" "}
                              <td>
                                <span class="text-capitalize">mr</span> Mohd
                                Yameen
                              </td>
                            </tr>
                            <tr>
                              {" "}
                              <td>Course</td>{" "}
                              <td>
                                {" "}
                                Diploma In Rural &amp; Urban Health Care (DRUHC){" "}
                              </td>
                            </tr>
                            <tr>
                              {" "}
                              <td>Course Duration</td> <td>2 Year</td>
                            </tr>
                            <tr>
                              {" "}
                              <td>Mother Name-</td>{" "}
                              <td>
                                <span class="text-capitalize">mrs</span> Afsana
                              </td>
                            </tr>
                            <tr>
                              <td>D.O.B</td> <td>2002-03-29</td>
                            </tr>
                            <tr>
                              {" "}
                              <td>Gender </td> <td>male</td>
                            </tr>
                            <tr>
                              {" "}
                              <td>Category</td>
                              <td>gen</td>
                            </tr>
                            <tr>
                              {" "}
                              <td>Marital Status</td>
                              <td>Unmarried </td>
                            </tr>
                            <tr>
                              {" "}
                              <td>Contact No</td>
                              <td>8860490690 </td>
                            </tr>
                            <tr>
                              {" "}
                              <td>Email Id </td>
                              <td></td>
                            </tr>
                            <tr>
                              {" "}
                              <td>Adhar No</td>
                              <td>258350810399</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid #000",
                  padding: "11px 19px",
                  textAlign: "center",
                  background: "#fff",
                  margin: "50px auto",
                  display: "block",
                  width: "300px",
                }}
              >
                {" "}
                Address
              </td>
            </tr>
            <tr>
              <td>
                {" "}
                <table
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    background: "#fff",
                  }}
                  cellSpacing="3"
                  cellPadding="10"
                  border="1"
                >
                  <tbody>
                    <tr>
                      <td>Address</td>{" "}
                      <td>K64 Shani Bazar budh Vihar Ghazibad</td>
                    </tr>
                    <tr>
                      {" "}
                      <td>State</td> <td>Uttar Pradesh</td>
                    </tr>
                    <tr>
                      {" "}
                      <td>City</td> <td>Ghaziabad</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ width: "100%" }}>
                <div
                  class="table-responsive"
                  style={{ width: "94%", margin: "19px auto" }}
                >
                  <table class="table">
                    <thead class="thead-info">
                      <tr>
                        <th scope="col">Examination Passed </th>
                        <th scope="col">Name of Stream </th>
                        <th scope="col">Board/University </th>
                        <th scope="col">Year of Passing</th>
                        <th scope="col">Marks Obtained </th>
                        <th scope="col">%of Marks </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <p>10th</p>
                          <input
                            type="hidden"
                            class="form-control"
                            name="examname[]"
                            value="10th"
                            placeholder="Board/Univeristy"
                          />
                        </td>

                        <td>
                          <input
                            type="text"
                            class="form-control"
                            name="stream[]"
                            placeholder="ex. science"
                            value="science"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            name="board[]"
                            placeholder="Board/Univeristy"
                            value="CBSE"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            name="year[]"
                            name=""
                            placeholder="ex. 2012"
                            value="2017"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            name="obtainmark[]"
                            placeholder="ex. 500"
                            value="10"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            name="percent[]"
                            placeholder="ex. 300"
                            value="8.9"
                          />
                        </td>
                      </tr>

                      {/* </tr>  */}
                      <tr>
                        <td>
                          <p>12th</p>
                          <input
                            type="hidden"
                            class="form-control"
                            name="examname[]"
                            value="12th"
                            placeholder="Board/Univeristy"
                          />
                        </td>

                        <td>
                          <input
                            type="text"
                            class="form-control"
                            name="stream[]"
                            placeholder="ex. science"
                            value="PCB"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            name="board[]"
                            placeholder="Board/Univeristy"
                            value="UP Board"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            name="year[]"
                            name=""
                            placeholder="ex. 2012"
                            value="2020"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            name="obtainmark[]"
                            placeholder="ex. 500"
                            value="500"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            name="percent[]"
                            placeholder="ex. 300"
                            value="243"
                          />
                        </td>
                      </tr>

                      {/* </tr>
                                                
                                                
												</tr>
                                                 
                                                
												</tr>  */}
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <h5 class="h5">Student Undertaking</h5>
                <p class="para">
                  मैं, घोषणा करता हूं कि, ऊपर की गई प्रविष्टियां मेरे सर्वोत्तम
                  ज्ञान और अभिलेखों के आधार पर पूर्ण और सत्य हैं। मैं समझता हूँ
                  कि कक्षाओं में 75% उपस्थिति अनिवार्य है और मैं इसका पालन करने
                  के लिए स्वयं को प्रतिबद्ध हूँ। मैं यह भी समझता हूँ कि यदि किसी
                  कारण से मेरी उपस्थिति कम हो जाती है, तो संस्थान का सक्षम
                  प्राधिकारी मेरे विरुद्ध ऐसी दंडात्मक कार्रवाई कर सकता है, जो
                  उचित और उचित हो। मैं, यह भी घोषणा करता/करती हूँ कि यदि मैं
                  अपात्र पाया जाता हूँ और/या मेरे द्वारा प्रदान की गई जानकारी
                  गलत पाई जाती है, तो मेरा प्रवेश किसी भी स्तर पर रद्द किया जा
                  सकता है।
                </p>
                <p class="para">
                  I, hereby, declare that, the entries made above are complete
                  and true to the best of my knowledge and based on records. I
                  understand that, 75% attendance in classes is compulsory and I
                  commit myself to adhere to the same. I also understand, in
                  case my attendance falls short, for any reason, the competent
                  authority of the Institute may take such punitive action
                  against me, as may be deemed fit and proper. I, further
                  declare that, my admission may be cancelled, at any stage, if
                  I am found ineligible and/or the informations provided by me
                  are found to be incorrect.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <div style={{ display: "flex" }}>
                  <div class="singmd">
                    <h5>
                      <span> Center Stamp & Sign </span>
                      <span>__________________________</span>
                    </h5>
                  </div>
                  <div class="singmde">
                    <span style={{ display: "block" }}>
                      <img
                        src="/students/1629460714835singh.jpeg"
                        id="img2"
                        width="150px"
                      />{" "}
                      Student Signature
                    </span>{" "}
                  </div>
                </div>{" "}
              </td>
            </tr>
          </tbody>
        </table>

        <button
          onClick={() => document.body.contentW}
          class="btn btn-info"
          id="download"
        >
          Print Now{" "}
        </button>
      </div>
    </div>
  );
}
