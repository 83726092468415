import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { viewVideoGallery } from "../../store/actions/adminActions/addGallery";
import Loader from "../Alert/Loader";
import "./css/gallery.css";

const VideoGallery = () => {
  const [videos, setVideos] = useState(null);
  const dispatch = useDispatch();

  useEffect(async () => {
    const response = await dispatch(viewVideoGallery());
    setVideos([...response.data]);
  }, []);
  var regExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;

  return (
    <div>
      <div className="gallery-box">
        <div className="container">
          <div className="title-box">
            <h4>Video Gallery</h4>
          </div>
          <div className="row">
            {videos == null ? (
              <Loader active={true} />
            ) : videos.length > 0 ? (
              videos.map((video) => (
                <div className="col-md-5 col-sm-12">
                  <div className="galleryBox">
                    <iframe
                      width={"100%"}
                      height={"100%"}
                      src={`//www.youtube.com/embed/${
                        video.video_url.match(regExp) &&
                        video.video_url.match(regExp)[2].length == 11 &&
                        video.video_url.match(regExp)[2]
                      }`}
                      allowFullScreen
                    ></iframe>
                    <h6 className="text-center">{video.title}</h6>
                  </div>
                </div>
              ))
            ) : (
              <h1>No Gallery found</h1>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoGallery;
