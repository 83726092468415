import React, { useState } from "react";
import { Button, Form, FormControl, Spinner, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
  findStudentFees,
  submitFees,
} from "../../../store/actions/councilActions/addFees";
import {
  courseType,
  examType,
  feesMode,
  getSemester,
  getYears,
} from "../../../utils/constant";
import Loader from "../../Alert/Loader";

const AddStudentFees = () => {
  const dispatch = useDispatch();

  const [roll_number, setroll_number] = useState("");
  const [loading, setLoading] = useState(null);
  const [data, setData] = useState(null);
  const [paidSems, setPaidSems] = useState([]);
  const [loader, setloader] = useState(false);

  const fetchStudent = async () => {
    setLoading(true);
    const response = await dispatch(findStudentFees({ roll_number }));
    if (response) {
      setLoading(false);
      setData(response.data);
      const list = [];
      response.data?.fees?.forEach((fee) => {
        return list.push(+fee.semester);
      });
      setPaidSems(list);
    } else {
      setLoading(false);
      setData(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    fetchStudent();
  };

  const handleSubmitFees = async (postData) => {
    setloader(true);
    const response = await dispatch(submitFees(postData));
    if (response) {
      setloader(false);
      fetchStudent();
    } else {
      setloader(false);
    }
  };

  return (
    <div>
      <div class="row">
        <Loader active={loader} />
        <div class="col-md-12">
          <div class="BigTitle">Add Student Fees</div>
          <div className="text-center mb-4">
            <Form
              className="d-flex mt-4 col-lg-6 col-md-8"
              onSubmit={handleSubmit}
            >
              <FormControl
                type="search"
                placeholder="Enter Roll Number"
                className="me-2"
                aria-label="Search"
                value={roll_number}
                onChange={(e) => setroll_number(e.target.value)}
              />
              <Button
                type="submit"
                variant="outline-success"
                disabled={!roll_number}
              >
                Search
              </Button>
            </Form>
          </div>
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" role="status" size="lg">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : data === false ? (
            <h3>Record not found</h3>
          ) : data ? (
            <>
              <div className="text-center text-white py-2 bg-secondary">
                {data.student.name}
              </div>
              {data.student?.feesMode === feesMode.offline ? (
                <div className="row">
                  <div class="col-md-12 col-lg-6">
                    <div className="card">
                      <div className="card-header">{data.name}</div>
                      <Table>
                        <tr>
                          <th>Duration</th>
                          <td>{data.duration}</td>
                        </tr>
                        <tr>
                          <th>Exam Fees</th>
                          <td>{data.exam_fee}rs</td>
                        </tr>
                        <tr>
                          <th>Registration fees</th>
                          <td>{data.registration_fee}rs</td>
                        </tr>
                        <tr>
                          <th>Total fees</th>
                          <td>{+data.registration_fee + +data.exam_fee}rs</td>
                        </tr>
                      </Table>
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-6">
                    <div className="card">
                      <div className="card-header">Fee Status</div>
                      <div className="card-body">
                        {data.exam_type === examType.course_wise ? (
                          <>
                            <Table>
                              <thead>
                                <th>Fees</th>
                                <th>Date</th>
                                <th>Status</th>
                              </thead>
                              <tbody>
                                {!data.fees?.length ? (
                                  <tr>
                                    <td>
                                      {+data.registration_fee + +data.exam_fee}
                                    </td>
                                    <td>--</td>
                                    <td>
                                      <button
                                        onClick={() => {
                                          handleSubmitFees({
                                            roll_number: roll_number,
                                            student_id: data.student.id,
                                            total_fees:
                                              +data.registration_fee +
                                              +data.exam_fee,
                                            registration_fee:
                                              data.registration_fee,
                                            exam_fee: data.exam_fee,
                                          });
                                        }}
                                        className="btn btn-success btn-small"
                                      >
                                        Submit fee
                                      </button>{" "}
                                    </td>
                                  </tr>
                                ) : (
                                  data.fees?.map((fee) => (
                                    <tr>
                                      <td>{fee.total_fees}</td>
                                      <td>
                                        {moment(data.createdAt).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </td>
                                      <td>Paid</td>
                                    </tr>
                                  ))
                                )}
                              </tbody>
                            </Table>
                          </>
                        ) : data.course_type == courseType.semester ? (
                          <>
                            <Table>
                              <thead>
                                <th>Fees</th>
                                <th>Semester</th>
                                <th>Date</th>
                                <th>Status</th>
                              </thead>
                              <tbody>
                                {data.fees?.map((fee) => (
                                  <tr>
                                    <td>{fee.total_fees}</td>
                                    <td>{fee.semester}</td>
                                    <td>
                                      {moment(data.createdAt).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td>Paid</td>
                                  </tr>
                                ))}
                                {Array.from(
                                  { length: getSemester(data.duration) },
                                  (_, i) => i + 1
                                )
                                  .filter((x) => !paidSems.includes(x))
                                  ?.map((sem) => (
                                    <tr>
                                      <td>
                                        {+data.registration_fee +
                                          +data.exam_fee}
                                      </td>
                                      <td>{sem}</td>
                                      <td>--</td>
                                      <td>
                                        {" "}
                                        <button
                                          onClick={() => {
                                            handleSubmitFees({
                                              roll_number: roll_number,
                                              student_id: data.student.id,
                                              total_fees:
                                                +data.registration_fee +
                                                +data.exam_fee,
                                              registration_fee:
                                                data.registration_fee,
                                              exam_fee: data.exam_fee,
                                              semester: sem,
                                            });
                                          }}
                                          className="btn btn-success btn-small"
                                        >
                                          Submit fee
                                        </button>{" "}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </Table>
                          </>
                        ) : (
                          <>
                            <Table>
                              <thead>
                                <th>Fees</th>
                                <th>Year</th>
                                <th>Date</th>
                                <th>Status</th>
                              </thead>

                              {data.fees?.map((fee) => (
                                <tr>
                                  <td>{fee.total_fees}</td>
                                  <td>{fee.semester}</td>
                                  <td>
                                    {moment(data.createdAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                  <td>Paid</td>
                                </tr>
                              ))}
                              {Array.from(
                                { length: getYears(data.duration) },
                                (_, i) => i + 1
                              )
                                .filter((x) => !paidSems.includes(x))
                                ?.map((sem) => (
                                  <tr>
                                    <td>
                                      {+data.registration_fee + +data.exam_fee}
                                    </td>
                                    <td>{sem}</td>
                                    <td>--</td>
                                    <td>Submit fee</td>
                                  </tr>
                                ))}
                            </Table>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                "Fees to be paid online"
              )}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AddStudentFees;
