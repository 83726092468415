import { getDataAPI, postDataAPI } from "../../../services/apiWrapper";
import { ALERT } from "../actionTypes";

export const addStaticPage = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(
      postDataAPI("admin/page/create", data, token)
    );
    dispatch(getStaticPage());
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
  }
};
export const updateStaticPage = (data, id) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(
      postDataAPI(`admin/page/edit/${id}`, data, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
  }
};

export const getStaticPage = () => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(getDataAPI("admin/page/list"));
    dispatch({
      type: ALERT,
      payload: { loading: false },
    });
    return {
      success: true,
      data: response.data.data || [],
    };
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    return {
      success: false,
      data: [],
    };
  }
};
export const getStaticPageDetail = (id) => async (dispatch) => {
  try {
    // dispatch({ type: ALERT, payload: { loading: true } });
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(getDataAPI(`admin/page/edit/${id}`, token));
    // dispatch({
    //   type: ALERT,
    //   payload: { loading: false },
    // });
    return {
      success: true,
      data: response.data.data,
    };
  } catch (error) {
    // dispatch({ type: ALERT, payload: { loading: false } });
    return {
      success: false,
    };
  }
};

export const viewStaticPage = (id) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(getDataAPI(`admin/page/list/${id}`));
    dispatch({ type: ALERT, payload: { loading: false } });

    return {
      success: true,
      data: response.data.data,
    };
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });

    return {
      success: false,
      data: false,
    };
  }
};
