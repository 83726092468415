import { postDataAPI } from "../../../services/apiWrapper";
import { errorAlert, successAlert } from "../../../utils/alert";
import { ALERT } from "../actionTypes";

export const findStudentFees = (data, page) => {
  return async (dispatch) => {
    try {
      let path = "admin/get-fees";
      if (page) {
        path = `${path}?page=${page}`;
      }
      const token = localStorage.getItem("adminToken");
      const response = await dispatch(postDataAPI(path, data, token));
      if (response) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};

export const submitFees = (data) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("adminToken");
      const response = await dispatch(
        postDataAPI("admin/add-fees", data, token)
      );
      if (response) {
        successAlert(response.data.message);
        return true;
      } else {
        successAlert(response.data.message);
        return false;
      }
    } catch (error) {
      if (error.response?.data?.message == "Validation errors") {
        Object.entries(error.response.data.data).map((d) => {
          dispatch({
            type: ALERT,
            payload: { error: d[1][0] },
          });
        });
      }
      return false;
    }
  };
};
