import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import BreadCrumb from "../BreadCrumb";
import { Spinner, Table } from "react-bootstrap";
import Pagination from "../misc/Pagination";
import { studentComplaintslist } from "../../store/actions/helpdeskActions/complaint";
import { Link } from "react-router-dom";

const StudentComplaints = () => {
  const breadcrumbs = [
    {
      path: "/helpdesk/dashboard",
      label: "Dashboard",
    },
    {
      label: "Student Complaints",
    },
  ];

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [activePage, setactivePage] = useState(1);
  const [totalPages, settotalPages] = useState(0);
  const fetchdata = useCallback(async () => {
    const response = await dispatch(studentComplaintslist(activePage));
    if (response) {
      setData(response.data || []);
      settotalPages(
        Math.ceil((response.total || 1) / (response.per_page || 1))
      );
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [activePage, dispatch]);
  useEffect(() => {
    setLoading(true);
    fetchdata();
  }, [fetchdata]);

  return (
    <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
      <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
        <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <BreadCrumb crumbs={breadcrumbs} />
        </div>
      </div>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status" size="lg">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <Table bordered hover striped responsive className="mb-0">
          <thead>
            <th>S No</th>
            <th>Complaint No.</th>
            <th>Title</th>
            <th>Complaint by</th>
            <th>Roll number</th>
            <th>Status</th>
            <th>Action</th>
          </thead>
          <tbody>
            {data.length == 0 ? (
              <tr>
                <td colSpan={5} className="text-center text-danger">
                  No Record Found
                </td>
              </tr>
            ) : (
              data.map((element, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{element.complain_number}</td>
                  <td>{element.title}</td>
                  <td>{element.student_name}</td>
                  <td>{element.roll_number}</td>
                  <td>{element.is_active == 0 ? "In Progress" : "Closed"}</td>
                  <td>
                    <Link to={`/helpdesk/details/${element.id}`}>View</Link>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      )}
      {totalPages && Number(totalPages) !== 0 ? (
        <Pagination
          setActivePage={(value) => setactivePage(value)}
          totalPages={totalPages}
        />
      ) : null}
    </div>
  );
};

export default StudentComplaints;
