import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CKEditor from "react-ckeditor-component";
import BreadCrumb from "../../../BreadCrumb";
import { getSubTrades } from "../../../../store/actions/councilActions/trade";
import { useHistory, Link } from "react-router-dom";
import {
  createCourse,
  getCourseEdit,
  updateCourse,
} from "../../../../store/actions/councilActions/courseAction";
import { useParams } from "react-router-dom";
export default function AddCourse() {
  const dispatch = useDispatch();
  const { admin } = useSelector((state) => state);
  const [edit, setEdit] = useState(null);
  const history = useHistory();
  const { subtrades } = admin;
  const { id } = useParams();
  const [description, setdescription] = useState("");
  const [data, setData] = useState({
    sub_trade_id: "",
    name: "",
    duration: "",
    course_type: "",
    exam_type: "",
    course_fee: "",
    registration_fee: "",
    certificate_fee: "",
    exam_fee: "",
    annual_fee: "",
    eligibility: "",
  });
  useEffect(async () => {
    if (id) {
      const response = await dispatch(getCourseEdit(id));
      if (response) {
        setEdit(true);
        setData({
          sub_trade_id: response.sub_trade_id,
          name: response.name,
          eligibility: response.eligibility,
          duration: response.duration,
          course_type: response.course_type,
          exam_type: response.exam_type,
          course_fee: response.fee?.course_fee,
          registration_fee: response.fee?.registration_fee,
          certificate_fee: response.fee?.certificate_fee,
          exam_fee: response.fee?.exam_fee,
          annual_fee: response.fee?.annual_fee,
        });
        setdescription(response.description);
      } else {
        setEdit(false);
      }
    }
  }, []);
  const breadcrumbs = [
    {
      path: "/admin/centers",
      label: "Course List",
    },
    {
      // path: "/aboutus",
      label: "Add Course",
    },
  ];

  const {
    sub_trade_id,
    name,
    duration,
    course_type,
    course_fee,
    registration_fee,
    certificate_fee,
    exam_fee,
    annual_fee,
    eligibility,
    exam_type,
  } = data;

  const [file, setFile] = useState([]);

  const onChange = (evt) => {
    var newContent = evt.editor.getData();
    setdescription(newContent);
  };

  const onBlur = (evt) => {
    //console.log("onBlur event called with event info: ", evt);
  };

  const afterPaste = (evt) => {
    //console.log("afterPaste event called with event info: ", evt);
  };

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (!subtrades) {
      dispatch(getSubTrades());
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    for (let [key, value] of Object.entries(data)) {
      if (Array.isArray(value)) {
        formdata.append(key, JSON.stringify(value));
      } else {
        formdata.append(key, value);
      }
    }

    formdata.append("file", file);
    formdata.append("description", description);
    if (!id) {
      const response = await dispatch(createCourse(formdata));
      if (response) {
        history.push("/admin/courses");
      }
    } else {
      formdata.append("course_id", id);
      const response = await dispatch(updateCourse(formdata));
      if (response) {
        history.push("/admin/courses");
      }
    }
  };

  return (
    <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
      <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
        <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <BreadCrumb crumbs={breadcrumbs} />

          <div class="d-flex align-items-center">
            <Link
              to={"/admin/courses"}
              class="btn btn-light-primary font-weight-bolder btn-sm"
            >
              View Course
            </Link>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-2"></div>
          {edit === false ? (
            <h1> No Course Exist</h1>
          ) : (
            <div class="col-md-8">
              <form onSubmit={handleSubmit}>
                <div class="form-group">
                  <label>
                    {" "}
                    Sub Trade <span class="text-danger">*</span>
                  </label>
                  <select
                    class="form-control"
                    name="sub_trade_id"
                    onChange={handleChange}
                    value={sub_trade_id}
                    required
                  >
                    <option value="">Select</option>
                    {subtrades &&
                      subtrades.map((subtrade) => (
                        <option key={subtrade.id} value={subtrade.id}>
                          {subtrade.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div class="form-group">
                  <label>
                    Course <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter course Name "
                    name="name"
                    value={name}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div class="form-group">
                  <label>
                    Image <span class="text-danger">*</span>
                  </label>
                  <input
                    type="file"
                    class="form-control"
                    placeholder="Enter course Name "
                    name="file"
                    onChange={(e) => setFile(e.target.files[0])}
                    required={!id}
                  />
                </div>
                <div class="form-group">
                  <label>
                    Course Eligibilty<span class="text-danger">*</span>
                  </label>
                  <textarea
                    class="form-control"
                    name="eligibility"
                    value={eligibility}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    required
                  ></textarea>
                </div>
                <div class="form-group">
                  <label>
                    Course Duration<span class="text-danger">*</span>
                  </label>
                  <select
                    class="form-control"
                    name="duration"
                    value={duration}
                    onChange={handleChange}
                    required
                  >
                    <option value="" disabled selected>
                      Select
                    </option>
                    <option value="1 months"> 1 months </option>
                    <option value="2 months"> 2 months </option>
                    <option value="3 months"> 3 months </option>
                    <option value="6 months"> 6 months </option>
                    <option value="1 years"> 1 years </option>
                    <option value="1.5 years"> 1.5 years </option>
                    <option value="2 years"> 2 years </option>
                    <option value="3 years"> 3 years </option>
                  </select>
                </div>
                <div class="form-group">
                  <label>
                    Course Type (Semester/Year)
                    <span class="text-danger">*</span>
                  </label>
                  <select
                    class="form-control"
                    name="course_type"
                    value={course_type}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select</option>
                    <option value="2"> Semester Wise </option>
                    <option value="1">Course Wise </option>
                    <option value="0">Yearly</option>
                  </select>
                </div>
                <div class="form-group">
                  <label>
                    Exam Type
                    <span class="text-danger">*</span>
                  </label>
                  <select
                    class="form-control"
                    name="exam_type"
                    value={exam_type}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select</option>
                    <option value="course_wise">Course Wise</option>
                    {/* all subject in one paper */}
                    <option value="subject_wise">Subject Wise</option>

                    <option value="semester"> Semester Wise </option>
                    {/* subject wise in semester */}
                    <option value="yearly">Yearly </option>
                    {/* subject wise in year  */}
                  </select>
                </div>
                <div class="row">
                  <div class="form-group col-md-6">
                    <label>
                      Course fee <span class="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      min={0}
                      class="form-control"
                      placeholder="Course fee "
                      name="course_fee"
                      value={course_fee}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label>
                      Registration fee <span class="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      min={0}
                      class="form-control"
                      placeholder="Registration "
                      name="registration_fee"
                      value={registration_fee}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label>
                      Exam Fee <span class="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      min={0}
                      class="form-control"
                      placeholder="Exam Fee "
                      name="exam_fee"
                      value={exam_fee}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label>
                      Certificate Fee <span class="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      min={0}
                      class="form-control"
                      placeholder="Certificate Fee "
                      name="certificate_fee"
                      value={certificate_fee}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label>
                      Annual Fee <span class="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      min={0}
                      class="form-control"
                      placeholder="Annual Fee "
                      name="annual_fee"
                      value={annual_fee}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  {/* <div class="form-group col-md-6">
                  <label>
                    Other Fee <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Other Fee  "
                    name="otherfee"
                  />
                </div> */}
                  {/* <div class="form-group col-md-6">
                  <label>
                    Other Fee Type<span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Other Fee Type"
                    name="otherfeetype"
                  />
                </div> */}
                  {/* <div class="form-group col-md-6">
                  <label>
                    Important<span class="text-danger">*</span>
                  </label>
                  <label>
                    <input type="radio" value="1" name="imp" />
                    <span>Yes</span>
                  </label>
                </div> */}
                </div>
                {/* <div class="form-group">
                <label>
                  Fees Structure
                  <span class="text-danger">*</span>
                </label>
                <textarea name="description" id="description"></textarea>
              </div> */}
                <div class="form-group ">
                  <label>
                    Description
                    <span class="text-danger">*</span>
                  </label>
                  <CKEditor
                    activeClass="p10"
                    content={description}
                    events={{
                      blur: onBlur,
                      afterPaste: afterPaste,
                      change: onChange,
                    }}
                  />

                  {/* <span class="form-text text-muted mt-4"> <%if(message){%><%=message%><%}%></span> */}
                </div>

                <button type="submit" class="btn btn-primary mr-2">
                  Submit
                </button>
                <button type="reset" class="btn btn-secondary">
                  Cancel
                </button>
              </form>
            </div>
          )}
          <div class="col-md-2"></div>
        </div>
      </div>
    </div>
  );
}
