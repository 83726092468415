import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import BreadCrumb from "../BreadCrumb";
import { emailList } from "../../store/actions/councilActions/emailActions";
import { rtiList } from "../../store/actions/councilActions/rtiActions";

const breadcrumbs = [
  {
    path: "/",
    label: "Home",
  },
  {
    label: "Minister RTI",
  },
];

const RtiMinister = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const fetchdata = async () => {
    const response = await dispatch(rtiList(1));
    if (response) {
      setData(response);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    setLoading(true);
    fetchdata();
  }, []);
  return (
    <div>
      {" "}
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
          <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <BreadCrumb crumbs={breadcrumbs} />
          </div>
        </div>
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status" size="lg">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <Table bordered hover striped responsive className="mb-0">
            <thead>
              <th>S No</th>
              <th>Title</th>
              <th>Department</th>
              <th>Reference Number</th>
              <th>Posted Date</th>
              <th>File</th>
            </thead>
            <tbody>
              {data.length == 0 ? (
                <tr>
                  <td colSpan={6} className="text-center text-danger">
                    No Record Found
                  </td>
                </tr>
              ) : (
                data.map((element, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{element.title}</td>
                    <td>{element.department}</td>
                    <td>{element.reference_number}</td>
                    <td>{element.posted_date}</td>
                    <td>
                      <a href={element.file} download target={"_blank"}>
                        Download
                      </a>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default RtiMinister;
