import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getNewsDetails } from "../../store/actions/adminActions/newsAction";
import Loader from "../Alert/Loader";

const NewsDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [data, setData] = useState(null);
  useEffect(async () => {
    const response = await dispatch(getNewsDetails(id));
    if (response.success) {
      setData(response.data);
    } else {
      setData(false);
    }
  }, [id]);
  return (
    <div className="container py-5">
      <div className="row">
        {data === null ? (
          <Loader active={true} />
        ) : data ? (
          <>
            <div className="col-12 col-sm-3 p-3 d-none d-sm-block">
              <img className="img-fluid" alt="" src={data.file} />
            </div>
            <div className="col-9 col-md-9">
              <div className="blog-post">
                <h2 className="blog-post-title">{data.title}</h2>
                {/* <p className="blog-post-meta">
                  {moment(data.created_at).format("ll")}
                </p> */}
                <div
                  className="heading"
                  dangerouslySetInnerHTML={{
                    __html: data.description,
                  }}
                ></div>
              </div>
            </div>
          </>
        ) : (
          <h1>No Data Found</h1>
        )}
      </div>
    </div>
  );
};

export default NewsDetails;
