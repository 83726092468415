import React from "react";

const AdminCard = () => {
  return (
    <div>
      <div class="mid-content-box">
        <div class="Rollnumberobox">
          <div class="Boxroll">
            <h1>Admit Card</h1>
          </div>

          <div class="rollNOads">
            <div class="Leftdiv">
              <div class="Img">
                <img src="/students/<%=item.studentphoto%>" />
              </div>
            </div>
            <div class="rightdiv">
              <div class="RollNodate">
                <div class="DateRr">
                  <span>Date</span>
                </div>
                <div class="DateRr">
                  <span>Time</span>
                </div>
                <div class="DateRr">
                  <span>Roll Number</span>
                  {"<%=item.rollnumber%>"}
                </div>
                <div class="DateRr">
                  <span>Course</span>
                </div>

                <div class="Examcenter">
                  <div class="Exmcen"> Center Name</div>
                </div>
                <div class="Examcenter">
                  <div class="Exmcen"> Exam Center </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCard;
