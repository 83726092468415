import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap"; // Removed Spinner import as it requires Bootstrap styles
import { getStudentVerification } from "../../../store/actions/globalActions";
import { getPrintStatus } from "../../../store/actions/printStatusAction";
import { ALERT, AUTH_FAIL } from "../../../store/actions/actionTypes";
import { checkMembershipstatus } from "../../../store/actions/printActions/printcategory";

export default function MembershipStatus() {
  const [data, setData] = useState({
    registration_number: "",
    membre_type: "",
  });
  const [loading, setLoading] = useState(false);
  const [expand, setExpand] = useState(false);
  const [student, setStudent] = useState([]);

  const { admin } = useSelector((state) => state);
  const { adminAuth } = admin;
  const token = adminAuth?.profile?.admin?.token;
  const { profile } = adminAuth;
  const dispatch = useDispatch();

  //   const handleSubmit = async (e) => {
  //     e.preventDefault();
  //     setLoading(true);
  //     try {
  //       const response = await dispatch(checkMembershipstatus(data,token));
  // console.log('response', response)

  //         setStudent(response.data);
  //         setLoading(false);

  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //       // Handle the error, e.g., toast.error("Failed to fetch data")
  //     }
  //   };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true before the API request

    try {
      const response = await dispatch(checkMembershipstatus(data));
      console.log("response", response);
      if (response) {
        setStudent(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error, e.g., toast.error("Failed to fetch data")
    } finally {
      setLoading(false); // Set loading to false after receiving a response or error
    }
  };

  console.log("student", student);
  useEffect(() => {
    if (profile) {
      // Assuming profile.admin.user_type is the correct path
      setData({ ...data });
    }
  }, [profile]);

  // useEffect(() => {
  //   if (expand) {
  //     setExpand(false);
  //   }
  // }, [window.location.pathname]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  return (
    <div>
      <div className="gallery-box">
        <div className="container">
          <div className="title-box">
            <div className="col-md-12 inner">
              <div className="clear-fix"></div>
              <div className="col-lg-12">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-12">
                      <h3>MemberShip Details</h3>
                    </div>
                    <div className="col-lg-4 table-box">MemberShip Type</div>
                    <div className="col-lg-8 table-box">
                      {/* <input
                        type="text"
                        maxLength="10"
                        id="membreType"
                        className="input-md textinput textInput form-control"
                        placeholder="Please Enter MemberShip Type"
                        name="membre_type"
                        value={data.membre_type}
                        onChange={(e) =>
                          setData({ ...data, membre_type: e.target.value })
                        }
                        style={{ width: "202px" }}
                        required
                      /> */}
                      <select
                        className="form-control form-control-sm"
                        onChange={handleChange}
                        name="membre_type"
                        value={data.membre_type}
                      >
                        <option value="">Choose option</option>
                        <option value="Individual">Individual</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                    <div className="col-lg-4 table-box">
                      Registration Number
                    </div>
                    <div className="col-lg-8 table-box">
                      <input
                        type="text"
                        maxLength="10"
                        id="registrationNumber"
                        className="input-md textinput textInput form-control"
                        placeholder="Please Enter Registration Number"
                        name="registration_number"
                        value={data.registration_number}
                        onChange={(e) =>
                          setData({
                            ...data,
                            registration_number: e.target.value,
                          })
                        }
                        style={{ width: "202px" }}
                        required
                      />
                    </div>
                    <div className="col-lg-8 table-box">
                      <input
                        type="submit"
                        value="Search Result"
                        className="btn btn-primary btn btn-info"
                      />
                    </div>
                  </div>
                </form>
              </div>

              {loading ? (
                <div className="text-center">
                  <Spinner animation="border" role="status" size="lg">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : student && Object.keys(student).length > 0 ? (
                // <div>
                //   <table className="table">
                //     <thead>
                //       <tr>
                //         <th>Sr.No</th>
                //         <th>Name</th>
                //         <th>Registration Number</th>
                //         <th>Status</th>
                //         <th>State</th>
                //         <th>City</th>
                //         {/* Add more table headers as needed */}
                //       </tr>
                //     </thead>
                //     <tbody>
                //       <tr>
                //         <td>1</td>
                //         <td>{student.student_name}</td>
                //         <td>{student.registration_number}</td>
                //         <td>
                //           <span
                //             style={{
                //               color:
                //                 student.is_approwed === 1 ? "green" : "red",
                //               fontWeight: "bold",
                //             }}
                //           >
                //             {student.is_approwed === 1
                //               ? "Approved"
                //               : "Rejected"}
                //           </span>
                //         </td>
                //         <td>{student.state}</td>
                //         <td>{student.city}</td>
                //       </tr>
                //     </tbody>
                //   </table>
                // </div>
                <div className="row">
                  <div className="col-lg-12">
                    <h3>{"Membership Status"}</h3>
                  </div>
                  <div className="col-lg-4 table-box">Student Name</div>
                  <div className="col-lg-8 table-box">
                    {student.student_name}
                  </div>
                  <div className="col-lg-4 table-box"> Registration Number</div>
                  <div className="col-lg-8 table-box">
                    {student.registration_number}
                  </div>
                  <div className="col-lg-4 table-box">Father Name</div>
                  <div className="col-lg-8 table-box">
                    {student.father_name}
                  </div>
                  <div className="col-lg-4 table-box">Course</div>
                  <div className="col-lg-8 table-box">{student.coursename}</div>
                  <div className="col-lg-4 table-box">Center Name</div>
                  <div className="col-lg-8 table-box">
                    {student.center_name}
                  </div>

                  <div className="col-lg-4 table-box"> State</div>
                  <div className="col-lg-8 table-box">{student.state}</div>
                  <div className="col-lg-4 table-box">city</div>
                  <div className="col-lg-8 table-box">{student.city}</div>

                  <div className="col-lg-4 table-box">staus</div>
                  <div className="col-lg-8 table-box">
                    {" "}
                    <span
                      style={{
                        color: student.is_approwed === 1 ? "green" : "red",
                        fontWeight: "bold",
                      }}
                    >
                      {student.is_approwed === 1 ? "Approved" : "Rejected"}
                    </span>
                  </div>
                </div>
              ) : (
                <tr>
                  <td colSpan={6}>No Record Found</td>
                </tr>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
