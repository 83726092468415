import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  centerDashboard,
  centerRegisterAction,
  centerUpdate,
  editCenter,
} from "../../../store/actions/centerActions/centerAuth";
import { getTrades } from "../../../store/actions/councilActions/trade";
import CenterDetails from "./CenterDetails";
import FacilityDetail from "./FacilityDetail";
import MdDetails from "./MdDetails";
import StaffDetail from "./StaffDetail";

export default function Profile() {
  const dispatch = useDispatch();
  const { center } = useSelector((state) => state);
  const { centerProfile } = center;
  const [college_front_pic, setcollege_front_pic] = useState([]);
  const [classroom_pic, setclassroom_pic] = useState([]);
  const [lab_pic, setlab_pic] = useState([]);
  const [adhaar_pic, setadhaar_pic] = useState([]);
  const [md_sign_pic, setmd_sign_pic] = useState([]);
  const [office_pic, setoffice_pic] = useState([]);
  const [md_pic, setmd_pic] = useState([]);

  useEffect(() => {
    dispatch(centerDashboard());
  }, []);
  useEffect(() => {
    dispatch(editCenter());
  }, []);

  useEffect(() => {
    dispatch(getTrades());
  }, []);

  // handle input change

  //handle Form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    var formdata = new FormData();

    for (let [key, value] of Object.entries(centerProfile)) {
      if (Array.isArray(value)) {
        formdata.append(key, JSON.stringify(value));
      } else {
        formdata.append(key, value);
      }
    }

    // formdata.append("college_front_pic", college_front_pic);
    // formdata.append("classroom_pic", classroom_pic);
    // formdata.append("lab_pic", lab_pic);
    // formdata.append("adhaar_pic", adhaar_pic);
    // formdata.append("md_sign_pic", md_sign_pic);
    // formdata.append("office_pic", office_pic);
    formdata.append("md_pic", md_pic);
    formdata.delete("centerfiles");

    dispatch(centerUpdate(formdata));
  };
  return (
    <div className="myForm card">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-9">
            <div className="row">
              <MdDetails />
              {/* <CenterDetails /> */}
              <StaffDetail />
              {/* <FacilityDetail /> */}
              {/* <div className="col-md-4 col-12">
                <div className="Fileboxbale">
                  <div className="custom-file">
                    <input type="hidden" value="" name="frontphoto_old" />
                    <input
                      type="file"
                      className="custom-file-input"
                      id="customFiler7"
                      name="frontphoto"
                      onChange={(e) => setcollege_front_pic(e.target.files[0])}
                    />
                    <label className="custom-file2" for="customFiler7">
                      <img src="/uploads/" id="frontphoto" />
                      <h4>College Front photo</h4>
                      <p>drag n drop photo here</p>
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="Fileboxbale">
                  <div className="custom-file">
                    <input
                      type="hidden"
                      value="officephotopathology-laboratory-500x500.jpg"
                      name="officephoto_old"
                    />
                    <input
                      type="file"
                      className="custom-file-input"
                      id="customFiler"
                      name="officephoto"
                      onChange={(e) => setoffice_pic(e.target.files[0])}
                    />
                    <label className="custom-file2" for="customFiler">
                      <img
                        src="/uploads/officephotopathology-laboratory-500x500.jpg"
                        id="officephoto"
                      />
                      <h4>Office photo</h4>
                      <p>drag n drop photo here</p>
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="Fileboxbale">
                  <div className="custom-file">
                    <input type="hidden" value="" name="labphoto_old" />
                    <input
                      type="file"
                      className="custom-file-input"
                      id="customFilee"
                      name="labphoto"
                      onChange={(e) => setlab_pic(e.target.files[0])}
                    />
                    <label className="custom-file2" for="customFilee">
                      <img src="/uploads/" id="labphoto" />
                      <h4>Lab photo</h4>
                      <p>drag n drop photo here</p>
                    </label>
                  </div>
                </div>
              </div>{" "}
              <div className="col-md-4 col-12">
                <div className="Fileboxbale">
                  <div className="custom-file">
                    <input type="hidden" value="" name="classphoto_old" />
                    <input
                      type="file"
                      className="custom-file-input"
                      id="customFilet"
                      name="classphoto"
                      onChange={(e) => setclassroom_pic(e.target.files[0])}
                    />
                    <label className="custom-file2" for="customFilet">
                      <img src="/uploads/" id="classphoto" />
                      <h4>Classromm photo</h4>
                      <p>drag n drop photo here</p>
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="Fileboxbale disabled">
                  <div className="custom-file">
                    <input type="hidden" value="" name="adharphoto_old" />
                    <input
                      type="file"
                      className="custom-file-input"
                      id="customFiley"
                      name="adharphoto"
                      onChange={(e) => setadhaar_pic(e.target.files[0])}
                    />
                    <label className="custom-file2" for="customFiley">
                      <img src="/uploads/" id="adharphotos" />
                      <h4>Aadhar card</h4>
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="Fileboxbale disabled">
                  <div className="custom-file">
                    <input type="hidden" value="" name="signphoto_old" />
                    <input
                      type="file"
                      className="custom-file-input"
                      id="customFileu"
                      name="signphoto"
                      onChange={(e) => setmd_sign_pic(e.target.files[0])}
                    />
                    <label className="custom-file2" for="customFileu">
                      <img src="/uploads/" id="signphoto" />
                      <h4>Md Signature photo</h4>
                    </label>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="col-md-3 col-12">
            <div className="Fileboxbale">
              <div className="custom-file">
                <input type="hidden" value={md_pic} name="mdphoto_old" />
                <input
                  type="file"
                  className="custom-file-input"
                  id="customFileddf"
                  name="mdphoto"
                  onChange={(e) => setmd_pic(e.target.files[0])}
                />
                <label className="custom-file2" for="customFileddf">
                  <img
                    src="/uploads/mdphotomohd-kashif-photo.jpg"
                    id="photomd"
                  />
                  <h4>Md Photo</h4>
                  <p>drag n drop photo here</p>
                </label>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div
              className="btn-groups mt-4 text-center"
              role="group"
              aria-label="First group"
            >
              <button className="btn btn-Next" type="submit">
                Submit
                <span>
                  <svg
                    width="35"
                    height="12"
                    viewBox="0 0 35 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M34.8403 6.00003C34.8403 6.2151 34.7582 6.43013 34.5943 6.5941L29.4346 11.7538C29.1063 12.0821 28.5742 12.0821 28.2461 11.7538C27.918 11.4257 27.918 10.8937 28.2461 10.5654L32.8117 6.00003L28.2462 1.43461C27.9181 1.10639 27.9181 0.574383 28.2462 0.246316C28.5743 -0.0820704 29.1065 -0.0820704 29.4347 0.246316L34.5945 5.40596C34.7584 5.57001 34.8403 5.78505 34.8403 6.00003Z"
                      fill="white"
                    ></path>
                    <line
                      x1="33.25"
                      y1="5.94995"
                      x2="0.75"
                      y2="5.94995"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    ></line>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
