import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card, Spinner } from "react-bootstrap";
import "./office.css";
import { officeList } from "../../store/actions/councilActions/officeAction";

const Offices = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const fetchdata = async () => {
    const response = await dispatch(officeList());
    if (response) {
      setData(response);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    setLoading(true);
    fetchdata();
  }, []);
  function GoogleMapsURLToEmbedURL(GoogleMapsURL) {
    var coords = /\@([0-9\.\,\-a-zA-Z]*)/.exec(GoogleMapsURL);
    if (coords != null) {
      var coordsArray = coords[1].split(",");
      return (
        "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d20000!2d" +
        coordsArray[1] +
        "!3d" +
        coordsArray[0] +
        "!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2suk!4v1486486434098"
      );
    }
  }
  return (
    <div className="">
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status" size="lg">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : data.length == 0 ? (
        <div className="text-center text-danger">
          <h5>No Record Found</h5>
        </div>
      ) : (
        data.map((element, index) => (
          <ul class="tilesWrap mb-0">
            <li>
              <h2>{index + 1}</h2>
              <h3 class="mb-3">{element.category}</h3>
              <div className="mb-3">
                <span className="text-white">{element.address}</span>
                {element.address_link && (
                  <a
                    href={element.address_link}
                    target="_blank"
                    className="float-right"
                  >
                    View in Map
                  </a>
                )}
              </div>
              <div className="officeMail  ">
                <div>
                  <i className="fa fa-mobile"></i>
                  <a href={`tel:${element.phone}`} style={{ color: "#b7b7b7" }}>
                    {" "}
                    {` ${element.phone}`}
                  </a>
                </div>
                <div className="mt-3">
                  <i class="fa fa-envelope" aria-hidden="true"></i>{" "}
                  <a
                    href={`mailto:${element.email}`}
                    style={{ color: "#b7b7b7" }}
                  >{` ${element.email}`}</a>
                </div>
              </div>
            </li>
          </ul>
        ))
      )}
    </div>
  );
};

export default Offices;
