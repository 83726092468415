import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AUTH_FAIL } from "../../../store/actions/actionTypes";
import { getStaticPage } from "../../../store/actions/adminActions/staticPages";

const AdminNav = () => {
  const [data, setData] = useState([]);

  const dispatch = useDispatch();
  useEffect(async () => {
    const response = await dispatch(getStaticPage());
    if (response.success) {
      setData(response.data);
    }
  }, []);

  return (
    <>
      <li>
        <Link to="/admin/dashboard" class="active">
          <span>
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M40.2832 18.5197C40.2823 18.5188 40.2813 18.5178 40.2804 18.5169L23.3925 1.62957C22.6726 0.909412 21.7156 0.512695 20.6976 0.512695C19.6796 0.512695 18.7225 0.909096 18.0024 1.62925L1.12331 18.508C1.11763 18.5137 1.11194 18.5197 1.10626 18.5254C-0.371955 20.0121 -0.369428 22.4243 1.11352 23.9073C1.79103 24.5851 2.68586 24.9777 3.64259 25.0188C3.68144 25.0226 3.7206 25.0245 3.76009 25.0245H4.43318V37.4525C4.43318 39.9117 6.43413 41.9127 8.89402 41.9127H15.5011C16.1707 41.9127 16.714 41.3697 16.714 40.6998V30.9563C16.714 29.834 17.6268 28.9212 18.7491 28.9212H22.6461C23.7684 28.9212 24.6812 29.834 24.6812 30.9563V40.6998C24.6812 41.3697 25.2241 41.9127 25.8941 41.9127H32.5012C34.9611 41.9127 36.962 39.9117 36.962 37.4525V25.0245H37.5861C38.6038 25.0245 39.5609 24.6281 40.2813 23.9079C41.7659 22.4224 41.7665 20.0061 40.2832 18.5197Z"
                fill="#B2CDF0"
              ></path>
            </svg>
          </span>
          Dashboard
        </Link>
      </li>
      <li>
        <Link to="/admin/view-gallery" class="active">
          <span>
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M40.2832 18.5197C40.2823 18.5188 40.2813 18.5178 40.2804 18.5169L23.3925 1.62957C22.6726 0.909412 21.7156 0.512695 20.6976 0.512695C19.6796 0.512695 18.7225 0.909096 18.0024 1.62925L1.12331 18.508C1.11763 18.5137 1.11194 18.5197 1.10626 18.5254C-0.371955 20.0121 -0.369428 22.4243 1.11352 23.9073C1.79103 24.5851 2.68586 24.9777 3.64259 25.0188C3.68144 25.0226 3.7206 25.0245 3.76009 25.0245H4.43318V37.4525C4.43318 39.9117 6.43413 41.9127 8.89402 41.9127H15.5011C16.1707 41.9127 16.714 41.3697 16.714 40.6998V30.9563C16.714 29.834 17.6268 28.9212 18.7491 28.9212H22.6461C23.7684 28.9212 24.6812 29.834 24.6812 30.9563V40.6998C24.6812 41.3697 25.2241 41.9127 25.8941 41.9127H32.5012C34.9611 41.9127 36.962 39.9117 36.962 37.4525V25.0245H37.5861C38.6038 25.0245 39.5609 24.6281 40.2813 23.9079C41.7659 22.4224 41.7665 20.0061 40.2832 18.5197Z"
                fill="#B2CDF0"
              ></path>
            </svg>
          </span>
          Gallery
        </Link>
      </li>
      <li>
        <Link to="/admin/view-activity-list" class="active">
          <span>
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M40.2832 18.5197C40.2823 18.5188 40.2813 18.5178 40.2804 18.5169L23.3925 1.62957C22.6726 0.909412 21.7156 0.512695 20.6976 0.512695C19.6796 0.512695 18.7225 0.909096 18.0024 1.62925L1.12331 18.508C1.11763 18.5137 1.11194 18.5197 1.10626 18.5254C-0.371955 20.0121 -0.369428 22.4243 1.11352 23.9073C1.79103 24.5851 2.68586 24.9777 3.64259 25.0188C3.68144 25.0226 3.7206 25.0245 3.76009 25.0245H4.43318V37.4525C4.43318 39.9117 6.43413 41.9127 8.89402 41.9127H15.5011C16.1707 41.9127 16.714 41.3697 16.714 40.6998V30.9563C16.714 29.834 17.6268 28.9212 18.7491 28.9212H22.6461C23.7684 28.9212 24.6812 29.834 24.6812 30.9563V40.6998C24.6812 41.3697 25.2241 41.9127 25.8941 41.9127H32.5012C34.9611 41.9127 36.962 39.9117 36.962 37.4525V25.0245H37.5861C38.6038 25.0245 39.5609 24.6281 40.2813 23.9079C41.7659 22.4224 41.7665 20.0061 40.2832 18.5197Z"
                fill="#B2CDF0"
              ></path>
            </svg>
          </span>
          Activity
        </Link>
      </li>
      <li>
        <Link to="/admin/view-body-list" class="active">
          <span>
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M40.2832 18.5197C40.2823 18.5188 40.2813 18.5178 40.2804 18.5169L23.3925 1.62957C22.6726 0.909412 21.7156 0.512695 20.6976 0.512695C19.6796 0.512695 18.7225 0.909096 18.0024 1.62925L1.12331 18.508C1.11763 18.5137 1.11194 18.5197 1.10626 18.5254C-0.371955 20.0121 -0.369428 22.4243 1.11352 23.9073C1.79103 24.5851 2.68586 24.9777 3.64259 25.0188C3.68144 25.0226 3.7206 25.0245 3.76009 25.0245H4.43318V37.4525C4.43318 39.9117 6.43413 41.9127 8.89402 41.9127H15.5011C16.1707 41.9127 16.714 41.3697 16.714 40.6998V30.9563C16.714 29.834 17.6268 28.9212 18.7491 28.9212H22.6461C23.7684 28.9212 24.6812 29.834 24.6812 30.9563V40.6998C24.6812 41.3697 25.2241 41.9127 25.8941 41.9127H32.5012C34.9611 41.9127 36.962 39.9117 36.962 37.4525V25.0245H37.5861C38.6038 25.0245 39.5609 24.6281 40.2813 23.9079C41.7659 22.4224 41.7665 20.0061 40.2832 18.5197Z"
                fill="#B2CDF0"
              ></path>
            </svg>
          </span>
          Bodies
        </Link>
      </li>
      <li>
        <Link to="/admin/view-news" class="active">
          <span>
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M40.2832 18.5197C40.2823 18.5188 40.2813 18.5178 40.2804 18.5169L23.3925 1.62957C22.6726 0.909412 21.7156 0.512695 20.6976 0.512695C19.6796 0.512695 18.7225 0.909096 18.0024 1.62925L1.12331 18.508C1.11763 18.5137 1.11194 18.5197 1.10626 18.5254C-0.371955 20.0121 -0.369428 22.4243 1.11352 23.9073C1.79103 24.5851 2.68586 24.9777 3.64259 25.0188C3.68144 25.0226 3.7206 25.0245 3.76009 25.0245H4.43318V37.4525C4.43318 39.9117 6.43413 41.9127 8.89402 41.9127H15.5011C16.1707 41.9127 16.714 41.3697 16.714 40.6998V30.9563C16.714 29.834 17.6268 28.9212 18.7491 28.9212H22.6461C23.7684 28.9212 24.6812 29.834 24.6812 30.9563V40.6998C24.6812 41.3697 25.2241 41.9127 25.8941 41.9127H32.5012C34.9611 41.9127 36.962 39.9117 36.962 37.4525V25.0245H37.5861C38.6038 25.0245 39.5609 24.6281 40.2813 23.9079C41.7659 22.4224 41.7665 20.0061 40.2832 18.5197Z"
                fill="#B2CDF0"
              ></path>
            </svg>
          </span>
          News
        </Link>
      </li>
      {data.map((d) => (
        <li>
          <Link to={`/admin/static-page/${d.id}`}>
            <span>
              <svg
                width="42"
                height="42"
                viewBox="0 0 42 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M40.2832 18.5197C40.2823 18.5188 40.2813 18.5178 40.2804 18.5169L23.3925 1.62957C22.6726 0.909412 21.7156 0.512695 20.6976 0.512695C19.6796 0.512695 18.7225 0.909096 18.0024 1.62925L1.12331 18.508C1.11763 18.5137 1.11194 18.5197 1.10626 18.5254C-0.371955 20.0121 -0.369428 22.4243 1.11352 23.9073C1.79103 24.5851 2.68586 24.9777 3.64259 25.0188C3.68144 25.0226 3.7206 25.0245 3.76009 25.0245H4.43318V37.4525C4.43318 39.9117 6.43413 41.9127 8.89402 41.9127H15.5011C16.1707 41.9127 16.714 41.3697 16.714 40.6998V30.9563C16.714 29.834 17.6268 28.9212 18.7491 28.9212H22.6461C23.7684 28.9212 24.6812 29.834 24.6812 30.9563V40.6998C24.6812 41.3697 25.2241 41.9127 25.8941 41.9127H32.5012C34.9611 41.9127 36.962 39.9117 36.962 37.4525V25.0245H37.5861C38.6038 25.0245 39.5609 24.6281 40.2813 23.9079C41.7659 22.4224 41.7665 20.0061 40.2832 18.5197Z"
                  fill="#B2CDF0"
                ></path>
              </svg>
            </span>
            {d.name}
          </Link>
        </li>
      ))}
      <li>
        <Link to="/admin/affilation-process" class="active">
          <span>
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M40.2832 18.5197C40.2823 18.5188 40.2813 18.5178 40.2804 18.5169L23.3925 1.62957C22.6726 0.909412 21.7156 0.512695 20.6976 0.512695C19.6796 0.512695 18.7225 0.909096 18.0024 1.62925L1.12331 18.508C1.11763 18.5137 1.11194 18.5197 1.10626 18.5254C-0.371955 20.0121 -0.369428 22.4243 1.11352 23.9073C1.79103 24.5851 2.68586 24.9777 3.64259 25.0188C3.68144 25.0226 3.7206 25.0245 3.76009 25.0245H4.43318V37.4525C4.43318 39.9117 6.43413 41.9127 8.89402 41.9127H15.5011C16.1707 41.9127 16.714 41.3697 16.714 40.6998V30.9563C16.714 29.834 17.6268 28.9212 18.7491 28.9212H22.6461C23.7684 28.9212 24.6812 29.834 24.6812 30.9563V40.6998C24.6812 41.3697 25.2241 41.9127 25.8941 41.9127H32.5012C34.9611 41.9127 36.962 39.9117 36.962 37.4525V25.0245H37.5861C38.6038 25.0245 39.5609 24.6281 40.2813 23.9079C41.7659 22.4224 41.7665 20.0061 40.2832 18.5197Z"
                fill="#B2CDF0"
              ></path>
            </svg>
          </span>
          Affiliation Process
        </Link>
      </li>
      <li>
        <Link to="/admin/about-us" class="active">
          <span>
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M40.2832 18.5197C40.2823 18.5188 40.2813 18.5178 40.2804 18.5169L23.3925 1.62957C22.6726 0.909412 21.7156 0.512695 20.6976 0.512695C19.6796 0.512695 18.7225 0.909096 18.0024 1.62925L1.12331 18.508C1.11763 18.5137 1.11194 18.5197 1.10626 18.5254C-0.371955 20.0121 -0.369428 22.4243 1.11352 23.9073C1.79103 24.5851 2.68586 24.9777 3.64259 25.0188C3.68144 25.0226 3.7206 25.0245 3.76009 25.0245H4.43318V37.4525C4.43318 39.9117 6.43413 41.9127 8.89402 41.9127H15.5011C16.1707 41.9127 16.714 41.3697 16.714 40.6998V30.9563C16.714 29.834 17.6268 28.9212 18.7491 28.9212H22.6461C23.7684 28.9212 24.6812 29.834 24.6812 30.9563V40.6998C24.6812 41.3697 25.2241 41.9127 25.8941 41.9127H32.5012C34.9611 41.9127 36.962 39.9117 36.962 37.4525V25.0245H37.5861C38.6038 25.0245 39.5609 24.6281 40.2813 23.9079C41.7659 22.4224 41.7665 20.0061 40.2832 18.5197Z"
                fill="#B2CDF0"
              ></path>
            </svg>
          </span>
          About us
        </Link>
      </li>
    </>
  );
};

const CouncilNav = () => {
  return (
    <>
      <li>
        <Link to="/admin/dashboard" class="active">
          <span>
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M40.2832 18.5197C40.2823 18.5188 40.2813 18.5178 40.2804 18.5169L23.3925 1.62957C22.6726 0.909412 21.7156 0.512695 20.6976 0.512695C19.6796 0.512695 18.7225 0.909096 18.0024 1.62925L1.12331 18.508C1.11763 18.5137 1.11194 18.5197 1.10626 18.5254C-0.371955 20.0121 -0.369428 22.4243 1.11352 23.9073C1.79103 24.5851 2.68586 24.9777 3.64259 25.0188C3.68144 25.0226 3.7206 25.0245 3.76009 25.0245H4.43318V37.4525C4.43318 39.9117 6.43413 41.9127 8.89402 41.9127H15.5011C16.1707 41.9127 16.714 41.3697 16.714 40.6998V30.9563C16.714 29.834 17.6268 28.9212 18.7491 28.9212H22.6461C23.7684 28.9212 24.6812 29.834 24.6812 30.9563V40.6998C24.6812 41.3697 25.2241 41.9127 25.8941 41.9127H32.5012C34.9611 41.9127 36.962 39.9117 36.962 37.4525V25.0245H37.5861C38.6038 25.0245 39.5609 24.6281 40.2813 23.9079C41.7659 22.4224 41.7665 20.0061 40.2832 18.5197Z"
                fill="#B2CDF0"
              ></path>
            </svg>
          </span>
          Dashboard
        </Link>
      </li>

      <li>
        <Link to="/admin/view-trade">
          <span>
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <path
                  d="M16.474 7.25184C18.3557 7.25184 19.8812 5.7264 19.8812 3.84467C19.8812 1.96294 18.3557 0.4375 16.474 0.4375C14.5923 0.4375 13.0668 1.96294 13.0668 3.84467C13.0668 5.7264 14.5923 7.25184 16.474 7.25184Z"
                  fill="#B2CDF0"
                ></path>
                <path
                  d="M39.513 8.36882C39.4033 8.21616 39.1907 8.18131 39.038 8.29087L29.6593 15.0265L24.3666 15.2135C23.8397 13.7386 23.4227 12.5548 23.1236 11.6844C22.3609 9.46471 21.0818 8.33923 19.3222 8.33923C16.8527 8.33923 16.0743 8.33923 13.6256 8.33923C11.866 8.33923 10.5869 9.46471 9.82418 11.6844C9.49257 12.6493 9.25646 13.3267 8.93343 14.2336L6.81095 13.1533L5.34004 7.75316C5.31303 7.65427 5.23743 7.576 5.13943 7.54567L4.25733 7.27302L3.64466 5.02359C3.61507 4.91516 3.52725 4.83227 3.41728 4.80915C3.30732 4.78602 3.19355 4.82645 3.12279 4.91386L1.8233 6.5207L3.26131 11.8005C2.81609 11.9121 2.41924 12.207 2.19461 12.6481C1.78271 13.4573 2.10469 14.4473 2.91385 14.8592L9.10453 18.0108C9.98598 18.4595 11.0621 18.0331 11.3972 17.1028C11.7837 16.0302 12.284 14.6226 12.284 14.6226C12.284 14.6226 10.4701 24.1662 9.95056 26.851C9.84108 27.4165 10.2769 27.9391 10.8472 27.9391C11.2013 27.9391 11.6262 27.9391 12.1018 27.9391V39.8652C12.1018 40.9548 12.9851 41.8381 14.0748 41.8381C15.1645 41.8381 16.0478 40.9548 16.0478 39.8652V27.9392C16.3313 27.9392 16.616 27.9392 16.8995 27.9392V39.8653C16.8995 40.955 17.7828 41.8383 18.8725 41.8383C19.9622 41.8383 20.8455 40.955 20.8455 39.8653V27.9392C21.3212 27.9392 21.746 27.9392 22.1001 27.9392C22.6734 27.9392 23.1062 27.4161 22.9967 26.8512C22.0263 21.8291 20.6615 14.6082 20.6615 14.6082C20.6615 14.6082 21.2898 16.3783 21.6763 17.4548C21.9169 18.1251 22.5634 18.5674 23.2818 18.5424L29.8661 18.3097C30.7736 18.2776 31.4833 17.516 31.4512 16.6085C31.4408 16.3159 31.354 16.0445 31.2118 15.8112L39.4566 8.82721C39.5899 8.71344 39.617 8.51372 39.513 8.36882Z"
                  fill="#B2CDF0"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect
                    width="41.4"
                    height="41.4"
                    fill="white"
                    transform="translate(0 0.4375)"
                  ></rect>
                </clipPath>
              </defs>
            </svg>
          </span>
          Trade
        </Link>
      </li>
      <li>
        <Link to="/admin/view-sub-trade">
          <span>
            <svg
              width="42"
              height="43"
              viewBox="0 0 42 43"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.7 0.987305C9.315 0.987305 0 10.3023 0 21.6873C0 33.0723 9.315 42.3873 20.7 42.3873C32.085 42.3873 41.4 33.0723 41.4 21.6873C41.4 10.3023 32.085 0.987305 20.7 0.987305ZM20.7 7.19731C24.219 7.19731 26.91 9.88831 26.91 13.4073C26.91 16.9263 24.219 19.6173 20.7 19.6173C17.181 19.6173 14.49 16.9263 14.49 13.4073C14.49 9.88831 17.181 7.19731 20.7 7.19731ZM20.7 36.5913C15.525 36.5913 10.971 33.9004 8.28 29.9673C8.28 25.8273 16.56 23.5503 20.7 23.5503C24.84 23.5503 33.12 25.8273 33.12 29.9673C30.429 33.9003 25.875 36.5913 20.7 36.5913Z"
                fill="#B2CDF0"
              ></path>
            </svg>
          </span>
          Subtrade
        </Link>
      </li>
      <li>
        <Link to="/admin/courses">
          <span>
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <path
                  d="M37.5892 3.125H19.4005C17.2957 3.125 15.5897 4.83126 15.5897 6.93581V15.8951C16.5512 16.373 17.59 16.8897 18.5597 17.3717L20.0433 14.4033C20.9416 12.6083 23.1314 11.879 24.9258 12.7757C26.724 13.6756 27.4527 15.8594 26.5525 17.6579L23.9078 22.9485C23.9779 23.6488 23.9157 24.3547 23.7183 25.0379H25.4644L20.8592 37.7012C20.5859 38.4523 21.1141 39.3288 21.9994 39.3288C22.4944 39.3288 22.9599 39.0234 23.1387 38.53L28.0452 25.0379H28.8952L33.8516 38.5341C34.032 39.025 34.4963 39.3288 34.9909 39.3288C35.8181 39.3288 36.4246 38.5026 36.1286 37.6981L31.4792 25.0379H37.5892C39.6937 25.0379 41.4 23.3316 41.4 21.2271V6.93581C41.4 4.83126 39.6937 3.125 37.5892 3.125V3.125ZM34.9903 19.8417H29.5484C28.8788 19.8417 28.3355 19.2985 28.3355 18.6288C28.3355 17.9586 28.8788 17.4159 29.5484 17.4159H34.9903C35.6605 17.4159 36.2032 17.9586 36.2032 18.6288C36.2032 19.2985 35.6605 19.8417 34.9903 19.8417ZM34.9903 15.2943H29.5484C28.8788 15.2943 28.3355 14.7517 28.3355 14.0814C28.3355 13.4118 28.8788 12.8686 29.5484 12.8686H34.9903C35.6605 12.8686 36.2032 13.4118 36.2032 14.0814C36.2032 14.7517 35.6605 15.2943 34.9903 15.2943ZM34.9903 10.7476H21.9994C21.3292 10.7476 20.7865 10.2043 20.7865 9.53469C20.7865 8.86507 21.3292 8.32179 21.9994 8.32179H34.9903C35.6605 8.32179 36.2032 8.86507 36.2032 9.53469C36.2032 10.2043 35.6605 10.7476 34.9903 10.7476Z"
                  fill="#B2CDF0"
                ></path>
                <path
                  d="M21.3406 24.5177C21.5598 23.8755 21.5582 23.2047 21.3731 22.5925L24.3832 16.5729C24.6826 15.9737 24.4401 15.2451 23.8409 14.9456C23.242 14.6459 22.5133 14.8888 22.2136 15.488L19.6463 20.6219C18.1119 19.8578 15.7805 18.6986 13.9024 17.7647C12.1551 16.8957 10.9081 16.1165 8.78051 16.1165H7.7088C9.36547 16.1165 10.844 15.354 11.8118 14.1607C12.5503 13.2526 12.9918 12.0953 12.9918 10.8341C12.9918 7.91592 10.6267 5.55078 7.70817 5.55078C4.79092 5.55078 2.42578 7.91592 2.42578 10.8341C2.42578 12.2356 2.97158 13.5091 3.86198 14.4542C4.82471 15.4778 6.19206 16.1165 7.70786 16.1168C5.67563 16.1168 3.68889 16.9403 2.25964 18.3733C0.802593 19.8266 0 21.7628 0 23.8253V27.7227C0 29.4005 1.08939 30.8282 2.59824 31.3361V38.1156C2.59824 38.7856 3.1412 39.3285 3.81113 39.3285H11.6058C12.2758 39.3285 12.8187 38.7856 12.8187 38.1156V24.488C13.9688 25.0628 15.4795 25.8181 16.7019 26.4296C17.5588 26.8579 18.5601 26.8964 19.453 26.5332C20.3434 26.1668 21.0298 25.4375 21.3358 24.5319C21.3374 24.5272 21.339 24.5224 21.3406 24.5177Z"
                  fill="#B2CDF0"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect
                    width="41.4"
                    height="41.4"
                    fill="white"
                    transform="translate(0 0.537598)"
                  ></rect>
                </clipPath>
              </defs>
            </svg>
          </span>
          Courses
        </Link>
      </li>
      <li>
        <Link to="/admin/subjects">
          <span>
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <path
                  d="M37.5892 3.125H19.4005C17.2957 3.125 15.5897 4.83126 15.5897 6.93581V15.8951C16.5512 16.373 17.59 16.8897 18.5597 17.3717L20.0433 14.4033C20.9416 12.6083 23.1314 11.879 24.9258 12.7757C26.724 13.6756 27.4527 15.8594 26.5525 17.6579L23.9078 22.9485C23.9779 23.6488 23.9157 24.3547 23.7183 25.0379H25.4644L20.8592 37.7012C20.5859 38.4523 21.1141 39.3288 21.9994 39.3288C22.4944 39.3288 22.9599 39.0234 23.1387 38.53L28.0452 25.0379H28.8952L33.8516 38.5341C34.032 39.025 34.4963 39.3288 34.9909 39.3288C35.8181 39.3288 36.4246 38.5026 36.1286 37.6981L31.4792 25.0379H37.5892C39.6937 25.0379 41.4 23.3316 41.4 21.2271V6.93581C41.4 4.83126 39.6937 3.125 37.5892 3.125V3.125ZM34.9903 19.8417H29.5484C28.8788 19.8417 28.3355 19.2985 28.3355 18.6288C28.3355 17.9586 28.8788 17.4159 29.5484 17.4159H34.9903C35.6605 17.4159 36.2032 17.9586 36.2032 18.6288C36.2032 19.2985 35.6605 19.8417 34.9903 19.8417ZM34.9903 15.2943H29.5484C28.8788 15.2943 28.3355 14.7517 28.3355 14.0814C28.3355 13.4118 28.8788 12.8686 29.5484 12.8686H34.9903C35.6605 12.8686 36.2032 13.4118 36.2032 14.0814C36.2032 14.7517 35.6605 15.2943 34.9903 15.2943ZM34.9903 10.7476H21.9994C21.3292 10.7476 20.7865 10.2043 20.7865 9.53469C20.7865 8.86507 21.3292 8.32179 21.9994 8.32179H34.9903C35.6605 8.32179 36.2032 8.86507 36.2032 9.53469C36.2032 10.2043 35.6605 10.7476 34.9903 10.7476Z"
                  fill="#B2CDF0"
                ></path>
                <path
                  d="M21.3406 24.5177C21.5598 23.8755 21.5582 23.2047 21.3731 22.5925L24.3832 16.5729C24.6826 15.9737 24.4401 15.2451 23.8409 14.9456C23.242 14.6459 22.5133 14.8888 22.2136 15.488L19.6463 20.6219C18.1119 19.8578 15.7805 18.6986 13.9024 17.7647C12.1551 16.8957 10.9081 16.1165 8.78051 16.1165H7.7088C9.36547 16.1165 10.844 15.354 11.8118 14.1607C12.5503 13.2526 12.9918 12.0953 12.9918 10.8341C12.9918 7.91592 10.6267 5.55078 7.70817 5.55078C4.79092 5.55078 2.42578 7.91592 2.42578 10.8341C2.42578 12.2356 2.97158 13.5091 3.86198 14.4542C4.82471 15.4778 6.19206 16.1165 7.70786 16.1168C5.67563 16.1168 3.68889 16.9403 2.25964 18.3733C0.802593 19.8266 0 21.7628 0 23.8253V27.7227C0 29.4005 1.08939 30.8282 2.59824 31.3361V38.1156C2.59824 38.7856 3.1412 39.3285 3.81113 39.3285H11.6058C12.2758 39.3285 12.8187 38.7856 12.8187 38.1156V24.488C13.9688 25.0628 15.4795 25.8181 16.7019 26.4296C17.5588 26.8579 18.5601 26.8964 19.453 26.5332C20.3434 26.1668 21.0298 25.4375 21.3358 24.5319C21.3374 24.5272 21.339 24.5224 21.3406 24.5177Z"
                  fill="#B2CDF0"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect
                    width="41.4"
                    height="41.4"
                    fill="white"
                    transform="translate(0 0.537598)"
                  ></rect>
                </clipPath>
              </defs>
            </svg>
          </span>
          Subjects
        </Link>
      </li>
      <li>
        <Link to="/admin/students">
          <span>
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <path
                  d="M37.5892 3.125H19.4005C17.2957 3.125 15.5897 4.83126 15.5897 6.93581V15.8951C16.5512 16.373 17.59 16.8897 18.5597 17.3717L20.0433 14.4033C20.9416 12.6083 23.1314 11.879 24.9258 12.7757C26.724 13.6756 27.4527 15.8594 26.5525 17.6579L23.9078 22.9485C23.9779 23.6488 23.9157 24.3547 23.7183 25.0379H25.4644L20.8592 37.7012C20.5859 38.4523 21.1141 39.3288 21.9994 39.3288C22.4944 39.3288 22.9599 39.0234 23.1387 38.53L28.0452 25.0379H28.8952L33.8516 38.5341C34.032 39.025 34.4963 39.3288 34.9909 39.3288C35.8181 39.3288 36.4246 38.5026 36.1286 37.6981L31.4792 25.0379H37.5892C39.6937 25.0379 41.4 23.3316 41.4 21.2271V6.93581C41.4 4.83126 39.6937 3.125 37.5892 3.125V3.125ZM34.9903 19.8417H29.5484C28.8788 19.8417 28.3355 19.2985 28.3355 18.6288C28.3355 17.9586 28.8788 17.4159 29.5484 17.4159H34.9903C35.6605 17.4159 36.2032 17.9586 36.2032 18.6288C36.2032 19.2985 35.6605 19.8417 34.9903 19.8417ZM34.9903 15.2943H29.5484C28.8788 15.2943 28.3355 14.7517 28.3355 14.0814C28.3355 13.4118 28.8788 12.8686 29.5484 12.8686H34.9903C35.6605 12.8686 36.2032 13.4118 36.2032 14.0814C36.2032 14.7517 35.6605 15.2943 34.9903 15.2943ZM34.9903 10.7476H21.9994C21.3292 10.7476 20.7865 10.2043 20.7865 9.53469C20.7865 8.86507 21.3292 8.32179 21.9994 8.32179H34.9903C35.6605 8.32179 36.2032 8.86507 36.2032 9.53469C36.2032 10.2043 35.6605 10.7476 34.9903 10.7476Z"
                  fill="#B2CDF0"
                ></path>
                <path
                  d="M21.3406 24.5177C21.5598 23.8755 21.5582 23.2047 21.3731 22.5925L24.3832 16.5729C24.6826 15.9737 24.4401 15.2451 23.8409 14.9456C23.242 14.6459 22.5133 14.8888 22.2136 15.488L19.6463 20.6219C18.1119 19.8578 15.7805 18.6986 13.9024 17.7647C12.1551 16.8957 10.9081 16.1165 8.78051 16.1165H7.7088C9.36547 16.1165 10.844 15.354 11.8118 14.1607C12.5503 13.2526 12.9918 12.0953 12.9918 10.8341C12.9918 7.91592 10.6267 5.55078 7.70817 5.55078C4.79092 5.55078 2.42578 7.91592 2.42578 10.8341C2.42578 12.2356 2.97158 13.5091 3.86198 14.4542C4.82471 15.4778 6.19206 16.1165 7.70786 16.1168C5.67563 16.1168 3.68889 16.9403 2.25964 18.3733C0.802593 19.8266 0 21.7628 0 23.8253V27.7227C0 29.4005 1.08939 30.8282 2.59824 31.3361V38.1156C2.59824 38.7856 3.1412 39.3285 3.81113 39.3285H11.6058C12.2758 39.3285 12.8187 38.7856 12.8187 38.1156V24.488C13.9688 25.0628 15.4795 25.8181 16.7019 26.4296C17.5588 26.8579 18.5601 26.8964 19.453 26.5332C20.3434 26.1668 21.0298 25.4375 21.3358 24.5319C21.3374 24.5272 21.339 24.5224 21.3406 24.5177Z"
                  fill="#B2CDF0"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect
                    width="41.4"
                    height="41.4"
                    fill="white"
                    transform="translate(0 0.537598)"
                  ></rect>
                </clipPath>
              </defs>
            </svg>
          </span>
          Students
        </Link>
      </li>
      <li>
        <Link to="/admin/centers">
          <span>
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <path
                  d="M37.5892 3.125H19.4005C17.2957 3.125 15.5897 4.83126 15.5897 6.93581V15.8951C16.5512 16.373 17.59 16.8897 18.5597 17.3717L20.0433 14.4033C20.9416 12.6083 23.1314 11.879 24.9258 12.7757C26.724 13.6756 27.4527 15.8594 26.5525 17.6579L23.9078 22.9485C23.9779 23.6488 23.9157 24.3547 23.7183 25.0379H25.4644L20.8592 37.7012C20.5859 38.4523 21.1141 39.3288 21.9994 39.3288C22.4944 39.3288 22.9599 39.0234 23.1387 38.53L28.0452 25.0379H28.8952L33.8516 38.5341C34.032 39.025 34.4963 39.3288 34.9909 39.3288C35.8181 39.3288 36.4246 38.5026 36.1286 37.6981L31.4792 25.0379H37.5892C39.6937 25.0379 41.4 23.3316 41.4 21.2271V6.93581C41.4 4.83126 39.6937 3.125 37.5892 3.125V3.125ZM34.9903 19.8417H29.5484C28.8788 19.8417 28.3355 19.2985 28.3355 18.6288C28.3355 17.9586 28.8788 17.4159 29.5484 17.4159H34.9903C35.6605 17.4159 36.2032 17.9586 36.2032 18.6288C36.2032 19.2985 35.6605 19.8417 34.9903 19.8417ZM34.9903 15.2943H29.5484C28.8788 15.2943 28.3355 14.7517 28.3355 14.0814C28.3355 13.4118 28.8788 12.8686 29.5484 12.8686H34.9903C35.6605 12.8686 36.2032 13.4118 36.2032 14.0814C36.2032 14.7517 35.6605 15.2943 34.9903 15.2943ZM34.9903 10.7476H21.9994C21.3292 10.7476 20.7865 10.2043 20.7865 9.53469C20.7865 8.86507 21.3292 8.32179 21.9994 8.32179H34.9903C35.6605 8.32179 36.2032 8.86507 36.2032 9.53469C36.2032 10.2043 35.6605 10.7476 34.9903 10.7476Z"
                  fill="#B2CDF0"
                ></path>
                <path
                  d="M21.3406 24.5177C21.5598 23.8755 21.5582 23.2047 21.3731 22.5925L24.3832 16.5729C24.6826 15.9737 24.4401 15.2451 23.8409 14.9456C23.242 14.6459 22.5133 14.8888 22.2136 15.488L19.6463 20.6219C18.1119 19.8578 15.7805 18.6986 13.9024 17.7647C12.1551 16.8957 10.9081 16.1165 8.78051 16.1165H7.7088C9.36547 16.1165 10.844 15.354 11.8118 14.1607C12.5503 13.2526 12.9918 12.0953 12.9918 10.8341C12.9918 7.91592 10.6267 5.55078 7.70817 5.55078C4.79092 5.55078 2.42578 7.91592 2.42578 10.8341C2.42578 12.2356 2.97158 13.5091 3.86198 14.4542C4.82471 15.4778 6.19206 16.1165 7.70786 16.1168C5.67563 16.1168 3.68889 16.9403 2.25964 18.3733C0.802593 19.8266 0 21.7628 0 23.8253V27.7227C0 29.4005 1.08939 30.8282 2.59824 31.3361V38.1156C2.59824 38.7856 3.1412 39.3285 3.81113 39.3285H11.6058C12.2758 39.3285 12.8187 38.7856 12.8187 38.1156V24.488C13.9688 25.0628 15.4795 25.8181 16.7019 26.4296C17.5588 26.8579 18.5601 26.8964 19.453 26.5332C20.3434 26.1668 21.0298 25.4375 21.3358 24.5319C21.3374 24.5272 21.339 24.5224 21.3406 24.5177Z"
                  fill="#B2CDF0"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect
                    width="41.4"
                    height="41.4"
                    fill="white"
                    transform="translate(0 0.537598)"
                  ></rect>
                </clipPath>
              </defs>
            </svg>
          </span>
          Centers
        </Link>
      </li>
      <li>
        <Link to="/admin/view-assignment-list">
          <span>
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <path
                  d="M37.5892 3.125H19.4005C17.2957 3.125 15.5897 4.83126 15.5897 6.93581V15.8951C16.5512 16.373 17.59 16.8897 18.5597 17.3717L20.0433 14.4033C20.9416 12.6083 23.1314 11.879 24.9258 12.7757C26.724 13.6756 27.4527 15.8594 26.5525 17.6579L23.9078 22.9485C23.9779 23.6488 23.9157 24.3547 23.7183 25.0379H25.4644L20.8592 37.7012C20.5859 38.4523 21.1141 39.3288 21.9994 39.3288C22.4944 39.3288 22.9599 39.0234 23.1387 38.53L28.0452 25.0379H28.8952L33.8516 38.5341C34.032 39.025 34.4963 39.3288 34.9909 39.3288C35.8181 39.3288 36.4246 38.5026 36.1286 37.6981L31.4792 25.0379H37.5892C39.6937 25.0379 41.4 23.3316 41.4 21.2271V6.93581C41.4 4.83126 39.6937 3.125 37.5892 3.125V3.125ZM34.9903 19.8417H29.5484C28.8788 19.8417 28.3355 19.2985 28.3355 18.6288C28.3355 17.9586 28.8788 17.4159 29.5484 17.4159H34.9903C35.6605 17.4159 36.2032 17.9586 36.2032 18.6288C36.2032 19.2985 35.6605 19.8417 34.9903 19.8417ZM34.9903 15.2943H29.5484C28.8788 15.2943 28.3355 14.7517 28.3355 14.0814C28.3355 13.4118 28.8788 12.8686 29.5484 12.8686H34.9903C35.6605 12.8686 36.2032 13.4118 36.2032 14.0814C36.2032 14.7517 35.6605 15.2943 34.9903 15.2943ZM34.9903 10.7476H21.9994C21.3292 10.7476 20.7865 10.2043 20.7865 9.53469C20.7865 8.86507 21.3292 8.32179 21.9994 8.32179H34.9903C35.6605 8.32179 36.2032 8.86507 36.2032 9.53469C36.2032 10.2043 35.6605 10.7476 34.9903 10.7476Z"
                  fill="#B2CDF0"
                ></path>
                <path
                  d="M21.3406 24.5177C21.5598 23.8755 21.5582 23.2047 21.3731 22.5925L24.3832 16.5729C24.6826 15.9737 24.4401 15.2451 23.8409 14.9456C23.242 14.6459 22.5133 14.8888 22.2136 15.488L19.6463 20.6219C18.1119 19.8578 15.7805 18.6986 13.9024 17.7647C12.1551 16.8957 10.9081 16.1165 8.78051 16.1165H7.7088C9.36547 16.1165 10.844 15.354 11.8118 14.1607C12.5503 13.2526 12.9918 12.0953 12.9918 10.8341C12.9918 7.91592 10.6267 5.55078 7.70817 5.55078C4.79092 5.55078 2.42578 7.91592 2.42578 10.8341C2.42578 12.2356 2.97158 13.5091 3.86198 14.4542C4.82471 15.4778 6.19206 16.1165 7.70786 16.1168C5.67563 16.1168 3.68889 16.9403 2.25964 18.3733C0.802593 19.8266 0 21.7628 0 23.8253V27.7227C0 29.4005 1.08939 30.8282 2.59824 31.3361V38.1156C2.59824 38.7856 3.1412 39.3285 3.81113 39.3285H11.6058C12.2758 39.3285 12.8187 38.7856 12.8187 38.1156V24.488C13.9688 25.0628 15.4795 25.8181 16.7019 26.4296C17.5588 26.8579 18.5601 26.8964 19.453 26.5332C20.3434 26.1668 21.0298 25.4375 21.3358 24.5319C21.3374 24.5272 21.339 24.5224 21.3406 24.5177Z"
                  fill="#B2CDF0"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect
                    width="41.4"
                    height="41.4"
                    fill="white"
                    transform="translate(0 0.537598)"
                  ></rect>
                </clipPath>
              </defs>
            </svg>
          </span>
          Assessments
        </Link>
      </li>
      <li>
        <Link to="/admin/add-exam-schedule">
          <span>
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <path
                  d="M37.5892 3.125H19.4005C17.2957 3.125 15.5897 4.83126 15.5897 6.93581V15.8951C16.5512 16.373 17.59 16.8897 18.5597 17.3717L20.0433 14.4033C20.9416 12.6083 23.1314 11.879 24.9258 12.7757C26.724 13.6756 27.4527 15.8594 26.5525 17.6579L23.9078 22.9485C23.9779 23.6488 23.9157 24.3547 23.7183 25.0379H25.4644L20.8592 37.7012C20.5859 38.4523 21.1141 39.3288 21.9994 39.3288C22.4944 39.3288 22.9599 39.0234 23.1387 38.53L28.0452 25.0379H28.8952L33.8516 38.5341C34.032 39.025 34.4963 39.3288 34.9909 39.3288C35.8181 39.3288 36.4246 38.5026 36.1286 37.6981L31.4792 25.0379H37.5892C39.6937 25.0379 41.4 23.3316 41.4 21.2271V6.93581C41.4 4.83126 39.6937 3.125 37.5892 3.125V3.125ZM34.9903 19.8417H29.5484C28.8788 19.8417 28.3355 19.2985 28.3355 18.6288C28.3355 17.9586 28.8788 17.4159 29.5484 17.4159H34.9903C35.6605 17.4159 36.2032 17.9586 36.2032 18.6288C36.2032 19.2985 35.6605 19.8417 34.9903 19.8417ZM34.9903 15.2943H29.5484C28.8788 15.2943 28.3355 14.7517 28.3355 14.0814C28.3355 13.4118 28.8788 12.8686 29.5484 12.8686H34.9903C35.6605 12.8686 36.2032 13.4118 36.2032 14.0814C36.2032 14.7517 35.6605 15.2943 34.9903 15.2943ZM34.9903 10.7476H21.9994C21.3292 10.7476 20.7865 10.2043 20.7865 9.53469C20.7865 8.86507 21.3292 8.32179 21.9994 8.32179H34.9903C35.6605 8.32179 36.2032 8.86507 36.2032 9.53469C36.2032 10.2043 35.6605 10.7476 34.9903 10.7476Z"
                  fill="#B2CDF0"
                ></path>
                <path
                  d="M21.3406 24.5177C21.5598 23.8755 21.5582 23.2047 21.3731 22.5925L24.3832 16.5729C24.6826 15.9737 24.4401 15.2451 23.8409 14.9456C23.242 14.6459 22.5133 14.8888 22.2136 15.488L19.6463 20.6219C18.1119 19.8578 15.7805 18.6986 13.9024 17.7647C12.1551 16.8957 10.9081 16.1165 8.78051 16.1165H7.7088C9.36547 16.1165 10.844 15.354 11.8118 14.1607C12.5503 13.2526 12.9918 12.0953 12.9918 10.8341C12.9918 7.91592 10.6267 5.55078 7.70817 5.55078C4.79092 5.55078 2.42578 7.91592 2.42578 10.8341C2.42578 12.2356 2.97158 13.5091 3.86198 14.4542C4.82471 15.4778 6.19206 16.1165 7.70786 16.1168C5.67563 16.1168 3.68889 16.9403 2.25964 18.3733C0.802593 19.8266 0 21.7628 0 23.8253V27.7227C0 29.4005 1.08939 30.8282 2.59824 31.3361V38.1156C2.59824 38.7856 3.1412 39.3285 3.81113 39.3285H11.6058C12.2758 39.3285 12.8187 38.7856 12.8187 38.1156V24.488C13.9688 25.0628 15.4795 25.8181 16.7019 26.4296C17.5588 26.8579 18.5601 26.8964 19.453 26.5332C20.3434 26.1668 21.0298 25.4375 21.3358 24.5319C21.3374 24.5272 21.339 24.5224 21.3406 24.5177Z"
                  fill="#B2CDF0"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect
                    width="41.4"
                    height="41.4"
                    fill="white"
                    transform="translate(0 0.537598)"
                  ></rect>
                </clipPath>
              </defs>
            </svg>
          </span>
          Add Exam Schedule
        </Link>
      </li>
      <li>
        <Link to="/admin/add/marks">
          <span>
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <path
                  d="M37.5892 3.125H19.4005C17.2957 3.125 15.5897 4.83126 15.5897 6.93581V15.8951C16.5512 16.373 17.59 16.8897 18.5597 17.3717L20.0433 14.4033C20.9416 12.6083 23.1314 11.879 24.9258 12.7757C26.724 13.6756 27.4527 15.8594 26.5525 17.6579L23.9078 22.9485C23.9779 23.6488 23.9157 24.3547 23.7183 25.0379H25.4644L20.8592 37.7012C20.5859 38.4523 21.1141 39.3288 21.9994 39.3288C22.4944 39.3288 22.9599 39.0234 23.1387 38.53L28.0452 25.0379H28.8952L33.8516 38.5341C34.032 39.025 34.4963 39.3288 34.9909 39.3288C35.8181 39.3288 36.4246 38.5026 36.1286 37.6981L31.4792 25.0379H37.5892C39.6937 25.0379 41.4 23.3316 41.4 21.2271V6.93581C41.4 4.83126 39.6937 3.125 37.5892 3.125V3.125ZM34.9903 19.8417H29.5484C28.8788 19.8417 28.3355 19.2985 28.3355 18.6288C28.3355 17.9586 28.8788 17.4159 29.5484 17.4159H34.9903C35.6605 17.4159 36.2032 17.9586 36.2032 18.6288C36.2032 19.2985 35.6605 19.8417 34.9903 19.8417ZM34.9903 15.2943H29.5484C28.8788 15.2943 28.3355 14.7517 28.3355 14.0814C28.3355 13.4118 28.8788 12.8686 29.5484 12.8686H34.9903C35.6605 12.8686 36.2032 13.4118 36.2032 14.0814C36.2032 14.7517 35.6605 15.2943 34.9903 15.2943ZM34.9903 10.7476H21.9994C21.3292 10.7476 20.7865 10.2043 20.7865 9.53469C20.7865 8.86507 21.3292 8.32179 21.9994 8.32179H34.9903C35.6605 8.32179 36.2032 8.86507 36.2032 9.53469C36.2032 10.2043 35.6605 10.7476 34.9903 10.7476Z"
                  fill="#B2CDF0"
                ></path>
                <path
                  d="M21.3406 24.5177C21.5598 23.8755 21.5582 23.2047 21.3731 22.5925L24.3832 16.5729C24.6826 15.9737 24.4401 15.2451 23.8409 14.9456C23.242 14.6459 22.5133 14.8888 22.2136 15.488L19.6463 20.6219C18.1119 19.8578 15.7805 18.6986 13.9024 17.7647C12.1551 16.8957 10.9081 16.1165 8.78051 16.1165H7.7088C9.36547 16.1165 10.844 15.354 11.8118 14.1607C12.5503 13.2526 12.9918 12.0953 12.9918 10.8341C12.9918 7.91592 10.6267 5.55078 7.70817 5.55078C4.79092 5.55078 2.42578 7.91592 2.42578 10.8341C2.42578 12.2356 2.97158 13.5091 3.86198 14.4542C4.82471 15.4778 6.19206 16.1165 7.70786 16.1168C5.67563 16.1168 3.68889 16.9403 2.25964 18.3733C0.802593 19.8266 0 21.7628 0 23.8253V27.7227C0 29.4005 1.08939 30.8282 2.59824 31.3361V38.1156C2.59824 38.7856 3.1412 39.3285 3.81113 39.3285H11.6058C12.2758 39.3285 12.8187 38.7856 12.8187 38.1156V24.488C13.9688 25.0628 15.4795 25.8181 16.7019 26.4296C17.5588 26.8579 18.5601 26.8964 19.453 26.5332C20.3434 26.1668 21.0298 25.4375 21.3358 24.5319C21.3374 24.5272 21.339 24.5224 21.3406 24.5177Z"
                  fill="#B2CDF0"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect
                    width="41.4"
                    height="41.4"
                    fill="white"
                    transform="translate(0 0.537598)"
                  ></rect>
                </clipPath>
              </defs>
            </svg>
          </span>
          Add Marks
        </Link>
      </li>
      <li>
        <Link to="/admin/office/add" class="active">
          <span>
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M40.2832 18.5197C40.2823 18.5188 40.2813 18.5178 40.2804 18.5169L23.3925 1.62957C22.6726 0.909412 21.7156 0.512695 20.6976 0.512695C19.6796 0.512695 18.7225 0.909096 18.0024 1.62925L1.12331 18.508C1.11763 18.5137 1.11194 18.5197 1.10626 18.5254C-0.371955 20.0121 -0.369428 22.4243 1.11352 23.9073C1.79103 24.5851 2.68586 24.9777 3.64259 25.0188C3.68144 25.0226 3.7206 25.0245 3.76009 25.0245H4.43318V37.4525C4.43318 39.9117 6.43413 41.9127 8.89402 41.9127H15.5011C16.1707 41.9127 16.714 41.3697 16.714 40.6998V30.9563C16.714 29.834 17.6268 28.9212 18.7491 28.9212H22.6461C23.7684 28.9212 24.6812 29.834 24.6812 30.9563V40.6998C24.6812 41.3697 25.2241 41.9127 25.8941 41.9127H32.5012C34.9611 41.9127 36.962 39.9117 36.962 37.4525V25.0245H37.5861C38.6038 25.0245 39.5609 24.6281 40.2813 23.9079C41.7659 22.4224 41.7665 20.0061 40.2832 18.5197Z"
                fill="#B2CDF0"
              ></path>
            </svg>
          </span>
          Add Office
        </Link>
      </li>
      <li>
        <Link to="/admin/enquiry/admission">
          <span>
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <path
                  d="M37.5892 3.125H19.4005C17.2957 3.125 15.5897 4.83126 15.5897 6.93581V15.8951C16.5512 16.373 17.59 16.8897 18.5597 17.3717L20.0433 14.4033C20.9416 12.6083 23.1314 11.879 24.9258 12.7757C26.724 13.6756 27.4527 15.8594 26.5525 17.6579L23.9078 22.9485C23.9779 23.6488 23.9157 24.3547 23.7183 25.0379H25.4644L20.8592 37.7012C20.5859 38.4523 21.1141 39.3288 21.9994 39.3288C22.4944 39.3288 22.9599 39.0234 23.1387 38.53L28.0452 25.0379H28.8952L33.8516 38.5341C34.032 39.025 34.4963 39.3288 34.9909 39.3288C35.8181 39.3288 36.4246 38.5026 36.1286 37.6981L31.4792 25.0379H37.5892C39.6937 25.0379 41.4 23.3316 41.4 21.2271V6.93581C41.4 4.83126 39.6937 3.125 37.5892 3.125V3.125ZM34.9903 19.8417H29.5484C28.8788 19.8417 28.3355 19.2985 28.3355 18.6288C28.3355 17.9586 28.8788 17.4159 29.5484 17.4159H34.9903C35.6605 17.4159 36.2032 17.9586 36.2032 18.6288C36.2032 19.2985 35.6605 19.8417 34.9903 19.8417ZM34.9903 15.2943H29.5484C28.8788 15.2943 28.3355 14.7517 28.3355 14.0814C28.3355 13.4118 28.8788 12.8686 29.5484 12.8686H34.9903C35.6605 12.8686 36.2032 13.4118 36.2032 14.0814C36.2032 14.7517 35.6605 15.2943 34.9903 15.2943ZM34.9903 10.7476H21.9994C21.3292 10.7476 20.7865 10.2043 20.7865 9.53469C20.7865 8.86507 21.3292 8.32179 21.9994 8.32179H34.9903C35.6605 8.32179 36.2032 8.86507 36.2032 9.53469C36.2032 10.2043 35.6605 10.7476 34.9903 10.7476Z"
                  fill="#B2CDF0"
                ></path>
                <path
                  d="M21.3406 24.5177C21.5598 23.8755 21.5582 23.2047 21.3731 22.5925L24.3832 16.5729C24.6826 15.9737 24.4401 15.2451 23.8409 14.9456C23.242 14.6459 22.5133 14.8888 22.2136 15.488L19.6463 20.6219C18.1119 19.8578 15.7805 18.6986 13.9024 17.7647C12.1551 16.8957 10.9081 16.1165 8.78051 16.1165H7.7088C9.36547 16.1165 10.844 15.354 11.8118 14.1607C12.5503 13.2526 12.9918 12.0953 12.9918 10.8341C12.9918 7.91592 10.6267 5.55078 7.70817 5.55078C4.79092 5.55078 2.42578 7.91592 2.42578 10.8341C2.42578 12.2356 2.97158 13.5091 3.86198 14.4542C4.82471 15.4778 6.19206 16.1165 7.70786 16.1168C5.67563 16.1168 3.68889 16.9403 2.25964 18.3733C0.802593 19.8266 0 21.7628 0 23.8253V27.7227C0 29.4005 1.08939 30.8282 2.59824 31.3361V38.1156C2.59824 38.7856 3.1412 39.3285 3.81113 39.3285H11.6058C12.2758 39.3285 12.8187 38.7856 12.8187 38.1156V24.488C13.9688 25.0628 15.4795 25.8181 16.7019 26.4296C17.5588 26.8579 18.5601 26.8964 19.453 26.5332C20.3434 26.1668 21.0298 25.4375 21.3358 24.5319C21.3374 24.5272 21.339 24.5224 21.3406 24.5177Z"
                  fill="#B2CDF0"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect
                    width="41.4"
                    height="41.4"
                    fill="white"
                    transform="translate(0 0.537598)"
                  ></rect>
                </clipPath>
              </defs>
            </svg>
          </span>
          Admission Enquiry
        </Link>
      </li>
      <li>
        <Link to="/admin/enquiry/affiliation">
          <span>
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <path
                  d="M37.5892 3.125H19.4005C17.2957 3.125 15.5897 4.83126 15.5897 6.93581V15.8951C16.5512 16.373 17.59 16.8897 18.5597 17.3717L20.0433 14.4033C20.9416 12.6083 23.1314 11.879 24.9258 12.7757C26.724 13.6756 27.4527 15.8594 26.5525 17.6579L23.9078 22.9485C23.9779 23.6488 23.9157 24.3547 23.7183 25.0379H25.4644L20.8592 37.7012C20.5859 38.4523 21.1141 39.3288 21.9994 39.3288C22.4944 39.3288 22.9599 39.0234 23.1387 38.53L28.0452 25.0379H28.8952L33.8516 38.5341C34.032 39.025 34.4963 39.3288 34.9909 39.3288C35.8181 39.3288 36.4246 38.5026 36.1286 37.6981L31.4792 25.0379H37.5892C39.6937 25.0379 41.4 23.3316 41.4 21.2271V6.93581C41.4 4.83126 39.6937 3.125 37.5892 3.125V3.125ZM34.9903 19.8417H29.5484C28.8788 19.8417 28.3355 19.2985 28.3355 18.6288C28.3355 17.9586 28.8788 17.4159 29.5484 17.4159H34.9903C35.6605 17.4159 36.2032 17.9586 36.2032 18.6288C36.2032 19.2985 35.6605 19.8417 34.9903 19.8417ZM34.9903 15.2943H29.5484C28.8788 15.2943 28.3355 14.7517 28.3355 14.0814C28.3355 13.4118 28.8788 12.8686 29.5484 12.8686H34.9903C35.6605 12.8686 36.2032 13.4118 36.2032 14.0814C36.2032 14.7517 35.6605 15.2943 34.9903 15.2943ZM34.9903 10.7476H21.9994C21.3292 10.7476 20.7865 10.2043 20.7865 9.53469C20.7865 8.86507 21.3292 8.32179 21.9994 8.32179H34.9903C35.6605 8.32179 36.2032 8.86507 36.2032 9.53469C36.2032 10.2043 35.6605 10.7476 34.9903 10.7476Z"
                  fill="#B2CDF0"
                ></path>
                <path
                  d="M21.3406 24.5177C21.5598 23.8755 21.5582 23.2047 21.3731 22.5925L24.3832 16.5729C24.6826 15.9737 24.4401 15.2451 23.8409 14.9456C23.242 14.6459 22.5133 14.8888 22.2136 15.488L19.6463 20.6219C18.1119 19.8578 15.7805 18.6986 13.9024 17.7647C12.1551 16.8957 10.9081 16.1165 8.78051 16.1165H7.7088C9.36547 16.1165 10.844 15.354 11.8118 14.1607C12.5503 13.2526 12.9918 12.0953 12.9918 10.8341C12.9918 7.91592 10.6267 5.55078 7.70817 5.55078C4.79092 5.55078 2.42578 7.91592 2.42578 10.8341C2.42578 12.2356 2.97158 13.5091 3.86198 14.4542C4.82471 15.4778 6.19206 16.1165 7.70786 16.1168C5.67563 16.1168 3.68889 16.9403 2.25964 18.3733C0.802593 19.8266 0 21.7628 0 23.8253V27.7227C0 29.4005 1.08939 30.8282 2.59824 31.3361V38.1156C2.59824 38.7856 3.1412 39.3285 3.81113 39.3285H11.6058C12.2758 39.3285 12.8187 38.7856 12.8187 38.1156V24.488C13.9688 25.0628 15.4795 25.8181 16.7019 26.4296C17.5588 26.8579 18.5601 26.8964 19.453 26.5332C20.3434 26.1668 21.0298 25.4375 21.3358 24.5319C21.3374 24.5272 21.339 24.5224 21.3406 24.5177Z"
                  fill="#B2CDF0"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect
                    width="41.4"
                    height="41.4"
                    fill="white"
                    transform="translate(0 0.537598)"
                  ></rect>
                </clipPath>
              </defs>
            </svg>
          </span>
          Affiliation Enquiry
        </Link>
      </li>
      <li>
        <Link to="/admin/download/list">
          <span>
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <path
                  d="M37.5892 3.125H19.4005C17.2957 3.125 15.5897 4.83126 15.5897 6.93581V15.8951C16.5512 16.373 17.59 16.8897 18.5597 17.3717L20.0433 14.4033C20.9416 12.6083 23.1314 11.879 24.9258 12.7757C26.724 13.6756 27.4527 15.8594 26.5525 17.6579L23.9078 22.9485C23.9779 23.6488 23.9157 24.3547 23.7183 25.0379H25.4644L20.8592 37.7012C20.5859 38.4523 21.1141 39.3288 21.9994 39.3288C22.4944 39.3288 22.9599 39.0234 23.1387 38.53L28.0452 25.0379H28.8952L33.8516 38.5341C34.032 39.025 34.4963 39.3288 34.9909 39.3288C35.8181 39.3288 36.4246 38.5026 36.1286 37.6981L31.4792 25.0379H37.5892C39.6937 25.0379 41.4 23.3316 41.4 21.2271V6.93581C41.4 4.83126 39.6937 3.125 37.5892 3.125V3.125ZM34.9903 19.8417H29.5484C28.8788 19.8417 28.3355 19.2985 28.3355 18.6288C28.3355 17.9586 28.8788 17.4159 29.5484 17.4159H34.9903C35.6605 17.4159 36.2032 17.9586 36.2032 18.6288C36.2032 19.2985 35.6605 19.8417 34.9903 19.8417ZM34.9903 15.2943H29.5484C28.8788 15.2943 28.3355 14.7517 28.3355 14.0814C28.3355 13.4118 28.8788 12.8686 29.5484 12.8686H34.9903C35.6605 12.8686 36.2032 13.4118 36.2032 14.0814C36.2032 14.7517 35.6605 15.2943 34.9903 15.2943ZM34.9903 10.7476H21.9994C21.3292 10.7476 20.7865 10.2043 20.7865 9.53469C20.7865 8.86507 21.3292 8.32179 21.9994 8.32179H34.9903C35.6605 8.32179 36.2032 8.86507 36.2032 9.53469C36.2032 10.2043 35.6605 10.7476 34.9903 10.7476Z"
                  fill="#B2CDF0"
                ></path>
                <path
                  d="M21.3406 24.5177C21.5598 23.8755 21.5582 23.2047 21.3731 22.5925L24.3832 16.5729C24.6826 15.9737 24.4401 15.2451 23.8409 14.9456C23.242 14.6459 22.5133 14.8888 22.2136 15.488L19.6463 20.6219C18.1119 19.8578 15.7805 18.6986 13.9024 17.7647C12.1551 16.8957 10.9081 16.1165 8.78051 16.1165H7.7088C9.36547 16.1165 10.844 15.354 11.8118 14.1607C12.5503 13.2526 12.9918 12.0953 12.9918 10.8341C12.9918 7.91592 10.6267 5.55078 7.70817 5.55078C4.79092 5.55078 2.42578 7.91592 2.42578 10.8341C2.42578 12.2356 2.97158 13.5091 3.86198 14.4542C4.82471 15.4778 6.19206 16.1165 7.70786 16.1168C5.67563 16.1168 3.68889 16.9403 2.25964 18.3733C0.802593 19.8266 0 21.7628 0 23.8253V27.7227C0 29.4005 1.08939 30.8282 2.59824 31.3361V38.1156C2.59824 38.7856 3.1412 39.3285 3.81113 39.3285H11.6058C12.2758 39.3285 12.8187 38.7856 12.8187 38.1156V24.488C13.9688 25.0628 15.4795 25.8181 16.7019 26.4296C17.5588 26.8579 18.5601 26.8964 19.453 26.5332C20.3434 26.1668 21.0298 25.4375 21.3358 24.5319C21.3374 24.5272 21.339 24.5224 21.3406 24.5177Z"
                  fill="#B2CDF0"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect
                    width="41.4"
                    height="41.4"
                    fill="white"
                    transform="translate(0 0.537598)"
                  ></rect>
                </clipPath>
              </defs>
            </svg>
          </span>
          Downloads
        </Link>
      </li>
      <li>
        <Link to="/admin/email/list">
          <span>
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <path
                  d="M37.5892 3.125H19.4005C17.2957 3.125 15.5897 4.83126 15.5897 6.93581V15.8951C16.5512 16.373 17.59 16.8897 18.5597 17.3717L20.0433 14.4033C20.9416 12.6083 23.1314 11.879 24.9258 12.7757C26.724 13.6756 27.4527 15.8594 26.5525 17.6579L23.9078 22.9485C23.9779 23.6488 23.9157 24.3547 23.7183 25.0379H25.4644L20.8592 37.7012C20.5859 38.4523 21.1141 39.3288 21.9994 39.3288C22.4944 39.3288 22.9599 39.0234 23.1387 38.53L28.0452 25.0379H28.8952L33.8516 38.5341C34.032 39.025 34.4963 39.3288 34.9909 39.3288C35.8181 39.3288 36.4246 38.5026 36.1286 37.6981L31.4792 25.0379H37.5892C39.6937 25.0379 41.4 23.3316 41.4 21.2271V6.93581C41.4 4.83126 39.6937 3.125 37.5892 3.125V3.125ZM34.9903 19.8417H29.5484C28.8788 19.8417 28.3355 19.2985 28.3355 18.6288C28.3355 17.9586 28.8788 17.4159 29.5484 17.4159H34.9903C35.6605 17.4159 36.2032 17.9586 36.2032 18.6288C36.2032 19.2985 35.6605 19.8417 34.9903 19.8417ZM34.9903 15.2943H29.5484C28.8788 15.2943 28.3355 14.7517 28.3355 14.0814C28.3355 13.4118 28.8788 12.8686 29.5484 12.8686H34.9903C35.6605 12.8686 36.2032 13.4118 36.2032 14.0814C36.2032 14.7517 35.6605 15.2943 34.9903 15.2943ZM34.9903 10.7476H21.9994C21.3292 10.7476 20.7865 10.2043 20.7865 9.53469C20.7865 8.86507 21.3292 8.32179 21.9994 8.32179H34.9903C35.6605 8.32179 36.2032 8.86507 36.2032 9.53469C36.2032 10.2043 35.6605 10.7476 34.9903 10.7476Z"
                  fill="#B2CDF0"
                ></path>
                <path
                  d="M21.3406 24.5177C21.5598 23.8755 21.5582 23.2047 21.3731 22.5925L24.3832 16.5729C24.6826 15.9737 24.4401 15.2451 23.8409 14.9456C23.242 14.6459 22.5133 14.8888 22.2136 15.488L19.6463 20.6219C18.1119 19.8578 15.7805 18.6986 13.9024 17.7647C12.1551 16.8957 10.9081 16.1165 8.78051 16.1165H7.7088C9.36547 16.1165 10.844 15.354 11.8118 14.1607C12.5503 13.2526 12.9918 12.0953 12.9918 10.8341C12.9918 7.91592 10.6267 5.55078 7.70817 5.55078C4.79092 5.55078 2.42578 7.91592 2.42578 10.8341C2.42578 12.2356 2.97158 13.5091 3.86198 14.4542C4.82471 15.4778 6.19206 16.1165 7.70786 16.1168C5.67563 16.1168 3.68889 16.9403 2.25964 18.3733C0.802593 19.8266 0 21.7628 0 23.8253V27.7227C0 29.4005 1.08939 30.8282 2.59824 31.3361V38.1156C2.59824 38.7856 3.1412 39.3285 3.81113 39.3285H11.6058C12.2758 39.3285 12.8187 38.7856 12.8187 38.1156V24.488C13.9688 25.0628 15.4795 25.8181 16.7019 26.4296C17.5588 26.8579 18.5601 26.8964 19.453 26.5332C20.3434 26.1668 21.0298 25.4375 21.3358 24.5319C21.3374 24.5272 21.339 24.5224 21.3406 24.5177Z"
                  fill="#B2CDF0"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect
                    width="41.4"
                    height="41.4"
                    fill="white"
                    transform="translate(0 0.537598)"
                  ></rect>
                </clipPath>
              </defs>
            </svg>
          </span>
          Email List
        </Link>
      </li>
      <li>
        <Link to="/admin/contact/list">
          <span>
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <path
                  d="M37.5892 3.125H19.4005C17.2957 3.125 15.5897 4.83126 15.5897 6.93581V15.8951C16.5512 16.373 17.59 16.8897 18.5597 17.3717L20.0433 14.4033C20.9416 12.6083 23.1314 11.879 24.9258 12.7757C26.724 13.6756 27.4527 15.8594 26.5525 17.6579L23.9078 22.9485C23.9779 23.6488 23.9157 24.3547 23.7183 25.0379H25.4644L20.8592 37.7012C20.5859 38.4523 21.1141 39.3288 21.9994 39.3288C22.4944 39.3288 22.9599 39.0234 23.1387 38.53L28.0452 25.0379H28.8952L33.8516 38.5341C34.032 39.025 34.4963 39.3288 34.9909 39.3288C35.8181 39.3288 36.4246 38.5026 36.1286 37.6981L31.4792 25.0379H37.5892C39.6937 25.0379 41.4 23.3316 41.4 21.2271V6.93581C41.4 4.83126 39.6937 3.125 37.5892 3.125V3.125ZM34.9903 19.8417H29.5484C28.8788 19.8417 28.3355 19.2985 28.3355 18.6288C28.3355 17.9586 28.8788 17.4159 29.5484 17.4159H34.9903C35.6605 17.4159 36.2032 17.9586 36.2032 18.6288C36.2032 19.2985 35.6605 19.8417 34.9903 19.8417ZM34.9903 15.2943H29.5484C28.8788 15.2943 28.3355 14.7517 28.3355 14.0814C28.3355 13.4118 28.8788 12.8686 29.5484 12.8686H34.9903C35.6605 12.8686 36.2032 13.4118 36.2032 14.0814C36.2032 14.7517 35.6605 15.2943 34.9903 15.2943ZM34.9903 10.7476H21.9994C21.3292 10.7476 20.7865 10.2043 20.7865 9.53469C20.7865 8.86507 21.3292 8.32179 21.9994 8.32179H34.9903C35.6605 8.32179 36.2032 8.86507 36.2032 9.53469C36.2032 10.2043 35.6605 10.7476 34.9903 10.7476Z"
                  fill="#B2CDF0"
                ></path>
                <path
                  d="M21.3406 24.5177C21.5598 23.8755 21.5582 23.2047 21.3731 22.5925L24.3832 16.5729C24.6826 15.9737 24.4401 15.2451 23.8409 14.9456C23.242 14.6459 22.5133 14.8888 22.2136 15.488L19.6463 20.6219C18.1119 19.8578 15.7805 18.6986 13.9024 17.7647C12.1551 16.8957 10.9081 16.1165 8.78051 16.1165H7.7088C9.36547 16.1165 10.844 15.354 11.8118 14.1607C12.5503 13.2526 12.9918 12.0953 12.9918 10.8341C12.9918 7.91592 10.6267 5.55078 7.70817 5.55078C4.79092 5.55078 2.42578 7.91592 2.42578 10.8341C2.42578 12.2356 2.97158 13.5091 3.86198 14.4542C4.82471 15.4778 6.19206 16.1165 7.70786 16.1168C5.67563 16.1168 3.68889 16.9403 2.25964 18.3733C0.802593 19.8266 0 21.7628 0 23.8253V27.7227C0 29.4005 1.08939 30.8282 2.59824 31.3361V38.1156C2.59824 38.7856 3.1412 39.3285 3.81113 39.3285H11.6058C12.2758 39.3285 12.8187 38.7856 12.8187 38.1156V24.488C13.9688 25.0628 15.4795 25.8181 16.7019 26.4296C17.5588 26.8579 18.5601 26.8964 19.453 26.5332C20.3434 26.1668 21.0298 25.4375 21.3358 24.5319C21.3374 24.5272 21.339 24.5224 21.3406 24.5177Z"
                  fill="#B2CDF0"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect
                    width="41.4"
                    height="41.4"
                    fill="white"
                    transform="translate(0 0.537598)"
                  ></rect>
                </clipPath>
              </defs>
            </svg>
          </span>
          Enquiry Numbers
        </Link>
      </li>
      <li>
        <Link to="/admin/rti/add">
          <span>
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <path
                  d="M37.5892 3.125H19.4005C17.2957 3.125 15.5897 4.83126 15.5897 6.93581V15.8951C16.5512 16.373 17.59 16.8897 18.5597 17.3717L20.0433 14.4033C20.9416 12.6083 23.1314 11.879 24.9258 12.7757C26.724 13.6756 27.4527 15.8594 26.5525 17.6579L23.9078 22.9485C23.9779 23.6488 23.9157 24.3547 23.7183 25.0379H25.4644L20.8592 37.7012C20.5859 38.4523 21.1141 39.3288 21.9994 39.3288C22.4944 39.3288 22.9599 39.0234 23.1387 38.53L28.0452 25.0379H28.8952L33.8516 38.5341C34.032 39.025 34.4963 39.3288 34.9909 39.3288C35.8181 39.3288 36.4246 38.5026 36.1286 37.6981L31.4792 25.0379H37.5892C39.6937 25.0379 41.4 23.3316 41.4 21.2271V6.93581C41.4 4.83126 39.6937 3.125 37.5892 3.125V3.125ZM34.9903 19.8417H29.5484C28.8788 19.8417 28.3355 19.2985 28.3355 18.6288C28.3355 17.9586 28.8788 17.4159 29.5484 17.4159H34.9903C35.6605 17.4159 36.2032 17.9586 36.2032 18.6288C36.2032 19.2985 35.6605 19.8417 34.9903 19.8417ZM34.9903 15.2943H29.5484C28.8788 15.2943 28.3355 14.7517 28.3355 14.0814C28.3355 13.4118 28.8788 12.8686 29.5484 12.8686H34.9903C35.6605 12.8686 36.2032 13.4118 36.2032 14.0814C36.2032 14.7517 35.6605 15.2943 34.9903 15.2943ZM34.9903 10.7476H21.9994C21.3292 10.7476 20.7865 10.2043 20.7865 9.53469C20.7865 8.86507 21.3292 8.32179 21.9994 8.32179H34.9903C35.6605 8.32179 36.2032 8.86507 36.2032 9.53469C36.2032 10.2043 35.6605 10.7476 34.9903 10.7476Z"
                  fill="#B2CDF0"
                ></path>
                <path
                  d="M21.3406 24.5177C21.5598 23.8755 21.5582 23.2047 21.3731 22.5925L24.3832 16.5729C24.6826 15.9737 24.4401 15.2451 23.8409 14.9456C23.242 14.6459 22.5133 14.8888 22.2136 15.488L19.6463 20.6219C18.1119 19.8578 15.7805 18.6986 13.9024 17.7647C12.1551 16.8957 10.9081 16.1165 8.78051 16.1165H7.7088C9.36547 16.1165 10.844 15.354 11.8118 14.1607C12.5503 13.2526 12.9918 12.0953 12.9918 10.8341C12.9918 7.91592 10.6267 5.55078 7.70817 5.55078C4.79092 5.55078 2.42578 7.91592 2.42578 10.8341C2.42578 12.2356 2.97158 13.5091 3.86198 14.4542C4.82471 15.4778 6.19206 16.1165 7.70786 16.1168C5.67563 16.1168 3.68889 16.9403 2.25964 18.3733C0.802593 19.8266 0 21.7628 0 23.8253V27.7227C0 29.4005 1.08939 30.8282 2.59824 31.3361V38.1156C2.59824 38.7856 3.1412 39.3285 3.81113 39.3285H11.6058C12.2758 39.3285 12.8187 38.7856 12.8187 38.1156V24.488C13.9688 25.0628 15.4795 25.8181 16.7019 26.4296C17.5588 26.8579 18.5601 26.8964 19.453 26.5332C20.3434 26.1668 21.0298 25.4375 21.3358 24.5319C21.3374 24.5272 21.339 24.5224 21.3406 24.5177Z"
                  fill="#B2CDF0"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect
                    width="41.4"
                    height="41.4"
                    fill="white"
                    transform="translate(0 0.537598)"
                  ></rect>
                </clipPath>
              </defs>
            </svg>
          </span>
          Add RTI
        </Link>
      </li>
      <li>
        <Link to="/admin/rti/list/department">
          <span>
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <path
                  d="M37.5892 3.125H19.4005C17.2957 3.125 15.5897 4.83126 15.5897 6.93581V15.8951C16.5512 16.373 17.59 16.8897 18.5597 17.3717L20.0433 14.4033C20.9416 12.6083 23.1314 11.879 24.9258 12.7757C26.724 13.6756 27.4527 15.8594 26.5525 17.6579L23.9078 22.9485C23.9779 23.6488 23.9157 24.3547 23.7183 25.0379H25.4644L20.8592 37.7012C20.5859 38.4523 21.1141 39.3288 21.9994 39.3288C22.4944 39.3288 22.9599 39.0234 23.1387 38.53L28.0452 25.0379H28.8952L33.8516 38.5341C34.032 39.025 34.4963 39.3288 34.9909 39.3288C35.8181 39.3288 36.4246 38.5026 36.1286 37.6981L31.4792 25.0379H37.5892C39.6937 25.0379 41.4 23.3316 41.4 21.2271V6.93581C41.4 4.83126 39.6937 3.125 37.5892 3.125V3.125ZM34.9903 19.8417H29.5484C28.8788 19.8417 28.3355 19.2985 28.3355 18.6288C28.3355 17.9586 28.8788 17.4159 29.5484 17.4159H34.9903C35.6605 17.4159 36.2032 17.9586 36.2032 18.6288C36.2032 19.2985 35.6605 19.8417 34.9903 19.8417ZM34.9903 15.2943H29.5484C28.8788 15.2943 28.3355 14.7517 28.3355 14.0814C28.3355 13.4118 28.8788 12.8686 29.5484 12.8686H34.9903C35.6605 12.8686 36.2032 13.4118 36.2032 14.0814C36.2032 14.7517 35.6605 15.2943 34.9903 15.2943ZM34.9903 10.7476H21.9994C21.3292 10.7476 20.7865 10.2043 20.7865 9.53469C20.7865 8.86507 21.3292 8.32179 21.9994 8.32179H34.9903C35.6605 8.32179 36.2032 8.86507 36.2032 9.53469C36.2032 10.2043 35.6605 10.7476 34.9903 10.7476Z"
                  fill="#B2CDF0"
                ></path>
                <path
                  d="M21.3406 24.5177C21.5598 23.8755 21.5582 23.2047 21.3731 22.5925L24.3832 16.5729C24.6826 15.9737 24.4401 15.2451 23.8409 14.9456C23.242 14.6459 22.5133 14.8888 22.2136 15.488L19.6463 20.6219C18.1119 19.8578 15.7805 18.6986 13.9024 17.7647C12.1551 16.8957 10.9081 16.1165 8.78051 16.1165H7.7088C9.36547 16.1165 10.844 15.354 11.8118 14.1607C12.5503 13.2526 12.9918 12.0953 12.9918 10.8341C12.9918 7.91592 10.6267 5.55078 7.70817 5.55078C4.79092 5.55078 2.42578 7.91592 2.42578 10.8341C2.42578 12.2356 2.97158 13.5091 3.86198 14.4542C4.82471 15.4778 6.19206 16.1165 7.70786 16.1168C5.67563 16.1168 3.68889 16.9403 2.25964 18.3733C0.802593 19.8266 0 21.7628 0 23.8253V27.7227C0 29.4005 1.08939 30.8282 2.59824 31.3361V38.1156C2.59824 38.7856 3.1412 39.3285 3.81113 39.3285H11.6058C12.2758 39.3285 12.8187 38.7856 12.8187 38.1156V24.488C13.9688 25.0628 15.4795 25.8181 16.7019 26.4296C17.5588 26.8579 18.5601 26.8964 19.453 26.5332C20.3434 26.1668 21.0298 25.4375 21.3358 24.5319C21.3374 24.5272 21.339 24.5224 21.3406 24.5177Z"
                  fill="#B2CDF0"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect
                    width="41.4"
                    height="41.4"
                    fill="white"
                    transform="translate(0 0.537598)"
                  ></rect>
                </clipPath>
              </defs>
            </svg>
          </span>
          Department RTI List
        </Link>
      </li>
      <li>
        <Link to="/admin/rti/list/minister">
          <span>
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <path
                  d="M37.5892 3.125H19.4005C17.2957 3.125 15.5897 4.83126 15.5897 6.93581V15.8951C16.5512 16.373 17.59 16.8897 18.5597 17.3717L20.0433 14.4033C20.9416 12.6083 23.1314 11.879 24.9258 12.7757C26.724 13.6756 27.4527 15.8594 26.5525 17.6579L23.9078 22.9485C23.9779 23.6488 23.9157 24.3547 23.7183 25.0379H25.4644L20.8592 37.7012C20.5859 38.4523 21.1141 39.3288 21.9994 39.3288C22.4944 39.3288 22.9599 39.0234 23.1387 38.53L28.0452 25.0379H28.8952L33.8516 38.5341C34.032 39.025 34.4963 39.3288 34.9909 39.3288C35.8181 39.3288 36.4246 38.5026 36.1286 37.6981L31.4792 25.0379H37.5892C39.6937 25.0379 41.4 23.3316 41.4 21.2271V6.93581C41.4 4.83126 39.6937 3.125 37.5892 3.125V3.125ZM34.9903 19.8417H29.5484C28.8788 19.8417 28.3355 19.2985 28.3355 18.6288C28.3355 17.9586 28.8788 17.4159 29.5484 17.4159H34.9903C35.6605 17.4159 36.2032 17.9586 36.2032 18.6288C36.2032 19.2985 35.6605 19.8417 34.9903 19.8417ZM34.9903 15.2943H29.5484C28.8788 15.2943 28.3355 14.7517 28.3355 14.0814C28.3355 13.4118 28.8788 12.8686 29.5484 12.8686H34.9903C35.6605 12.8686 36.2032 13.4118 36.2032 14.0814C36.2032 14.7517 35.6605 15.2943 34.9903 15.2943ZM34.9903 10.7476H21.9994C21.3292 10.7476 20.7865 10.2043 20.7865 9.53469C20.7865 8.86507 21.3292 8.32179 21.9994 8.32179H34.9903C35.6605 8.32179 36.2032 8.86507 36.2032 9.53469C36.2032 10.2043 35.6605 10.7476 34.9903 10.7476Z"
                  fill="#B2CDF0"
                ></path>
                <path
                  d="M21.3406 24.5177C21.5598 23.8755 21.5582 23.2047 21.3731 22.5925L24.3832 16.5729C24.6826 15.9737 24.4401 15.2451 23.8409 14.9456C23.242 14.6459 22.5133 14.8888 22.2136 15.488L19.6463 20.6219C18.1119 19.8578 15.7805 18.6986 13.9024 17.7647C12.1551 16.8957 10.9081 16.1165 8.78051 16.1165H7.7088C9.36547 16.1165 10.844 15.354 11.8118 14.1607C12.5503 13.2526 12.9918 12.0953 12.9918 10.8341C12.9918 7.91592 10.6267 5.55078 7.70817 5.55078C4.79092 5.55078 2.42578 7.91592 2.42578 10.8341C2.42578 12.2356 2.97158 13.5091 3.86198 14.4542C4.82471 15.4778 6.19206 16.1165 7.70786 16.1168C5.67563 16.1168 3.68889 16.9403 2.25964 18.3733C0.802593 19.8266 0 21.7628 0 23.8253V27.7227C0 29.4005 1.08939 30.8282 2.59824 31.3361V38.1156C2.59824 38.7856 3.1412 39.3285 3.81113 39.3285H11.6058C12.2758 39.3285 12.8187 38.7856 12.8187 38.1156V24.488C13.9688 25.0628 15.4795 25.8181 16.7019 26.4296C17.5588 26.8579 18.5601 26.8964 19.453 26.5332C20.3434 26.1668 21.0298 25.4375 21.3358 24.5319C21.3374 24.5272 21.339 24.5224 21.3406 24.5177Z"
                  fill="#B2CDF0"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect
                    width="41.4"
                    height="41.4"
                    fill="white"
                    transform="translate(0 0.537598)"
                  ></rect>
                </clipPath>
              </defs>
            </svg>
          </span>
          Minister RTI List
        </Link>
      </li>
      <li>
        <Link to="/admin/add-student-fees">
          <span>
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <path
                  d="M37.5892 3.125H19.4005C17.2957 3.125 15.5897 4.83126 15.5897 6.93581V15.8951C16.5512 16.373 17.59 16.8897 18.5597 17.3717L20.0433 14.4033C20.9416 12.6083 23.1314 11.879 24.9258 12.7757C26.724 13.6756 27.4527 15.8594 26.5525 17.6579L23.9078 22.9485C23.9779 23.6488 23.9157 24.3547 23.7183 25.0379H25.4644L20.8592 37.7012C20.5859 38.4523 21.1141 39.3288 21.9994 39.3288C22.4944 39.3288 22.9599 39.0234 23.1387 38.53L28.0452 25.0379H28.8952L33.8516 38.5341C34.032 39.025 34.4963 39.3288 34.9909 39.3288C35.8181 39.3288 36.4246 38.5026 36.1286 37.6981L31.4792 25.0379H37.5892C39.6937 25.0379 41.4 23.3316 41.4 21.2271V6.93581C41.4 4.83126 39.6937 3.125 37.5892 3.125V3.125ZM34.9903 19.8417H29.5484C28.8788 19.8417 28.3355 19.2985 28.3355 18.6288C28.3355 17.9586 28.8788 17.4159 29.5484 17.4159H34.9903C35.6605 17.4159 36.2032 17.9586 36.2032 18.6288C36.2032 19.2985 35.6605 19.8417 34.9903 19.8417ZM34.9903 15.2943H29.5484C28.8788 15.2943 28.3355 14.7517 28.3355 14.0814C28.3355 13.4118 28.8788 12.8686 29.5484 12.8686H34.9903C35.6605 12.8686 36.2032 13.4118 36.2032 14.0814C36.2032 14.7517 35.6605 15.2943 34.9903 15.2943ZM34.9903 10.7476H21.9994C21.3292 10.7476 20.7865 10.2043 20.7865 9.53469C20.7865 8.86507 21.3292 8.32179 21.9994 8.32179H34.9903C35.6605 8.32179 36.2032 8.86507 36.2032 9.53469C36.2032 10.2043 35.6605 10.7476 34.9903 10.7476Z"
                  fill="#B2CDF0"
                ></path>
                <path
                  d="M21.3406 24.5177C21.5598 23.8755 21.5582 23.2047 21.3731 22.5925L24.3832 16.5729C24.6826 15.9737 24.4401 15.2451 23.8409 14.9456C23.242 14.6459 22.5133 14.8888 22.2136 15.488L19.6463 20.6219C18.1119 19.8578 15.7805 18.6986 13.9024 17.7647C12.1551 16.8957 10.9081 16.1165 8.78051 16.1165H7.7088C9.36547 16.1165 10.844 15.354 11.8118 14.1607C12.5503 13.2526 12.9918 12.0953 12.9918 10.8341C12.9918 7.91592 10.6267 5.55078 7.70817 5.55078C4.79092 5.55078 2.42578 7.91592 2.42578 10.8341C2.42578 12.2356 2.97158 13.5091 3.86198 14.4542C4.82471 15.4778 6.19206 16.1165 7.70786 16.1168C5.67563 16.1168 3.68889 16.9403 2.25964 18.3733C0.802593 19.8266 0 21.7628 0 23.8253V27.7227C0 29.4005 1.08939 30.8282 2.59824 31.3361V38.1156C2.59824 38.7856 3.1412 39.3285 3.81113 39.3285H11.6058C12.2758 39.3285 12.8187 38.7856 12.8187 38.1156V24.488C13.9688 25.0628 15.4795 25.8181 16.7019 26.4296C17.5588 26.8579 18.5601 26.8964 19.453 26.5332C20.3434 26.1668 21.0298 25.4375 21.3358 24.5319C21.3374 24.5272 21.339 24.5224 21.3406 24.5177Z"
                  fill="#B2CDF0"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect
                    width="41.4"
                    height="41.4"
                    fill="white"
                    transform="translate(0 0.537598)"
                  ></rect>
                </clipPath>
              </defs>
            </svg>
          </span>
          Add Student Fees
        </Link>
      </li>
    </>
  );
};

export default function AdminLayout({ children }) {
  const [user_type, setuser_type] = useState("");
  const [expand, setExpand] = useState(false);
  const { admin } = useSelector((state) => state);
  const { adminAuth } = admin;
  const { profile } = adminAuth;
  const dispatch = useDispatch();
  const handleLogout = () => {
    localStorage.removeItem("adminToken");
    dispatch({ type: AUTH_FAIL });
  };
  useEffect(() => {
    if (profile) {
      setuser_type(profile.admin.user_type);
    }
  }, [profile]);

  useEffect(() => {
    if (expand) {
      setExpand(false);
    }
  }, [window.location.pathname]);
  return (
    <div>
      <nav class="navbar navbar-dashboard fixed-top student-layout">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-9 col-9">
              <div class="menu">
                <div class="logo-dashboard">
                  <button class="barsicon" onClick={() => setExpand(!expand)}>
                    {expand ? (
                      <i class="fa fa-times" aria-hidden="true"></i>
                    ) : (
                      <i class="fa fa-bars"></i>
                    )}
                  </button>
                  <Link class="navbar-logo active" to="/">
                    <img src="/images/logo.png" />
                  </Link>
                  <div class="college-name m-auto">
                    <h1>Welcome : {user_type === 0 ? "Council" : "Admin"} </h1>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-3" style={{ paddingTop: "16px" }}>
              <Button variant="primary float-right" onClick={handleLogout}>
                Logout
              </Button>
            </div>
            {/* <div class="col-md-4 col-4">
              <div class="navbar-collapse" id="adm24">
                <ul class="navbar-autho">
                  <div class="active-bar">
                    <div class="notification-bar">
                      <div class="user-account">
                        K
                        <div class="hoverlist">
                          <a href="#">Logout</a>
                        </div>
                      </div>
                      <div class="bar-box">
                        <svg
                          width="20"
                          height="16"
                          viewBox="0 0 20 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.0127 1.03101H19.3127"
                            stroke="white"
                            stroke-width="1.297"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M1.0127 8.03101H19.3127"
                            stroke="white"
                            stroke-width="1.297"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M1.0127 15.031H19.3127"
                            stroke="white"
                            stroke-width="1.297"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </nav>
      <div class="content-box">
        <div class={`sideBar ${expand && "d-block overflow-scroll"}`}>
          <div class="side-nav">
            <ul>
              {adminAuth?.profile?.admin.user_type == "1" && <AdminNav />}
              {adminAuth?.profile?.admin.user_type == "0" && <CouncilNav />}
              <li>
                <a onClick={handleLogout} style={{ color: "#FFF" }}>
                  <span>
                    <svg
                      width="42"
                      height="42"
                      viewBox="0 0 1024 1024"
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon"
                    >
                      <path d="M868 732h-70.3c-4.8 0-9.3 2.1-12.3 5.8-7 8.5-14.5 16.7-22.4 24.5a353.84 353.84 0 0 1-112.7 75.9A352.8 352.8 0 0 1 512.4 866c-47.9 0-94.3-9.4-137.9-27.8a353.84 353.84 0 0 1-112.7-75.9 353.28 353.28 0 0 1-76-112.5C167.3 606.2 158 559.9 158 512s9.4-94.2 27.8-137.8c17.8-42.1 43.4-80 76-112.5s70.5-58.1 112.7-75.9c43.6-18.4 90-27.8 137.9-27.8 47.9 0 94.3 9.3 137.9 27.8 42.2 17.8 80.1 43.4 112.7 75.9 7.9 7.9 15.3 16.1 22.4 24.5 3 3.7 7.6 5.8 12.3 5.8H868c6.3 0 10.2-7 6.7-12.3C798 160.5 663.8 81.6 511.3 82 271.7 82.6 79.6 277.1 82 516.4 84.4 751.9 276.2 942 512.4 942c152.1 0 285.7-78.8 362.3-197.7 3.4-5.3-.4-12.3-6.7-12.3zm88.9-226.3L815 393.7c-5.3-4.2-13-.4-13 6.3v76H488c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h314v76c0 6.7 7.8 10.5 13 6.3l141.9-112a8 8 0 0 0 0-12.6z" />
                    </svg>
                  </span>
                  Logout
                </a>
              </li>
            </ul>
            {/* <ul>
              <li>
                <Link to="/admin/dashboard" class="active">
                  <span>
                    <svg
                      width="42"
                      height="42"
                      viewBox="0 0 42 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M40.2832 18.5197C40.2823 18.5188 40.2813 18.5178 40.2804 18.5169L23.3925 1.62957C22.6726 0.909412 21.7156 0.512695 20.6976 0.512695C19.6796 0.512695 18.7225 0.909096 18.0024 1.62925L1.12331 18.508C1.11763 18.5137 1.11194 18.5197 1.10626 18.5254C-0.371955 20.0121 -0.369428 22.4243 1.11352 23.9073C1.79103 24.5851 2.68586 24.9777 3.64259 25.0188C3.68144 25.0226 3.7206 25.0245 3.76009 25.0245H4.43318V37.4525C4.43318 39.9117 6.43413 41.9127 8.89402 41.9127H15.5011C16.1707 41.9127 16.714 41.3697 16.714 40.6998V30.9563C16.714 29.834 17.6268 28.9212 18.7491 28.9212H22.6461C23.7684 28.9212 24.6812 29.834 24.6812 30.9563V40.6998C24.6812 41.3697 25.2241 41.9127 25.8941 41.9127H32.5012C34.9611 41.9127 36.962 39.9117 36.962 37.4525V25.0245H37.5861C38.6038 25.0245 39.5609 24.6281 40.2813 23.9079C41.7659 22.4224 41.7665 20.0061 40.2832 18.5197Z"
                        fill="#B2CDF0"
                      ></path>
                    </svg>
                  </span>
                  Dashboard
                </Link>
              </li>

              <li>
                <Link to="/admin/view-trade">
                  <span>
                    <svg
                      width="42"
                      height="42"
                      viewBox="0 0 42 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0)">
                        <path
                          d="M16.474 7.25184C18.3557 7.25184 19.8812 5.7264 19.8812 3.84467C19.8812 1.96294 18.3557 0.4375 16.474 0.4375C14.5923 0.4375 13.0668 1.96294 13.0668 3.84467C13.0668 5.7264 14.5923 7.25184 16.474 7.25184Z"
                          fill="#B2CDF0"
                        ></path>
                        <path
                          d="M39.513 8.36882C39.4033 8.21616 39.1907 8.18131 39.038 8.29087L29.6593 15.0265L24.3666 15.2135C23.8397 13.7386 23.4227 12.5548 23.1236 11.6844C22.3609 9.46471 21.0818 8.33923 19.3222 8.33923C16.8527 8.33923 16.0743 8.33923 13.6256 8.33923C11.866 8.33923 10.5869 9.46471 9.82418 11.6844C9.49257 12.6493 9.25646 13.3267 8.93343 14.2336L6.81095 13.1533L5.34004 7.75316C5.31303 7.65427 5.23743 7.576 5.13943 7.54567L4.25733 7.27302L3.64466 5.02359C3.61507 4.91516 3.52725 4.83227 3.41728 4.80915C3.30732 4.78602 3.19355 4.82645 3.12279 4.91386L1.8233 6.5207L3.26131 11.8005C2.81609 11.9121 2.41924 12.207 2.19461 12.6481C1.78271 13.4573 2.10469 14.4473 2.91385 14.8592L9.10453 18.0108C9.98598 18.4595 11.0621 18.0331 11.3972 17.1028C11.7837 16.0302 12.284 14.6226 12.284 14.6226C12.284 14.6226 10.4701 24.1662 9.95056 26.851C9.84108 27.4165 10.2769 27.9391 10.8472 27.9391C11.2013 27.9391 11.6262 27.9391 12.1018 27.9391V39.8652C12.1018 40.9548 12.9851 41.8381 14.0748 41.8381C15.1645 41.8381 16.0478 40.9548 16.0478 39.8652V27.9392C16.3313 27.9392 16.616 27.9392 16.8995 27.9392V39.8653C16.8995 40.955 17.7828 41.8383 18.8725 41.8383C19.9622 41.8383 20.8455 40.955 20.8455 39.8653V27.9392C21.3212 27.9392 21.746 27.9392 22.1001 27.9392C22.6734 27.9392 23.1062 27.4161 22.9967 26.8512C22.0263 21.8291 20.6615 14.6082 20.6615 14.6082C20.6615 14.6082 21.2898 16.3783 21.6763 17.4548C21.9169 18.1251 22.5634 18.5674 23.2818 18.5424L29.8661 18.3097C30.7736 18.2776 31.4833 17.516 31.4512 16.6085C31.4408 16.3159 31.354 16.0445 31.2118 15.8112L39.4566 8.82721C39.5899 8.71344 39.617 8.51372 39.513 8.36882Z"
                          fill="#B2CDF0"
                        ></path>
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect
                            width="41.4"
                            height="41.4"
                            fill="white"
                            transform="translate(0 0.4375)"
                          ></rect>
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  Trade
                </Link>
              </li>
              <li>
                <Link to="/admin/view-sub-trade">
                  <span>
                    <svg
                      width="42"
                      height="43"
                      viewBox="0 0 42 43"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.7 0.987305C9.315 0.987305 0 10.3023 0 21.6873C0 33.0723 9.315 42.3873 20.7 42.3873C32.085 42.3873 41.4 33.0723 41.4 21.6873C41.4 10.3023 32.085 0.987305 20.7 0.987305ZM20.7 7.19731C24.219 7.19731 26.91 9.88831 26.91 13.4073C26.91 16.9263 24.219 19.6173 20.7 19.6173C17.181 19.6173 14.49 16.9263 14.49 13.4073C14.49 9.88831 17.181 7.19731 20.7 7.19731ZM20.7 36.5913C15.525 36.5913 10.971 33.9004 8.28 29.9673C8.28 25.8273 16.56 23.5503 20.7 23.5503C24.84 23.5503 33.12 25.8273 33.12 29.9673C30.429 33.9003 25.875 36.5913 20.7 36.5913Z"
                        fill="#B2CDF0"
                      ></path>
                    </svg>
                  </span>
                  Subtrade
                </Link>
              </li>
              <li>
                <Link to="/admin/courses">
                  <span>
                    <svg
                      width="42"
                      height="42"
                      viewBox="0 0 42 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0)">
                        <path
                          d="M37.5892 3.125H19.4005C17.2957 3.125 15.5897 4.83126 15.5897 6.93581V15.8951C16.5512 16.373 17.59 16.8897 18.5597 17.3717L20.0433 14.4033C20.9416 12.6083 23.1314 11.879 24.9258 12.7757C26.724 13.6756 27.4527 15.8594 26.5525 17.6579L23.9078 22.9485C23.9779 23.6488 23.9157 24.3547 23.7183 25.0379H25.4644L20.8592 37.7012C20.5859 38.4523 21.1141 39.3288 21.9994 39.3288C22.4944 39.3288 22.9599 39.0234 23.1387 38.53L28.0452 25.0379H28.8952L33.8516 38.5341C34.032 39.025 34.4963 39.3288 34.9909 39.3288C35.8181 39.3288 36.4246 38.5026 36.1286 37.6981L31.4792 25.0379H37.5892C39.6937 25.0379 41.4 23.3316 41.4 21.2271V6.93581C41.4 4.83126 39.6937 3.125 37.5892 3.125V3.125ZM34.9903 19.8417H29.5484C28.8788 19.8417 28.3355 19.2985 28.3355 18.6288C28.3355 17.9586 28.8788 17.4159 29.5484 17.4159H34.9903C35.6605 17.4159 36.2032 17.9586 36.2032 18.6288C36.2032 19.2985 35.6605 19.8417 34.9903 19.8417ZM34.9903 15.2943H29.5484C28.8788 15.2943 28.3355 14.7517 28.3355 14.0814C28.3355 13.4118 28.8788 12.8686 29.5484 12.8686H34.9903C35.6605 12.8686 36.2032 13.4118 36.2032 14.0814C36.2032 14.7517 35.6605 15.2943 34.9903 15.2943ZM34.9903 10.7476H21.9994C21.3292 10.7476 20.7865 10.2043 20.7865 9.53469C20.7865 8.86507 21.3292 8.32179 21.9994 8.32179H34.9903C35.6605 8.32179 36.2032 8.86507 36.2032 9.53469C36.2032 10.2043 35.6605 10.7476 34.9903 10.7476Z"
                          fill="#B2CDF0"
                        ></path>
                        <path
                          d="M21.3406 24.5177C21.5598 23.8755 21.5582 23.2047 21.3731 22.5925L24.3832 16.5729C24.6826 15.9737 24.4401 15.2451 23.8409 14.9456C23.242 14.6459 22.5133 14.8888 22.2136 15.488L19.6463 20.6219C18.1119 19.8578 15.7805 18.6986 13.9024 17.7647C12.1551 16.8957 10.9081 16.1165 8.78051 16.1165H7.7088C9.36547 16.1165 10.844 15.354 11.8118 14.1607C12.5503 13.2526 12.9918 12.0953 12.9918 10.8341C12.9918 7.91592 10.6267 5.55078 7.70817 5.55078C4.79092 5.55078 2.42578 7.91592 2.42578 10.8341C2.42578 12.2356 2.97158 13.5091 3.86198 14.4542C4.82471 15.4778 6.19206 16.1165 7.70786 16.1168C5.67563 16.1168 3.68889 16.9403 2.25964 18.3733C0.802593 19.8266 0 21.7628 0 23.8253V27.7227C0 29.4005 1.08939 30.8282 2.59824 31.3361V38.1156C2.59824 38.7856 3.1412 39.3285 3.81113 39.3285H11.6058C12.2758 39.3285 12.8187 38.7856 12.8187 38.1156V24.488C13.9688 25.0628 15.4795 25.8181 16.7019 26.4296C17.5588 26.8579 18.5601 26.8964 19.453 26.5332C20.3434 26.1668 21.0298 25.4375 21.3358 24.5319C21.3374 24.5272 21.339 24.5224 21.3406 24.5177Z"
                          fill="#B2CDF0"
                        ></path>
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect
                            width="41.4"
                            height="41.4"
                            fill="white"
                            transform="translate(0 0.537598)"
                          ></rect>
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  Courses
                </Link>
              </li>
              <li>
                <Link to="/admin/subjects">
                  <span>
                    <svg
                      width="42"
                      height="42"
                      viewBox="0 0 42 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0)">
                        <path
                          d="M37.5892 3.125H19.4005C17.2957 3.125 15.5897 4.83126 15.5897 6.93581V15.8951C16.5512 16.373 17.59 16.8897 18.5597 17.3717L20.0433 14.4033C20.9416 12.6083 23.1314 11.879 24.9258 12.7757C26.724 13.6756 27.4527 15.8594 26.5525 17.6579L23.9078 22.9485C23.9779 23.6488 23.9157 24.3547 23.7183 25.0379H25.4644L20.8592 37.7012C20.5859 38.4523 21.1141 39.3288 21.9994 39.3288C22.4944 39.3288 22.9599 39.0234 23.1387 38.53L28.0452 25.0379H28.8952L33.8516 38.5341C34.032 39.025 34.4963 39.3288 34.9909 39.3288C35.8181 39.3288 36.4246 38.5026 36.1286 37.6981L31.4792 25.0379H37.5892C39.6937 25.0379 41.4 23.3316 41.4 21.2271V6.93581C41.4 4.83126 39.6937 3.125 37.5892 3.125V3.125ZM34.9903 19.8417H29.5484C28.8788 19.8417 28.3355 19.2985 28.3355 18.6288C28.3355 17.9586 28.8788 17.4159 29.5484 17.4159H34.9903C35.6605 17.4159 36.2032 17.9586 36.2032 18.6288C36.2032 19.2985 35.6605 19.8417 34.9903 19.8417ZM34.9903 15.2943H29.5484C28.8788 15.2943 28.3355 14.7517 28.3355 14.0814C28.3355 13.4118 28.8788 12.8686 29.5484 12.8686H34.9903C35.6605 12.8686 36.2032 13.4118 36.2032 14.0814C36.2032 14.7517 35.6605 15.2943 34.9903 15.2943ZM34.9903 10.7476H21.9994C21.3292 10.7476 20.7865 10.2043 20.7865 9.53469C20.7865 8.86507 21.3292 8.32179 21.9994 8.32179H34.9903C35.6605 8.32179 36.2032 8.86507 36.2032 9.53469C36.2032 10.2043 35.6605 10.7476 34.9903 10.7476Z"
                          fill="#B2CDF0"
                        ></path>
                        <path
                          d="M21.3406 24.5177C21.5598 23.8755 21.5582 23.2047 21.3731 22.5925L24.3832 16.5729C24.6826 15.9737 24.4401 15.2451 23.8409 14.9456C23.242 14.6459 22.5133 14.8888 22.2136 15.488L19.6463 20.6219C18.1119 19.8578 15.7805 18.6986 13.9024 17.7647C12.1551 16.8957 10.9081 16.1165 8.78051 16.1165H7.7088C9.36547 16.1165 10.844 15.354 11.8118 14.1607C12.5503 13.2526 12.9918 12.0953 12.9918 10.8341C12.9918 7.91592 10.6267 5.55078 7.70817 5.55078C4.79092 5.55078 2.42578 7.91592 2.42578 10.8341C2.42578 12.2356 2.97158 13.5091 3.86198 14.4542C4.82471 15.4778 6.19206 16.1165 7.70786 16.1168C5.67563 16.1168 3.68889 16.9403 2.25964 18.3733C0.802593 19.8266 0 21.7628 0 23.8253V27.7227C0 29.4005 1.08939 30.8282 2.59824 31.3361V38.1156C2.59824 38.7856 3.1412 39.3285 3.81113 39.3285H11.6058C12.2758 39.3285 12.8187 38.7856 12.8187 38.1156V24.488C13.9688 25.0628 15.4795 25.8181 16.7019 26.4296C17.5588 26.8579 18.5601 26.8964 19.453 26.5332C20.3434 26.1668 21.0298 25.4375 21.3358 24.5319C21.3374 24.5272 21.339 24.5224 21.3406 24.5177Z"
                          fill="#B2CDF0"
                        ></path>
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect
                            width="41.4"
                            height="41.4"
                            fill="white"
                            transform="translate(0 0.537598)"
                          ></rect>
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  Subjects
                </Link>
              </li>
              <li>
                <Link to="/admin/students">
                  <span>
                    <svg
                      width="42"
                      height="42"
                      viewBox="0 0 42 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0)">
                        <path
                          d="M37.5892 3.125H19.4005C17.2957 3.125 15.5897 4.83126 15.5897 6.93581V15.8951C16.5512 16.373 17.59 16.8897 18.5597 17.3717L20.0433 14.4033C20.9416 12.6083 23.1314 11.879 24.9258 12.7757C26.724 13.6756 27.4527 15.8594 26.5525 17.6579L23.9078 22.9485C23.9779 23.6488 23.9157 24.3547 23.7183 25.0379H25.4644L20.8592 37.7012C20.5859 38.4523 21.1141 39.3288 21.9994 39.3288C22.4944 39.3288 22.9599 39.0234 23.1387 38.53L28.0452 25.0379H28.8952L33.8516 38.5341C34.032 39.025 34.4963 39.3288 34.9909 39.3288C35.8181 39.3288 36.4246 38.5026 36.1286 37.6981L31.4792 25.0379H37.5892C39.6937 25.0379 41.4 23.3316 41.4 21.2271V6.93581C41.4 4.83126 39.6937 3.125 37.5892 3.125V3.125ZM34.9903 19.8417H29.5484C28.8788 19.8417 28.3355 19.2985 28.3355 18.6288C28.3355 17.9586 28.8788 17.4159 29.5484 17.4159H34.9903C35.6605 17.4159 36.2032 17.9586 36.2032 18.6288C36.2032 19.2985 35.6605 19.8417 34.9903 19.8417ZM34.9903 15.2943H29.5484C28.8788 15.2943 28.3355 14.7517 28.3355 14.0814C28.3355 13.4118 28.8788 12.8686 29.5484 12.8686H34.9903C35.6605 12.8686 36.2032 13.4118 36.2032 14.0814C36.2032 14.7517 35.6605 15.2943 34.9903 15.2943ZM34.9903 10.7476H21.9994C21.3292 10.7476 20.7865 10.2043 20.7865 9.53469C20.7865 8.86507 21.3292 8.32179 21.9994 8.32179H34.9903C35.6605 8.32179 36.2032 8.86507 36.2032 9.53469C36.2032 10.2043 35.6605 10.7476 34.9903 10.7476Z"
                          fill="#B2CDF0"
                        ></path>
                        <path
                          d="M21.3406 24.5177C21.5598 23.8755 21.5582 23.2047 21.3731 22.5925L24.3832 16.5729C24.6826 15.9737 24.4401 15.2451 23.8409 14.9456C23.242 14.6459 22.5133 14.8888 22.2136 15.488L19.6463 20.6219C18.1119 19.8578 15.7805 18.6986 13.9024 17.7647C12.1551 16.8957 10.9081 16.1165 8.78051 16.1165H7.7088C9.36547 16.1165 10.844 15.354 11.8118 14.1607C12.5503 13.2526 12.9918 12.0953 12.9918 10.8341C12.9918 7.91592 10.6267 5.55078 7.70817 5.55078C4.79092 5.55078 2.42578 7.91592 2.42578 10.8341C2.42578 12.2356 2.97158 13.5091 3.86198 14.4542C4.82471 15.4778 6.19206 16.1165 7.70786 16.1168C5.67563 16.1168 3.68889 16.9403 2.25964 18.3733C0.802593 19.8266 0 21.7628 0 23.8253V27.7227C0 29.4005 1.08939 30.8282 2.59824 31.3361V38.1156C2.59824 38.7856 3.1412 39.3285 3.81113 39.3285H11.6058C12.2758 39.3285 12.8187 38.7856 12.8187 38.1156V24.488C13.9688 25.0628 15.4795 25.8181 16.7019 26.4296C17.5588 26.8579 18.5601 26.8964 19.453 26.5332C20.3434 26.1668 21.0298 25.4375 21.3358 24.5319C21.3374 24.5272 21.339 24.5224 21.3406 24.5177Z"
                          fill="#B2CDF0"
                        ></path>
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect
                            width="41.4"
                            height="41.4"
                            fill="white"
                            transform="translate(0 0.537598)"
                          ></rect>
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  Students
                </Link>
              </li>
              <li>
                <Link to="/admin/centers">
                  <span>
                    <svg
                      width="42"
                      height="42"
                      viewBox="0 0 42 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0)">
                        <path
                          d="M37.5892 3.125H19.4005C17.2957 3.125 15.5897 4.83126 15.5897 6.93581V15.8951C16.5512 16.373 17.59 16.8897 18.5597 17.3717L20.0433 14.4033C20.9416 12.6083 23.1314 11.879 24.9258 12.7757C26.724 13.6756 27.4527 15.8594 26.5525 17.6579L23.9078 22.9485C23.9779 23.6488 23.9157 24.3547 23.7183 25.0379H25.4644L20.8592 37.7012C20.5859 38.4523 21.1141 39.3288 21.9994 39.3288C22.4944 39.3288 22.9599 39.0234 23.1387 38.53L28.0452 25.0379H28.8952L33.8516 38.5341C34.032 39.025 34.4963 39.3288 34.9909 39.3288C35.8181 39.3288 36.4246 38.5026 36.1286 37.6981L31.4792 25.0379H37.5892C39.6937 25.0379 41.4 23.3316 41.4 21.2271V6.93581C41.4 4.83126 39.6937 3.125 37.5892 3.125V3.125ZM34.9903 19.8417H29.5484C28.8788 19.8417 28.3355 19.2985 28.3355 18.6288C28.3355 17.9586 28.8788 17.4159 29.5484 17.4159H34.9903C35.6605 17.4159 36.2032 17.9586 36.2032 18.6288C36.2032 19.2985 35.6605 19.8417 34.9903 19.8417ZM34.9903 15.2943H29.5484C28.8788 15.2943 28.3355 14.7517 28.3355 14.0814C28.3355 13.4118 28.8788 12.8686 29.5484 12.8686H34.9903C35.6605 12.8686 36.2032 13.4118 36.2032 14.0814C36.2032 14.7517 35.6605 15.2943 34.9903 15.2943ZM34.9903 10.7476H21.9994C21.3292 10.7476 20.7865 10.2043 20.7865 9.53469C20.7865 8.86507 21.3292 8.32179 21.9994 8.32179H34.9903C35.6605 8.32179 36.2032 8.86507 36.2032 9.53469C36.2032 10.2043 35.6605 10.7476 34.9903 10.7476Z"
                          fill="#B2CDF0"
                        ></path>
                        <path
                          d="M21.3406 24.5177C21.5598 23.8755 21.5582 23.2047 21.3731 22.5925L24.3832 16.5729C24.6826 15.9737 24.4401 15.2451 23.8409 14.9456C23.242 14.6459 22.5133 14.8888 22.2136 15.488L19.6463 20.6219C18.1119 19.8578 15.7805 18.6986 13.9024 17.7647C12.1551 16.8957 10.9081 16.1165 8.78051 16.1165H7.7088C9.36547 16.1165 10.844 15.354 11.8118 14.1607C12.5503 13.2526 12.9918 12.0953 12.9918 10.8341C12.9918 7.91592 10.6267 5.55078 7.70817 5.55078C4.79092 5.55078 2.42578 7.91592 2.42578 10.8341C2.42578 12.2356 2.97158 13.5091 3.86198 14.4542C4.82471 15.4778 6.19206 16.1165 7.70786 16.1168C5.67563 16.1168 3.68889 16.9403 2.25964 18.3733C0.802593 19.8266 0 21.7628 0 23.8253V27.7227C0 29.4005 1.08939 30.8282 2.59824 31.3361V38.1156C2.59824 38.7856 3.1412 39.3285 3.81113 39.3285H11.6058C12.2758 39.3285 12.8187 38.7856 12.8187 38.1156V24.488C13.9688 25.0628 15.4795 25.8181 16.7019 26.4296C17.5588 26.8579 18.5601 26.8964 19.453 26.5332C20.3434 26.1668 21.0298 25.4375 21.3358 24.5319C21.3374 24.5272 21.339 24.5224 21.3406 24.5177Z"
                          fill="#B2CDF0"
                        ></path>
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect
                            width="41.4"
                            height="41.4"
                            fill="white"
                            transform="translate(0 0.537598)"
                          ></rect>
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  Centers
                </Link>
              </li>
              <li>
                <a onClick={handleLogout} style={{ color: "#FFF" }}>
                  <span>
                    <svg
                      width="42"
                      height="42"
                      viewBox="0 0 1024 1024"
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon"
                    >
                      <path d="M868 732h-70.3c-4.8 0-9.3 2.1-12.3 5.8-7 8.5-14.5 16.7-22.4 24.5a353.84 353.84 0 0 1-112.7 75.9A352.8 352.8 0 0 1 512.4 866c-47.9 0-94.3-9.4-137.9-27.8a353.84 353.84 0 0 1-112.7-75.9 353.28 353.28 0 0 1-76-112.5C167.3 606.2 158 559.9 158 512s9.4-94.2 27.8-137.8c17.8-42.1 43.4-80 76-112.5s70.5-58.1 112.7-75.9c43.6-18.4 90-27.8 137.9-27.8 47.9 0 94.3 9.3 137.9 27.8 42.2 17.8 80.1 43.4 112.7 75.9 7.9 7.9 15.3 16.1 22.4 24.5 3 3.7 7.6 5.8 12.3 5.8H868c6.3 0 10.2-7 6.7-12.3C798 160.5 663.8 81.6 511.3 82 271.7 82.6 79.6 277.1 82 516.4 84.4 751.9 276.2 942 512.4 942c152.1 0 285.7-78.8 362.3-197.7 3.4-5.3-.4-12.3-6.7-12.3zm88.9-226.3L815 393.7c-5.3-4.2-13-.4-13 6.3v76H488c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h314v76c0 6.7 7.8 10.5 13 6.3l141.9-112a8 8 0 0 0 0-12.6z" />
                    </svg>
                  </span>
                  Logout
                </a>
              </li>
            </ul> */}
          </div>
        </div>
        {children}
      </div>
    </div>
  );
}
