import React, { useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { useDispatch } from "react-redux";
import { approveCenter } from "../../../../store/actions/councilActions/centerActions";

const ApproveCenter = ({ show, onHide, id }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    total_seats: "",
    center_code: "",
    password: "",
    id: id,
    payment_mode: "",
  });
  const { total_seats, center_code, password, payment_mode } = data;
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await dispatch(approveCenter(data, id));
    if (true) {
      onHide();
    }
  };
  return (
    <div>
      <Modal show={show} onHide={onHide} centered>
        <ModalHeader>Approve Center</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <div class="form-group">
              <label>
                Center Code <span class="text-danger">*</span>
              </label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter Center Code"
                name="center_code"
                value={center_code}
                required
                onChange={handleChange}
              />
            </div>
            <div class="form-group">
              <label>
                Center Password <span class="text-danger">*</span>
              </label>
              <input
                type="password"
                class="form-control"
                placeholder="Enter Center Password"
                name="password"
                value={password}
                required
                onChange={handleChange}
              />
            </div>
            <div class="form-group">
              <label>
                No of Seats <span class="text-danger">*</span>
              </label>
              <input
                type="number"
                class="form-control"
                placeholder="Enter Number of Seats"
                name="total_seats"
                value={total_seats}
                required
                onChange={handleChange}
              />
            </div>
            <div class="form-group">
              <label>
                Payment Method <span class="text-danger">*</span>
              </label>
              <select
                name={"payment_mode"}
                class="form-control"
                value={payment_mode}
                onChange={handleChange}
                required
              >
                <option value={""}>--Select--</option>
                <option value={"online"}>Online</option>
                <option value="offline">offline</option>
                <option value="both">Both (Online/Offline)</option>
              </select>
            </div>
            <button type="submit" class="btn btn-primary">
              Upload
            </button>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ApproveCenter;
