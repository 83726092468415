import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Spinner, Table } from "react-bootstrap";

import {
  downloadEnquiry,
  enquiry,
} from "../../../store/actions/councilActions/enquiryAction";
import BreadCrumb from "../../BreadCrumb";
import Pagination from "../../misc/Pagination";
const breadcrumbs = [
  {
    path: "/admin/dashboard",
    label: "Dashboard",
  },
  {
    label: "Affiliation Enquiry",
  },
];
const AffiliationEnquiry = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [activePage, setactivePage] = useState(1);
  const [totalPages, settotalPages] = useState(0);

  const fetchData = useCallback(async () => {
    const response = await dispatch(enquiry("affiliation", activePage));
    if (response) {
      setData(response.data);
      setLoading(false);
      settotalPages(Math.ceil(response.total / response.per_page));
    } else {
      setLoading(false);
    }
  }, [activePage, dispatch]);
  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [fetchData]);

  const handleDownload = () => {
    dispatch(downloadEnquiry("affiliation"));
  };

  return (
    <div>
      <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
        <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <BreadCrumb crumbs={breadcrumbs} />

          <div class="d-flex align-items-center">
            <button
              onClick={handleDownload}
              className="btn btn-light-primary font-weight-bolder btn-sm"
            >
              Download list
            </button>
            <Link
              to="/admin/enquiry/admission"
              class="btn btn-light-primary font-weight-bolder btn-sm"
            >
              Admission Enquiry
            </Link>
          </div>
        </div>
      </div>
      <div className="container">
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status" size="lg">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <Table bordered hover striped responsive className="mb-0">
            <thead>
              <th>S No</th>
              <th>Trade</th>
              <th>Name</th>
              <th>Email</th>
              <th>Mobile</th>
              <th>Address</th>
              <th>City</th>
              <th>State</th>
              <th>Message</th>
            </thead>
            <tbody>
              {data.length === 0 ? (
                <tr>
                  <td colSpan={9} className="text-center text-danger">
                    No Record Found
                  </td>
                </tr>
              ) : (
                data.map((e, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{e.trade?.name}</td>
                    <td>{e.name}</td>
                    <td>{e.email}</td>
                    <td>{e.mobile}</td>
                    <td>{e.address}</td>
                    <td>{e.city}</td>
                    <td>{e.state}</td>
                    <td>{e.message}</td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        )}
        {totalPages && Number(totalPages) !== 0 ? (
          <Pagination
            setActivePage={(value) => setactivePage(value)}
            totalPages={totalPages}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AffiliationEnquiry;
