import moment from "moment";
import React, { useCallback, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useLoadMore } from "../../Council/News/useLoadMore";

const Notifications = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, data, setData, hasMore] = useLoadMore(
    "admin/news/list/notification?page=",
    pageNumber
  );

  const observer = useRef();
  const lastDataElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );
  return (
    <>
      <div class="col-lg-3 col-md-12 col-sm-12 mb-2">
        <div class="NOtification">
          <h5>Notifications/ Press Release</h5>
          <div class="notification-items">
            {data.length > 0 ? (
              data.map((n, index) => {
                if (data.length === index + 1) {
                  return (
                    <div class="upboxs" ref={lastDataElementRef} key={n}>
                      <div class="upDate">
                        <h4>{moment(n.created_at).format("D")}</h4>
                        <p>{moment(n.created_at).format("MMM")} </p>
                      </div>
                      <div class="upData">
                        <Link to={`/news/${n.id}`}>
                          <h4>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: n.description,
                              }}
                            ></div>
                            {/* {n.description}{" "} */}
                            <img src="/images/BlinkingNew.gif" />
                          </h4>
                        </Link>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div class="upboxs">
                      <div class="upDate">
                        <h4>{moment(n.created_at).format("D")}</h4>
                        <p>{moment(n.created_at).format("MMM")} </p>
                      </div>
                      <div class="upData">
                        <Link to={`/news/${n.id}`}>
                          <h4>
                            <div
                              className="heading"
                              dangerouslySetInnerHTML={{
                                __html: n.description,
                              }}
                            ></div>
                            <img src="/images/BlinkingNew.gif" />
                          </h4>
                        </Link>
                      </div>
                    </div>
                  );
                }
              })
            ) : (
              <div></div>
            )}
            {!loading && data.length == 0 && <div>No Notification</div>}
            {loading && <div>Loading... </div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default Notifications;
