import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import SvgTypes from "../SVG/SvgTypes";
import Meta from "../Meta";
import "./css/sorry.css";

export default function SorryPage({ location }) {
  const [message, setMessage] = useState(null);
  const [title, setTitle] = useState(null);
  useEffect(() => {
    if (location.state) {
      const { message, title } = location.state;

      if (message) {
        setMessage(message);
      }

      if (title) {
        setTitle(title);
      }
    }
  }, [location.state]);
  return (
    <>
      <section className="sorry">
        <div>
          <SvgTypes name="404" />
          {!title && (
            <Fragment>
              <h1>404</h1>
              <h2>PAGE NOT FOUND</h2>
            </Fragment>
          )}
          {title && <h1>{title}</h1>}
          {!message && (
            <p>WE’RE SORRY, THE PAGE you requested is not available.</p>
          )}
          {message && <p>{message}</p>}
          <Link to="/">Home</Link>
        </div>
      </section>
    </>
  );
}
