import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import StateCitySelector from "../../misc/StateCitySelector";
import { ALERT } from "../../../store/actions/actionTypes";
import { centerRegisterAction } from "../../../store/actions/centerActions/centerAuth";
import { getTrades } from "../../../store/actions/councilActions/trade";
import BreadCrumb from "../../BreadCrumb";

import Profile from "../Profile/Profile";

const breadcrumbs = [
  {
    path: "/",
    label: "Home",
  },
  {
    // path: "/aboutus",
    label: "Center Register",
  },
];
const initialState = {
  md_name: "",
  mobile: "",
  email: "",
  md_qualification: "",
  md_adhar: "",
  md_pan: "",
  md_address: "",
  md_city: "",
  md_state: "",
  md_pin_code: "",
  md_dob: "",
  center_name: "",
  center_type: "",
  center_trade: "",
  center_subtrade: "",
  center_mobile: "",
  center_email: "",
  center_state: "",
  center_city: "",
  center_address: "",
  center_pincode: "",
};
export default function Register() {
  const dispatch = useDispatch();
  const [trades, setTrades] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [college_front_pic, setcollege_front_pic] = useState([]);
  const [classroom_pic, setclassroom_pic] = useState([]);
  const [lab_pic, setlab_pic] = useState([]);
  const [adhaar_pic, setadhaar_pic] = useState([]);
  const [md_sign_pic, setmd_sign_pic] = useState([]);
  const [office_pic, setoffice_pic] = useState([]);
  const [md_pic, setmd_pic] = useState([]);
  const [college_front_picpreview, setcollege_front_picpreview] =
    useState(null);
  const [classroom_picpreview, setclassroom_picpreview] = useState(null);
  const [lab_picpreview, setlab_picpreview] = useState(null);
  const [adhaar_picpreview, setadhaar_picpreview] = useState(null);
  const [md_sign_picpreview, setmd_sign_picpreview] = useState(null);
  const [office_picpreview, setoffice_picpreview] = useState(null);
  const [md_picpreview, setmd_picpreview] = useState(null);
  const [data, setdata] = useState(initialState);
  const subtraderef = useRef();
  const {
    md_name,
    center_subtrade,
    mobile,
    email,
    md_qualification,
    md_adhar,
    md_pan,
    md_address,
    md_city,
    md_state,
    md_pin_code,
    md_dob,
    center_name,
    center_type,
    center_trade,
    center_mobile,
    center_email,
    center_state,
    center_city,
    center_address,
    center_pincode,
  } = data;
  useEffect(async () => {
    const response = await dispatch(getTrades());
    setTrades(response || []);
  }, []);
  const [subtrades, setSubtrades] = useState([]);
  const [subtrade, setSubtrade] = useState([]);
  const selectTrade = (e) => {
    setSubtrade([]);
    setdata({
      ...data,
      center_trade: e.target.value,
    });
    const myTrades = trades.find((trade) => trade.id == e.target.value);
    setSubtrades(myTrades.subtrade);
  };
  const [staffDetail, setStaffDetail] = useState([
    { name: "", qualification: "", designation: "", date_of_joining: "" },
  ]);
  const [facilityDetail, setfacilityDetail] = useState([
    { name: "", number: "", carpet_area: "", seat_capicity: "" },
  ]);
  const handlefacilityDetailChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...facilityDetail];
    list[index][name] = value;
    setfacilityDetail(list);
  };
  // handle click event of the Remove button
  const handlefacilityRemoveClick = (index) => {
    const list = [...facilityDetail];
    list.splice(index, 1);
    setfacilityDetail(list);
  };

  // handle click event of the Add button
  const handlefacilityAddClick = () => {
    setfacilityDetail([
      ...facilityDetail,
      { name: "", number: "", carpet_area: "", seat_capicity: "" },
    ]);
  };
  const handleStaffDetailChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...staffDetail];
    list[index][name] = value;
    setStaffDetail(list);
  };
  // handle click event of the Remove button
  const handleStaffRemoveClick = (index) => {
    const list = [...staffDetail];
    list.splice(index, 1);
    setStaffDetail(list);
  };

  // handle click event of the Add button
  const handleStaffAddClick = () => {
    setStaffDetail([
      ...staffDetail,
      { name: "", qualification: "", designation: "", date_of_joining: "" },
    ]);
  };
  const handleChange = (e) => {
    setdata({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!center_subtrade) {
      dispatch({
        type: ALERT,
        payload: { error: "Please select subtrade" },
      });
      subtraderef.current.scrollIntoView();
      return;
    }
    var formdata = new FormData();

    for (let [key, value] of Object.entries(data)) {
      if (Array.isArray(value)) {
        formdata.append(key, JSON.stringify(value));
      } else {
        formdata.append(key, value);
      }
    }
    formdata.append("staff", JSON.stringify(staffDetail));
    formdata.append("facility", JSON.stringify(facilityDetail));
    formdata.append("college_front_pic", college_front_pic);
    formdata.append("classroom_pic", classroom_pic);
    formdata.append("lab_pic", lab_pic);
    formdata.append("adhaar_pic", adhaar_pic);
    formdata.append("md_sign_pic", md_sign_pic);
    formdata.append("office_pic", office_pic);
    formdata.append("md_pic", md_pic);
    setSubmitting(true);
    const response = await dispatch(centerRegisterAction(formdata));
    if (response) {
      setdata(initialState);
      setfacilityDetail([]);
      setStaffDetail([]);
      setSubmitting(false);
    } else {
      setSubmitting(false);
    }

    // dispatch(centerRegisterAction(formdata));
  };

  const selectcenter_subtrade = (e) => {
    if (center_subtrade.split(",").includes(e.target.value)) {
      const checkedSubtrades = center_subtrade
        .split(",")
        .filter((id) => id != e.target.value);
      setdata({
        ...data,
        center_subtrade: checkedSubtrades.join(","),
      });
    } else {
      setdata({
        ...data,
        center_subtrade:
          center_subtrade == ""
            ? e.target.value
            : [...center_subtrade.split(","), e.target.value].join(","),
      });
    }
  };
  return (
    <>
      <div class="lists-box">
        <div class="container">
          <BreadCrumb crumbs={breadcrumbs} />
          <div class="title-box">
            <h4>Center Register</h4>
            <h6> Application Form</h6>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-9">
                <div className="row">
                  <>
                    <div className="col-md-12">
                      <div className="formTitle">MD Details</div>
                    </div>
                    <div className="col-md-6">
                      <div className="boxlabel">
                        <p>Md Name</p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="md_name"
                          value={md_name}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="boxlabel">
                        <p> Mobile no</p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="mobile"
                          value={mobile}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Md email</p>
                        <input
                          type="email"
                          className="form-control form-control-sm"
                          name="email"
                          value={email}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Mid Qualification</p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="md_qualification"
                          value={md_qualification}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Md DOB</p>
                        <input
                          type="date"
                          className="form-control form-control-sm"
                          name="md_dob"
                          value={md_dob}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Md Addhar Card Number</p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="md_adhar"
                          value={md_adhar}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Md Pan Number</p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="md_pan"
                          value={md_pan}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="formTitle">Md Address</div>
                    </div>
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Address </p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="md_address"
                          value={md_address}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <StateCitySelector
                      user_city={md_city}
                      user_state={md_state}
                      set_user_state={(e) => setdata({ ...data, md_state: e })}
                      set_user_city={(e) => setdata({ ...data, md_city: e })}
                    />
                    {/* <div className="col-md-4">
                      <div className="boxlabel">
                        <p>City </p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="md_city"
                          value={md_city}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>State </p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="md_state"
                          value={md_state}
                          onChange={handleChange}
                        />
                      </div>
                    </div> */}
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Pincode </p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="md_pin_code"
                          value={md_pin_code}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </>
                  <>
                    <div className="col-md-12">
                      <div className="formTitle">Center Details</div>
                    </div>
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Center Name </p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="center_name"
                          value={center_name}
                          onChange={handleChange}
                          readonly
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div
                        className="boxlabel"
                        value={center_type}
                        onChange={handleChange}
                      >
                        <p>Type</p>
                        <label className="Radioc">
                          Rented
                          <input
                            type="radio"
                            name="center_type"
                            value={"rented"}
                            checked={center_type == "rented"}
                            // onChange={handleChange}
                            required
                          />
                          <span className="checC"></span>
                        </label>
                        <label className="Radioc">
                          Own
                          <input
                            type="radio"
                            name="center_type"
                            value={"own"}
                            checked={center_type == "own"}
                            // onChange={handleChange}
                          />
                          <span className="checC"></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Center Trade </p>
                        <select
                          className="form-control form-control-sm"
                          name="center_trade"
                          id="example-getting-started"
                          onChange={selectTrade}
                          value={center_trade}
                          required
                        >
                          <option value={""} selected>
                            Select trade
                          </option>
                          {trades == null ? (
                            <option disabled value="">
                              {" "}
                              Loading...
                            </option>
                          ) : trades.length > 0 ? (
                            trades.map((trade, index) => (
                              <option
                                key={index}
                                value={trade.id}
                                selected={center_trade == trade.id}
                              >
                                {trade.name}
                              </option>
                            ))
                          ) : (
                            <option value={""} disabled>
                              No Trade Found
                            </option>
                          )}
                        </select>
                      </div>
                    </div>
                    {center_trade && (
                      <div className="col-md-4">
                        <div className="boxlabel" ref={subtraderef}>
                          <p>Center Sub Trade </p>
                          {center_trade == "" ? (
                            <option value={""} disabled>
                              {" "}
                              Select trade first
                            </option>
                          ) : (
                            <>
                              {subtrades.length > 0 ? (
                                subtrades.map((trade, index) => (
                                  <label>
                                    <input
                                      type={"checkbox"}
                                      onChange={selectcenter_subtrade}
                                      value={trade.id}
                                    ></input>
                                    {trade.name}
                                  </label>
                                ))
                              ) : (
                                <option value={""} disabled>
                                  No Sub Trade Found
                                </option>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Contact </p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          value={center_mobile}
                          name="center_mobile"
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Email </p>
                        <input
                          type="email"
                          className="form-control form-control-sm"
                          name="center_email"
                          value={center_email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      {" "}
                      <div className="formTitle">Center Address</div>
                    </div>
                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Address</p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          value={center_address}
                          name="center_address"
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <StateCitySelector
                      user_city={center_city}
                      user_state={center_state}
                      set_user_state={(e) =>
                        setdata({ ...data, center_state: e })
                      }
                      set_user_city={(e) =>
                        setdata({ ...data, center_city: e })
                      }
                    />

                    <div className="col-md-4">
                      <div className="boxlabel">
                        <p>Pincode</p>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          value={center_pincode}
                          name="center_pincode"
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                  </>
                  <>
                    <div class="col-md-12">
                      {" "}
                      <div className="container-fluid">
                        <div className="row mb-3">
                          <div className="col-sm-8 col-8">
                            <h6 class="">Details of the Staff</h6>
                          </div>
                          <div className="col-sm-4 col-4">
                            <button
                              class="btn btn-success btn-sm btn-add"
                              type="button"
                              id="addstaff"
                              onClick={handleStaffAddClick}
                            >
                              Add+
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="table-responsive">
                        <table class="table">
                          <thead class="thead-info">
                            <tr>
                              <th scope="col">Name</th>
                              <th scope="col">Qualification</th>
                              <th scope="col">Designation</th>
                              <th scope="col">Date of Joining</th>
                              {staffDetail.length !== 1 && (
                                <th scope="col">Delete</th>
                              )}
                            </tr>
                          </thead>
                          <tbody class="appendstaf">
                            {staffDetail.map((staff, index) => (
                              <>
                                <tr>
                                  <td>
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="Ex Rahul"
                                      name="name"
                                      value={staff.name}
                                      onChange={(e) =>
                                        handleStaffDetailChange(e, index)
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="ex.MBA"
                                      name="qualification"
                                      value={staff.qualification}
                                      onChange={(e) =>
                                        handleStaffDetailChange(e, index)
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="ex. Manager"
                                      name="designation"
                                      value={staff.designation}
                                      onChange={(e) =>
                                        handleStaffDetailChange(e, index)
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="date"
                                      class="form-control"
                                      placeholder="ex. 12/02/2020"
                                      name="date_of_joining"
                                      value={staff.date_of_joining}
                                      onChange={(e) =>
                                        handleStaffDetailChange(e, index)
                                      }
                                    />
                                  </td>
                                  {staffDetail.length !== 1 && (
                                    <td>
                                      <button
                                        class="btn btn-danger btn-sm btn-add"
                                        type="button"
                                        onClick={() =>
                                          handleStaffRemoveClick(index)
                                        }
                                      >
                                        Remove
                                      </button>
                                    </td>
                                  )}
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                  <>
                    <div class="col-md-12">
                      {" "}
                      <div className="container-fluid">
                        <div className="row mb-4">
                          <div className="col-sm-8 col-6">
                            <h6>Details of the Facility</h6>
                          </div>
                          <div className="col-sm-4 col-6">
                            <button
                              class="btn btn-success btn-sm btn-add"
                              type="button"
                              id="addfacillity"
                              onClick={handlefacilityAddClick}
                            >
                              Add More<small className="small">Facility</small>+
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 mb-4">
                      {" "}
                      <div class="table-responsive">
                        <table class="table">
                          <thead class="thead-info">
                            <tr>
                              <th scope="col">Name of Facility</th>
                              <th scope="col">Number of Facility</th>
                              <th scope="col">Carpet area</th>
                              <th scope="col">Capacity of seat</th>
                              {facilityDetail.length !== 1 && (
                                <th scope="col">Delete</th>
                              )}
                            </tr>
                          </thead>
                          <tbody class="appendfacility">
                            {facilityDetail.map((facility, index) => (
                              <>
                                <tr>
                                  <td>
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="Ex Lab"
                                      name="name"
                                      value={facility.name}
                                      onChange={(e) =>
                                        handlefacilityDetailChange(e, index)
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="ex.1"
                                      name="number"
                                      value={facility.number}
                                      onChange={(e) =>
                                        handlefacilityDetailChange(e, index)
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="ex. 200 ft"
                                      name="carpet_area"
                                      value={facility.carpet_area}
                                      onChange={(e) =>
                                        handlefacilityDetailChange(e, index)
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="ex. 20"
                                      name="seat_capicity"
                                      value={facility.seat_capicity}
                                      onChange={(e) =>
                                        handlefacilityDetailChange(e, index)
                                      }
                                    />
                                  </td>
                                  {facilityDetail.length !== 1 && (
                                    <td>
                                      <button
                                        class="btn btn-danger btn-sm btn-add"
                                        type="button"
                                        onClick={() =>
                                          handlefacilityRemoveClick(index)
                                        }
                                      >
                                        Remove
                                      </button>
                                    </td>
                                  )}
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>{" "}
                      </div>
                    </div>
                  </>
                  <div className="col-md-4 col-12">
                    <div className="Fileboxbale">
                      <div className="custom-file">
                        <input type="hidden" value="" name="frontphoto_old" />
                        <input
                          type="file"
                          className="custom-file-input"
                          id="customFiler7"
                          name="frontphoto"
                          onChange={(e) => {
                            setcollege_front_pic(e.target.files[0]);
                            setcollege_front_picpreview(
                              URL.createObjectURL(e.target.files[0])
                            );
                          }}
                        />
                        <label className="custom-file2" for="customFiler7">
                          {college_front_picpreview ? (
                            <img
                              src={
                                college_front_picpreview
                                  ? college_front_picpreview
                                  : ""
                              }
                              height={"100px"}
                              id="frontphoto"
                            />
                          ) : (
                            <>
                              <img
                                src="/images/dummy.png"
                                height={"100px"}
                              ></img>
                              {/* <p>drag n drop photo here</p> */}
                            </>
                          )}
                          <h4>College Front photo</h4>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-12">
                    <div className="Fileboxbale">
                      <div className="custom-file">
                        <input
                          type="hidden"
                          value="officephotopathology-laboratory-500x500.jpg"
                          name="officephoto_old"
                        />
                        <input
                          type="file"
                          className="custom-file-input"
                          id="customFiler"
                          name="officephoto"
                          onChange={(e) => {
                            setoffice_pic(e.target.files[0]);
                            setoffice_picpreview(
                              URL.createObjectURL(e.target.files[0])
                            );
                          }}
                        />
                        <label className="custom-file2" for="customFiler">
                          {office_picpreview ? (
                            <img
                              src={office_picpreview}
                              height={"100px"}
                              id="frontphoto"
                            />
                          ) : (
                            <>
                              <img
                                src="/images/dummy.png"
                                height={"100px"}
                              ></img>
                              {/* <p>drag n drop photo here</p> */}
                            </>
                          )}
                          <h4>Office photo</h4>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-12">
                    <div className="Fileboxbale">
                      <div className="custom-file">
                        <input type="hidden" value="" name="labphoto_old" />
                        <input
                          type="file"
                          className="custom-file-input"
                          id="customFilee"
                          name="labphoto"
                          onChange={(e) => {
                            setlab_pic(e.target.files[0]);
                            setlab_picpreview(
                              URL.createObjectURL(e.target.files[0])
                            );
                          }}
                        />
                        <label className="custom-file2" for="customFilee">
                          {lab_picpreview ? (
                            <img
                              src={lab_picpreview}
                              height={"100px"}
                              id="frontphoto"
                            />
                          ) : (
                            <>
                              <img
                                src="/images/dummy.png"
                                height={"100px"}
                              ></img>
                              {/* <p>drag n drop photo here</p> */}
                            </>
                          )}
                          <h4>Lab photo</h4>
                        </label>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="col-md-4 col-12">
                    <div className="Fileboxbale">
                      <div className="custom-file">
                        <input type="hidden" value="" name="classphoto_old" />
                        <input
                          type="file"
                          className="custom-file-input"
                          id="customFilet"
                          name="classphoto"
                          onChange={(e) => {
                            setclassroom_pic(e.target.files[0]);
                            setclassroom_picpreview(
                              URL.createObjectURL(e.target.files[0])
                            );
                          }}
                        />
                        <label className="custom-file2" for="customFilet">
                          {classroom_picpreview ? (
                            <img
                              src={classroom_picpreview}
                              height={"100px"}
                              id="frontphoto"
                            />
                          ) : (
                            <>
                              <img
                                src="/images/dummy.png"
                                height={"100px"}
                              ></img>
                              {/* <p>drag n drop photo here</p> */}
                            </>
                          )}
                          <h4>Classromm photo</h4>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-12">
                    <div className="Fileboxbale disabled">
                      <div className="custom-file">
                        <input type="hidden" value="" name="adharphoto_old" />
                        <input
                          type="file"
                          className="custom-file-input"
                          id="customFiley"
                          name="adharphoto"
                          onChange={(e) => {
                            setadhaar_pic(e.target.files[0]);
                            setadhaar_picpreview(
                              URL.createObjectURL(e.target.files[0])
                            );
                          }}
                        />
                        <label className="custom-file2" for="customFiley">
                          {adhaar_picpreview ? (
                            <img
                              src={adhaar_picpreview}
                              height={"100px"}
                              id="frontphoto"
                            />
                          ) : (
                            <>
                              <img
                                src="/images/dummy.png"
                                height={"100px"}
                              ></img>
                              {/* <p>drag n drop photo here</p> */}
                            </>
                          )}
                          <h4>Aadhar card</h4>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-12">
                    <div className="Fileboxbale disabled">
                      <div className="custom-file">
                        <input type="hidden" value="" name="signphoto_old" />
                        <input
                          type="file"
                          className="custom-file-input"
                          id="customFileu"
                          name="signphoto"
                          onChange={(e) => {
                            setmd_sign_pic(e.target.files[0]);
                            setmd_sign_picpreview(
                              URL.createObjectURL(e.target.files[0])
                            );
                          }}
                        />
                        <label className="custom-file2" for="customFileu">
                          {md_sign_picpreview ? (
                            <img
                              src={md_sign_picpreview}
                              height={"100px"}
                              id="frontphoto"
                            />
                          ) : (
                            <>
                              <img
                                src="/images/dummy.png"
                                height={"100px"}
                              ></img>
                              {/* <p>drag n drop photo here</p> */}
                            </>
                          )}
                          <h4>Md Signature photo</h4>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-12">
                <div className="Fileboxbale">
                  <div className="custom-file">
                    <input type="hidden" value={md_pic} name="mdphoto_old" />
                    <input
                      type="file"
                      className="custom-file-input"
                      id="customFileddf"
                      name="mdphoto"
                      onChange={(e) => {
                        setmd_pic(e.target.files[0]);
                        setmd_picpreview(
                          URL.createObjectURL(e.target.files[0])
                        );
                      }}
                    />
                    <label className="custom-file2" for="customFileddf">
                      {md_picpreview ? (
                        <img
                          src={md_picpreview}
                          height={"100px"}
                          id="frontphoto"
                        />
                      ) : (
                        <>
                          <img src="/images/dummy.png" height={"100px"}></img>
                          {/* <p>drag n drop photo here</p> */}
                        </>
                      )}
                      <h4>Md Photo</h4>
                      {/* <p>drag n drop photo here</p> */}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div
                  className="btn-groups mt-4 text-center"
                  role="group"
                  aria-label="First group"
                >
                  <button
                    className="btn btn-Next"
                    type="submit"
                    disabled={submitting}
                  >
                    Submit
                    <span>
                      <svg
                        width="35"
                        height="12"
                        viewBox="0 0 35 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M34.8403 6.00003C34.8403 6.2151 34.7582 6.43013 34.5943 6.5941L29.4346 11.7538C29.1063 12.0821 28.5742 12.0821 28.2461 11.7538C27.918 11.4257 27.918 10.8937 28.2461 10.5654L32.8117 6.00003L28.2462 1.43461C27.9181 1.10639 27.9181 0.574383 28.2462 0.246316C28.5743 -0.0820704 29.1065 -0.0820704 29.4347 0.246316L34.5945 5.40596C34.7584 5.57001 34.8403 5.78505 34.8403 6.00003Z"
                          fill="white"
                        ></path>
                        <line
                          x1="33.25"
                          y1="5.94995"
                          x2="0.75"
                          y2="5.94995"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                        ></line>
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
