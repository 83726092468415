import React from "react";

const Activities = () => {
  return (
    <div>
      <div class="gallery-box">
        <div class="container">
          <div class="title-box">
            <h4>Our Activity</h4>
          </div>

          <div class="row">
            {" <%photos.forEach((item,index)=>{%>   "}
            <div class="col-md-3">
              <div class="galleryBox">
                <a
                  href={"/images/logo.png"}
                  data-fancybox
                  data-caption="<%=item.title%>"
                >
                  <img src={"/images/logo.png"} />
                </a>
                <h1>{"<%=item.title%>"}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Activities;
