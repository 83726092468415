import React, { useEffect, useState } from "react";
import BreadCrumb from "../../BreadCrumb";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  addBody,
  getBodyDetail,
  updateBody,
} from "../../../store/actions/adminActions/bodyAction";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
const GoverningBody = () => {
  const dispatch = useDispatch();
  const [exist, setExist] = useState(true);
  const history = useHistory();
  const { id } = useParams();
  const [data, setData] = useState({
    name: "",
    email: "",
    designation: "",
    mobile: "",
    body_type: "0",
  });
  useEffect(async () => {
    if (id) {
      const response = await dispatch(getBodyDetail(id));
      if (response.success) {
        setData({
          name: response.data.name,
          email: response.data.email,
          designation: response.data.designation,
          mobile: response.data.mobile,
          body_type: response.data.body_type,
        });
      } else {
        setExist(false);
      }
    }
  }, [id]);
  const { name, email, designation, mobile, body_type } = data;
  const breadcrumbs = [
    {
      path: "/admin/dashboard",
      label: "Dashboard",
    },
    {
      // path: "/aboutus",
      label: "Add Bodies",
    },
  ];
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (id) {
      const response = await dispatch(updateBody({ body_id: id, ...data }));
      if (response) {
        history.push("/admin/view-body-list");
      }
    } else {
      const response = await dispatch(addBody(data));
      if (response) {
        history.push("/admin/view-body-list");
      }
    }
  };
  return (
    <div>
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
          <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <BreadCrumb crumbs={breadcrumbs} />

            <div class="d-flex align-items-center">
              <Link
                to={"/admin/view-body-list"}
                class="btn btn-light-primary font-weight-bolder btn-sm"
              >
                View List
              </Link>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-2"></div>

            <div class="col-md-8">
              {exist ? (
                <form onSubmit={handleSubmit}>
                  <div class="form-group">
                    <label>
                      {" "}
                      Select Body Type <span class="text-danger">*</span>
                    </label>
                    <select
                      class="form-control"
                      name="body_type"
                      onChange={handleChange}
                      value={body_type}
                      required
                    >
                      <option value={"0"}>Governing Body</option>
                      <option value={"1"}>General Body</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label>
                      Name <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Name "
                      name="name"
                      value={name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label>
                      Email <span class="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Enter Email "
                      name="email"
                      value={email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label>
                      mobile <span class="text-danger">*</span>
                    </label>
                    <input
                      type="tel"
                      class="form-control"
                      placeholder="Enter Mobile "
                      name="mobile"
                      value={mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label>
                      Designation <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Designation "
                      name="designation"
                      value={designation}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <button type="submit" class="btn btn-primary mr-2">
                    Submit
                  </button>
                  {/* <button type="reset" class="btn btn-secondary">
                  Cancel
                </button> */}
                </form>
              ) : (
                <h1>Record does not exist</h1>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoverningBody;
