import {
  deleteDataAPI,
  getDataAPI,
  postDataAPI,
} from "../../../services/apiWrapper";
import { exportToCSV } from "../../../utils/exportCsv";
import { ALERT } from "../actionTypes";
export const addBody = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(
      postDataAPI("admin/governing/create", data, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });
    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};

export const updateBody = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(
      postDataAPI("admin/governing/edit", data, token)
    );
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });
    return true;
  } catch (error) {
    dispatch({ type: ALERT, payload: { loading: false } });
    if (error.response?.data?.message == "Validation errors") {
      Object.entries(error.response.data.data).map((d) => {
        dispatch({
          type: ALERT,
          payload: { error: d[1][0] },
        });
      });
    }
    return false;
  }
};

export const getBodyList = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");

    const response = await dispatch(getDataAPI(`admin/governing/list`, token));

    return {
      data: response.data.data || [],
    };
  } catch (error) {
    // dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    return {
      data: [],
    };
  }
};
export const getGeneralBodyList = (id, page) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");

    const response = await dispatch(
      getDataAPI(`admin/governing/list/${id}?page=${page}`, token)
    );

    return response.data.data;
  } catch (error) {
    // dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    return false;
  }
};

export const downloadBodies = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");

    const response = await dispatch(
      getDataAPI(`admin/governing/list/${id}`, token)
    );
    const dataToExport = [];
    response.data.data.forEach((ele, index) => {
      dataToExport.push({
        Sno: index + 1,
        Name: ele.name,
        Email: ele.email,
        Mobile: ele.mobile,
        Designation: ele.designation,
      });
    });
    exportToCSV(dataToExport, "Bodies");
  } catch (error) {
    // dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    return false;
  }
};

export const getBodyDetail = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem("adminToken");
    const response = await dispatch(
      getDataAPI(`admin/governing/edit/${id}`, token)
      
    );

    return {
      data: response.data.data,
      success: true,
    };
  } catch (error) {
    // dispatch({ type: ALERT, payload: { error: error.response.data.message } });
    return {
      data: false,
      success: false,
    };
  }
};
