import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Loader from "./../Alert/Loader";
import { getCourses } from "../../store/actions/councilActions/courseAction";
import "./css/course.css";

const Courses = () => {
  const { id } = useParams();
  const [list, setList] = useState(null);
  const dispatch = useDispatch();
  useEffect(async () => {
    const response = await dispatch(getCourses(id));
    if (response) {
      setList(response);
    } else {
      setList([]);
    }
  }, [id]);
  return (
    <div>
      {list === null ? (
        <Loader active={true} />
      ) : (
        <div className="container">
          <div className="row">
            {list.length > 0 ? (
              list.map((element, index) => {
                return (
                  <div
                    key={element.id}
                    className="col-12 col-sm-6 col-md-3 my-3"
                  >
                    <div className="card">
                      <Link to={`/course/${element.id}`}>
                        <img
                          src={element.file}
                          className="card-img-top"
                          alt={element.name}
                          height="200px"
                        />
                        {/* <div className="card-body">
                          <h5 className="card-title">{element.name}</h5>
                          <p className="card-text">
                            <small className="text-muted">
                              Duration : {element.duration}
                            </small>
                          </p>
                        </div> */}
                      </Link>
                    </div>
                  </div>
                );
              })
            ) : (
              <h1>No Course Found</h1>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Courses;
