import {
  AUTH_FAIL,
  ADMIN_AUTH_SUCCESS,
  SET_TRADE,
  SET_CENTER_LIST,
  SET_SUB_TRADE,
  SET_COURSE,
  SET_SUBJECT,
  SET_TRADE_COURSE,
  SET_SUBJECTS,
  ASSIGNMENT_LIST,
  GET_ASSIGNMENT_QUESTIONS,
  CLEAR_ASSIGNMENT_QUESTION,
  GET_SCHEDULE,
} from "../../actions/actionTypes";

const initialState = {
  adminProfile: {},
  adminAuth: {
    isAuth: false,
    profile: null,
    loading: true,
    token: null,
  },
  trades: null,
};

export const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_AUTH_SUCCESS:
      return {
        ...state,
        adminAuth: {
          isAuth: true,
          profile: action.payload,
          loading: false,
        },
      };
    case AUTH_FAIL:
      return {
        ...state,
        adminProfile: {},
        adminAuth: {
          isAuth: false,
          profile: null,
          loading: false,
        },
      };
    case SET_TRADE:
      return {
        ...state,
        trades: action.payload,
      };
    case SET_SUB_TRADE:
      return {
        ...state,
        subtrades: action.payload,
      };
    case SET_COURSE:
      return {
        ...state,
        courses: action.payload,
      };
    case SET_TRADE_COURSE:
      return {
        ...state,
        tradeCourses: action.payload,
      };
    case SET_SUBJECTS:
      return {
        ...state,
        courseSubjects: action.payload,
      };
    case SET_SUBJECT:
      return {
        ...state,
        subjects: action.payload,
      };
    case SET_CENTER_LIST:
      return {
        ...state,
        centerlist: action.payload,
      };
    case ASSIGNMENT_LIST:
      return {
        ...state,
        assignments: action.payload,
      };
    case GET_ASSIGNMENT_QUESTIONS:
      return {
        ...state,
        questions: action.payload,
      };
    case CLEAR_ASSIGNMENT_QUESTION:
      return {
        ...state,
        questions: null,
      };
    case GET_SCHEDULE:
      return {
        ...state,
        schedule: action.payload,
      };
    default:
      return state;
  }
};
