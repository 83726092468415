import { combineReducers } from "redux";
import { globalReducer } from "./globalReducer";
import { adminReducer } from "./adminReducers/adminReducer";
import { helpdeskReducer } from "./helpdeskReducers/adminReducer";
import { alertReducer } from "./alertReducer";
import centerReducer from "./centerReducers/centerReducer";
import studentReducer from "./studentReducers/studentReducer";
import memberReducer from "./memberReducers/memberReducer";

const rootReducer = combineReducers({
  center: centerReducer,
  alert: alertReducer,
  admin: adminReducer,
  global: globalReducer,
  student: studentReducer,
  helpdesk: helpdeskReducer,
  member:memberReducer,
});

export default rootReducer;
