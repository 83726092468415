import { getDataAPI, postDataAPI } from "../../../services/apiWrapper";
import { ALERT, GET_SCHEDULE } from "../actionTypes";

export const addExamSchedule = (data) => async (dispatch) => {
  try {
    dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(
      postDataAPI("admin/date-sheet", data, localStorage.getItem("adminToken"))
    );
    dispatch({
      type: ALERT,
      payload: { loading: false, success: response.data.message },
    });
    return true;
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: { loading: false, error: error.message },
    });
    return false;
  }
};

export const getExamSchedule = () => async (dispatch) => {
  try {
    const response = await dispatch(
      getDataAPI("admin/date-sheet", localStorage.getItem("adminToken"))
    );
    dispatch({ type: GET_SCHEDULE, payload: response.data.data });
  } catch (error) {
    dispatch({
      type: ALERT,
      payload: { loading: true, error: error.message },
    });
    dispatch({ type: GET_SCHEDULE, payload: [] });
  }
};
